import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
// import MultiSelect from '../MultiSelect';
import ReactSelect from 'react-select';
import { fillRange } from '../../utils';

type Inputs = {
  utility_id: any;
  datayear: string;
  // bm_reportname: string;
  user_role: string;
  bmr_comments: string;
};

type IFormProps = {
  initialValues?: any;
  utilities?: any;
  roles?: any;
  onEdit: any;
  onAdd: any;
};

export default function BenchmarkForm(props: IFormProps) {
  console.log(props);

  const { utilities, roles } = props;
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);
  const schema = yup.object().shape({
    // utility_id: yup.string().required('Field is required'),
    utility_id: yup
      .array(
        yup.object({
          label: yup.string().required('Field is required'),
          value: yup.string().nullable(),
        }),
      )
      .min(1, 'At least one utility is required')
      .required('Field is required'),
    datayear: yup.string().required('Field is required'),
    // bm_reportname: yup.string().required("Field is required"),
    user_role: yup.string().required('Field is required'),
    bmr_comments: yup.string().required('Field is required'),
  });
  // const utilities: any[] = props.initialValues[0];
  // const yearFilters: any[] = props.initialValues[1];
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    if (props.initialValues) {
      const role = roles.find(
        (role: any) => role.user_role_id === props.initialValues.user_role_id,
      );
      console.log(role.role);

      const utility = utilities.find(
        (utility: any) => utility.utility_id === props.initialValues.utility_id,
      );

      reset({
        ...props.initialValues,
        utility_id: utility
          ? [
              {
                label: utility.utility_name,
                value: utility.utility_id,
              },
            ]
          : [],
        user_role: props.initialValues.user_role,
      });
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (Object.values(errors).length < 1) {
      // console.log('no errors', data);
      const utilityArray =
        data.utility_id[0].label === 'Select All'
          ? utilities.map((utility: any) => ({
              value: utility.utility_id,
              label: utility.utility_name,
            }))
          : data.utility_id;
      // console.log(utilityArray);
      const utilityInfo = utilityArray.map((option: any) => {
        const { code, utility_id, utility_name } = utilities.find(
          (utility: any) => utility.utility_id === option.value,
        );
        return { code, utility_id, utility_name };
      });

      //   console.log(selected);

      //   const selectedUtilities = utilities
      //     .filter((utility: any) =>
      //       data.utility_id[0].label === 'Select All'
      //         ? true
      //         : data.utility_id
      //             .map((id: any) => id.value)
      //             .includes(utility.utility_id),
      //     )
      //     .map(({ code, utility_id, utility_name }: any) => ({
      //       utility_id,
      //       code,
      //       utility_name,
      //     }));
      const role = roles.find((role: any) => role.role === data.user_role);
      console.log(role);
      let roleName = '';
      if (role?.role?.toLowerCase().includes('carilec')) {
        roleName = 'Carilec';
      } else if (role?.role?.toLowerCase().includes('utility')) {
        roleName = 'Utility';
      } else if (role?.role?.toLowerCase().includes('premium')) {
        roleName = 'Premium';
      } else {
        roleName = 'External';
      }
      const { datayear } = data;
      console.log(utilityInfo);
      // return;
      const formData = {
        ...data,
        user_role_id: role.user_role_id,
        selectedUtilities: utilityInfo,
        // bm_reportname: `${
        //   utility?.code || "ALL"
        // }_${datayear}_${roleName}_report`,
      };
      //   console.log(formData);
      if (props.initialValues) {
        props.onEdit(formData);
      } else {
        props.onAdd(formData);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    } else {
      console.log('errors', errors);
    }
  };

  console.log(watch('utility_id')); // watch input value by passing the name of it
  // console.log(getValues('utility_id')?.[0]?.value);
  console.log(errors);
  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="flex space-x-4">
        <label className="flex-1 text-gray-400 text-sm">
          Choose Utility
          <Controller
            control={control}
            name="utility_id"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <ReactSelect
                key="utility_id"
                value={value}
                options={[
                  { label: 'Select All', value: 'all' },
                  ...utilities?.map((option: any) => ({
                    label: option.utility_name,
                    value: option.utility_id,
                  })),
                ]}
                isMulti
                closeMenuOnScroll={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isSearchable={false}
                // isDisabled={userPermission === 'ANON' ? true : false}
                onChange={(value: any) => {
                  //if all is selected, remove all other options
                  if (value[value.length - 1]?.value === 'all') {
                    value = value.filter(
                      (option: any) => option.value === 'all',
                    );
                  } else {
                    value = value.filter(
                      (option: any) => option.value !== 'all',
                    );
                  }
                  onChange(value);
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }: any) => (
              <p className="block text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('datayear')}
            placeholder="Select a Year"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="datayear"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Role
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('user_role')}
          >
            <option value="">--Select One--</option>
            {roles
              .filter((option: any) => {
                if (getValues('utility_id')?.[0]?.value === 'all') {
                  return !option.role.toLowerCase().includes('utility'); //show carilec and external roles
                } else if (getValues('utility_id')?.length > 1) {
                  return true; //show all roles
                } else {
                  return option.role.toLowerCase().includes('utility'); // show utility roles
                }
              })
              .sort((a: any, b: any) => {
                //sort alphabetically
                if (a.role < b.role) {
                  return -1;
                }
              })
              .map((option: any) => (
                <option key={option.user_role_id} value={option.role}>
                  {option.role}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="user_role"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Comments</span>
        <textarea
          {...register('bmr_comments')}
          placeholder="Enter text here"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name="bmr_comments"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
