// import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { regex } from "_utils";

type Inputs = {
  country_name: string;
  population: string;
  gdp: string;
  area: string;
  grid_emission_factor: number;
};

type IFormProps = {
  initialValues?: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIUtilityGeneralForm(props: IFormProps) {
  console.log(props);
  const schema = yup.object().shape({
    country_name: yup.string().required("Field is required"),
    population: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .notRequired()
      .nullable()
      .transform((value) => (!!value ? value : null)),
    gdp: yup
      .string()
      .matches(regex.numeric92.validation, regex.numeric92.message)
      .notRequired()
      .nullable()
      .transform((value) => (!!value ? value : null)),
    area: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .notRequired()
      .nullable()
      .transform((value) => (!!value ? value : null)),
    grid_emission_factor: yup
      .string()
      .transform((value) => (value !== null ? Number(value).toString() : null))
      .required("Field is required")
      .matches(regex.numeric76.validation, regex.numeric76.message),
  });
  // const utilities: any[] = props.initialValues[0];
  // const yearFilters: any[] = props.initialValues[1];
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
    // defaultValues: {
    //   name: "Grenada",
    //   population: "222",
    //   gdp: "21",
    //   area: "232",
    //   grid_emission_factor: 3,
    // },
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Country name</span>
        <input
          {...register("country_name")}
          type="text"
          placeholder="Enter text here"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="country_name"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Population</span>
        <input
          {...register("population")}
          type="number"
          step="any"
          placeholder="Enter amount here"
        />
        <ErrorMessage
          errors={errors}
          name="population"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">GDP</span>
        <input
          {...register("gdp")}
          type="number"
          step="any"
          placeholder="Enter amount here (US $1,000,000s)"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="gdp"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Area</span>
        <input
          {...register("area")}
          type="number"
          step="any"
          placeholder="Enter amount here (sq km)"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="area"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Grid Emission Factor
        </span>
        <input
          {...register("grid_emission_factor")}
          type="text"
          placeholder="Enter amount here (kg CO2eq/MWh)"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="grid_emission_factor"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
