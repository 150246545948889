import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { findTechnologies } from 'services/technologies.service';
import { findUtilities } from 'services/utilities.service';

import { getUtilityGeneralData } from 'services/utilitygeneraldata.service';
import { getFinancialData } from 'services/utilityfinancialdata.service';
import { getUtilityTechnicalData } from 'services/utilitytechnicaldata.service';
import { getUtilityCustomerData } from 'services/utilitycustomerdata.service';
import { getRestorationPeriod } from 'services/restorationperiod.service';
import { getTechnologyData } from 'services/technologydata.service';
import { getFuelData } from 'services/fueldata.service';
import { findFuels } from 'services/fuels.service';

import { findUsageCategories } from 'services/usagecategories.service';
import { getUsageCategoryData } from 'services/usagecategorydata.service';

import { findKPIs } from 'services/kpi.service';
import { findRoles } from 'services/roles.service';
import PageTitle from 'containers/PageTitle';
import Sidebar from 'containers/ReportSideBar';
import Table from 'components/Table';

import ReportFilterForm from 'components/forms/ReportFilterForm';
import BarChart from 'components/charts/BarChart';
import {
  calculateSystemLoadFactor,
  calculateReserveMargin,
  calculateSystemEquiv,
  calculateGenerationEfficiency,
  calculateFuelCost,
  // calculateEfficiencyFuelUnits,
  calculateGenerationOM,
  calculateLubeOilConsumption,
  calculateAverageGenerationCost,
  calculateThermalGenerationCost,
  calculateGenerationProductivity,
  calculateEfficiencyFuelUnits,
} from '_utils/calculations';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { formatReportTitle } from '_utils';

export default function App() {
  let { currentUser } = useAuth();
  //   console.log(currentUser);

  const { title: reportTitle } = useParams();
  // console.log(reportTitle);

  //   const currentRole: string = currentUser.user_role;
  const userUtility: string[] = [currentUser.utility_id];
  const [graphData, setGraphData] = React.useState<any>(null);
  const [tableColumns, setTableColumns] = React.useState<any>(null);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [selectedUtility, setSelectedUtility] = React.useState<any>(null);
  const [selectedStartYear, setSelectedStartYear] = React.useState<any>(null);
  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);
  const [tableRowData, setTableRowData] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any>(null);

  React.useEffect(() => {
    setGraphData(null);
    setTableData(null);
  }, [reportTitle]);

  const { data: utilities } = useQuery('utilities', () =>
    findUtilities().then(res => res.data),
  );

  const { data: usagecategories } = useQuery('usercategories', () =>
    findUsageCategories().then(res => res.data),
  );

  const { data: utilityFinancialData } = useQuery('utilityfinancialdata', () =>
    getFinancialData().then(res => res.data),
  );

  const { data: utilityGeneralData } = useQuery('utilitygeneraldata', () =>
    getUtilityGeneralData().then(res => res.data),
  );

  const {
    isLoading,
    error,
    data: usageCategoryData,
  } = useQuery('usagecategorydata', () =>
    getUsageCategoryData().then(res => res.data),
  );

  const location = useLocation();
  console.log(location.pathname);
  const category = location.pathname.split('/')[3];
  const { data: kpis } = useQuery(['kpipermissions', category], () =>
    findKPIs().then(res => {
      const kpis = res.data;
      const items = kpis?.filter((item: any) =>
        item.kpi_category.toLowerCase().includes('generation'),
      );
      return items;
    }),
  );

  React.useEffect(() => {
    const current_kpi = kpis?.find(
      (kpi: any) =>
        kpi?.kpi?.trim().toLowerCase() === reportTitle?.split('-').join(' '),
    );
    // const title =
    //   current_kpi?.description.substring(0, 6) +
    //   " - " +
    //   current_kpi?.kpi +
    //   " (" +
    //   current_kpi?.kpi_units +
    //   ")";
    // console.log(title);
    setCurrentTitle(formatReportTitle(current_kpi));
  }, [kpis, reportTitle]);

  const { data: userPermission } = useQuery(
    ['userPermission', reportTitle],
    () =>
      findKPIs().then(res => {
        const kpis = res.data;
        const kpi = kpis?.find(
          (kpi: any) =>
            kpi.kpi.trim().toLowerCase() === reportTitle?.split('-').join(' '),
        );
        // console.log(kpi);
        console.log(kpi, reportTitle, currentUser);
        const userPermission = kpi?.kpipermissions?.find(
          (permission: any) => permission.user_role_id === currentUser.role_id,
        )?.kpi_status;
        console.log(userPermission);
        return userPermission;
      }),
    { enabled: !!reportTitle },
  );

  // console.log(userPermission);

  // console.log(
  //   kpis?.find((kpi: any) => kpi.kpi.toLowerCase() === "service coverage")
  // );

  // const { data: roles } = useQuery("roles", () =>
  //   findRoles().then((res) => res.data)
  // );

  // console.log(roles);

  const { data: utilityTechnicalData } = useQuery('utilitytechnicaldata', () =>
    getUtilityTechnicalData().then(res => res.data),
  );

  const { data: technologyData } = useQuery('technologydata', () =>
    getTechnologyData().then(res => res.data),
  );

  const { data: fuelData } = useQuery('fueldata', () =>
    getFuelData().then(res => res.data),
  );

  const { data: fuels } = useQuery(
    'fuels',
    () =>
      findFuels().then(res => {
        const record = res.data;
        // console.log(record, fuelData);
        record.map((rec: any) => {
          const fuel = fuelData?.filter(
            (fuel: any) => fuel.fuel_id === rec.fuel_id,
          );
          // console.log(fuel);
          fuel.map((f: any) => {
            rec.utility_id = f?.utility_id;
            rec.data_year = f?.data_year;
            rec.utility_code = f?.utility_code;
            rec.fuel_cost = f?.fuel_cost;
            rec.energy_generated = f?.energy_generated;
          });

          return rec;
        });
        // console.log(record);
        // const utilityTechInfo = technologyData.find(
        //   (techData: any) => techData.utility_id === record.utility_id
        // );
        // console.log(utilityTechInfo);
        return record;
      }),
    { enabled: !!fuelData },
  );

  const onSubmit = (data: any) => {
    console.log(data);
    let selectedUtilities = data.utility_id;
    //If data.utility_id is null then
    if (data.utility_id[0].label === 'Select All') {
      selectedUtilities = utilities?.map((utility: any) => ({
        value: utility.utility_id,
        label: utility.utility_name,
      }));
    }
    setSelectedUtility(selectedUtilities);
    setSelectedStartYear(data.year_start);
    // setSelectedCategory(data.usage_category_id);
    //TODO: Check if the year range is valid

    let calcResults: any = {};
    const reportType =
      reportTitle &&
      [
        'disaggregated-tariffs',
        'installed-capacity',
        'energy-generation-per-pes',
        'capacity-change-per-pes',
        'generation-change-per-pes',
        'cost-by-type',
        'cost-by-business-area',
        'fuel-cost',
        'fuel-efficiency-own-units',
        'thermal-generation-cost',
        'grid-loss-per-volt-level',
        'share-of-underground-line',
        'share-per-customer-group',
        'cap-threshold-for-der',
        'support-mechanism-for-der',
        'ee-and-dsm-programs',
        'availability-of-funds',
        'grid-modernization',
        'osh-implementation',
        'gender',
      ].includes(reportTitle)
        ? 'table'
        : 'graph';

    if (reportType === 'table') {
      var results = [];
      console.log('working on table', tableRowData);
      switch (reportTitle) {
        case 'fuel-cost':
          results = calculateFuelCost(
            tableRowData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          break;
        case 'fuel-efficiency-own-units':
          results = calculateEfficiencyFuelUnits(
            tableRowData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          break;
        case 'thermal-generation-cost':
          results = calculateThermalGenerationCost(
            tableRowData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          break;
      }

      setTableData({
        label: reportTitle,
        headers: tableColumns,
        data: results,
      });
    } else {
      switch (reportTitle) {
        case 'system-load-factor': //report505
          calcResults = calculateSystemLoadFactor(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'generation-reserve-margin':
          calcResults = calculateReserveMargin(
            data.year_start,
            data.year_end,
            selectedUtilities,
            technologyData,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'system-equiv-availability':
          calcResults = calculateSystemEquiv(
            data.year_start,
            data.year_end,
            selectedUtilities,
            technologyData,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'plant-energy-consumption':
          calcResults = calculateGenerationEfficiency(
            data.year_start,
            data.year_end,
            selectedUtilities,
            technologyData,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'generation-o&m-cost':
          calcResults = calculateGenerationOM(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilityFinancialData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'lube-oil-consumption':
          calcResults = calculateLubeOilConsumption(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        case 'average-generation-cost':
          calcResults = calculateAverageGenerationCost(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilityFinancialData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        // case "thermal-generation-cost":
        //   calcResults = calculateThermalGenerationCost(
        //     data.year_start,
        //     data.year_end,
        //     data.utility_id,
        //     utilityTechnicalData,
        //     userPermission,
        //     userUtility,
        //     utilities
        //   );
        //   break;
        case 'generation-productivity':
          calcResults = calculateGenerationProductivity(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilityGeneralData,
            userPermission,
            userUtility,
            utilities,
          );
          break;
        default:
          calcResults = calculateSystemLoadFactor(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            userPermission,
            userUtility,
            utilities,
          );
      }
      setGraphData({
        labels: calcResults.labels,
        datasets: [
          {
            label: reportTitle,
            data: calcResults.results,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });
    }
  };

  React.useEffect(() => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];

    fuels?.map((fuel: any) => {
      headerColumns.push({
        Header: fuel.fuel_name,
        accessor: fuel.fuel_name.split(' ').join('_').toLowerCase(),
        width: 100,
        disableResizing: true,
      });
    });

    setTableColumns(headerColumns);
  }, [fuels]);

  // headerColumns.push()

  // const columns = React.useMemo(
  //   () => headerColumns, //eslint-disable-next-line
  //   []
  // );

  //Filter utility options if user permissions is not full
  //If user permissions is ANON then no utility options should be available
  //If user permissions is UTIL then only their utility should be available
  const utilityOptions =
    userPermission !== 'FULL'
      ? userPermission === 'ANON'
        ? []
        : utilities?.filter((utility: any) =>
            userUtility.includes(utility.utility_id),
          )
      : utilities?.sort((a: any, b: any) =>
          a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
            ? 1
            : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
            ? -1
            : 0,
        );

  //for each utility, get each fuel data and the corresponding fuel name
  // const tableData: any = [];

  React.useEffect(() => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const utilityFuelData = fuelData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      utilityFuelData?.map((data: any) => {
        const fuel = fuels?.find((fuel: any) => fuel.fuel_id === data.fuel_id);

        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.data_year,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility_code &&
            singleRecord.data_year === data.data_year,
        );

        const tableRow = {
          fuel_name: fuel?.fuel_name,
          fuel_cost: data.fuel_cost,
          fuel_used: data.fuel_used,
          energy_generated: data.energy_generated,
          generation_cost: data.generation_cost,
        };
        //If exists
        if (existingRecord > -1) {
          if (fuel) {
            tableRows[existingRecord]?.fields.push(tableRow);
          }
        } else {
          if (fuel) {
            tableEntry.fields.push(tableRow);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record

        // tableData.push(tableEntry);
      });
    });
    setTableRowData(tableRows);
  }, [fuels, fuelData]);

  console.log(tableRowData);

  const handleDownload = () => {
    var contentNode: any = document.getElementById('kpi-chart');
    if (contentNode.childNodes.length < 1) {
      alert('No data to download');
      return;
    }

    const utilityNames = selectedUtility
      ?.map((item: any) => item.label)
      .join('-');

    console.log(selectedUtility, selectedStartYear);
    console.log(utilityNames);
    htmlToImage.toPng(contentNode).then(function (dataUrl) {
      saveAs(
        dataUrl,
        `${reportTitle}-${utilityNames}-${selectedStartYear}.png`,
      );
    });
  };

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  return (
    <div className="h-full">
      <PageTitle
        title="Generation KPIs"
        subTitle="Major Company KPIs"
        onPrint={handleDownload}
      />
      <div className="flex w-full h-full">
        <Sidebar links={kpis} />
        <div className="bg-white shadow rounded-sm mb-4 p-4 sm:p-6 h-full w-full overflow-hidden">
          {utilityOptions && (
            <ReportFilterForm
              utilityOptions={utilityOptions}
              onSubmit={onSubmit}
              userPermission={userPermission}
              canSelectMultiple={currentUser?.user_role
                .toLowerCase()
                .includes('carilec')}
            />
          )}
          <div id="kpi-chart" className="bg-white p-1 overflow-hidden">
            <div className="text-center text-gray-600 border-t border-b border-gray-200 mb-5 py-3">
              <h1 className="font-semibold capitalize">{currentTitle}</h1>
            </div>
            {tableData && (
              <div className="overflow-x-scroll">
                <Table columns={tableData.headers} data={tableData.data} />
              </div>
            )}
            {graphData && <BarChart data={graphData} />}
          </div>
        </div>
      </div>
    </div>
  );
}
