import React from "react";
import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import VoltageCategoryDataForm from "../../components/forms/VoltageCategoryDataForm";
import { findUtilities } from "services/utilities.service";
import {
  addVoltageCategoryData,
  getVoltageCategoryData,
  updateVoltageCategoryData,
  removeVoltageCategoryData,
} from "services/voltagecategorydata.service";
import {
  findVoltageCategories,
  // addVoltageCategory,
  // updateVoltageCategory,
} from "services/voltagecategories.service";
import toast from "react-hot-toast";
// import { Tab } from "@headlessui/react";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { findUsageCategories } from "services/usagecategories.service";
import useConfirmationDialog from "hooks/useConfirm";
import PageTitle from "containers/PageTitle";

const UsageCategoryData = (props: any) => {
  const { utility } = props;
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const { getConfirmation } = useConfirmationDialog();

  const minYear = 1980;
  const maxYear = new Date().getFullYear() - 1;

  const fillRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const yearFilters = fillRange(maxYear, minYear, -1);

  const queryClient = useQueryClient();

  const { data: utilities } = useQuery("utilities", () =>
    findUtilities().then((res) => res.data)
  );

  const {
    // isLoading,
    // error,
    data: voltagecategories,
  } = useQuery("voltagecategories", () =>
    findVoltageCategories().then((res) => res.data)
  );

  // const { data: usagecategories } = useQuery("usagecategories", () =>
  //   findUsageCategories().then((res) => res.data)
  // );

  const {
    isLoading,
    error,
    data: voltageCategoryData,
  } = useQuery("voltagecategorydata", () =>
    getVoltageCategoryData().then((res) => res.data)
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Year",
        accessor: "data_year",
        width: 100,
        disableResizing: true,
      },
      {
        Header: "Utility",
        accessor: "utility_name",
      },
      {
        Header: "Voltage category",
        accessor: "voltage_category",
      },
      {
        Header: "Entry Energy",
        accessor: "entry_energy",
      },
      {
        Header: "Delivered Energy",
        accessor: "delivered_energy",
      },
      {
        Header: "Percentage Underground",
        accessor: "percentage_underground",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions
              onEdit={() => handleToggleModal(row?.original)}
              onDelete={() => handleRemove(row?.original)}
            />
          );
        },
      },
    ],
    []
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord({
        data_year: utility.data_year,
        utility_id: utility.utility_id,
      });
    } else setRecord(data);
    toggleModal();
  };

  const handleViewRecord = (data: any) => {
    console.log(data);
  };

  const handleAddRecord = (data: any) => {
    addVoltageCategoryData(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("voltagecategorydata");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    console.log(record);
    updateVoltageCategoryData({
      ...data,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("voltagecategorydata");
        handleToggleModal();
      }
    });
  };

  const handleRemove = async (data: any) => {
    const confirmed = await getConfirmation({
      title: "Are you sure?",
      message: "Removing this record cannot be reverted",
    });

    if (confirmed) {
      console.log("remove record");
      // deleteUserMutation.mutate(data);
      removeVoltageCategoryData(data?.voltage_cat_data_id).then((res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.data.message);
        } else {
          toast.success("Record removed");
          queryClient.invalidateQueries("voltagecategorydata");
          // handleToggleModal();
        }
      });
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="flex h-full">
      <div className="flex-1 bg-grey-50">
        <PageTitle
          title="Voltage Category"
          onClick={() => handleToggleModal()}
        />
        {voltageCategoryData && (
          <Table
            columns={columns}
            data={voltageCategoryData.filter(
              (record: any) =>
                record.utility_id === utility.utility_id &&
                record.data_year === utility.data_year
            )}
          />
        )}
        {isModalOpen && (
          <Modal
            modalTitle={`${
              record?.voltage_cat_data_id ? "Update" : "Add"
            } Voltage Category Data`}
            isOpen={isModalOpen}
            closeModal={() => handleToggleModal()}
          >
            <VoltageCategoryDataForm
              utilities={utilities}
              voltagecategories={voltagecategories}
              initialValues={record}
              onEdit={handleEditRecord}
              onAdd={handleAddRecord}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default UsageCategoryData;
