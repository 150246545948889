import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { fillRange } from '../../utils';
import { regex } from '_utils';
import useAuth from 'hooks/useAuth';
// import { NumericFormat } from 'react-number-format';

type Inputs = {
  utility_id: string;
  data_year: string;
  gross_energy_input: string;
  net_energy_input: string;
  energy_purchased: string;
  annual_system_peak_load: string;
  available_energy_from_installs: string;
  net_energy_generated: string;
  total_lube_oil_consumed: string;
  own_thermal_energy_generated: string;
  purchased_fossil_energy_from_ipps: string;
  re_delivered_by_ipps: string;
  energy_losses: string;
  nbr_of_grids_in_areas_served: string;
  energy_from_own_re_sources: string;
  // energy_delivered_from_solar_pv: string;
  // energy_delivered_from_wind: string;
  installed_capacity_for_small_scale_re: string;
  capacity_threshold_for_residential_re: string;
  capacity_threshold_for_c_i_re: string;
  time_in_use_tariffs: boolean;
  microgrids_with_flexible_connection: boolean;
  incentivization_via_storage_capacities: boolean;
  other_dms: string;
  other_dms_in_use: boolean;
  system_installed_capacity: string;
  total_re_delivered: string;
  waste_management_program: boolean;
  spill_management_program: boolean;
  recycling_program: boolean;
  certified_environ_mgmt: boolean;
  name_of_certification_standard: string;
  existing_gen_forecast_system: boolean;
  auto_pwr_ctrl__netwk_monitoring: boolean;
  dynamic_electric_cust_tariffs: boolean;
  dispatchable_demand_response: boolean;
  nbr_of_grid_connected_microgrids: number;
  grid_connected_battery_storage: string;
  technical_comment: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  onEdit: any;
  onAdd: any;
};

export default function UtilityTechnicalDataForm(props: IFormProps) {
  // console.log(props);
  let { currentUser } = useAuth();
  const { utilities } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required('Field is required'),
    data_year: yup.string().required('Field is required'),
    gross_energy_input: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    net_energy_input: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    energy_purchased: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric103.validation, regex.numeric103.message),
    annual_system_peak_load: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    available_energy_from_installs: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    net_energy_generated: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    total_lube_oil_consumed: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    own_thermal_energy_generated: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    purchased_fossil_energy_from_ipps: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    re_delivered_by_ipps: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    energy_losses: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    nbr_of_grids_in_areas_served: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    energy_from_own_re_sources: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    system_installed_capacity: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    total_re_delivered: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    // energy_delivered_from_solar_pv: yup
    //   .string()
    //   .required("Field is required")
    //   .matches(regex.numeric101.validation, regex.numeric101.message),
    // energy_delivered_from_wind: yup
    //   .string()
    //   .required("Field is required")
    //   .matches(regex.numeric101.validation, regex.numeric101.message),
    installed_capacity_for_small_scale_re: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    capacity_threshold_for_residential_re: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    capacity_threshold_for_c_i_re: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric101.validation, regex.numeric101.message),
    time_in_use_tariffs: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    microgrids_with_flexible_connection: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    incentivization_via_storage_capacities: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    other_dms: yup.string().max(40, 'Cannot be longer than 40 characters'),
    other_dms_in_use: yup.bool().oneOf([true, false], 'Must be true or false'),
    waste_management_program: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    spill_management_program: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    recycling_program: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    certified_environ_mgmt: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    name_of_certification_standard: yup
      .string()
      .max(40, 'Cannot be longer than 40 characters'),
    existing_gen_forecast_system: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    auto_pwr_ctrl__netwk_monitoring: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    dynamic_electric_cust_tariffs: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    dispatchable_demand_response: yup
      .bool()
      .oneOf([true, false], 'Must be true or false')
      .required('Field is required'),
    nbr_of_grid_connected_microgrids: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    grid_connected_battery_storage: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric105.validation, regex.numeric105.message),
  });

  const {
    control,
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    // console.log(props.initialValues);
    if (props.initialValues) {
      reset(props.initialValues);
    } else {
      // console.log(currentUser);
      if (currentUser.user_role.toLowerCase().includes('utility')) {
        reset({ utility_id: currentUser.utility_id });
      }
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (Object.values(errors).length < 1) {
      console.log('no errors');
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-2">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('utility_id')}
            placeholder="Select a Utility"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0,
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('data_year')}
            placeholder="Select a Year"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-4 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Gross Energy Input (GWh)
          </span>
          <Controller
            name="gross_energy_input"
            control={control}
            render={({ field: { ...rest } }) => (
              <input type="number" placeholder="Enter amount (GWh)" {...rest} />
            )}
          />

          <ErrorMessage
            errors={errors}
            name="gross_energy_input"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Net Energy Input (GWh)
          </span>
          <Controller
            name="net_energy_input"
            control={control}
            render={({ field: { ...rest } }) => (
              <input type="number" placeholder="Enter amount (GWh)" {...rest} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="net_energy_input"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Energy Purchased (GWh)
          </span>
          <Controller
            name="energy_purchased"
            control={control}
            render={({ field: { ...rest } }) => (
              <input type="number" placeholder="Enter amount (GWh)" {...rest} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="energy_purchased"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Annual System Peak Load (MW)
          </span>
          <Controller
            name="annual_system_peak_load"
            control={control}
            render={({ field: { ...rest } }) => (
              <input type="number" placeholder="Enter amount (GWh)" {...rest} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="annual_system_peak_load"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Available Energy From Installed Units (MWh)
          </span>
          <input
            type="text"
            {...register('available_energy_from_installs')}
            placeholder="Enter amount (rating x hrs) (MWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="available_energy_from_installs"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Own Net Energy Generated (GWh)
          </span>
          <input
            type="text"
            {...register('net_energy_generated')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="net_energy_generated"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Total Lube Oil Consumed
          </span>
          <input
            type="text"
            {...register('total_lube_oil_consumed')}
            placeholder="Enter amount (L)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="total_lube_oil_consumed"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Gross Own Thermal Energy Generated (GWh)
          </span>
          <input
            type="text"
            {...register('own_thermal_energy_generated')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="own_thermal_energy_generated"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Purchased Fossil Energy from IPPs (GWh)
          </span>
          <input
            type="text"
            {...register('purchased_fossil_energy_from_ipps')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="purchased_fossil_energy_from_ipps"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            RE Delivered By IPPs (GWh)
          </span>
          <input
            type="text"
            {...register('re_delivered_by_ipps')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="re_delivered_by_ipps"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Technical Grid Losses (GWh)
          </span>
          <input
            type="text"
            {...register('energy_losses')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="energy_losses"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Total RE Delivered to Grid (GWh)
          </span>
          <input
            type="text"
            {...register('total_re_delivered')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="total_re_delivered"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Gross Energy from Own RE sources (GWh)
          </span>
          <input
            type="text"
            {...register('energy_from_own_re_sources')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="energy_from_own_re_sources"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        {/* <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Energy Delivered from Solar PV
          </span>
          <input
            type="text"
            {...register("energy_delivered_from_solar_pv")}
            // placeholder="12345"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name={"energy_delivered_from_solar_pv"}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label> */}

        {/* <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Energy Delivered from Wind
          </span>
          <input
            type="text"
            {...register("energy_delivered_from_wind")}
            // type="checkbox"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name={"energy_delivered_from_wind"}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label> */}

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Installed Capacity Small Scale RE (MW)
          </span>
          <input
            type="text"
            {...register('installed_capacity_for_small_scale_re')}
            placeholder="Enter amount (MW)"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'installed_capacity_for_small_scale_re'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Capacity Threshold Residential RE (kW)
          </span>
          <input
            type="text"
            {...register('capacity_threshold_for_residential_re')}
            placeholder="Enter amount (kW)"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'capacity_threshold_for_residential_re'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Capacity Threshold CI RE (kW)
          </span>
          <input
            type="text"
            {...register('capacity_threshold_for_c_i_re')}
            placeholder="Enter amount (kW)"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'capacity_threshold_for_c_i_re'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            System Installed Capacity
          </span>
          <input
            type="text"
            {...register('system_installed_capacity')}
            placeholder="Enter amount (MW)"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'system_installed_capacity'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Grids in areas served
          </span>
          <input
            type="text"
            {...register('nbr_of_grids_in_areas_served')}
            placeholder="Enter amount"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <ErrorMessage
            errors={errors}
            name="nbr_of_grids_in_areas_served"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Grid Connected Microgrids
          </span>
          <input
            type="number"
            {...register('nbr_of_grid_connected_microgrids')}
            placeholder="Enter amount"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'nbr_of_grid_connected_microgrids'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Grid Connected Battery Storage (GWh)
          </span>
          <input
            {...register('grid_connected_battery_storage')}
            type="text"
            placeholder="Enter amount (GWh)"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'grid_connected_battery_storage'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="border-b border-indigo-200 w-full">
        <h2 className="text-indigo-400 text-sm">
          Please tick each facility below if it is present in your
          utility/jurisdiction
        </h2>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Time in use Tariffs
          </span>
          <input
            {...register('time_in_use_tariffs')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'time_in_use_tariffs'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Microgrids with flexible connection
          </span>
          <input
            {...register('microgrids_with_flexible_connection')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'microgrids_with_flexible_connection'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Incentivization via storage capacities
          </span>
          <input
            {...register('incentivization_via_storage_capacities')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'incentivization_via_storage_capacities'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Waste Management Program
          </span>
          <input
            {...register('waste_management_program')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'waste_management_program'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Spill Management Program
          </span>
          <input
            {...register('spill_management_program')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'spill_management_program'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Recycling Program
          </span>
          <input
            {...register('recycling_program')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'recycling_program'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Existing Gen Forecast System
          </span>
          <input
            {...register('existing_gen_forecast_system')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'existing_gen_forecast_system'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Auto Pwr Ctrl Network Monitoring
          </span>
          <input
            {...register('auto_pwr_ctrl__netwk_monitoring')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'auto_pwr_ctrl_netwk_monitoring'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Dynamic Electric Cust Tariffs
          </span>
          <input
            {...register('dynamic_electric_cust_tariffs')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'dynamic_electric_cust_tariffs'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Dispatchable Demand Response
          </span>
          <input
            {...register('dispatchable_demand_response')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'dispatchable_demand_response'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Certified Environ Mgmt
          </span>
          <input
            {...register('certified_environ_mgmt')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'certified_environ_mgmt'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block col-span-2">
          <span className="text-sm font-medium text-gray-400">
            Name of Certification Standard
          </span>
          <input
            type="text"
            {...register('name_of_certification_standard')}
            placeholder="Enter text here"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'name_of_certification_standard'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Other DSM in use
          </span>
          <input
            {...register('other_dms_in_use')}
            type="checkbox"
            className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'other_dms_in_use'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block col-span-2">
          <span className="text-sm font-medium text-gray-400">Other DSM</span>
          <input
            type="text"
            {...register('other_dms')}
            placeholder="Enter text here"
            // className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'other_dms'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Technical Comment
        </span>
        <textarea
          {...register('technical_comment')}
          placeholder="Enter text here"
          className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name={'technical_comment'}
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
