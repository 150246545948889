import axios from "../config/apiClient";

export const getFinancialData = async () => {
  const response = await axios.get("/utilityfinancialdata");

  return response;
};

export const addFinancialData = async (data: any) => {
  const response = await axios.post("/utilityfinancialdata", data);

  return response;
};

export const updateFinancialData = async (data: any) => {
  const response = await axios.put("/utilityfinancialdata", data);

  return response;
};

export const removeFinancialData = async () => {
  const response = await axios.delete("/utilityfinancialdata");

  return response;
};
