import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import DERSupportForm from "../../components/forms/DERSupportForm";
import {
  findDERSupport,
  addDERSupport,
  updateDERSupport,
} from "services/dersupport.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const DERSupport = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: supportTypes,
  } = useQuery("dersupport", () => findDERSupport().then((res) => res.data));

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addDERSupport(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("dersupport");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateDERSupport({ ...data, der_supp_id: record?.der_supp_id }).then(
      (res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.data.message);
        } else {
          toast.success("Record created");
          queryClient.invalidateQueries("dersupport");
          handleToggleModal();
        }
      }
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Support",
        accessor: "support",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(utilities);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="DER Support Mechanisms" onClick={toggleModal} />
      <Table columns={columns} data={supportTypes} />
      {isModalOpen && (
        <Modal
          modalTitle="New Entry"
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <DERSupportForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default DERSupport;
