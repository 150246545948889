import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { alphanumericRegExp } from "../../_utils";

type FormValues = {
  username: string;
};

interface FormProps {
  onSubmit: (data: FormValues) => void;
}

const ForgotPasswordForm = (props: FormProps) => {
  const schema = yup
    .object({
      username: yup
        .string()
        .email("must be a valid email")
        // .min(6, "Minimum of 6 characters")
        // .matches(
        //   alphanumericRegExp,
        //   "User name must not contain any spaces or special characters (e.g. user.name or username)"
        // )
        .required("Field is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    reset,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormValues) => {
    // console.log(data, errors, Object.values(errors));
    if (Object.values(errors).length < 1) {
      // console.log("no errors")
      // return;
      props.onSubmit(data);
      //Clear the form fields using react-hook-form
      reset();
      // props.toggleModal()
    }
  };
  return (
    <div className="w-2/3 p-4 mx-auto relative">
      <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-label">
          Username / Email
          <input
            type="text"
            {...register("username")}
            className="w-full p-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:bg-white mt-2"
            placeholder="Enter your user name / email"
          />
          <ErrorMessage
            errors={errors}
            name="username"
            render={({ message }: any) => (
              <p className="block text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <div className="mt-6 mb-5">
          <button
            type="submit"
            className="mt-5 tracking-wide font-semibold bg-blue-500 text-white w-full py-4 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
          >
            {isSubmitting && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Reset Password
          </button>
        </div>
      </form>
      <div>
        <a
          href="/login"
          className="text-xs font-display text-indigo-600 hover:text-indigo-800 cursor-pointer"
        >
          Return to login
        </a>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
