import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { regex } from "_utils";

type Inputs = {
  technology: string;
  renewable_energy: boolean;
  pv: boolean;
  wind: boolean;
  emissions_factor: string;
};

type IFormProps = {
  initialValues?: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIUtilityGeneralForm(props: IFormProps) {
  console.log(props.initialValues);

  const schema = yup.object().shape({
    technology: yup.string().required("Field is required"),
    emissions_factor: yup
      .string()
      .matches(regex.numeric76.validation, regex.numeric76.message)
      .required("Field is required"),
    renewable_energy: yup
      .bool()
      .oneOf([true, false], "Field is required")
      .required("Field is required"),
    wind: yup.bool().oneOf([true, false], "Field is required").required(),
    pv: yup.bool().oneOf([true, false], "Field is required").required(),
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues: { pv: false, wind: true, renewable_energy: false },
    // defaultValues: props.initialValues
    //   ? props.initialValues
    //   : { pv: false, wind: true, renewable_energy: false },
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
    // if (Object.values(errors).length < 1) {
    //   console.log("no errors");
    //   // return;
    if (props.initialValues) {
      props.onEdit(data);
    } else {
      props.onAdd(data);
    }

    // }
  };
  // console.log(getValues("renewable_energy"));
  // console.log(watch("renewable_energy"));
  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Technology</span>
        <input
          {...register("technology")}
          placeholder="Name of technology"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="technology"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      <label className="block flex items-center">
        <input
          type="checkbox"
          {...register("renewable_energy")}
          className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <span className="text-sm font-medium text-gray-400">
          Renewable Energy
        </span>
      </label>
      <ErrorMessage
        errors={errors}
        name="renewable_energy"
        render={({ message }) => (
          <p className="text-xs text-red-500">{message}</p>
        )}
      />

      <label className="block flex items-center">
        <input
          type="checkbox"
          {...register("pv")}
          className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <span className="text-sm font-medium text-gray-400">Photovoltaic</span>
      </label>
      <ErrorMessage
        errors={errors}
        name="pv"
        render={({ message }) => (
          <p className="text-xs text-red-500">{message}</p>
        )}
      />

      <label className="block flex items-center">
        <input
          type="checkbox"
          {...register("wind")}
          className="mx-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <span className="text-sm font-medium text-gray-400">Wind</span>
      </label>
      <ErrorMessage
        errors={errors}
        name="wind"
        render={({ message }) => (
          <p className="text-xs text-red-500">{message}</p>
        )}
      />

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Emissions Factor
        </span>
        <input
          {...register("emissions_factor")}
          placeholder="emissions factor (kg CO2eq/kWh)"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="emissions_factor"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
