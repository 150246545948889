import axios from "../config/apiClient";

export const findBenchmarks = async () => {
  const response = await axios.get("/benchmarks");

  return response;
};

export const addBenchmark = async (data: any) => {
  const response = await axios.post("/benchmarks", data);

  return response;
};

export const updateBenchmark = async (data: any) => {
  const response = await axios.put("/benchmarks", data);

  return response;
};

export const removeBenchmark = async (id: string) => {
  return await axios.delete(`/benchmarks/${id}`).then((res) => {
    return res.data;
  });
};
