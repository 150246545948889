import axios from "../config/apiClient";

const api_route = "/restorationperiods";

export const getRestorationPeriod = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addRestorationPeriod = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateRestorationPeriod = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeRestorationPeriod = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res.data;
  });
};
