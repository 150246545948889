// import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect } from "react";

type Inputs = {
  kpi: string;
  description: string;
  kpi_formula: string;
  kpi_categ_id: number;
  kpi_units: string;
};

type IFormProps = {
  initialValues?: any;
  categories?: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIPermissionForm(props: IFormProps) {
  console.log(props);
  const kpicategories = props.categories;

  const schema = yup.object().shape({
    kpi: yup.string().required("Field is required"),
    description: yup.string().required("Field is required"),
    kpi_formula: yup.string().required("Field is required"),
    kpi_categ_id: yup.string().required("Field is required"),
    kpi_units: yup.string().required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  useEffect(() => {
    if (props.initialValues) {
      const { kpipermissions, ...formValues } = props.initialValues;
      reset(formValues);
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors", data);

      // const prunedPermissions = data.kpipermissions.map((item: any) => {
      //   const { role_title, ...rest } = item;
      //   return rest;
      // });
      // return;
      if (props.initialValues) {
        // console.log(props.initialValues);
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <label className="block">
        <span className="text-sm font-medium text-gray-400">KPI</span>
        <input
          {...register("kpi")}
          placeholder="Name of KPI"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="kpi"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">KPI Units</span>
        <input
          {...register("kpi_units")}
          placeholder="Units of KPI"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="kpi_units"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Description</span>
        <textarea
          {...register("description")}
          placeholder="description"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name="description"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">KPI Formula</span>
        <textarea
          {...register("kpi_formula")}
          placeholder="Formula for KPI"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name="kpi_formula"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">KPI Category</span>
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register("kpi_categ_id")}
          placeholder="Select a Role"
        >
          <option value="">--Select One--</option>
          {kpicategories
            ?.sort((a: any, b: any) =>
              a.kpi_category.toLowerCase() > b.kpi_category.toLowerCase()
                ? 1
                : b.kpi_category.toLowerCase() > a.kpi_category.toLowerCase()
                ? -1
                : 0
            )
            .map((option: any) => (
              <option key={option.kpi_categ_id} value={option.kpi_categ_id}>
                {option.kpi_category}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="kpi_categ_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
