import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { fillRange } from '../../utils';
import { regex } from '_utils';
import useAuth from 'hooks/useAuth';

type Inputs = {
  utility_id: string;
  data_year: string;
  population_with_service: string;
  total_population: string;
  fte_staff: string;
  lost_employee_working_hours: string;
  total_employee_working_hours: string;
  fte_generation_staff: string;
  fte_td_staff: string;
  fte_commercial_staff: string;
  health_and_safety_audits: boolean;
  health_and_safety_manual: boolean;
  health_and_safety_committee: boolean;
  regular_safety_meetings: boolean;
  regular_tailboards_meetings: boolean;
  continued_work_observations: boolean;
  regular_facility_inspections: boolean;
  regular_worksite_inspections: boolean;
  safety_audits: boolean;
  percent_male_staff: string;
  percent_female_staff: string;
  percent_technical_male_staff: string;
  percent_technical_female_staff: string;
  percent_male_senior_staff: string;
  percent_senior_female_staff: string;
  general_comment: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  onEdit: any;
  onAdd: any;
};

export default function UtilityGeneralDataForm(props: IFormProps) {
  // console.log(props);
  let { currentUser } = useAuth();
  const { utilities } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required('Field is required'),
    data_year: yup.string().required('Field is required'),
    population_with_service: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    total_population: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .notRequired()
      .nullable()
      .transform(value => (!!value ? value : null)),
    fte_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    lost_employee_working_hours: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    total_employee_working_hours: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    fte_generation_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    fte_td_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    fte_commercial_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.integer.validation, regex.integer.message),
    health_and_safety_audits: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    health_and_safety_manual: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    health_and_safety_committee: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    regular_safety_meetings: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    regular_tailboards_meetings: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    continued_work_observations: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    regular_facility_inspections: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    regular_worksite_inspections: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    safety_audits: yup
      .bool()
      .oneOf([true, false], 'Field is required')
      .required('Field is required'),
    percent_male_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
    percent_female_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
    percent_technical_male_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
    percent_technical_female_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
    percent_male_senior_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
    percent_senior_female_staff: yup
      .string()
      .required('Field is required')
      .matches(regex.numeric52.validation, regex.numeric52.message),
  });

  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    // console.log(props.initialValues);
    if (props.initialValues) {
      reset(props.initialValues);
    } else {
      // console.log(currentUser);
      if (currentUser.user_role.toLowerCase().includes('utility')) {
        reset({ utility_id: currentUser.utility_id });
      }
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (Object.values(errors).length < 1) {
      console.log('no errors');
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-2">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('utility_id')}
            placeholder="Select a Utility"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0,
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('data_year')}
            placeholder="Select a Year"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Population with Service
          </span>
          <input
            {...register('population_with_service')}
            placeholder="Enter amount"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="population_with_service"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Total Population
          </span>
          <input
            {...register('total_population')}
            placeholder="Enter amount"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="total_population"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            FTE of Staff
          </span>
          <input
            {...register('fte_staff')}
            placeholder="Enter amount (person-years)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="fte_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Lost Working Hours from sev. inj.
          </span>
          <input
            {...register('lost_employee_working_hours')}
            placeholder="Enter amount (hrs)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="lost_employee_working_hours"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Total Working Hours
          </span>
          <input
            {...register('total_employee_working_hours')}
            placeholder="Enter amount"
            type="text"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="total_employee_working_hours"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            FTE of Generation Staff
          </span>
          <input
            {...register('fte_generation_staff')}
            placeholder="Enter amount (person-yrs)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="fte_generation_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            FTE of T&D Staff
          </span>
          <input
            {...register('fte_td_staff')}
            placeholder="Enter amount (person-yrs)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="fte_td_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            FTE of Commercial Staff
          </span>
          <input
            {...register('fte_commercial_staff')}
            placeholder="Enter amount (person-yrs)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="fte_commercial_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % male staff
          </span>
          <input
            {...register('percent_male_staff')}
            placeholder="Enter amount (%)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="percent_male_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % female staff
          </span>
          <input
            {...register('percent_female_staff')}
            placeholder="Enter amount (%)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="percent_female_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % male in technical staff
          </span>
          <input
            {...register('percent_technical_male_staff')}
            placeholder="Enter amount (%)"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="percent_technical_male_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % female in technical staff
          </span>
          <input
            {...register('percent_technical_female_staff')}
            type="text"
            placeholder="Enter amount (%)"
          />
          <ErrorMessage
            errors={errors}
            name="percent_technical_female_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % male in management staff
          </span>
          <input
            {...register('percent_male_senior_staff')}
            type="text"
            placeholder="Enter amount (%)"
          />
          <ErrorMessage
            errors={errors}
            name="percent_male_senior_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % female in management staff
          </span>
          <input
            {...register('percent_senior_female_staff')}
            type="text"
            placeholder="Enter amount (%)"
          />
          <ErrorMessage
            errors={errors}
            name="percent_senior_female_staff"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>
      <div className="border-b border-indigo-200 w-full">
        <h2 className="text-indigo-400 text-sm">
          Please tick each facility below if it is present in your
          utility/jurisdiction
        </h2>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Health & Safety Audits
          </span>
          <input
            {...register('health_and_safety_audits')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'health_and_safety_audits'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Health & Safety Manual
          </span>
          <input
            {...register('health_and_safety_manual')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'health_and_safety_manual'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Health & Safety Committee
          </span>
          <input
            {...register('health_and_safety_committee')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'health_and_safety_committee'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Regular Safety Meetings
          </span>
          <input
            {...register('regular_safety_meetings')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'regular_safety_meetings'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Regular Tailboards Meetings
          </span>
          <input
            {...register('regular_tailboards_meetings')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'regular_tailboards_meetings'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Continued Work Observations
          </span>
          <input
            {...register('continued_work_observations')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'continued_work_observations'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Regular Facility Inspections
          </span>
          <input
            {...register('regular_facility_inspections')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'regular_facility_inspections'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Regular Worksite Inspections
          </span>
          <input
            {...register('regular_worksite_inspections')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'regular_worksite_inspections'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Safety Audits
          </span>
          <input
            {...register('safety_audits')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'safety_audits'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          General Comment
        </span>
        <textarea
          {...register('general_comment')}
          placeholder="Enter your comment here"
          // className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name={'general_comment'}
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
