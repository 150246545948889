import axios from "../config/apiClient";

export const getUtilityExtraData = async () => {
  const response = await axios.get("/utilityextradata");

  return response;
};

export const addUtilityExtraData = async (data: any) => {
  const response = await axios.post("/utilityextradata", data);

  return response;
};

export const updateUtilityExtraData = async (data: any) => {
  const response = await axios.put("/utilityextradata", data);

  return response;
};

export const removeUtilityExtraData = async () => {
  const response = await axios.delete("/utilityextradata");

  return response;
};
