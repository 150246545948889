import { NavLink } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import MyPopover from 'components/Popover';
import {
	HiOutlineDatabase,
	// HiOutlineAdjustments,
	HiOutlineChartPie,
	HiOutlineCog,
	HiOutlineQuestionMarkCircle,
	HiOutlineDocumentSearch,
	HiOutlineTrendingUp,
	HiCurrencyDollar,
	HiUserCircle,
	HiQuestionMarkCircle,
	HiDesktopComputer,
	HiStar,
	HiLibrary,
	HiOutlineChatAlt,
} from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Sidebar = () => {
	// const iconStyle = "w-6 h-6 text-blue-500";
	// const { logoutUser } = useAuthState();
	const navigate = useNavigate();
	const { logout, currentUser } = useAuth();
	// console.log(currentUser);
	const handleSignOut = () => {
		// logoutUser();
		console.log('logout user');
		logout().then(navigate('/login'));
	};

	let links = [
		{
			title: 'Dashboard',
			url: 'dashboard',
			icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
		},
		{
			title: 'Administration',
			url: 'admin',
			icon: <HiOutlineDatabase className="w-6 h-6 text-blue-500" />,
			links: [
				{
					title: 'User Accounts',
					url: 'users',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'User Categories',
					url: 'roles',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
			],
		},

		{
			title: 'Configuration',
			url: 'configuration',
			icon: <HiOutlineCog className="w-6 h-6 text-blue-500" />,
			links: [
				{
					title: 'Countries',
					url: 'countries',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Utilities',
					url: 'utilities',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Technologies',
					url: 'technologies',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Fuels',
					url: 'fuels',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Customer Categories',
					url: 'customers',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Usage Categories',
					url: 'usage',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Voltage Categories',
					url: 'voltages',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				// {
				//   title: "Restoration Categories",
				//   url: "restoration",
				//   icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				// },
				{
					title: 'KPI Categories',
					url: 'kpicategories',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'KPIs with Permissions',
					url: 'kpis',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},

				// {
				//   title: "KPIs with Permissions",
				//   url: "kpipermissions",
				//   icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				// },
				// {
				//   title: "Support Types",
				//   url: "support",
				//   icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				// },
				{
					title: 'DER Support Mechanisms',
					url: 'dersupport',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Climate Resilience',
					url: 'climate',
					icon: <HiOutlineChartPie className="w-6 h-6 text-blue-500" />,
				},
			],
		},
		{
			title: 'Utility Data',
			url: 'mgmt',
			icon: <HiOutlineTrendingUp className="w-6 h-6 text-blue-500" />,
			links: [
				{
					title: 'Utility Customer Data',
					url: 'customer',
					icon: <HiUserCircle className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Utility Financial Data',
					url: 'financial',
					icon: <HiCurrencyDollar className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Utility General Data',
					url: 'general',
					icon: <HiQuestionMarkCircle className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Utility Technical Data',
					url: 'technical',
					icon: <HiDesktopComputer className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Utility OSH Data',
					url: 'extra',
					icon: <HiStar className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Data Registry',
					url: 'registry',
					icon: <HiLibrary className="w-6 h-6 text-blue-500" />,
					disable: ['utility department'],
				},
			],
		},
		{
			title: 'Annual Reports',
			url: 'annualreports',
			icon: <HiOutlineDocumentSearch className="w-6 h-6 text-blue-500" />,
			links: [
				{
					title: 'Manage Benchmarking Reports',
					url: 'benchmarks',
					icon: <HiOutlineDocumentSearch className="w-6 h-6 text-blue-500" />,
					disable: [
						'utility management',
						'utility department',
						'premium external',
						'external',
					],
				},
				{
					title: 'Accessing Benchmarking Reports',
					url: 'accessing',
					icon: <HiOutlineDocumentSearch className="w-6 h-6 text-blue-500" />,
				},
				{
					title: 'Accessing Raw Data',
					url: 'raw',
					icon: <HiOutlineDocumentSearch className="w-6 h-6 text-blue-500" />,
					disable: ['premium external', 'external'],
				},
			],
		},
		{
			title: 'Need Help?',
			url: 'help',
			icon: <HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />,
			links: [
				{
					title: 'General',
					url: 'Help_General.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: [
					//   "utility management",
					//   "utility department",
					//   "premium external",
					//   "external",
					// ],
				},
				{
					title: 'Administration',
					url: 'Help_Administration.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: [
					//   "utility management",
					//   "utility department",
					//   "premium external",
					//   "external",
					// ],
				},
				{
					title: 'Configuration',
					url: 'Help_Configuration.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: [
					//   "utility management",
					//   "utility department",
					//   "premium external",
					//   "external",
					// ],
				},
				{
					title: 'Management',
					url: 'Help_Management.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: ["premium external", "external"],
				},
				{
					title: 'Annual Reports',
					url: 'Help_Annual_Reporting.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
				},
				{
					title: 'Dashboard',
					url: 'Help_KPI_Reporting.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
				},
				{
					title: 'KPI Information',
					url: 'Help_KPI_Information.pdf',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: [
					//   "utility management",
					//   "utility department",
					//   "premium external",
					//   "external",
					// ],
				},
				{
					title: 'Utility Data Templates',
					url: 'Utility_Data_Template.xlsx',
					icon: (
						<HiOutlineQuestionMarkCircle className="w-6 h-6 text-blue-500" />
					),
					// disable: [
					//   "utility management",
					//   "utility department",
					//   "premium external",
					//   "external",
					// ],
				},
			],
		},
		// {
		//   title: "Contact Us",
		//   url: "mailto:esa@carilec.org",
		//   icon: <HiOutlineChatAlt className="w-6 h-6 text-blue-500" />,
		// },
	];

	if (currentUser.user_role.toLowerCase().includes('utility management')) {
		//utility management
		links = links.filter(
			linkObj =>
				linkObj.title !== 'Administration' && linkObj.title !== 'Configuration',
		);
	} else if (
		currentUser.user_role.toLowerCase().includes('utility department')
	) {
		//utility department
		links = links.filter(
			linkObj =>
				linkObj.title !== 'Administration' && linkObj.title !== 'Configuration',
		);
	} else if (currentUser.user_role.toLowerCase().includes('premium')) {
		//premium external
		links = links.filter(
			linkObj =>
				linkObj.title !== 'Administration' &&
				linkObj.title !== 'Configuration' &&
				linkObj.title !== 'Utility Data',
		);
	} else if (currentUser.user_role.toLowerCase().includes('external')) {
		// external
		links = links.filter(
			linkObj =>
				linkObj.title !== 'Administration' &&
				linkObj.title !== 'Configuration' &&
				linkObj.title !== 'Utility Data',
		);
	}

	return (
		<aside
			id="sidebar"
			className="h-full border-r border-gray-200 bg-white top-0 left-0 flex flex-shrink-0 flex-col w-48 transition-width duration-75"
			aria-label="Sidebar"
		>
			<div className="flex-1 flex flex-col min-h-0 bg-white pt-0">
				<div className="flex-1 flex flex-col pb-4 overflow-y-auto">
					<div className="flex items-center p-3 justify-between bg-white border-b border-gray-200">
						<h3 className="text-gray-900 text-xl font-semibold ">Menu</h3>
					</div>
					<div className="flex-1 px-3 pt-6 bg-white space-y-1">
						<ul className="space-y-2 pb-2">
							{links.map(
								(item: {
									url: string;
									title: string;
									icon: React.ReactElement;
									links?: any;
								}) =>
									!item.links ? (
										<NavLink
											key={item.title}
											to={`/dashboard/${item.url}`}
											className="w-full flex justify-start items-center gap-4 p-2 hover:bg-indigo-100 text-gray-600 rounded-md"
										>
											{item.icon}
											{item.title}
										</NavLink>
									) : (
										<MyPopover
											key={item.title}
											icon={item.icon}
											title={item.title}
											url={item.url}
											position="right-center"
											options={
												item.links.filter((link: any) => {
													// console.log(link.disable);
													if (
														link.disable?.includes(
															currentUser.user_role.toLowerCase(),
														)
													) {
														return null;
													} else return link;
												}) || null
											}
										/>
									),
							)}
						</ul>

						<a
							className="w-full flex justify-start items-center gap-4 p-2 hover:bg-indigo-100 text-gray-600 rounded-md"
							href="mailto:esa@carilec.org"
						>
							<HiOutlineChatAlt className="w-6 h-6 text-blue-500" />
							Contact Us
						</a>
					</div>

					<div className="text-center text-xs text-slate-400">
						<p>
							Copyright CARILEC
							<br />
							All rights reserved
						</p>
					</div>
				</div>
			</div>
		</aside>
	);
};

export default Sidebar;
