import React from 'react';
import { useQuery, useQueries, useQueryClient } from 'react-query';
import Table from '../../components/Table';
import TableActions from 'components/TableActions';
import PageTitle from 'containers/PageTitle';
import {
  findBenchmarks,
  // addBenchmark,
  // updateBenchmark,
} from 'services/benchmarks.service';
import { findRoles } from 'services/roles.service';
import { downloadFile } from 'services/file.server';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import Loading from 'components/Loading';
import useAuth from 'hooks/useAuth';

const AccessingReports = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  // console.log(currentUser);
  const {
    isLoading: loadingBenchmarks,
    error,
    data: benchmarks,
  } = useQuery('benchmarks', () =>
    findBenchmarks().then(res => {
      if (currentUser?.user_role.toLowerCase().includes('utility')) {
        return res.data.filter(
          (report: any) => report.utility_id === currentUser?.utility_id,
        );
      } else return res.data;
    }),
  );

  console.log(benchmarks);

  const { data: userroles } = useQuery('userroles', () =>
    findRoles().then(res => res.data),
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleDownloadFile = async (id: string, title: string) => {
    console.log(id);

    // try {
    const fileAttachment: any = await downloadFile(id);
    console.log(fileAttachment);
    if (fileAttachment.error) {
      toast.error(
        'An error occured while downloading the file. File may not exist',
      );
      return;
    }
    // window.location.href = fileAttachment.data;
    const url = window.URL.createObjectURL(new Blob([fileAttachment.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title}`);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
    toast.success('File downloaded successfully');
    // } catch (err) {
    //   console.log(err);
    //   toast.error("Error downloading file");
    // }
  };

  //Benchmark columns
  const columns = [
    {
      Header: 'Year',
      accessor: 'datayear',
      width: 70,
      disableResizing: true,
    },
    {
      Header: 'Utility',
      accessor: 'utility_code',
      width: 70,
      disableResizing: true,
      Cell: ({ cell: { value } }: any) => {
        // console.log(value);
        return value || 'All';
      },
    },
    {
      Header: 'Report Name',
      accessor: 'bm_reportname',
    },
    // {
    //   Header: "Status",
    //   accessor: "bmr_status",
    //   width: 70,
    //   disableResizing: true,
    // },
    {
      Header: 'Created By',
      accessor: 'report_author',
      // width: 100,
      // disableResizing: true,
    },
    {
      Header: 'Date Created',
      accessor: 'created_on',
      width: 120,
      disableResizing: true,
      Cell: ({ cell: { value } }: any) => {
        return format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      width: 100,
      disableResizing: true,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ cell: { row } }: any) => {
        const { uploaded_status, user_role_id } = row.original;
        //Identify user role and benchmark role
        const benchmark_role = userroles
          ?.find((role: any) => role.user_role_id === user_role_id)
          .role.toLowerCase();
        const user_role = currentUser?.user_role.toLowerCase();
        const isUtilityUser = user_role.includes('utility');
        const isPremiumUser =
          user_role.includes('premium') && benchmark_role.includes('premium');
        const isExternalUser =
          user_role.includes('external') &&
          benchmark_role.includes('external') &&
          !benchmark_role.includes('premium');
        //Determine if user can download file
        const isDisabled =
          isUtilityUser || isPremiumUser || isExternalUser ? true : false;

        if (!uploaded_status) {
          return <div>N/A</div>;
        } else if (!isDisabled) {
          return (
            <TableActions
              // onEdit={() => handleToggleModal(row?.original)}
              // onUpload={() => handleFileModal(row?.original)}
              onDownload={() => {
                const { bm_report_id, bm_reportname } = row.original;
                // processReportData({ year_start: datayear, utility_id });
                handleDownloadFile(bm_report_id, bm_reportname);
              }}
            />
          );
        } else return <div></div>;
      },
    },
  ];
  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Accessing Benchmarks" />
      {/* <div>Reports: {reportTitles?.length}</div> */}
      {!benchmarks ? (
        <Loading />
      ) : (
        <Table columns={columns} data={benchmarks} />
      )}
    </div>
  );
};
export default AccessingReports;
