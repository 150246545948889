import axios from "../config/apiClient";

const api_route = "/customercategories";

export const findCustomerCategories = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addCustomerCategory = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateCustomerCategory = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeCustomerCategory = async () => {
  const response = await axios.delete(api_route);

  return response;
};
