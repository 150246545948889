import React from "react";
import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import { useQuery, useQueryClient } from "react-query";
import ProfileForm from "components/forms/ProfileForm";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm";
import { findCountries } from "services/countries.service";
import { getCurrentUser, updateUser } from "services/users.service";
import { changePassword } from "../../services/auth.service";
import { format } from "date-fns";
import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";
import { HiDownload } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import Pdf from "react-to-pdf";
// const ref = React.createRef();
import { renderToString } from "react-dom/server";

import jsPDF from "jspdf";
import PageTitle from "containers/PageTitle";

interface FPFormValues {
  password: string;
  confirm_password: string;
}

const Profile = () => {
  let { currentUser } = useAuth();
  const docRef = React.useRef<any>(null);

  const userId = currentUser.id;

  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data: profileData,
  } = useQuery(
    ["profile", userId],
    () => getCurrentUser(userId).then((res) => res.data),
    { enabled: !!userId }
  );

  console.log(currentUser, profileData);

  const {
    // isLoading,
    // error,
    data: countries,
  } = useQuery("countries", () => findCountries().then((res) => res.data));

  const print = (userData: any) => {
    console.log(userData);
    // const string = renderToString(<Prints user={userData} />);
    const doc = new jsPDF();

    const xOffset = 10;
    let yOffset = 10;
    doc.setFontSize(10);

    const contentArray = [
      { title: "Date Printed:", value: format(new Date(), "PP") },
      { title: "Name:", value: userData?.name },
      // { title: "User Name:", value: userData?.user_name },
      { title: "Country:", value: userData.user_country },
      { title: "Email:", value: userData.email },
      { title: "Telephone:", value: userData.phone },
      {
        title: "Password Expiry Date:",
        value: format(new Date(userData.passw_expiry), "PP"),
      },
    ];

    doc.text("Your Profile Information", xOffset, yOffset);

    contentArray.map((content: any) => {
      yOffset += 5;
      doc.text(`${content.title} ${content.value}`, xOffset, yOffset);
    });

    // doc.text(`Date Printed: ${format(new Date(), "PP")}`, xOffset, yOffset + 5);
    // doc.text(`Name: ${userData.name}`, xOffset, yOffset + 10);
    // doc.text(`User Name: ${userData.user_name}`, 10, 35);
    // doc.text(`Country: ${userData.user_country}`, 10, 40);
    // doc.text(`Email: ${userData.email}`, 10, 45);
    // doc.text(`Telephone: ${userData.phone}`, 10, 50);
    // doc.text(
    //   `Password Expiry Date: ${format(
    //     new Date(userData.passw_expiry),
    //     "yyyyMMdd"
    //   )}`,
    //   10,
    //   55
    // );

    doc.save(`profile-${format(new Date(), "yyyyMMddHHss")}`);
  };

  const handleSubmit = (data: any) => {
    console.log(data);
    updateUser({ ...data }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("profile");
        // handleToggleModal();
      }
    });
  };

  const handleChangePassword = async (data: FPFormValues) => {
    const { password } = data;
    // console.log(password, profileData);
    try {
      console.log("request password reset", data);
      changePassword({ password, user_name: profileData.user_name }).then(
        (res: any) => {
          console.log(res);
          if (res.error) {
            toast.error(res.data.message);
          } else {
            toast.success("Record updated");
            //  queryClient.invalidateQueries("profile");
            // handleToggleModal();
          }
        }
      );
    } catch (error) {
      toast.error("Invalid user credentials");
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div>
      <PageTitle title="User Profile" />
      <Tab.Group>
        <Tab.List>
          <Tab
            className={({ selected }) =>
              selected
                ? "px-4 py-2 bg-blue-500 text-white"
                : "px-4 py-2 bg-white text-black"
            }
          >
            Profile Information
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "px-4 py-2 bg-blue-500 text-white"
                : "px-4 py-2 bg-white text-black"
            }
          >
            Change Password
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="p-3 border-t border-gray-300">
            {" "}
            <div className="w-full flex justify-end">
              <button
                className="inline-flex px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
                onClick={() => print(profileData)}
              >
                <HiDownload className="mr-3 h-5 w-5" />
                Download
              </button>
            </div>
            <div ref={docRef}>
              <ProfileForm
                countries={countries}
                initialValues={profileData}
                onSubmit={handleSubmit}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel className="p-3 border-t border-gray-300">
            <ChangePasswordForm onSubmit={handleChangePassword} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Profile;
