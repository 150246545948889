const Loading = () => (
  <div className="bg-white opacity-60 z-10 h-full w-full flex items-center justify-center">
    <div className="flex flex-col items-center">
      <span className="text-md mr-4">
        Sorry but an error occurred. Please refresh the page
      </span>

      <button className="mt-6 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
        Contact Support
      </button>
    </div>
  </div>
);

export default Loading;
