import { useContext } from "react";
import { ConfirmationDialogContext } from "context/dialog";

const useConfirmationDialog = () => {
  const { openDialog }: any = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default useConfirmationDialog;
