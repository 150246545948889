import axios from "../config/apiClient";

const api_route = "/usagecategories";

export const findUsageCategories = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addUsageCategory = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateUsageCategory = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeUsageCategory = async () => {
  const response = await axios.delete(api_route);

  return response;
};
