import axios from "../config/apiClient";

export const findFuels = async () => {
  const response = await axios.get("/fuels");

  return response;
};

export const addFuel = async (data: any) => {
  const response = await axios.post("/fuels", data);

  return response;
};

export const updateFuel = async (data: any) => {
  const response = await axios.put("/fuels", data);

  return response;
};

export const removeFuel = async () => {
  const response = await axios.delete("/fuels");

  return response;
};
