import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import KPIPermissionForm from "../../components/forms/KPIPermissionForm";
import {
  findKPIPermissions,
  addKPIPermission,
  updateKPIPermission,
} from "services/kpi.service";
import { findRoles } from "services/roles.service";
import { findKPIs } from "services/kpi.service";

const Technologies = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: kpipermissions,
  } = useQuery("kpipermissions", () =>
    findKPIPermissions().then((res) => res.data)
  );

  const { data: userroles } = useQuery("userroles", () =>
    findRoles().then((res) => res.data)
  );

  const { data: kpis } = useQuery("kpis", () =>
    findKPIs().then((res) => res.data)
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addKPIPermission(data).then((res) => {
      console.log(res);
      queryClient.invalidateQueries("kpipermissions");
      handleToggleModal();
    });
  };
  const handleEditRecord = (data: any) => {
    updateKPIPermission(data).then((res) => {
      console.log(res);
      queryClient.invalidateQueries("kpipermissions");
      handleToggleModal();
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "KPI",
        accessor: "kpi_title",
      },
      {
        Header: "User Role",
        accessor: "user_role",
      },
      {
        Header: "KPI Status",
        accessor: "kpi_status",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <div className="flex">
              <TableActions onEdit={() => handleToggleModal(row?.original)} />
              <button
                type="button"
                onClick={() => handleToggleModal(row?.original)}
                className="px-4 py-2 leading-6 text-base rounded-md border border-transparent text-white focus:outline-none bg-blue-500 text-blue-100 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-flex items-center justify-center items-center font-medium focus:outline-none"
              >
                Edit
              </button>
            </div>
          );
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(technologies);

  return (
    <div className="h-full bg-grey-100 p-6">
      <div className="w-full flex justify-between">
        <p className="font-semibold dark:text-white">KPI Permissions</p>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded"
          onClick={toggleModal}
        >
          Add
        </button>
      </div>
      <Table columns={columns} data={kpipermissions} />
      {isModalOpen && (
        <Modal
          modalTitle="New Entry"
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          {/* <KPIPermissionForm
            userroles={userroles}
            kpis={kpis}
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          /> */}
        </Modal>
      )}
    </div>
  );
};
export default Technologies;
