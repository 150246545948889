import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { fillRange } from '../../utils';
import { regex } from '_utils';

type Inputs = {
  utility_id: string;
  data_year: string;
  usage_category_id: string;
  customers: string;
  base_rate: string;
  fuel_surcharge: string;
  vat: string;
  other_levies_taxes: string;
  other_variable_charges: string;
  fixed_charges: string;
  type_of_other_charges: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  usagecategories?: any;
  onEdit: any;
  onAdd: any;
};

export default function RestorationPeriodForm(props: IFormProps) {
  // console.log(props);

  const { utilities, usagecategories } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required('Field is required'),
    data_year: yup.string().required('Field is required'),
    usage_category_id: yup.string().required('Field is required'),
    type_of_other_charges: yup.string(),
    // customers: yup
    //   .string()
    //   .matches(regex.integer.validation, regex.integer.message)
    //   .required(),
    base_rate: yup
      .string()
      .matches(regex.numeric85.validation, regex.numeric85.message)
      .required('Field is required'),
    fuel_surcharge: yup
      .string()
      .matches(regex.numeric85.validation, regex.numeric85.message)
      .required('Field is required'),
    vat: yup
      .string()
      .matches(regex.numeric85.validation, regex.numeric85.message)
      .required('Field is required'),
    other_levies_taxes: yup
      .string()
      .matches(regex.numeric85.validation, regex.numeric85.message)
      .required('Field is required'),
    other_variable_charges: yup
      .string()
      .matches(regex.numeric85.validation, regex.numeric85.message)
      .required('Field is required'),
    fixed_charges: yup
      .string()
      .matches(regex.numeric92.validation, regex.numeric92.message)
      .required('Field is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    console.log(errors, data);
    if (Object.values(errors).length < 1) {
      console.log('no errors');
      // return;
      if (props.initialValues?.ucat_data_id) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="flex justify-between space-x-4">
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('utility_id')}
            placeholder="Select a Utility"
            disabled
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0,
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('data_year')}
            placeholder="Select a Year"
            disabled
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Usage Category
        </span>
        <select
          // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('usage_category_id')}
          placeholder="Select a Usage Category"
        >
          <option value="">--Select One--</option>
          {usagecategories
            ?.sort((a: any, b: any) => {
              return Number(a.usagecategory.slice(3)) >
                Number(b.usagecategory.slice(3))
                ? 1
                : -1;
            })
            .map((option: any) => (
              <option
                key={option.usage_category_id}
                value={option.usage_category_id}
              >
                {option.usagecategory}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="usage_category_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      <div className="grid grid-cols-3 gap-2">
        {/* <label className="block">
          <span className="text-sm font-medium text-gray-400">Customers</span>
          <input
            type="number"
            {...register("customers")}
            placeholder="Enter number here"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="customers"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label> */}
        <label className="block">
          <span className="text-sm font-medium text-gray-400">Base Rate</span>
          <input
            type="number"
            step="any"
            {...register('base_rate')}
            placeholder="Enter amount per kWh ($US)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="base_rate"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Fuel Surcharge
          </span>
          <input
            type="number"
            step="any"
            {...register('fuel_surcharge')}
            placeholder="Enter amount per kWh ($US)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="fuel_surcharge"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">VAT</span>
          <input
            type="number"
            step="any"
            {...register('vat')}
            placeholder="Enter amount per kWh ($US)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="vat"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Other Levies Taxes
          </span>
          <input
            type="number"
            step="any"
            {...register('other_levies_taxes')}
            placeholder="Enter amount per kWh"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="other_levies_taxes"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Other Variable charges
          </span>
          <input
            type="number"
            step="any"
            {...register('other_variable_charges')}
            placeholder="Enter amount per kWh"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="other_variable_charges"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Fixed charges
          </span>
          <input
            type="number"
            step="any"
            {...register('fixed_charges')}
            placeholder="Enter amount per month ($US)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="fixed_charges"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Type of Other Charges
        </span>
        <textarea
          {...register('type_of_other_charges')}
          placeholder="Enter text here"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name="type_of_other_charges"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
