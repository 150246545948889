// import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { regex } from "_utils";
type Inputs = {
  utility_name: string;
  code: string;
  address_1: string;
  address_2?: string;
  country_id: number;
  contact: string;
  contact_phone: string;
  contact_email: string;
};

type IFormProps = {
  initialValues?: any;
  countries: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIUtilityGeneralForm(props: IFormProps) {
  console.log(props);

  const countries = props.countries;

  const schema = yup.object().shape({
    utility_name: yup.string().required("Field is required"),
    code: yup
      .string()
      .max(3, "Code must be no longer than 3 characters")
      .required("Field is required"),
    country_id: yup.string().required("Field is required"),
    contact: yup.string().required("Field is required"),
    contact_phone: yup
      .string()
      .matches(regex.phoneNumber.validation, regex.phoneNumber.message)
      .required("Field is required"),
    contact_email: yup.string().required("Field is required"),
    // bmr_status: yup.string().oneOf(["ANON", "UTIL", "FULL"]).required(),
    // kpi_override: yup
    //   .bool()
    //   .oneOf([true, false], "Accept Ts & Cs is required")
    //   .required(),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(errors);
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  // console.log(getValues());
  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        <label className="block col-span-2">
          <span className="text-sm font-medium text-gray-400">Name</span>
          <input
            {...register("utility_name")}
            placeholder="utility name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="utility_name"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">Code</span>
          <input
            {...register("code")}
            placeholder="utility code"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="code"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Address 1</span>
        <input
          {...register("address_1")}
          placeholder="address line 1"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Address 2</span>
        <input
          {...register("address_2")}
          placeholder="address line 2"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </label>

      <label className="text-gray-400 font-semibold text-sm">
        Choose Country
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register("country_id")}
          placeholder="Select a Country"
        >
          <option value="">--Select One--</option>
          {countries
            ?.sort((a: any, b: any) =>
              a.country_name.toLowerCase() > b.country_name.toLowerCase()
                ? 1
                : b.country_name.toLowerCase() > a.country_name.toLowerCase()
                ? -1
                : 0
            )
            .map((option: any) => (
              <option key={option.country_name} value={option.country_id}>
                {option.country_name}
              </option>
            ))}
        </select>{" "}
        <ErrorMessage
          errors={errors}
          name="country_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Contact Person
        </span>
        <input
          {...register("contact")}
          placeholder="name of contact person"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="contact"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Contact Email</span>
        <input
          {...register("contact_email")}
          placeholder="contact email address"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="contact_email"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Contact Telephone
        </span>
        <input
          {...register("contact_phone")}
          placeholder="contact phone number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="contact_phone"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
