import axios from "../config/apiClient";

export const getUtilityTechnicalData = async () => {
  const response = await axios.get("/utilitytechnicaldata");

  return response;
};

export const findUtilityTechnicalData = async (data_id: any) => {
  const response = await axios.get(`/utilitytechnicaldata/${data_id}`);

  return response;
};

export const addUtilityTechnicalData = async (data: any) => {
  const response = await axios.post("/utilitytechnicaldata", data);

  return response;
};

export const updateUtilityTechnicalData = async (data: any) => {
  const response = await axios.put("/utilitytechnicaldata", data);

  return response;
};

export const removeUtilityTechnicalData = async () => {
  const response = await axios.delete("/utilitytechnicaldata");

  return response;
};
