import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { regex } from '_utils';

type Inputs = {
  // user_name: string;
  name: string;
  email: string;
  phone: string;
  utility_id: string | null;
  country_id: string;
  user_role_id: string;
  locked: string;
};

type IFormProps = {
  initialValues?: any;
  countries: any;
  utilities: any;
  userroles: any;
  onEdit: any;
  onAdd: any;
};

export default function UserForm(props: IFormProps) {
  // console.log(props);
  const countries = props.countries;
  const utilities = props.utilities;
  const roles = props.userroles;
  // const utilities: any[] = props.initialValues[0];
  // const yearFilters: any[] = props.initialValues[1];

  const schema = yup.object().shape({
    // user_name: yup.string().required(),
    name: yup.string().required('Field is required'),
    email: yup.string().email().required('Field is required'),
    phone: yup
      .string()
      .matches(regex.phoneNumber.validation, regex.phoneNumber.message)
      .required('Field is required'),
    country_id: yup.string().required('Field is required'),
    // utility_id: yup.string().required(),
    user_role_id: yup.string().required('Field is required'),
    locked: yup.string().required('Field is required').default('N'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (Object.values(errors).length < 1) {
      console.log('no errors');

      const formData = { ...data };
      if (!formData.utility_id || formData.utility_id?.length < 1) {
        formData.utility_id = null;
      }
      // return;
      if (props.initialValues) {
        props.onEdit(formData);
      } else {
        props.onAdd(formData);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      {/* <label className="block">
        <span className="text-sm font-medium text-gray-400">User name</span>
        <input
          {...register("user_name")}
          placeholder="user name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="user_name"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label> */}

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Full name</span>
        <input
          {...register('name')}
          placeholder="full name"
          type="text"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Email</span>
        <input
          {...register('email')}
          placeholder="Enter email address"
          type="text"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Telephone</span>
        <input
          {...register('phone')}
          placeholder="Enter Telephone number"
          type="text"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="text-gray-400 font-semibold text-sm">
        Choose Country
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('country_id')}
          placeholder="Select a Country"
        >
          <option value="">--Select One--</option>
          {countries
            ?.sort((a: any, b: any) =>
              a.country_name.toLowerCase() > b.country_name.toLowerCase()
                ? 1
                : b.country_name.toLowerCase() > a.country_name.toLowerCase()
                ? -1
                : 0,
            )
            .map((option: any) => (
              <option key={option.country_name} value={option.country_id}>
                {option.country_name}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="country_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="text-gray-400 font-semibold text-sm">
        Select Utility
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('utility_id')}
          placeholder="Select a Utility"
        >
          <option value="">--Select One--</option>
          {utilities
            ?.sort((a: any, b: any) =>
              a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                ? 1
                : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                ? -1
                : 0,
            )
            .map((option: any) => (
              <option key={option.utility_name} value={option.utility_id}>
                {option.utility_name}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="utility_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="text-gray-400 font-semibold text-sm">
        Select Role
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('user_role_id')}
          placeholder="Select a Role"
          // disabled={!!props.initialValues?.user_role_id}
        >
          <option value="">--Select One--</option>
          {roles
            ?.sort((a: any, b: any) =>
              a.role.toLowerCase() > b.role.toLowerCase()
                ? 1
                : b.role.toLowerCase() > a.role.toLowerCase()
                ? -1
                : 0,
            )
            .map((option: any) => (
              <option key={option.role} value={option.user_role_id}>
                {option.role}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="user_role_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      {props.initialValues ? (
        <label className="text-gray-400 font-semibold text-sm">
          Locked?
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('locked')}
            placeholder="Select a Role"
          >
            <option value="">--Select One--</option>
            {[
              { value: 'N', title: 'No' },
              { value: 'Y', title: 'Yes' },
            ].map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="user_role_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      ) : null}

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
