import React from 'react';
// import faker from "@faker-js/faker";
import Table from '../../components/Table';
import TableActions from 'components/TableActions';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import ErrorPage from '../../components/ErrorPage';
import { useQuery, useQueryClient } from 'react-query';
import UserForm from '../../components/forms/UserForm';
import {
  findUsers,
  addUser,
  updateUser,
  removeUser,
} from 'services/users.service';
import { findCountries } from 'services/countries.service';
import { findUtilities } from 'services/utilities.service';
import { findRoles } from 'services/roles.service';
import toast from 'react-hot-toast';
import PageTitle from 'containers/PageTitle';

import useConfirmationDialog from 'hooks/useConfirm';

const Users = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const { getConfirmation } = useConfirmationDialog();

  const queryClient = useQueryClient();

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addUser({ ...data, locked: 'N' }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message || 'Sorry, an unknown error occurred');
      } else {
        toast.success('Record created');
        queryClient.invalidateQueries('users');
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateUser({ ...data }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record updated');
        queryClient.invalidateQueries('users');
        handleToggleModal();
      }
    });
  };

  const handleRemoveUser = async (data: any) => {
    // console.log(data);
    const { active } = data;
    const confirmed = await getConfirmation({
      title: 'Are you sure?',
      message:
        active === 'Y'
          ? 'If you proceed, this user account will be deactivated and locked. They will no longer be able to login'
          : ' This user account is deactivated so if you proceed, their account will be permanently deleted. Please note that user accounts, even deactivated ones, cannot be deleted if they are currently in use',
    });

    if (confirmed) {
      console.log('remove user');
      // deleteUserMutation.mutate(data);
      removeUser(data).then((res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.message);
        } else {
          toast.success(res.message);
          queryClient.invalidateQueries('users');
          // handleToggleModal();
        }
      });
    }
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "First Name",
      //   accessor: "firstName",
      // },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email / Username',
        accessor: 'email',
      },
      // {
      //   Header: "Telephone",
      //   accessor: "telephone",
      // },
      // {
      //   Header: "Country",
      //   accessor: "user_country",
      // },
      {
        Header: 'Utility',
        accessor: 'utility_name',
      },
      {
        Header: 'Role',
        accessor: 'user_role',
      },
      {
        Header: 'Active',
        accessor: 'active',
      },
      {
        Header: 'Locked',
        accessor: 'locked',
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions
              onEdit={() => handleToggleModal(row?.original)}
              onDelete={() => handleRemoveUser(row?.original)}
            />
          );
        },
      },
    ],
    [],
  );

  const {
    isLoading,
    error,
    data: users,
  } = useQuery('users', () => findUsers().then(res => res.data));

  const { data: countries } = useQuery('countries', () =>
    findCountries().then(res => res.data),
  );

  const { data: utilities } = useQuery('utilities', () =>
    findUtilities().then(res => res.data),
  );

  const { data: userroles } = useQuery('userroles', () =>
    findRoles().then(res => res.data),
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  // console.log(users);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Users" onClick={toggleModal} />
      {/* <div className="w-full flex justify-between">
        <p className="font-semibold">Users</p>
      </div> */}
      {users && <Table columns={columns} data={users} />}
      {isModalOpen && (
        <Modal
          modalTitle={`${record ? 'Update' : 'Add'} User`}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <UserForm
            countries={countries}
            utilities={utilities}
            userroles={userroles}
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Users;
