import axios from "../config/apiClient";

export const uploadFile = async (data: any, id: any) => {
  const response = await axios.post(`/benchmarks/upload/${id}`, data, {
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });

  return response;
};

export const downloadFile = async (id: string) => {
  const response = await axios.get(`/benchmarks/download/${id}`, {
    responseType: "blob",
  });

  return response;
};
