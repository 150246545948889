import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { fillRange } from "../../utils";
import { regex } from "_utils";
import useAuth from "hooks/useAuth";
type Inputs = {
 utility_id: string;
 data_year: number;
 fatalities: number;
 restricted_work_injuries: number;
 medical_injuries: number;
 // customer_comment: string;
 days_away_from_work_cases: number;
 total_recordable_injury_freq: string;
 lost_days: number;
 occupational_disease: number;
 first_aid_injuries: number;
 near_misses: number;
 dangerous_occurrences: number;
 major_motor_vehicle_accidents: number;
 minor_motor_vehicle_accidents: number;
 property_damage: number;
 environmental_incidents: number;
 incidents_investigated: number;
 business_interruptions: number;
 loss_control_failures: number;
 extra_comment: string;
 injury_freq_for_serious_injury: string;
};

type IFormProps = {
 initialValues: any;
 utilities?: any;
 onEdit: any;
 onAdd: any;
};

export default function UtilityTechnicalDataForm(props: IFormProps) {
 // console.log(props);
 let { currentUser } = useAuth();
 const { utilities } = props;
 // const yearFilters: any[] = props.initialValues[1];
 const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

 const schema = yup.object().shape({
  utility_id: yup.string().required("Field is required"),
  data_year: yup
   .number()
   .typeError("Must be a number")
   .integer()
   .required("Field is required"),
  fatalities: yup
   .number()
   .typeError("Must be a number")
   .integer()
   .required("Field is required"),
  restricted_work_injuries: yup
   .number()
   .typeError("Must be a number")
   .integer()
   .required("Field is required"),
  medical_injuries: yup
   .number()
   .integer()
   .required("Field is required")
   .typeError("Must be a number"),
  days_away_from_work_cases: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  total_recordable_injury_freq: yup
   .string()
   .matches(regex.numeric101.validation, regex.numeric101.message)
   .required("Field is required"),
  lost_days: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  occupational_disease: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  first_aid_injuries: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  near_misses: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  dangerous_occurrences: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  major_motor_vehicle_accidents: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  minor_motor_vehicle_accidents: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  property_damage: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  environmental_incidents: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  incidents_investigated: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  business_interruptions: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  loss_control_failures: yup
   .number()
   .typeError("Must be a number")
   .integer("Must be an integer")
   .required("Field is required"),
  injury_freq_for_serious_injury: yup
   .string()
   .matches(regex.numeric101.validation, regex.numeric101.message)
   .required("Field is required"),
 });

 const {
  register,
  handleSubmit,
  // watch,
  reset,
  formState: { errors },
 } = useForm<Inputs>({
  mode: "onChange",
  resolver: yupResolver(schema),
  // defaultValues: props.initialValues,
 });

 React.useEffect(() => {
  console.log(props.initialValues);
  if (props.initialValues) {
   reset(props.initialValues);
  } else {
   console.log(currentUser);
   if (currentUser.user_role.toLowerCase().includes("utility")) {
    reset({ utility_id: currentUser.utility_id });
   }
  }
 }, [props.initialValues]);

 const onSubmit: SubmitHandler<Inputs> = (data) => {
  console.log(errors, data);
  if (Object.values(errors).length < 1) {
   console.log("no errors");
   // return;
   if (props.initialValues) {
    props.onEdit(data);
   } else {
    props.onAdd(data);
   }

   // setFormSuccess(!formSuccess)
   //Clear the form fields using react-hook-form
   // props.toggleModal()
  }
 };

 return (
  /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
  <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
   <div className="grid grid-cols-2 gap-2">
    <label className="text-gray-400 font-semibold text-sm">
     Choose Utility
     <select
      // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
      {...register("utility_id")}
      placeholder="Select a Utility"
      disabled={props.initialValues ? true : false}
     >
      <option value="">--Select One--</option>
      {utilities
       ?.sort((a: any, b: any) =>
        a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
         ? 1
         : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
         ? -1
         : 0
       )
       .map((option: any) => (
        <option key={option.utility_id} value={option.utility_id}>
         {option.utility_name}
        </option>
       ))}
     </select>
     <ErrorMessage
      errors={errors}
      name="utility_id"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     Choose Year
     <select
      // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
      {...register("data_year")}
      placeholder="Select a Year"
      disabled={props.initialValues ? true : false}
     >
      <option value="">--Select One--</option>
      {yearFilters.map((option: number) => (
       <option key={option} value={option}>
        {option}
       </option>
      ))}
     </select>
     <ErrorMessage
      errors={errors}
      name="data_year"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
   </div>

   <div className="grid grid-cols-3 gap-2">
    <label className="block">
     <span className="text-sm font-medium text-gray-400">Fatalities</span>
     <input
      type="text"
      {...register("fatalities")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="fatalities"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Restricted Work Injuries
     </span>
     <input
      type="text"
      {...register("restricted_work_injuries")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="restricted_work_injuries"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>{" "}
    <label className="block">
     <span className="text-sm font-medium text-gray-400">Medical Injuries</span>
     <input
      type="text"
      {...register("medical_injuries")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="medical_injuries"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Days Away from Work
     </span>
     <input
      type="text"
      {...register("days_away_from_work_cases")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="days_away_from_work_cases"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Total Recordable Injury Freq
     </span>
     <input
      type="text"
      {...register("total_recordable_injury_freq")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="total_recordable_injury_freq"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>{" "}
    <label className="block">
     <span className="text-sm font-medium text-gray-400">Lost Days</span>
     <input
      type="text"
      {...register("lost_days")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="lost_days"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Occupational Disease
     </span>
     <input
      type="text"
      {...register("occupational_disease")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="occupational_disease"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      First Aid Injuries
     </span>
     <input
      type="text"
      {...register("first_aid_injuries")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="first_aid_injuries"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">Near Misses</span>
     <input
      type="text"
      {...register("near_misses")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="near_misses"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Dangerous Occurrences
     </span>
     <input
      type="text"
      {...register("dangerous_occurrences")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="dangerous_occurrences"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Major Motor Vehicle Accidents
     </span>
     <input
      type="text"
      {...register("major_motor_vehicle_accidents")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="major_motor_vehicle_accidents"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>{" "}
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Minor Motor Vehicle Accidents
     </span>
     <input
      type="text"
      {...register("minor_motor_vehicle_accidents")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="minor_motor_vehicle_accidents"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">Property Damage</span>
     <input
      type="text"
      {...register("property_damage")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="property_damage"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Environmental Incidents
     </span>
     <input
      type="text"
      {...register("environmental_incidents")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="environmental_incidents"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>{" "}
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Incidents Investigated
     </span>
     <input
      type="text"
      {...register("incidents_investigated")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="incidents_investigated"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Business Interruptions
     </span>
     <input
      type="text"
      {...register("business_interruptions")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />{" "}
     <ErrorMessage
      errors={errors}
      name="business_interruptions"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Loss Control Failures
     </span>
     <input
      type="text"
      {...register("loss_control_failures")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="loss_control_failures"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>{" "}
    <label className="block">
     <span className="text-sm font-medium text-gray-400">
      Injury Freq Serious Injury
     </span>
     <input
      type="text"
      {...register("injury_freq_for_serious_injury")}
      placeholder="Enter amount here"
      // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     />
     <ErrorMessage
      errors={errors}
      name="injury_freq_for_serious_injury"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
   </div>

   <label className="block">
    <span className="text-sm font-medium text-gray-400">Comments</span>
    <textarea
     {...register("extra_comment")}
     placeholder="Enter text here"
     // className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    ></textarea>
    <ErrorMessage
     errors={errors}
     name={"extra_comment"}
     render={({ message }) => <p className="text-xs text-red-500">{message}</p>}
    />
   </label>

   <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
    Save
   </button>
  </form>
 );
}
