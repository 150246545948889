import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import VoltageCategoryForm from "../../components/forms/VoltageCategoryForm";
import {
  findVoltageCategories,
  addVoltageCategory,
  updateVoltageCategory,
} from "services/voltagecategories.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const Technologies = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: voltagecategories,
  } = useQuery("voltagecategories", () =>
    findVoltageCategories().then((res) => res.data)
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addVoltageCategory(data).then((res: any) => {
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("voltagecategories");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateVoltageCategory({
      ...data,
      voltage_cat_id: record?.voltage_cat_id,
    }).then((res: any) => {
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("voltagecategories");
        handleToggleModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "voltage_category",
      },
      {
        Header: "Lower Limit",
        accessor: "lower_voltage_limit",
      },
      {
        Header: "Upper Limit",
        accessor: "upper_voltage_limit",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(technologies);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Voltage Categories" onClick={toggleModal} />
      <Table columns={columns} data={voltagecategories} />
      {isModalOpen && (
        <Modal
          modalTitle="New Entry"
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <VoltageCategoryForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Technologies;
