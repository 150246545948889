import { RouteProps, Outlet } from "react-router-dom";
import Navbar from "../containers/Navbar";
import Sidebar from "../containers/Sidebar";
interface IProps extends RouteProps {}

const PageWrapper: React.FC<IProps> = () => {
  //   const location = useLocation();
  // Grab the pathname for the current path
  //   const routeName = location.pathname.split("/").pop();
  // console.log(routeName);
  return (
    <div className="h-screen min-h-full flex flex-col">
      <Navbar />
      <div className="flex-1 flex overflow-hidden bg-gray-100">
        <Sidebar />
        {/* <div
          className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
          id="sidebarBackdrop"
        ></div> */}
        <main className="flex-1 bg-gray-100 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default PageWrapper;
