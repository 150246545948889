import axios from "../config/apiClient";

const api_route = "/voltagecategories";

export const findVoltageCategories = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addVoltageCategory = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateVoltageCategory = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeVoltageCategory = async () => {
  const response = await axios.delete(api_route);

  return response;
};
