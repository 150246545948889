import axios from "../config/apiClient";

const api_route = "/climatedata";

export const getClimateData = async () => {
  const response = await axios.get("/climatedata");

  return response;
};

export const findClimateData = async (data_id: any) => {
  const response = await axios.get(`/climatedata/${data_id}`);

  return response;
};

export const addClimateData = async (data: any) => {
  const response = await axios.post("/climatedata", data);

  return response;
};

export const updateClimateData = async (data: any) => {
  const response = await axios.put("/climatedata", data);

  return response;
};

export const removeClimateData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res;
  });
};
