import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { fillRange } from '../../utils';
import { regex } from '_utils';

type Inputs = {
  utility_id: string;
  data_year: string;
  tech_id: string;
  installed_capacity: string;
  generated_energy: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  technologies?: any;
  onEdit: any;
  onAdd: any;
};

export default function TechnologyDataForm(props: IFormProps) {
  // console.log(props);

  const { utilities, technologies } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required('Field is required'),
    data_year: yup.string().required('Field is required'),
    tech_id: yup.string().required('Field is required'),
    generated_energy: yup
      .string()
      .matches(regex.numeric101.validation, regex.numeric101.message)
      .required('Field is required'),
    installed_capacity: yup
      .string()
      .matches(regex.numeric101.validation, regex.numeric101.message)
      .required('Field is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    console.log(errors, data);
    if (Object.values(errors).length < 1) {
      console.log('no errors');
      // return;
      if (props.initialValues?.tech_data_id) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('utility_id')}
            placeholder="Select a Utility"
            disabled
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0,
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('data_year')}
            placeholder="Select a Year"
            disabled
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Technology</span>
        <select
          // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('tech_id')}
          placeholder="Select a Technology"
        >
          <option value="">--Select One--</option>
          {technologies?.map((option: any) => (
            <option key={option.tech_id} value={option.tech_id}>
              {option.technology}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="tech_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      <div className="grid grid-cols-3 gap-4">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generated Energy
          </span>
          <input
            type="text"
            {...register('generated_energy')}
            placeholder="Enter amount (GWh)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="generated_energy"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Installed Capacity
          </span>
          <input
            type="text"
            {...register('installed_capacity')}
            placeholder="Enter amount (MW)"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="installed_capacity"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
