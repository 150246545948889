// import React from "react";
import {
  // Controller,
  useForm,
  useFieldArray,
  SubmitHandler,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type Inputs = {
  // kpi_id: number;
  kpipermissions: [any];
  // kpi_status: string;
};

type IFormProps = {
  initialValues?: any;
  userroles?: any;
  // kpi_id?: any;
  onEdit: any;
  onAdd: any;
  toggleModal?: any;
};

export default function KPIPermissionForm(props: IFormProps) {
  // console.log(props.initialValues);
  const roles = props.userroles;

  const schema = yup.object().shape({
    kpipermissions: yup
      .array()
      .of(
        yup.object().shape({
          user_role_id: yup.string().required("Field is required"),
          kpi_status: yup.string().required("Field is required"),
        })
      )
      .typeError("Please select kpi permissions")
      .nullable()
      .default([]),
  });

  const {
    control,
    formState: { errors },
    register,
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      kpipermissions: props.initialValues?.kpipermissions,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "kpipermissions", // unique name for your Field Array
  });

  const handleAddRow = () => {
    append({ user_role_id: "", kpi_status: "" });
  };

  const onSubmit: SubmitHandler<Inputs> = (data: any) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors", data);
      const prunedPermissions = data.kpipermissions.map((item: any) => {
        const { role_title, ...rest } = item;
        return { ...rest, kpi_id: props.initialValues.kpi_id };
      });
      console.log({
        ...props.initialValues,
        kpipermissions: prunedPermissions,
      });
      // return;
      if (props.initialValues) {
        props.onEdit({
          ...props.initialValues,
          kpi_permission: prunedPermissions,
        });
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      props.toggleModal();
    }
  };

  var roleOptions = roles?.filter(
    (n: any) =>
      !getValues("kpipermissions").some(
        (n2: any) => n.user_role_id == n2.user_role_id
      )
  );
  // console.log(roleOptions);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form className="space-y-6">
      {fields.map((field: any, index) => (
        <div key={field.id} className="flex items-center gap-4 w-full">
          <label className="flex-1">
            <span className="text-sm font-medium text-gray-400">User Role</span>
            <select
              className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
              {...register(`kpipermissions.${index}.user_role_id`)}
              placeholder="Select a Role"
            >
              <option value="">--Select One--</option>
              {field.user_role_id
                ? roles
                    .sort((a: any, b: any) =>
                      a.role.toLowerCase() > b.role.toLowerCase()
                        ? 1
                        : b.role.toLowerCase() > a.role.toLowerCase()
                        ? -1
                        : 0
                    )
                    .map((option: any) => (
                      <option key={option.role} value={option.user_role_id}>
                        {option.role}
                      </option>
                    ))
                : roleOptions
                    .sort((a: any, b: any) =>
                      a.role.toLowerCase() > b.role.toLowerCase()
                        ? 1
                        : b.role.toLowerCase() > a.role.toLowerCase()
                        ? -1
                        : 0
                    )
                    .map((option: any) => (
                      <option key={option.role} value={option.user_role_id}>
                        {option.role}
                      </option>
                    ))}
            </select>
            <p className="text-xs text-red-500">
              {errors.kpipermissions?.[index]?.user_role_id?.message}
            </p>
          </label>
          <label className="flex-1">
            <span className="text-sm font-medium text-gray-400">
              KPI Status
            </span>

            <select
              {...register(`kpipermissions.${index}.kpi_status`)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">--Select One--</option>
              {["FULL", "ANON", "UTIL"]
                .sort((a: any, b: any) =>
                  a.toLowerCase() > b.toLowerCase()
                    ? 1
                    : b.toLowerCase() > a.toLowerCase()
                    ? -1
                    : 0
                )
                .map((option) => (
                  <option key={option}>{option}</option>
                ))}
            </select>
            <p className="text-xs text-red-500">
              {errors.kpipermissions?.[index]?.kpi_status?.message}
            </p>
          </label>
          <div>
            <button
              className="rounded-full w-6 h-6 bg-red-300 text-xs text-white p-1"
              onClick={() => remove(index)}
            >
              x
            </button>
          </div>
        </div>
      ))}

      {/* <Controller
          control={control}
          name="kpipermissions"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <ReactSelect
              isMulti
              value={value}
              onChange={(e) => onChange(e)}
              options={permissionsOptions}
            />
          )}
        /> */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleAddRow}
          className="px-4 py-2 bg-blue-600 text-white rounded"
        >
          Add Permission Type
        </button>
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          className="px-4 py-2 bg-blue-600 text-white rounded"
        >
          Save
        </button>
      </div>
    </form>
  );
}
