import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
// import { MultiSelect } from 'react-multi-select-component';
import MultiSelect from '../MultiSelect';
import ReactSelect from 'react-select';
import * as yup from 'yup';

const ReportFilter = ({
  usageCategories,
  utilityOptions,
  onSubmit,
  userPermission,
  canSelectMultiple,
}: any) => {
  const [selected, setSelected] = React.useState([]);
  const { pathname } = useLocation();
  // // console.log(location);
  // console.log(pathname.split("/").pop());
  const reportPath = pathname?.split('/').pop();
  const disabledEndYear = [
    'installed-capacity',
    'energy-generation-per-pes',
    'capacity-change-per-pes',
    'generation-change-per-pes',
    'disaggregated-tariffs',
    'cost-by-type',
    'cost-by-business-area',
    'fuel-cost',
    'fuel-efficiency-own-units',
    'thermal-generation-cost',
    'grid-loss-per-volt-level',
    'share-of-underground-line',
    'share-per-customer-group',
    'cap-threshold-for-der',
    'support-mechanism-for-der',
    'ee-and-dsm-programs',
    'availability-of-funds',
    'grid-modernization',
    'gender',
    'environmental-performance',
    'osh-implementation',
  ].includes(reportPath || '');
  const minYear = 1980;
  const maxYear = new Date().getFullYear() - 1;

  const fillRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );

  const yearFilters = fillRange(maxYear, minYear, -1);
  const schema = yup
    .object({
      year_start: yup
        .number()
        .label('Start Year')
        .typeError('Field must be a number')
        .transform(value => (isNaN(value) ? undefined : value))
        .required(),
      year_end: yup.number().when([], {
        is: (val: any) => {
          return !disabledEndYear;
        },
        then: yup
          .number()
          .label('End Year')
          .typeError('Field must be a number')
          .transform(value => (isNaN(value) ? undefined : value))
          .required(),
        otherwise: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable(),
      }),
      utility_id: yup
        .array(
          yup.object({
            label: yup.string().required('Field is required'),
            value: yup.string().nullable(),
          }),
        )
        .min(1, 'At least one utility is required')
        .required('Field is required'),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    // Remove utility_id value if not in user utilities
    // console.log(getValues('utility_id'));

    //Set utility_id value if user permission is ANON
    if (userPermission === 'ANON') {
      setValue('utility_id', [{ label: 'Select All', value: 'all' }]);
    } else {
      let difference = getValues('utility_id')?.filter((x: any) =>
        utilityOptions
          .map((option: any) => option.utility_id)
          .includes(x.value),
      );
      // console.log(difference);
      setValue('utility_id', difference);
      // console.log(utilityOptions);
    }
  }, [utilityOptions, userPermission]);

  console.log(watch('utility_id'));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex items-center gap-4 mb-6"
    >
      <label className=" text-gray-400 text-sm">
        Start Year
        <select
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register('year_start')}
          placeholder="Select a Year"
          onChange={(evt: any) => {
            const { value } = evt.target;
            if (
              getValues('utility_id')?.length > 1 ||
              getValues('utility_id').some(
                (option: any) => option.value === 'all',
              )
            ) {
              // const year_start = getValues("year_start");
              setValue('year_end', value);
            }
          }}
        >
          <option value="">--Select Year--</option>
          {yearFilters.map((option: number) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="year_start"
          render={({ message }: any) => (
            <p className="block text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      {!disabledEndYear && (
        <label className=" text-gray-400 text-sm">
          End Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('year_end')}
            placeholder="Select a Year"
            disabled={watch('utility_id')?.length > 1}
          >
            <option value="">--Select Year--</option>
            {yearFilters
              .filter((year: number) =>
                getValues('year_start')?.length > 0
                  ? Number(year) >= Number(watch('year_start'))
                  : true,
              )
              .map((option: number) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="year_end"
            render={({ message }: any) => (
              <p className="block text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      )}
      <label className="flex-1 text-gray-400 text-sm">
        Choose Utility
        <Controller
          control={control}
          name="utility_id"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <ReactSelect
              key="utility_id"
              value={value}
              options={[
                { label: 'Select All', value: 'all' },
                ...utilityOptions?.map((option: any) => ({
                  label: option.utility_name,
                  value: option.utility_id,
                })),
              ]}
              isMulti
              closeMenuOnScroll={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              isDisabled={userPermission === 'ANON' ? true : false}
              onChange={(value: any) => {
                //If all utilities are selected, select ALL utilities
                if (
                  value.length > 1 &&
                  value.length === utilityOptions.length
                ) {
                  value = [{ label: 'Select All', value: 'all' }];
                }

                //if all is selected, remove all other options
                if (value[value.length - 1]?.value === 'all') {
                  value = value.filter((option: any) => option.value === 'all');
                } else {
                  value = value.filter((option: any) => option.value !== 'all');
                }
                //If more than one utility is selected...
                if (
                  value.length > 1 ||
                  value.some((option: any) => option.value === 'all')
                ) {
                  //If multi select is disabled, select the last option
                  if (!canSelectMultiple) {
                    value = [value[value.length - 1]];
                  }
                  const year_start = getValues('year_start');
                  setValue('year_end', Number(year_start), {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }
                onChange(value);
              }}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="utility_id"
          render={({ message }: any) => (
            <p className="block text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      {usageCategories && (
        <label className="flex-1 text-gray-400 text-sm">
          Usage Categories
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register('usage_category_id')}
            placeholder="Select a Usage Category"
          >
            <option value="">--Select One--</option>

            {usageCategories
              ?.sort((a: any, b: any) => {
                return Number(a.usagecategory.slice(3)) >
                  Number(b.usagecategory.slice(3))
                  ? 1
                  : -1;
              })
              .map((option: any) => (
                <option
                  key={option.usage_category_id}
                  value={option.usage_category_id}
                >
                  {option.usagecategory}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="usage_category_id"
            render={({ message }: any) => (
              <p className="block text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      )}
      <div>
        <button
          type="submit"
          className="px-4 py-2 leading-6 text-base rounded-md border border-transparent text-white focus:outline-none bg-blue-500 text-blue-100 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-flex items-center justify-center items-center font-medium focus:outline-none"
        >
          Run
        </button>
      </div>
    </form>
  );
};

export default ReportFilter;
