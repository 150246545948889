import React from "react";
import { useQuery, useQueries, useQueryClient } from "react-query";
// import Table from "../../components/Table";
// import TableActions from "components/TableActions";
import RawDataForm from "components/forms/RawDataForm";
import PageTitle from "containers/PageTitle";
import { findUtilities } from "services/utilities.service";
import { findBenchmarks } from "services/benchmarks.service";
import { getFinancialData } from "services/utilityfinancialdata.service";
import { getUtilityGeneralData } from "services/utilitygeneraldata.service";
import { getUtilityExtraData } from "services/utilityextradata.service";
import { getUtilityTechnicalData } from "services/utilitytechnicaldata.service";
import { getClimateData } from "services/climateresiliencedata.service";
import { getUtilityCustomerData } from "services/utilitycustomerdata.service";
import { getCustomerCategoryData } from "services/customercategorydata.service";
import { getUsageCategoryData } from "services/usagecategorydata.service";
import { getRestorationPeriod } from "services/restorationperiod.service";
import { getVoltageCategoryData } from "services/voltagecategorydata.service";
import { getFuelData } from "services/fueldata.service";
import { getTechnologyData } from "services/technologydata.service";
import { getDerSupportData } from "services/dersupportdata.service";
import { format } from "date-fns";
import * as XLSX from "xlsx";

import useAuth from "hooks/useAuth";

const RawData = () => {
 let [isModalOpen, setIsOpen] = React.useState(false);

 let { currentUser } = useAuth();
 //  console.log(currentUser);

 const { isLoading: loadingUtilities, data: utilities } = useQuery(
  "utilities",
  () =>
   findUtilities().then((res) => {
    //Filter utilities by user role
    return res.data;
   })
 );

 const { isLoading: loadingUtilityFinancialData, data: utilityFinancialData } =
  useQuery("utilityfinancialdata", () =>
   getFinancialData().then((res) => res.data)
  );

 const { isLoading: loadingUtilityExtraData, data: utilityExtraData } =
  useQuery("utilityextradata", () =>
   getUtilityExtraData().then((res) => res.data)
  );

 const { data: utilityGeneralData } = useQuery("utilitygeneraldata", () =>
  getUtilityGeneralData().then((res) => res.data)
 );

 const { data: climateResilienceData } = useQuery("climateresiliencedata", () =>
  getClimateData().then((res) => res.data)
 );

 const { data: utilityCustomerData } = useQuery("utilitycustomerdata", () =>
  getUtilityCustomerData().then((res) => res.data)
 );

 const { data: customerCategoryData } = useQuery("customercategorydata", () =>
  getCustomerCategoryData().then((res) => res.data)
 );

 const { data: restorationPeriodData } = useQuery("restorationperioddata", () =>
  getRestorationPeriod().then((res) => res.data)
 );

 const { data: usageCategoryData } = useQuery("usagecategorydata", () =>
  getUsageCategoryData().then((res) => res.data)
 );

 //technical data & subtables
 const { data: utilityTechnicalData } = useQuery("utilitytechnicaldata", () =>
  getUtilityTechnicalData().then((res) => res.data)
 );

 const { data: voltageCategoryData } = useQuery("voltagecategorydata", () =>
  getVoltageCategoryData().then((res) => res.data)
 );

 const { data: fuelData } = useQuery("fueldata", () =>
  getFuelData().then((res) => res.data)
 );

 const { data: technologyData } = useQuery("technologydata", () =>
  getTechnologyData().then((res) => res.data)
 );

 const { data: derSupportData } = useQuery("dersupportdata", () =>
  getDerSupportData().then((res) => res.data)
 );

 function toggleModal() {
  setIsOpen(!isModalOpen);
 }

 const handleProcessData = (data: any) => {
  console.log(data);
  const { utility_id, data_type } = data;

  const utility = utilities.find((u: any) => u.utility_id === utility_id);

  //Check type of report to generate
  let exportData = null;
  const subTables: any = [];
  if (data_type === "financial") {
   //Get financial data
   const financialData = utilityFinancialData.filter((item: any) =>
    utility
     ? item.data_year === Number(data.data_year) &&
       item.utility_id === utility_id
     : item.data_year === Number(data.data_year)
   );
   console.log("get financial data", financialData);

   exportData = financialData?.map((item: any) => {
    const {
     utility_id,
     utility_name,
     utility_code,
     utility_financial_data_id,
     ...rest
    } = item;
    return { utility_name, utility_code, ...rest };
   });
   // console.log(exportData);
   // exportData = [{ utility_name, utility_code, ...rest }];
  } else if (data_type === "general") {
   const generalData = utilityGeneralData.filter((item: any) =>
    utility
     ? item.data_year === Number(data.data_year) &&
       item.utility_id === utility_id
     : item.data_year === Number(data.data_year)
   );

   exportData = generalData?.map((item: any) => {
    const {
     utility_id,
     utility_name,
     utility_code,
     utility_gen_data_id,
     ...rest
    } = item;

    //Using the utility_id,
    // get the subtable data (climate resilience)
    const climate_data = climateResilienceData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, clim_resil_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Climate Data`,
     data: climate_data,
    });

    return { utility_name, utility_code, ...rest };
   });
   // console.log(exportData);
  } else if (data_type === "customer") {
   const customerData = utilityCustomerData.filter((item: any) =>
    utility
     ? item.data_year === Number(data.data_year) &&
       item.utility_id === utility_id
     : item.data_year === Number(data.data_year)
   );

   exportData = customerData?.map((item: any) => {
    const {
     utility_id,
     utility_name,
     utility_code,
     utility_cust_data_id,
     ...rest
    } = item;

    //Using the utility_id,
    // get the subtables data (customer category, restoration period, usage category)
    const cust_category_data = customerCategoryData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, cust_categ_dataid, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Customer Category`,
     data: cust_category_data,
    });

    const restoration_period_data = restorationPeriodData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, restore_pd_data_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Restoration Period`,
     data: restoration_period_data,
    });

    const customer_usage_data = usageCategoryData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, ucat_data_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Customer Usage`,
     data: customer_usage_data,
    });

    return { utility_name, utility_code, ...rest };
   });
  } else if (data_type === "technical") {
   const technicalData = utilityTechnicalData.filter((item: any) =>
    utility
     ? item.data_year === Number(data.data_year) &&
       item.utility_id === utility_id
     : item.data_year === Number(data.data_year)
   );

   exportData = technicalData?.map((item: any) => {
    const {
     utility_id,
     utility_name,
     utility_code,
     utility_tech_data_id,
     ...rest
    } = item;

    //Using the utility_id,
    // get the subtables data (voltage category, technology, restoration period, usage category)
    const voltage_category_data = voltageCategoryData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, voltage_cat_data_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Voltage Category`,
     data: voltage_category_data,
    });

    const technology_data = technologyData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, tech_data_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Technology`,
     data: technology_data,
    });

    const der_support_data = derSupportData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, der_suppdata_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} DER Support`,
     data: der_support_data,
    });

    const fuel_data = fuelData
     .filter((data: any) => data.utility_id === utility_id)
     .map((record: any) => {
      const { utility_id, utility_name, fuel_data_id, ...rest } = record;
      return { utility_name, ...rest };
     });
    subTables.push({
     title: `${utility_code} Fuel`,
     data: fuel_data,
    });

    return { utility_name, utility_code, ...rest };
   });
  } else if (data_type === "extra") {
   //Get extra data

   const extraData = utilityExtraData.filter((item: any) =>
    utility
     ? item.data_year === Number(data.data_year) &&
       item.utility_id === utility_id
     : item.data_year === Number(data.data_year)
   );
   // console.log("get extra data", extraData);
   exportData = extraData?.map((item: any) => {
    const {
     utility_id,
     utility_name,
     utility_code,
     utility_extra_id,
     ...rest
    } = item;
    return { utility_name, utility_code, ...rest };
   });
  } else {
   alert("Invalid report type selected");
   return;
  }

  // console.log(exportData);
  const wb = XLSX.utils.book_new();

  // creating sheet and adding data from 2nd row of column A.
  // Leaving first row to add Heading
  const ws = XLSX.utils.json_to_sheet(exportData);

  // appending sheet with a name
  XLSX.utils.book_append_sheet(wb, ws, data_type);

  //Based on data type, add additional sheets
  subTables.length > 0 &&
   subTables.map((table: any) => {
    const ws = XLSX.utils.json_to_sheet(table.data);
    XLSX.utils.book_append_sheet(wb, ws, table.title);
   });
  const utility_code = utility ? utility.code : "All";

  // const fileContent = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
  XLSX.writeFile(
   wb,
   `${utility_code}-${data_type}-${format(new Date(), "yyyyMMddHHmmss")}.xlsx`
  );
 };

 return (
  <div className="h-full bg-grey-100">
   <PageTitle title="Access Raw Data" />
   {utilities && (
    <RawDataForm
     utilityOptions={
      !currentUser?.user_role.toLowerCase().includes("carilec")
       ? utilities.filter(
          (utility: any) => utility.utility_id === currentUser.utility_id
         )
       : utilities
     }
     onSubmit={handleProcessData}
    />
   )}
  </div>
 );
};
export default RawData;
