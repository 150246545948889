import React from "react";
// import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import CountryForm from "../../components/forms/CountryForm";
import {
  findCountries,
  addCountry,
  updateCountry,
} from "services/countries.service";
import toast from "react-hot-toast";
import PageTitle from "containers/PageTitle";

const Countries = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: countries,
  } = useQuery("countries", () => findCountries().then((res) => res.data));

  // if (isLoading) return <div>Loading</div>;

  // if (error) return <div>An error occurred...</div>;

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addCountry(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("countries");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateCountry({ ...data, id: record?.country_id }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("countries");
        handleToggleModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Country Code",
      //   accessor: "countryCode",
      // },
      {
        Header: "Country",
        accessor: "country_name",
      },
      {
        Header: "Population",
        accessor: "population",
      },
      {
        Header: "GDP",
        accessor: "gdp",
      },
      {
        Header: "Area",
        accessor: "area",
      },
      {
        Header: "Grid Emission Factor",
        accessor: "grid_emission_factor",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ], //eslint-disable-next-line
    []
  );
  console.log(countries);

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Countries" onClick={toggleModal} />

      <Table columns={columns} data={countries} />
      {isModalOpen && (
        <Modal
          modalTitle={`${record ? "Update" : "Add"} Country`}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <CountryForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Countries;
