import axios from "../config/apiClient";

const api_route = "/technologydata";

export const getTechnologyData = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addTechnologyData = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateTechnologyData = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeTechnologyData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res;
  });
};
