import axios from "../config/apiClient";

const api_route = "/dersupportdata";

export const getDerSupportData = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addDerSupportData = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateDerSupportData = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeDerSupportData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res.data;
  });
};
