import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { findTechnologies } from 'services/technologies.service';
import { findUtilities } from 'services/utilities.service';

import { getUtilityGeneralData } from 'services/utilitygeneraldata.service';
import { getFinancialData } from 'services/utilityfinancialdata.service';
import { getUtilityTechnicalData } from 'services/utilitytechnicaldata.service';
import { getUtilityCustomerData } from 'services/utilitycustomerdata.service';
import { getRestorationPeriod } from 'services/restorationperiod.service';
import { getTechnologyData } from 'services/technologydata.service';
import { getClimateData } from 'services/climateresiliencedata.service';
import {
  findDERSupport,
  addDERSupport,
  updateDERSupport,
} from 'services/dersupport.service';
import { findUsageCategories } from 'services/usagecategories.service';
import { getUsageCategoryData } from 'services/usagecategorydata.service';
import { getDerSupportData } from 'services/dersupportdata.service';

import { findKPIs } from 'services/kpi.service';
import { findRoles } from 'services/roles.service';
import PageTitle from 'containers/PageTitle';
import Sidebar from 'containers/ReportSideBar';
import Table from 'components/Table';

import ReportFilterForm from 'components/forms/ReportFilterForm';
import BarChart from 'components/charts/BarChart';
import {
  calculateDERCapacity,
  calculateAvgRECost,
  calculateSolarCapacityFactor,
  calculateWindCapacityFactor,
  calculateEnvironmentalPerformance,
  calculateClimateResilience,
  calculateOSHImplementation,
  calculateEEDSMProgram,
  calculateAvailabilityOfFunds,
  calculateCapThresholdDER,
  calculateGender,
  calculateSupportMechanismDER,
  calculateGridModernization,
} from '_utils/calculations';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { formatReportTitle } from '_utils';

export default function App() {
  let { currentUser } = useAuth();
  //   console.log(currentUser);

  const { title: reportTitle } = useParams();
  const location = useLocation();
  console.log(location.pathname);
  const category = location.pathname.split('/')[3];

  //   const currentRole: string = currentUser.user_role;
  const userUtility: string[] = [currentUser.utility_id];
  const [graphData, setGraphData] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any>(null);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [tableColumns, setTableColumns] = React.useState<any>(null);
  const [tableColumnsDER, setTableColumnsDER] = React.useState<any>(null);

  const [selectedUtility, setSelectedUtility] = React.useState<any>(null);
  const [selectedStartYear, setSelectedStartYear] = React.useState<any>(null);

  React.useEffect(() => {
    setGraphData(null);
    setTableData(null);
  }, [reportTitle]);

  const { data: utilities } = useQuery('utilities', () =>
    findUtilities().then(res => res.data),
  );

  const { data: usagecategories } = useQuery('usercategories', () =>
    findUsageCategories().then(res => res.data),
  );

  const {
    isLoading,
    error,
    data: usageCategoryData,
  } = useQuery('usagecategorydata', () =>
    getUsageCategoryData().then(res => res.data),
  );

  const { data: kpis } = useQuery(['kpipermissions', category], () =>
    findKPIs().then(res => {
      const kpis = res.data;
      const items = kpis?.filter((item: any) =>
        item.kpi_category.toLowerCase().includes('sustainability'),
      );
      return items;
    }),
  );

  React.useEffect(() => {
    const current_kpi = kpis?.find(
      (kpi: any) =>
        kpi?.kpi?.trim().toLowerCase() === reportTitle?.split('-').join(' '),
    );
    // const title =
    //   current_kpi?.description.substring(0, 6) +
    //   " - " +
    //   current_kpi?.kpi +
    //   " (" +
    //   current_kpi?.kpi_units +
    //   ")";
    // console.log(title);
    setCurrentTitle(formatReportTitle(current_kpi));
  }, [kpis, reportTitle]);

  const { data: userPermission } = useQuery(
    ['userPermission', reportTitle],
    () =>
      findKPIs().then(res => {
        const kpis = res.data;
        const kpi = kpis?.find(
          (kpi: any) =>
            kpi.kpi.trim().toLowerCase() === reportTitle?.split('-').join(' '),
        );
        // console.log(kpi);
        console.log(kpi, reportTitle, currentUser);
        const userPermission = kpi?.kpipermissions?.find(
          (permission: any) => permission.user_role_id === currentUser.role_id,
        )?.kpi_status;
        console.log(userPermission);
        return userPermission;
      }),
    { enabled: !!reportTitle },
  );

  // console.log(userPermission);

  const { data: utilityFinancialData } = useQuery('utilityfinancialdata', () =>
    getFinancialData().then(res => res.data),
  );

  const { data: utilityTechnicalData } = useQuery('utilitytechnicaldata', () =>
    getUtilityTechnicalData().then(res => res.data),
  );

  const { data: utilityGeneralData } = useQuery('utilitygeneraldata', () =>
    getUtilityGeneralData().then(res => res.data),
  );

  const { data: technologyData } = useQuery('technologydata', () =>
    getTechnologyData().then(res => res.data),
  );

  const { data: technologies } = useQuery('technologies', () =>
    findTechnologies().then(res => res.data),
  );

  const { data: derSupport } = useQuery('dersupport', () =>
    findDERSupport().then(res => res.data),
  );

  const { data: derSupportData } = useQuery('dersupportdata', () =>
    getDerSupportData().then(res => res.data),
  );

  const { data: climateResilienceData } = useQuery(
    'climateresiliencedata',
    () => getClimateData().then(res => res.data),
  );

  // console.log(utilityGeneralData);

  React.useEffect(() => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Residential Band',
        accessor: 'capacity_threshold_for_residential_re',
      },
      {
        Header: 'Commercial/Industrial Band',
        accessor: 'capacity_threshold_for_c_i_re',
      },
    ];

    setTableColumns(headerColumns);
  }, [technologies]);

  // React.useEffect(() => {
  // 	let headerColumns: any = [
  // 		{
  // 			Header: 'Utility',
  // 			accessor: 'utility_code',
  // 			width: 100,
  // 			disableResizing: true,
  // 		},
  // 		{
  // 			Header: 'Year',
  // 			accessor: 'data_year',
  // 			width: 100,
  // 			disableResizing: true,
  // 		},
  // 	];

  // 	derSupport?.map((data: any) => {
  // 		// console.log(tech);
  // 		headerColumns.push({
  // 			Header: data?.support,
  // 			accessor: data?.support.split(' ').join('_').toLowerCase(),
  // 			// accessor: data?.der_supp_id,
  // 			// width: 100,
  // 			// disableResizing: true,
  // 		});
  // 	});
  // 	// headerColumns.push()
  // 	// console.log(technologies);

  // 	setTableColumnsDER(headerColumns);
  // }, [derSupport]);

  const onSubmit = (data: any) => {
    let selectedUtilities = data.utility_id;
    //If data.utility_id is null then
    if (data.utility_id[0].label === 'Select All') {
      selectedUtilities = utilities?.map((utility: any) => ({
        value: utility.utility_id,
        label: utility.utility_name,
      }));
    }
    setSelectedUtility(selectedUtilities);
    setSelectedStartYear(data.year_start);
    //If utility = all

    //TODO: Check if the year range is valid

    let calcResults: any = {};
    const reportType =
      reportTitle &&
      [
        'disaggregated-tariffs',
        'installed-capacity',
        'energy-generation-per-pes',
        'capacity-change-per-pes',
        'generation-change-per-pes',
        'cost-by-type',
        'cost-by-business-area',
        'fuel-cost',
        'fuel-efficiency-own-units',
        'thermal-generation-cost',
        'grid-loss-per-volt-level',
        'share-of-underground-line',
        'share-per-customer-group',
        'cap-threshold-for-der',
        'support-mechanism-for-der',
        'ee-and-dsm-programs',
        'availability-of-funds',
        'grid-modernization',
        // 'osh-implementation',
        'gender',
      ].includes(reportTitle)
        ? 'table'
        : 'graph';
    if (reportType === 'table') {
      var results = [];
      let headerColumns: any = [];
      switch (reportTitle) {
        case 'cap-threshold-for-der': //report505
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Residential Band',
              accessor: 'capacity_threshold_for_residential_re',
            },
            {
              Header: 'Commercial/Industrial Band',
              accessor: 'capacity_threshold_for_c_i_re',
            },
          ];
          results = calculateCapThresholdDER(
            utilityTechnicalData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );

          break;
        case 'support-mechanism-for-der':
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
          ];

          derSupport?.map((data: any) => {
            // console.log(tech);
            headerColumns.push({
              Header: data?.support,
              accessor: data?.support.split(' ').join('_').toLowerCase(),
              // accessor: data?.der_supp_id,
              // width: 100,
              // disableResizing: true,
            });
          });

          results = calculateSupportMechanismDER(
            derSupportData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );

          break;
        case 'ee-and-dsm-programs': //report505
          // const tableData = [];
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
          ];

          [
            { title: 'Esco Models', key: 'esco_models' },
            {
              title: 'EE Grant/Loan Residential',
              key: 'grant_loan_prog_ee_in_residential',
            },
            {
              title: 'EE Grant/Loan Commercial',
              key: 'grant_loan_prog_ee_in_commercial',
            },
            {
              title: 'EE Grant/Loan Industry',
              key: 'grant_loan_prog_ee_in_industry',
            },
            {
              title: 'EE Grant/Loan Tourism',
              key: 'grant_loan_prog_ee_in_tourism',
            },
            { title: 'Other EE', key: 'other_ee' },
            { title: 'Time-in-use Tariffs', key: 'time_in_use_tariffs' },
            {
              title: 'Microgrids',
              key: 'microgrids_with_flexible_connection',
            },
            {
              title: 'Load provision programme',
              key: 'incentivization_via_storage_capacities',
            },
            { title: 'Other DMS', key: 'other_dms' },
          ].map((item: any) => {
            // console.log(tech);
            headerColumns.push({
              Header: item.title,
              accessor: item.key,
              width: 120,
              // disableResizing: true,
            });
          });

          results = calculateEEDSMProgram(
            utilityFinancialData,
            utilityTechnicalData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          console.log('DSM tableData', tableData);
          // setTableColumns(headerColumns1);
          break;
        case 'availability-of-funds': //report505
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
          ];

          [
            { title: '% Asset Cover', key: 'pcent_of_asset_cover' },
            {
              title: 'Disaster Fund',
              key: 'disaster_fund',
            },
          ].map((item: any) => {
            // console.log(tech);
            headerColumns.push({
              Header: item.title,
              accessor: item.key,
              // width: 100,
              // disableResizing: true,
            });
          });

          results = calculateAvailabilityOfFunds(
            utilityFinancialData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );

          // setTableColumns(headerColumns2);
          break;
        case 'gender': //report505
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
          ];

          [
            { title: '% Males', key: 'percent_male_staff' },
            { title: '% Females', key: 'percent_female_staff' },
            { title: '% Tech Males', key: 'percent_technical_male_staff' },
            { title: '% Tech Females', key: 'percent_technical_female_staff' },
            {
              title: '% Management Male Staff',
              key: 'percent_male_senior_staff',
            },
            {
              title: '% Management Female Staff',
              key: 'percent_senior_female_staff',
            },
          ].map((item: any) => {
            // console.log(tech);
            headerColumns.push({
              Header: item.title,
              accessor: item.key,
              // width: 100,
              // disableResizing: true,
            });
          });

          results = calculateGender(
            utilityGeneralData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          break;
        case 'grid-modernization': //report505
          headerColumns = [
            {
              Header: 'Utility',
              accessor: 'utility_code',
              width: 100,
              disableResizing: true,
            },
            {
              Header: 'Year',
              accessor: 'data_year',
              width: 100,
              disableResizing: true,
            },
          ];

          [
            { title: 'Gen Forecast', key: 'existing_gen_forecast_system' },
            {
              title: 'Power Ctrl',
              key: 'auto_pwr_ctrl_netwk_monitoring',
            },
            {
              title: 'Dyn Tariff',
              key: 'dynamic_electric_cust_tariffs',
            },
            {
              title: 'Demand Response',
              key: 'dispatchable_demand_response',
            },
            {
              title: '# Microgrids',
              key: 'nbr_of_grid_connected_microgrids',
            },
            {
              title: 'Battery Storage',
              key: 'grid_connected_battery_storage',
            },
          ].map((item: any) => {
            // console.log(tech);
            headerColumns.push({
              Header: item.title,
              accessor: item.key,
              // width: 100,
              // disableResizing: true,
            });
          });

          results = calculateGridModernization(
            utilityTechnicalData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );

          break;
      }

      setTableData({
        title: reportTitle,
        label: reportTitle,
        headers: headerColumns,
        data: results,
      });
    } else {
      console.log('working on report', reportTitle, userPermission);
      switch (reportTitle) {
        case 'average-re-cost-per-mwh': //report505
          calcResults = calculateAvgRECost(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilityFinancialData,
            utilities,
            userPermission,
            userUtility,
          );
          break;
        case 'capacity-factor-solar-pv': //report505
          calcResults = calculateSolarCapacityFactor(
            data.year_start,
            data.year_end,
            selectedUtilities,
            technologyData,
            technologies,
            utilities,
            userPermission,
            userUtility,
          );
          break;
        case 'capacity-factor-wind': //report505
          calcResults = calculateWindCapacityFactor(
            data.year_start,
            data.year_end,
            selectedUtilities,
            technologyData,
            technologies,
            utilities,
            userPermission,
            userUtility,
          );
          break;

        case 'installed-der-capacity': //report505
          calcResults = calculateDERCapacity(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilities,
            userPermission,
            userUtility,
          );
          break;
        case 'environmental-performance': //report505
          calcResults = calculateEnvironmentalPerformance(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityTechnicalData,
            utilities,
            userPermission,
            userUtility,
          );
          break;
        case 'assess-climate-resilience': //report505
          calcResults = calculateClimateResilience(
            data.year_start,
            data.year_end,
            selectedUtilities,
            climateResilienceData,
            utilities,
            userPermission,
            userUtility,
          );
          break;
        case 'osh-implementation': //report505
          calcResults = calculateOSHImplementation(
            data.year_start,
            data.year_end,
            selectedUtilities,
            utilityGeneralData,
            utilities,
            userPermission,
            userUtility,
          );
          break;
      }
      setGraphData({
        labels: calcResults.labels,
        //   labels: graphicalData.map((data) => data.year),
        datasets: [
          {
            label: reportTitle,
            data: calcResults.results,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });
    }
  };

  const handleDownload = () => {
    var contentNode: any = document.getElementById('kpi-chart');
    if (contentNode.childNodes.length < 1) {
      alert('No data to download');
      return;
    }

    const utilityNames = selectedUtility
      ?.map((item: any) => item.label)
      .join('-');
    htmlToImage.toPng(contentNode).then(function (dataUrl) {
      saveAs(
        dataUrl,
        `${reportTitle}-${utilityNames}-${selectedStartYear}.png`,
      );
    });
  };

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  //Filter utility options if user permissions is not full
  //If user permissions is ANON then no utility options should be available
  //If user permissions is UTIL then only their utility should be available
  const utilityOptions =
    userPermission !== 'FULL'
      ? userPermission === 'ANON'
        ? []
        : utilities?.filter((utility: any) =>
            userUtility.includes(utility.utility_id),
          )
      : utilities?.sort((a: any, b: any) =>
          a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
            ? 1
            : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
            ? -1
            : 0,
        );
  console.log(tableData);
  return (
    <div className="h-full">
      <PageTitle
        title="Sustainability & Resilience KPIs"
        onPrint={handleDownload}
      />
      <div className="flex w-full h-full">
        <Sidebar links={kpis} />
        <div className="overflow-hidden flex-1 bg-white shadow rounded-sm mb-4 p-4 sm:p-6 h-full w-full">
          {utilityOptions && (
            <ReportFilterForm
              utilityOptions={utilityOptions}
              onSubmit={onSubmit}
              userPermission={userPermission}
              canSelectMultiple={currentUser?.user_role
                .toLowerCase()
                .includes('carilec')}
            />
          )}
          <div id="kpi-chart" className="bg-white p-1">
            <>
              <div className="text-center text-gray-600 border-t border-b border-gray-200 mb-5 py-3">
                <h1 className="font-semibold capitalize">{currentTitle}</h1>
              </div>
              {tableData && (
                <div className="overflow-x-scroll">
                  <Table columns={tableData.headers} data={tableData.data} />
                </div>
              )}
              {graphData && <BarChart data={graphData} />}
              {/* {reportTitle?.toLowerCase() === 'gender' ? (
									<div className="overflow-x-scroll">
										<Table
											columns={tableColumns}
											data={utilityGeneralData
												.filter((data: any) => {
													// console.log(data, selectedUtility);
													if (selectedUtility.toLowerCase() === 'all')
														return data.data_year === Number(selectedStartYear);
													else
														return (
															data.utility_id === selectedUtility &&
															data.data_year === Number(selectedStartYear)
														);
												})
												.sort(() =>
													userPermission === 'UTIL' || userPermission === 'ANON'
														? Math.random() - 0.5
														: 0,
												)
												.map((data: any) => {
													let tableColumns: any = {
														utility_code:
															userPermission === 'FULL'
																? data.utility_code
																: userPermission === 'UTIL' &&
																  data.utility_id === userUtility
																? data.utility_code
																: '',
														data_year: data.data_year,
														// esco_models: "Y",
													};

													[
														'percent_male_staff',
														'percent_female_staff',
														'percent_technical_male_staff',
														'percent_technical_female_staff',
														'percent_male_senior_staff',
														'percent_senior_female_staff',
													].map((item: any) => {
														// console.log(item, data[item]);
														tableColumns[item] = data[item] + '%';
													});

													return tableColumns;
												})}
										/>
									</div>
								) : reportTitle?.toLowerCase() === 'grid-modernization' ? (
									<div className="overflow-x-scroll">
										<Table
											columns={tableColumns}
											data={utilityTechnicalData
												.filter((data: any) => {
													// console.log(data, selectedUtility);
													if (selectedUtility.toLowerCase() === 'all')
														return data.data_year === Number(selectedStartYear);
													else
														return (
															data.utility_id === selectedUtility &&
															data.data_year === Number(selectedStartYear)
														);
												})
												.sort(() =>
													userPermission === 'UTIL' || userPermission === 'ANON'
														? Math.random() - 0.5
														: 0,
												)
												.map((data: any) => {
													let tableColumns: any = {
														utility_code:
															userPermission === 'FULL'
																? data.utility_code
																: userPermission === 'UTIL' &&
																  data.utility_id === userUtility
																? data.utility_code
																: '',
														data_year: data.data_year,
														// esco_models: "Y",
													};

													[
														'existing_gen_forecast_system',
														'auto_pwr_ctrl_netwk_monitoring',
														'dynamic_electric_cust_tariffs',
														'dispatchable_demand_response',
														'nbr_of_grid_connected_microgrids',
														'grid_connected_battery_storage',
													].map((item: any) => {
														tableColumns[item] = data[item]
															? typeof data[item] === 'boolean'
																? 'Y'
																: data[item]
															: 'N';
													});

													return tableColumns;
												})}
										/>
									</div>
								) : reportTitle?.toLowerCase() === 'availability-of-funds' ? (
									<div className="overflow-x-scroll">
										<Table
											columns={tableColumns}
											data={utilityFinancialData
												.filter((data: any) => {
													// console.log(data, selectedUtility);
													if (selectedUtility.toLowerCase() === 'all')
														return data.data_year === Number(selectedStartYear);
													else
														return (
															data.utility_id === selectedUtility &&
															data.data_year === Number(selectedStartYear)
														);
												})
												.sort(() =>
													userPermission === 'UTIL' || userPermission === 'ANON'
														? Math.random() - 0.5
														: 0,
												)
												.map((data: any) => {
													let tableColumns: any = {
														utility_code:
															userPermission === 'FULL'
																? data.utility_code
																: userPermission === 'UTIL' &&
																  data.utility_id === userUtility
																? data.utility_code
																: '',
														data_year: data.data_year,
														// esco_models: "Y",
													};

													['pcent_of_asset_cover', 'disaster_fund'].map(
														(item: any) => {
															// console.log(item, data[item]);
															tableColumns[item] =
																item === 'pcent_of_asset_cover'
																	? data[item]
																	: data[item]
																	? 'Y'
																	: 'N';
														},
													);

													return tableColumns;
												})}
										/>
									</div>
								) : reportTitle?.toLowerCase() === 'ee-and-dsm-programs' ? (
									<div className="overflow-x-scroll">
										<Table columns={tableData.columns} data={tableData.data} />
									</div>
								) : reportTitle?.toLowerCase() ===
								  'support-mechanism-for-der' ? (
									<div className="overflow-x-scroll">
										<Table
											columns={tableColumnsDER}
											data={derSupportData
												.filter((data: any) => {
													// console.log(data, selectedUtility);
													if (selectedUtility.toLowerCase() === 'all')
														return data.data_year === Number(selectedStartYear);
													else
														return (
															data.utility_id === selectedUtility &&
															data.data_year === Number(selectedStartYear)
														);
												})
												.sort(() =>
													userPermission === 'UTIL' || userPermission === 'ANON'
														? Math.random() - 0.5
														: 0,
												)
												.map((data: any) => {
													// console.log(data);
													const { support_title } = data;
													const supportKey = support_title
														.split(' ')
														.join('_')
														.toLowerCase();
													console.log(supportKey);
													// TODO: Group results by utility code??
													const tableColumns: any = {
														utility_code:
															userPermission === 'FULL'
																? data.utility_code
																: userPermission === 'UTIL' &&
																  data.utility_id === userUtility
																? data.utility_code
																: '',
														data_year: data.data_year,
														[supportKey]: 'Y',
													};

													return tableColumns;
												})}
										/>
									</div>
								) : reportTitle?.toLowerCase() === 'cap-threshold-for-der' ? (
									<div className="overflow-x-scroll">
										<Table
											columns={tableColumns}
											data={utilityTechnicalData
												.filter((data: any) => {
													// console.log(data, selectedUtility);
													if (selectedUtility.toLowerCase() === 'all')
														return data.data_year === Number(selectedStartYear);
													else
														return (
															data.utility_id === selectedUtility &&
															data.data_year === Number(selectedStartYear)
														);
												})
												.sort(() =>
													userPermission === 'UTIL' || userPermission === 'ANON'
														? Math.random() - 0.5
														: 0,
												)
												.map((data: any) => {
													// console.log(data);
													const {
														capacity_threshold_for_residential_re,
														capacity_threshold_for_c_i_re,
													} = data;
													// TODO: Group results by utility code??
													const tableColumns: any = {
														utility_code:
															userPermission === 'FULL'
																? data.utility_code
																: userPermission === 'UTIL' &&
																  data.utility_id === userUtility
																? data.utility_code
																: '',
														data_year: data.data_year,
														capacity_threshold_for_residential_re,
														capacity_threshold_for_c_i_re,
													};

													return tableColumns;
												})}
										/>
									</div>
								) : (
									<BarChart data={graphData} />
								)} */}
            </>
          </div>

          {/* {graphData2 && <Bar options={options} data={graphData2} />} */}
        </div>
      </div>
    </div>
  );
}
