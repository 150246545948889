import axios from "../config/apiClient";

export const findUsers = async () => {
  const response = await axios.get("/users");

  return response;
};

export const addUser = async (data: any) => {
  const response = await axios.post("/users", data);

  return response;
};

export const updateUser = async (data: any) => {
  const response = await axios.put("/users", data);

  return response;
};

export const removeUser = async (data: any) => {
  return await axios.delete(`/users/${data.user_id}`).then((res) => {
    return res.data;
  });
};

export const getCurrentUser = async (userid: any) => {
  // console.log(userid);
  const response = await axios.get(`/users/${userid}`);
  // console.log(response);
  return response;
};

export async function signUp(params: {
  user_name: string;
  full_name?: string;
  user_password: string;
  user_password_expiration?: string;
  email: string;
  telephone?: string;
  country_id?: string;
  master_code?: string;
}): Promise<any> {
  const response = await axios.post("/signup", { user: params });

  return response.data.data;
}
