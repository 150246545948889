import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ links }: any) => {
  // console.log(links);

  let { pathname } = useLocation();
  // console.log(pathname);
  // console.log(pathname.split("/").pop());
  // console.log(pathname.split("/").slice(1, -1));
  pathname = pathname.split("/").slice(1, -1).join("/");
  // console.log(pathname);

  // console.log(location, useParams());
  const iconStyle = "w-6 h-6 text-blue-500";

  const navLinks = links
    ?.sort((a: any, b: any) =>
      a.description
        .substr(0, 6)
        .split(".")
        .join("")
        .localeCompare(
          b.description.substr(0, 6).split(".").join(""),
          undefined,
          { numeric: true }
        )
    )
    // ?.sort((a: any, b: any) =>
    //   parseInt(a.description.substr(0, 6).split(".").join(""), 10) >
    //   parseInt(b.description.substr(0, 6).split(".").join(""), 10)
    //     ? 1
    //     : parseInt(b.description.substr(0, 6).split(".").join(""), 10) >
    //       parseInt(a.description.substr(0, 6).split(".").join(""), 10)
    //     ? -1
    //     : 0
    // )
    // ?.sort((a: any, b: any) =>
    //   a.description.substr(0, 6).split(".").join("") >
    //   b.description.substr(0, 6).split(".").join("")
    //     ? 1
    //     : -1
    // )
    .map((link: any) => ({
      title: link.kpi,
      url: link.kpi.toLowerCase().trim().split(" ").join("-"),
    }));

  return (
    <div className="w-48 pt-6 h-full bg-white border-r border-gray-200">
      {/* <p className="font-semibold text-center py-3">Major Company KPIs</p> */}
      <ul className="h-full overflow-y-auto divide-y">
        {navLinks?.map((item: { url: string; title: string; links?: any }) => (
          <li key={item.title}>
            <NavLink
              // to={`/dashboard/kpi/major/${item.url}`}
              to={`/${pathname}/${item.url}`}
              className={({ isActive }) =>
                `text-base text-gray-900 font-normal flex items-start ${
                  isActive ? "bg-blue-200" : "hover:bg-gray-100"
                } group`
              }
            >
              <span className="ml-3 capitalize p-2">{item.title}</span>
              {item.links && (
                <div className="hidden group-hover:flex flex-col z-50 border border-grey-100 justify-start fixed -mt-2 ml-36  bg-white shadow rounded-lg p-2">
                  {item.links?.map((link: any) => (
                    <NavLink
                      to={`/app/${item.url}/${link.url}`}
                      className={({ isActive }) =>
                        `text-base text-gray-900 font-normal rounded-lg  p-2 ${
                          isActive ? "bg-blue-200" : "hover:bg-blue-100"
                        } `
                      }
                    >
                      {link.title}
                    </NavLink>
                  ))}
                </div>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
