import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import TechnologyForm from "../../components/forms/TechnologyForm";

import {
  findTechnologies,
  addTechnology,
  updateTechnology,
} from "services/technologies.service";
import toast from "react-hot-toast";
import PageTitle from "containers/PageTitle";

const Technologies = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: technologies,
  } = useQuery("technologies", () =>
    findTechnologies().then((res) => res.data)
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    console.log("table row", data);
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addTechnology(data).then((res: any) => {
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("technologies");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    // console.log(data);
    updateTechnology({
      ...data,
      tech_id: record?.tech_id,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("technologies");
        handleToggleModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Technology",
        accessor: "technology",
      },
      {
        Header: "Renewable Energy",
        accessor: "renewable_energy",
        Cell: ({ cell: { value } }: any) => {
          return <div>{value ? "Y" : "N"}</div>;
        },
      },
      {
        Header: "Photovoltaic",
        accessor: "pv",
        Cell: ({ cell: { value } }: any) => {
          return <div>{value ? "Y" : "N"}</div>;
        },
      },
      {
        Header: "Wind",
        accessor: "wind",
        Cell: ({ cell: { value } }: any) => {
          return <div>{value ? "Y" : "N"}</div>;
        },
      },
      {
        Header: "Emissions Factor",
        accessor: "emissions_factor",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  console.log(technologies);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Technologies" onClick={toggleModal} />
      <Table columns={columns} data={technologies} />
      {isModalOpen && (
        <Modal
          modalTitle="New Entry"
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <TechnologyForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Technologies;
