import React from "react";
import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import RestorationPeriodForm from "../../components/forms/RestorationPeriodForm";
import { findUtilities } from "services/utilities.service";
import {
 addRestorationPeriod,
 getRestorationPeriod,
 updateRestorationPeriod,
 removeRestorationPeriod,
} from "services/restorationperiod.service";
import toast from "react-hot-toast";
// import { Tab } from "@headlessui/react";
// import { HiOutlineEye, HiOutlinePencil } from "react-icons/hi";
import PageTitle from "containers/PageTitle";

import useConfirmationDialog from "hooks/useConfirm";

const UtilityCustomerData = (props: any) => {
 const { utility } = props;
 let [isModalOpen, setIsOpen] = React.useState(false);
 let [record, setRecord] = React.useState<any>(null);

 const minYear = 1980;
 const maxYear = new Date().getFullYear() - 1;

 const fillRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

 const yearFilters = fillRange(maxYear, minYear, -1);
 const { getConfirmation } = useConfirmationDialog();
 const queryClient = useQueryClient();

 const {
  // isLoading,
  // error,
  data: utilities,
 } = useQuery("utilities", () => findUtilities().then((res) => res.data));

 const {
  isLoading,
  error,
  data: restorationPeriodData,
 } = useQuery("restorationperiod", () =>
  getRestorationPeriod().then((res) => res.data)
 );

 const columns = React.useMemo(
  () => [
   {
    Header: "Year",
    accessor: "data_year",
   },
   {
    Header: "Utility",
    accessor: "utility_name",
   },
   {
    Header: "Sum of Customer Interruption Duration",
    accessor: "restoration_period",
   },
   {
    Header: "Total # of interrupted customers",
    accessor: "customers_restored",
   },
   {
    Header: "Action",
    accessor: "action",
    width: 100,
    disableResizing: true,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ cell: { row } }: any) => {
     return (
      <TableActions
       onEdit={() => handleToggleModal(row?.original)}
       onDelete={() => handleRemoveRecord(row?.original)}
      />
     );
    },
   },
  ],
  []
 );

 function toggleModal() {
  setIsOpen(!isModalOpen);
 }

 const handleToggleModal = (data?: any) => {
  if (!data) {
   setRecord({
    data_year: utility.data_year,
    utility_id: utility.utility_id,
   });
  } else setRecord(data);
  toggleModal();
 };

 const handleViewRecord = (data: any) => {
  console.log(data);
 };

 const handleAddRecord = (data: any) => {
  addRestorationPeriod(data).then((res: any) => {
   console.log(res);
   if (res.error) {
    toast.error(res.data.message);
   } else {
    toast.success("Record created");
    queryClient.invalidateQueries("restorationperiod");
    handleToggleModal();
   }
  });
 };
 const handleEditRecord = (data: any) => {
  updateRestorationPeriod({ ...data }).then((res: any) => {
   console.log(res);
   if (res.error) {
    toast.error(res.data.message);
   } else {
    toast.success("Record updated");
    queryClient.invalidateQueries("restorationperiod");
    handleToggleModal();
   }
  });
 };

 const handleRemoveRecord = async (data: any) => {
  const confirmed = await getConfirmation({
   title: "Are you sure?",
   message: "Removing this record is an action that cannot be reverted",
  });

  if (confirmed) {
   console.log("remove record");
   // deleteUserMutation.mutate(data);
   removeRestorationPeriod(data?.restore_pd_data_id).then((res: any) => {
    console.log(res);
    if (res.error) {
     toast.error(res.data.message);
    } else {
     toast.success("Record removed");
     queryClient.invalidateQueries("restorationperiod");
     // handleToggleModal();
    }
   });
  }
 };

 if (isLoading) return <Loading />;

 if (error) return <ErrorPage />;

 return (
  <div className="flex h-full">
   <div className="flex-1 bg-grey-50">
    <PageTitle
     title="Sum of Customer Interruption Duration"
     onClick={() => handleToggleModal()}
    />
    {restorationPeriodData && (
     <Table
      columns={columns}
      data={restorationPeriodData.filter(
       (record: any) =>
        record.utility_id === utility.utility_id &&
        record.data_year === utility.data_year
      )}
     />
    )}
    {isModalOpen && (
     <Modal
      modalTitle={`${
       record?.restore_pd_data_id ? "Update" : "Add"
      } Interruption Duration`}
      isOpen={isModalOpen}
      closeModal={() => handleToggleModal()}
     >
      <RestorationPeriodForm
       utilities={utilities}
       initialValues={record}
       onEdit={handleEditRecord}
       onAdd={handleAddRecord}
      />
     </Modal>
    )}
   </div>
  </div>
 );
};

export default UtilityCustomerData;
