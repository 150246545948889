export const alphanumericRegExp = /^[a-zA-Z0-9]+\.?[a-zA-Z0-9]+$/;

export const phoneRegExp =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const formatReportTitle = (kpi: any) => {
  return (
    kpi?.description.substring(0, 6).replace(/\s/g, '') +
    ' ' +
    kpi?.kpi +
    ' (' +
    kpi?.kpi_units +
    ')'
  );
};

export const regex = {
  integer: {
    validation: /^[0-9]+$/,
    message: 'Must be only digits and no decimals',
  },
  phoneNumber: {
    validation: /^\d{10,15}$/,
    message: 'Phone number must be 10 to 15 digits',
  },
  floatingPoint: /^(0|[1-9]\d*)(\.\d+)?$/,
  numeric52: {
    validation: /^((?!0)\d{1,3}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm,
    message:
      'Must be only digits. Digits must be 5 significant figures (2 decimal places)',
  },
  numeric76: {
    validation: /^((?!0)\d{1,1}|0|\.\d{1,6})($|\.$|\.\d{1,6}$)/gm,
    message:
      'Must be only digits. Digits must be 7 significant figures (6 decimal places)',
  },
  numeric85: {
    validation: /^((?!0)\d{1,3}|0|\.\d{1,5})($|\.$|\.\d{1,5}$)/gm,
    message:
      'Must be only digits. Digits must be 8 significant figures (5 decimal places)',
  },
  numeric92: {
    validation: /^((?!0)\d{1,7}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm,
    message:
      'Must be only digits. Digits must be 9 significant figures (2 decimal places)',
  },
  numeric93: {
    validation: /^((?!0)\d{1,6}|0|\.\d{1,3})($|\.$|\.\d{1,3}$)/gm,
    message:
      'Must be only digits. Digits must be 9 significant figures (3 decimal places)',
  },
  numeric101: {
    validation: /^((?!0)\d{1,9}|0|\.\d{1,1})($|\.$|\.\d{1,1}$)/gm,
    // validation: /^\d{1,3}(,\d{3})*(\.\d)?$/gm,
    message:
      'Must be only digits. Digits must be 10 significant figures (1 decimal places)',
  },
  numeric103: {
    validation: /^((?!0)\d{1,7}|0|\.\d{1,3})($|\.$|\.\d{1,3}$)/gm,
    // validation: /^\d{1,3}(,\d{3})*(\.\d{3})?$/gm,
    message:
      'Must be only digits. Digits must be 10 significant figures (3 decimal places)',
  },
  numeric105: {
    validation: /^((?!0)\d{1,5}|0|\.\d{1,5})($|\.$|\.\d{1,5}$)/gm,
    // validation: /^\d{1,3}(,\d{3})*(\.\d{1,5})?$/gm,
    message:
      'Must be only digits. Digits must be 10 significant figures (5 decimal places)',
  },
  numeric112: {
    validation: /^((?!0)\d{1,9}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm,
    // validation: /^\d{1,3}(,\d{3})*(\.\d{1,5})?$/gm,
    message:
      'Must be only digits. Digits must be 11 significant figures (2 decimal places)',
  },
};
