import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import axios from "../../config/apiClient";
import LoginForm from "../../components/forms/LoginForm";
// import { useAuthState } from "../../context/auth";
import useAuth from "../../hooks/useAuth";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const { login } = useAuth();

  // React.useEffect(() => {
  //   if (authenticated) {
  //     navigate("/dashboard");
  //   }
  //   //eslint-disable-next-line
  // }, [authenticated]);

  const handleLogin = async (data: LoginFormValues) => {
    // login(data);
    try {
      console.log("login user", data);
      login(data).then((res: any) => {
        console.log(res);
        if (res.error) {
          console.log("an error occurred", res);
          toast.error("Invalid user credentials");
        } else {
          console.log("navigate to admin dashboard");
          navigate("/dashboard");
        }
        // navigate("/dashboard");
      });
    } catch (error) {
      toast.error("Invalid user credentials");
    }
  };
  return (
    <div className="max-w-xl mt-36 p-6 bg-white shadow-xl border border-gray-100">
      <div className="w-full mx-auto">
        <h1 className="text-3xl text-center py-4 font-bold">
          CARILEC Automated Utility Benchmarking Platform
          {/* {process.env.REACT_APP_API_SERVER} */}
        </h1>
        <h3 className="text-xl text-center py-4 font-bold">Login</h3>
        {/* <h1 className="text-lg font-bold">Login</h1> */}
        <LoginForm onSubmit={handleLogin} />
      </div>
    </div>
  );
};

export default LoginPage;
