import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { findTechnologies } from 'services/technologies.service';
import { findUtilities } from 'services/utilities.service';
import { findCustomerCategories } from 'services/customercategories.service';

import { getUtilityGeneralData } from 'services/utilitygeneraldata.service';
import { getFinancialData } from 'services/utilityfinancialdata.service';
import { getUtilityTechnicalData } from 'services/utilitytechnicaldata.service';
import { getUtilityCustomerData } from 'services/utilitycustomerdata.service';
import { getRestorationPeriod } from 'services/restorationperiod.service';
import { getTechnologyData } from 'services/technologydata.service';

import { findUsageCategories } from 'services/usagecategories.service';
import { getUsageCategoryData } from 'services/usagecategorydata.service';
import { getCustomerCategoryData } from 'services/customercategorydata.service';

import { findKPIs } from 'services/kpi.service';
import { findRoles } from 'services/roles.service';
import PageTitle from 'containers/PageTitle';
import Sidebar from 'containers/ReportSideBar';
import Table from 'components/Table';

import ReportFilterForm from 'components/forms/ReportFilterForm';
import BarChart from 'components/charts/BarChart';
import {
  calculateNumberCustomerComplaints,
  calculateSmartMeterCustomers,
  calculateCommercializationCost,
  calculateBadDebt,
  calculateCommercialProductivity,
  calculateSharePerCustomerGroup,
} from '_utils/calculations';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { formatReportTitle } from '_utils';

export default function App() {
  let { currentUser } = useAuth();
  //   console.log(currentUser);

  const { title: reportTitle } = useParams();
  // console.log(reportTitle);

  //   const currentRole: string = currentUser.user_role;
  const userUtility: string[] = [currentUser.utility_id];
  const [graphData, setGraphData] = React.useState<any>(null);
  // const [graphData2, setGraphData2] = React.useState<any>(null);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [selectedUtility, setSelectedUtility] = React.useState<any>(null);
  const [selectedStartYear, setSelectedStartYear] = React.useState<any>(null);
  const [tableColumns, setTableColumns] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any>(null);
  const [tableRowData, setTableRowData] = React.useState<any>(null);

  React.useEffect(() => {
    setTableData(null);
    setGraphData(null);
  }, [reportTitle]);

  const { data: utilities } = useQuery('utilities', () =>
    findUtilities().then(res => res.data),
  );

  const { data: customercategories } = useQuery('customercategories', () =>
    findCustomerCategories().then(res => res.data),
  );

  const {
    isLoading,
    error,
    data: usageCategoryData,
  } = useQuery('usagecategorydata', () =>
    getUsageCategoryData().then(res => res.data),
  );

  const {
    // isLoading,
    // error,
    data: customerCategoryData,
  } = useQuery('customercategorydata', () =>
    getCustomerCategoryData().then(res => res.data),
  );

  const location = useLocation();
  console.log(location.pathname);
  const category = location.pathname.split('/')[3];
  const { data: kpis } = useQuery(['kpipermissions', category], () =>
    findKPIs().then(res => {
      const kpis = res.data;
      const items = kpis?.filter((item: any) =>
        item.kpi_category.toLowerCase().includes('commercialization'),
      );
      return items;
    }),
  );

  React.useEffect(() => {
    const current_kpi = kpis?.find(
      (kpi: any) =>
        kpi?.kpi?.trim().toLowerCase() === reportTitle?.split('-').join(' '),
    );
    // const title =
    //   current_kpi?.description.substring(0, 6) +
    //   " - " +
    //   current_kpi?.kpi +
    //   " (" +
    //   current_kpi?.kpi_units +
    //   ")";
    // console.log(title);
    setCurrentTitle(formatReportTitle(current_kpi));
  }, [kpis, reportTitle]);

  const { data: userPermission } = useQuery(
    ['userPermission', reportTitle],
    () =>
      findKPIs().then(res => {
        const kpis = res.data;
        const kpi = kpis?.find(
          (kpi: any) =>
            kpi.kpi.trim().toLowerCase() === reportTitle?.split('-').join(' '),
        );
        // console.log(kpi);
        console.log(kpi, reportTitle, currentUser);
        const userPermission = kpi?.kpipermissions?.find(
          (permission: any) => permission.user_role_id === currentUser.role_id,
        )?.kpi_status;
        console.log(userPermission);
        return userPermission;
      }),
    { enabled: !!reportTitle },
  );

  // console.log(userPermission);

  // console.log(
  //   kpis?.find((kpi: any) => kpi.kpi.toLowerCase() === "service coverage")
  // );

  // const { data: roles } = useQuery("roles", () =>
  //   findRoles().then((res) => res.data)
  // );

  // console.log(roles);

  const {
    // isLoading,
    // error,
    data: utilityGeneralData,
  } = useQuery('utilitygeneraldata', () =>
    getUtilityGeneralData().then(res => res.data),
  );

  const { data: utilityFinancialData } = useQuery('utilityfinancialdata', () =>
    getFinancialData().then(res => res.data),
  );

  // const { data: utilityTechnicalData } = useQuery("utilitytechnicaldata", () =>
  //   getUtilityTechnicalData().then((res) => res.data)
  // );

  // const { data: restorationPeriodData } = useQuery("restorationperiod", () =>
  //   getRestorationPeriod().then((res) => res.data)
  // );

  const { data: utilityCustomerData } = useQuery('utilitycustomerdata', () =>
    getUtilityCustomerData().then(res => res.data),
  );

  console.log(utilityCustomerData);

  const onSubmit = (data: any) => {
    console.log(data);
    let selectedUtilities = data.utility_id;
    //If data.utility_id is null then
    if (data.utility_id[0].label === 'Select All') {
      selectedUtilities = utilities?.map((utility: any) => ({
        value: utility.utility_id,
        label: utility.utility_name,
      }));
    }
    setSelectedUtility(selectedUtilities);
    setSelectedStartYear(data.year_start);

    let calcResults: any = {};

    const reportType =
      reportTitle &&
      [
        'disaggregated-tariffs',
        'installed-capacity',
        'energy-generation-per-pes',
        'capacity-change-per-pes',
        'generation-change-per-pes',
        'cost-by-type',
        'cost-by-business-area',
        'fuel-cost',
        'fuel-efficiency-own-units',
        'thermal-generation-cost',
        'grid-loss-per-volt-level',
        'share-of-underground-line',
        'share-per-customer-group',
        'cap-threshold-for-der',
        'support-mechanism-for-der',
        'ee-and-dsm-programs',
        'availability-of-funds',
        'grid-modernization',
        'osh-implementation',
        'gender',
      ].includes(reportTitle)
        ? 'table'
        : 'graph';
    switch (reportTitle) {
      case 'number-of-complaints': //report505
        calcResults = calculateNumberCustomerComplaints(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityCustomerData,
          userPermission,
          userUtility,
          utilities,
        );
        break;
      case 'smart-meter-customers': //report505
        calcResults = calculateSmartMeterCustomers(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityCustomerData,
          userPermission,
          userUtility,
          utilities,
        );
        break;
      case 'commercialization-cost': //report505
        calcResults = calculateCommercializationCost(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityCustomerData,
          utilityFinancialData,
          userPermission,
          userUtility,
          utilities,
        );
        break;
      case 'bad-debt':
        calcResults = calculateBadDebt(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityFinancialData,
          userPermission,
          userUtility,
          utilities,
        );
        break;
      case 'commercial-productivity':
        calcResults = calculateCommercialProductivity(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityGeneralData,
          utilityCustomerData,
          userPermission,
          userUtility,
          utilities,
        );
        break;
      default:
        calcResults = calculateNumberCustomerComplaints(
          data.year_start,
          data.year_end,
          selectedUtilities,
          utilityCustomerData,
          userPermission,
          userUtility,
          utilities,
        );
    }
    // console.log(calcResults);
    if (reportType === 'table') {
      var results = [];
      switch (reportTitle) {
        case 'share-per-customer-group':
          results = calculateSharePerCustomerGroup(
            tableRowData,
            selectedUtilities,
            data.year_start,
            userPermission,
            userUtility,
          );
          break;
      }

      setTableData({
        label: reportTitle,
        headers: tableColumns,
        data: results,
      });
    } else {
      setGraphData({
        labels: calcResults.labels,
        datasets: [
          {
            label: reportTitle,
            data: calcResults.results,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });
    }
  };

  React.useEffect(() => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];

    customercategories?.map((data: any) => {
      headerColumns.push({
        Header: data.cust_category,
        accessor: data.cust_category_id,
        // accessor: data.cust_category.trim().split(" ").join("_").toLowerCase(),
        // width: 100,
        // disableResizing: true,
      });
    });

    setTableColumns(headerColumns);
  }, [customercategories]);

  React.useEffect(() => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const customerData = customerCategoryData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      customerData?.map((data: any) => {
        // console.log(data);
        const customercategory = customercategories?.find(
          (category: any) =>
            category.cust_category_id === data.cust_category_id,
        );

        const utilityData = utilityCustomerData?.find(
          (record: any) =>
            record.utility_id === data.utility_id &&
            record.data_year === data.datayear,
        );
        // console.log(utilityData);
        // console.log(customercategory);
        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.datayear,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility.code &&
            singleRecord.data_year === data.datayear,
        );

        const tableRow = {
          cust_category_id: customercategory?.cust_category_id,
          nbr_customer_meters: Number(utilityData?.number_of_customer_meters),
          num_customers: Number(utilityData?.no_of_customers_served),
          customers: data.customers,
          // fuel_cost: data.fuel_cost,
          // fuel_used: data.fuel_used,
          // energy_generated: data.energy_generated,
          // generation_cost: data.generation_cost,
        };
        //If exists
        if (existingRecord > -1) {
          if (customercategory) {
            tableRows[existingRecord]?.fields.push(tableRow);
          }
        } else {
          if (customercategory) {
            tableEntry.fields.push(tableRow);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record

        // tableData.push(tableEntry);
      });
    });
    setTableRowData(tableRows);
  }, [utilities, customercategories, customerCategoryData]);

  //Filter utility options if user permissions is not full
  //If user permissions is ANON then no utility options should be available
  //If user permissions is UTIL then only their utility should be available
  const utilityOptions =
    userPermission !== 'FULL'
      ? userPermission === 'ANON'
        ? []
        : utilities?.filter((utility: any) =>
            userUtility.includes(utility.utility_id),
          )
      : utilities?.sort((a: any, b: any) =>
          a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
            ? 1
            : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
            ? -1
            : 0,
        );

  const handleDownload = () => {
    var contentNode: any = document.getElementById('kpi-chart');
    if (contentNode.childNodes.length < 1) {
      alert('No data to download');
      return;
    }

    const utilityNames = selectedUtility
      ?.map((item: any) => item.label)
      .join('-');

    htmlToImage.toPng(contentNode).then(function (dataUrl) {
      saveAs(
        dataUrl,
        `${reportTitle}-${utilityNames}-${selectedStartYear}.png`,
      );
    });
  };

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  return (
    <div className="h-full">
      <PageTitle
        title="Commercialization KPIs"
        subTitle="Major Company KPIs"
        onPrint={handleDownload}
      />
      <div className="flex h-full">
        <Sidebar links={kpis} />
        <div className="bg-white shadow rounded-sm mb-4 p-4 sm:p-6 h-full w-full">
          {utilityOptions && (
            <ReportFilterForm
              utilityOptions={utilityOptions}
              onSubmit={onSubmit}
              userPermission={userPermission}
              canSelectMultiple={currentUser?.user_role
                .toLowerCase()
                .includes('carilec')}
            />
          )}
          <div id="kpi-chart" className="bg-white p-1">
            <div className="text-center text-gray-600 border-t border-b border-gray-200 mb-5 py-3">
              <h1 className="font-semibold capitalize">{currentTitle}</h1>
            </div>
            {tableData && (
              <Table columns={tableData.headers} data={tableData.data} />
            )}
            <>{graphData && <BarChart data={graphData} />}</>
          </div>
        </div>
      </div>
    </div>
  );
}
