import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { findTechnologies } from 'services/technologies.service';
import { findUtilities } from 'services/utilities.service';

// import { getUtilityGeneralData } from "services/utilitygeneraldata.service";
// import { getFinancialData } from "services/utilityfinancialdata.service";
// import { getUtilityTechnicalData } from "services/utilitytechnicaldata.service";
// import { getUtilityCustomerData } from "services/utilitycustomerdata.service";
// import { getRestorationPeriod } from "services/restorationperiod.service";
// import { getTechnologyData } from "services/technologydata.service";

import { findUsageCategories } from 'services/usagecategories.service';
import { getUsageCategoryData } from 'services/usagecategorydata.service';

import { findKPIs } from 'services/kpi.service';
import { findRoles } from 'services/roles.service';
import PageTitle from 'containers/PageTitle';
import Sidebar from 'containers/ReportSideBar';
import Table from 'components/Table';

import ReportFilterForm from 'components/forms/ReportFilterForm';
import BarChart from 'components/charts/BarChart';
import {
  calculateMonthlyCustomer,
  calculateDisaggregatedTariffs,
} from '_utils/calculations';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { formatReportTitle } from '_utils';

export default function App() {
  let { currentUser } = useAuth();
  //   console.log(currentUser);

  const { title: reportTitle } = useParams();
  //   console.log(reportTitle);

  //   const currentRole: string = currentUser.user_role;
  const userUtility: string[] = [currentUser.utility_id];
  const [graphData, setGraphData] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any>(null);
  // const [graphData2, setGraphData2] = React.useState<any>(null);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [selectedUtility, setSelectedUtility] = React.useState<any>(null);
  const [selectedStartYear, setSelectedStartYear] = React.useState<any>(null);
  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);

  React.useEffect(() => {
    setGraphData(null);
    setTableData(null);
  }, [reportTitle]);

  const { data: utilities } = useQuery('utilities', () =>
    findUtilities().then(res => res.data),
  );

  const { data: usageCategories } = useQuery('usagecategories', () =>
    findUsageCategories().then(res => res.data),
  );

  const {
    isLoading,
    error,
    data: usageCategoryData,
  } = useQuery('usagecategorydata', () =>
    getUsageCategoryData().then(res => res.data),
  );
  const location = useLocation();
  console.log(location.pathname);
  const category = location.pathname.split('/')[3];
  const { data: kpis } = useQuery(['kpipermissions', category], () =>
    findKPIs().then(res => {
      const kpis = res.data;
      const items = kpis?.filter((item: any) =>
        item.kpi_category.toLowerCase().includes('customer services'),
      );
      return items;
    }),
  );

  React.useEffect(() => {
    const current_kpi = kpis?.find(
      (kpi: any) =>
        kpi?.kpi?.trim().toLowerCase() === reportTitle?.split('-').join(' '),
    );
    // console.log(kpis, current_kpi);
    // const title =
    //   current_kpi?.description.substring(0, 6) +
    //   " - " +
    //   current_kpi?.kpi +
    //   " (" +
    //   current_kpi?.kpi_units +
    //   ")";
    // console.log(title);
    setCurrentTitle(formatReportTitle(current_kpi));
  }, [kpis, reportTitle]);

  const { data: userPermission } = useQuery(
    ['userPermission', reportTitle],
    () =>
      findKPIs().then(res => {
        const kpis = res.data;
        const kpi = kpis?.find(
          (kpi: any) =>
            kpi.kpi.trim().toLowerCase() === reportTitle?.split('-').join(' '),
        );
        // console.log(kpi);
        console.log(kpi, reportTitle, currentUser);
        const userPermission = kpi?.kpipermissions?.find(
          (permission: any) => permission.user_role_id === currentUser.role_id,
        )?.kpi_status;
        console.log(userPermission);
        return userPermission;
      }),
    { enabled: !!reportTitle },
  );

  // console.log(userPermission);

  // console.log(
  //   kpis?.find((kpi: any) => kpi.kpi.toLowerCase() === "service coverage")
  // );

  const { data: roles } = useQuery('roles', () =>
    findRoles().then(res => res.data),
  );

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Base Rate',
        accessor: 'base_rate',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Other Levies&Taxes',
        accessor: 'other_levies_taxes',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Fuel Surcharge',
        accessor: 'fuel_surcharge',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'VAT',
        accessor: 'vat',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Variable Charges',
        accessor: 'other_variable_charges',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Fixed Charges',
        accessor: 'fixed_charges',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Monthly Bill',
        accessor: 'charge',
        width: 100,
        // disableResizing: true,
      },
      {
        Header: 'Average Unit Cost',
        accessor: 'unitCharge',
        width: 100,
        // disableResizing: true,
      },
    ], //eslint-disable-next-line
    [],
  );

  const onSubmit = (data: any) => {
    // console.log(data);
    let selectedUtilities = data.utility_id;
    //If data.utility_id is null then
    if (data.utility_id[0].label === 'Select All') {
      selectedUtilities = utilities?.map((utility: any) => ({
        value: utility.utility_id,
        label: utility.utility_name,
      }));
    }
    setSelectedUtility(selectedUtilities);
    setSelectedStartYear(data.year_start);
    setSelectedCategory(data.usage_category_id);

    const usageCategory = usageCategories.find(
      (item: any) => item.usage_category_id === data?.usage_category_id,
    );
    //TODO: Check if the year range is valid

    let calcResults: any = {};

    if (reportTitle === 'monthly-customer-bills') {
      calcResults = calculateMonthlyCustomer(
        data.year_start,
        data.year_end,
        selectedUtilities,
        usageCategory.usage_category_id, //"d7fe9e8e-f2f8-4bf0-9660-48d1a5c2438e", //usage category id
        usageCategory.kwh, //"500", //usage category kwh
        usageCategoryData,
        userPermission,
        userUtility,
        utilities,
      );

      setGraphData({
        labels: calcResults.labels,
        //   labels: graphicalData.map((data) => data.year),
        datasets: [
          {
            label: reportTitle,
            data: calcResults.results,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });
    } else {
      const results = calculateDisaggregatedTariffs(
        usageCategoryData,
        selectedUtilities,
        usageCategory.usage_category_id,
        data.year_start,
        userPermission,
        usageCategories,
        userUtility,
      );

      setTableData({
        label: reportTitle,
        headers: tableColumns,
        data: results,
      });
    }

    // switch (reportTitle) {
    // 	case 'monthly-customer-bills': //report105
    // 		calcResults = calculateMonthlyCustomer(
    // 			data.year_start,
    // 			data.year_end,
    // 			data.utility_id,
    // 			usageCategory.usage_category_id, //"d7fe9e8e-f2f8-4bf0-9660-48d1a5c2438e", //usage category id
    // 			usageCategory.kwh, //"500", //usage category kwh
    // 			usageCategoryData,
    // 			userPermission,
    // 			userUtility,
    // 			utilities,
    // 		);
    // 		break;
    // 	// case "disaggregated-tariffs": //report110
    // 	//   calcResults = calculateTariffs(
    // 	//     data.year_start,
    // 	//     data.year_end,
    // 	//     data.utility_id,
    // 	//     usageCategory.usage_category_id, //"d7fe9e8e-f2f8-4bf0-9660-48d1a5c2438e", //usage category id
    // 	//     usageCategory.kwh, //"500", //usage category kwh
    // 	//     usageCategoryData,
    // 	//     userPermission,
    // 	//     userUtility
    // 	//   );
    // 	//   break;

    // 	default:
    // 		calcResults = calculateMonthlyCustomer(
    // 			data.year_start,
    // 			data.year_end,
    // 			data.utility_id,
    // 			usageCategory.usage_category_id, //"d7fe9e8e-f2f8-4bf0-9660-48d1a5c2438e", //usage category id
    // 			usageCategory.kwh, //"500", //usage category kwh
    // 			usageCategoryData,
    // 			userPermission,
    // 			userUtility,
    // 			utilities,
    // 		);
    // }
    // // console.log(calcResults);
    // setGraphData({
    // 	labels: calcResults.labels,
    // 	//   labels: graphicalData.map((data) => data.year),
    // 	datasets: [
    // 		{
    // 			label: reportTitle,
    // 			data: calcResults.results,
    // 			backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // 		},
    // 	],
    // });
  };

  const handleDownload = () => {
    var contentNode: any = document.getElementById('kpi-chart');
    if (contentNode.childNodes.length < 1) {
      alert('No data to download');
      return;
    }

    const utilityNames = selectedUtility
      ?.map((item: any) => item.label)
      .join('-');

    htmlToImage.toPng(contentNode).then(function (dataUrl) {
      saveAs(
        dataUrl,
        `${reportTitle}-${utilityNames}-${selectedStartYear}-${selectedCategory}.png`,
      );
    });
  };

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  //Filter utility options if user permissions is not full
  //If user permissions is ANON then no utility options should be available
  //If user permissions is UTIL then only their utility should be available
  const utilityOptions =
    userPermission !== 'FULL'
      ? userPermission === 'ANON'
        ? []
        : utilities?.filter((utility: any) =>
            userUtility.includes(utility.utility_id),
          )
      : utilities?.sort((a: any, b: any) =>
          a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
            ? 1
            : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
            ? -1
            : 0,
        );
  // console.log(graphData);
  console.log(tableData);
  console.log(usageCategories);
  console.log(utilityOptions);

  return (
    <div className="h-full">
      <PageTitle
        title="Customer Service KPIs"
        subTitle="Major Company KPIs"
        onPrint={handleDownload}
      />
      <div className="flex h-full">
        <Sidebar links={kpis} />
        <div className="bg-white shadow rounded-sm mb-4 p-4 sm:p-6 h-full w-full overflow-hidden">
          {utilityOptions && usageCategories && (
            <ReportFilterForm
              utilityOptions={utilityOptions}
              usageCategories={usageCategories}
              onSubmit={onSubmit}
              userPermission={userPermission}
              canSelectMultiple={currentUser?.user_role
                .toLowerCase()
                .includes('carilec')}
            />
          )}

          <div id="kpi-chart" className="bg-white p-1">
            <div>
              <div className="text-center text-gray-600 border-t border-b border-gray-200 mb-5 py-3">
                <h1 className="font-semibold capitalize">{currentTitle}</h1>
              </div>

              {tableData && (
                <div className="overflow-x-scroll">
                  <Table columns={tableData.headers} data={tableData.data} />
                </div>
              )}
              {graphData && <BarChart data={graphData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
