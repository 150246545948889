import axios from "../config/apiClient";

export const findRoles = async () => {
  const response = await axios.get("/roles");

  return response;
};

export const addRole = async (data: any) => {
  const response = await axios.post("/roles", data);

  return response;
};

export const updateRole = async (data: any) => {
  const response = await axios.put("/roles", data);

  return response;
};
