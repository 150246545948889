import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = ({ data }: any) => {
  const options = {
    maintainAspectRatio: false,
    bezierCurve: false,
    animation: {
      duration: 0,
      // onComplete: () => {
      //   console.log("animation complete");
      // },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,
      },
      title: {
        display: true,
        position: 'bottom' as const,
        text: 'Source: CARILEC Automated Utility Benchmarking Platform (CAUBP)',
      },
    },
  };
  return (
    <div className="w-full h-[42rem] pb-10">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
