import React from "react";
// import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import UserCategoryForm from "../../components/forms/UserCategoryForm";
// import { findUsers, addUser, updateUser } from "services/users.service";
// import { findCountries } from "services/countries.service";
// import { findUtilities } from "services/utilities.service";
import { findRoles, addRole, updateRole } from "services/roles.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const Users = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const queryClient = useQueryClient();

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addRole(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("userroles");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateRole({ ...data, user_role_id: record?.user_role_id }).then(
      (res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.data.message);
        } else {
          toast.success("Record updated");
          queryClient.invalidateQueries("userroles");
          handleToggleModal();
        }
      }
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Role",
        accessor: "role",
      },
      // {
      //   Header: "KPI Override",
      //   accessor: "kpi_override",
      //   Cell: ({ cell: { value } }: any) => {
      //     return <div style={{ overflow: "unset" }}>{value ? "Y" : "N"}</div>;
      //   },
      // },
      // {
      //   Header: "BMR Status",
      //   accessor: "bmr_status",
      // },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    []
  );

  const {
    isLoading,
    error,
    data: userroles,
  } = useQuery("userroles", () => findRoles().then((res) => res.data));

  // const { data: countries } = useQuery("countries", () =>
  //   findCountries().then((res) => res.data)
  // );

  // const { data: utilities } = useQuery("utilities", () =>
  //   findUtilities().then((res) => res.data)
  // );

  // const { data: userroles } = useQuery("userroles", () =>
  //   findRoles().then((res) => res.data)
  // );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(users);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="User Categories" onClick={toggleModal} />

      <Table columns={columns} data={userroles} />
      {isModalOpen && (
        <Modal
          modalTitle={`${!record ? "Add" : "Edit"} Role`}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <UserCategoryForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Users;
