import axios from "../config/apiClient";

export const getUtilityGeneralData = async () => {
  const response = await axios.get("/utilitygeneraldata");

  return response;
};

export const findUtilityGeneralData = async (data_id: any) => {
  const response = await axios.get(`/utilitygeneraldata/${data_id}`);

  return response;
};

export const addUtilityGeneralData = async (data: any) => {
  const response = await axios.post("/utilitygeneraldata", data);

  return response;
};

export const updateUtilityGeneralData = async (data: any) => {
  const response = await axios.put("/utilitygeneraldata", data);

  return response;
};

export const removeUtilityGeneralData = async () => {
  const response = await axios.delete("/utilitygeneraldata");

  return response;
};
