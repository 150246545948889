import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

type Inputs = {
  role: string;
  kpi_override: any;
  // bmr_status: string;
};

type IFormProps = {
  initialValues?: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIUtilityGeneralForm(props: IFormProps) {
  console.log(props);

  const schema = yup.object().shape({
    role: yup.string().required("Field is required"),
    // bmr_status: yup
    //   .string()
    //   .oneOf(["ANON", "UTIL", "FULL"])
    //   .required("Field is required"),
    // kpi_override: yup
    //   .bool()
    //   .oneOf([true, false], "Accept Ts & Cs is required")
    //   .required(),
  });

  // const utilities: any[] = props.initialValues[0];
  // const yearFilters: any[] = props.initialValues[1];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues
    //   ? props.initialValues
    //   : {
    //       kpi_override: false,
    //     },
  });

  // React.useEffect(() => {
  //   console.log("kpi_override", props.initialValues?.kpi_override);
  //   setValue("kpi_override", props.initialValues?.kpi_override ? true : false);
  // }, [props.initialValues]);

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors", data);
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  // console.log(watch("kpi_override"), errors); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Role</span>
        <input
          {...register("role")}
          placeholder="Enter role here"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ErrorMessage
          errors={errors}
          name="role"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      {/* <label className="block">
        <input
          type="checkbox"
          id="kpi_override"
          {...register("kpi_override")}
          // value={true}
          className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 m-3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <span className="text-sm font-medium text-gray-400">KPI Override</span>
      </label>
      <ErrorMessage
        errors={errors}
        name="kpi_override"
        render={({ message }) => (
          <p className="text-xs text-red-500">{message}</p>
        )}
      /> */}

      {/* <label className="block">
        <span className="text-sm font-medium text-gray-400">BMR Status</span>
      <select
          {...register("bmr_status")}
          placeholder="Type value here"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">--Select One--</option>
          {["ANON", "UTIL", "FULL"]
            .sort((a: any, b: any) =>
              a.toLowerCase() > b.toLowerCase()
                ? 1
                : b.toLowerCase() > a.toLowerCase()
                ? -1
                : 0
            )
            .map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="bmr_status"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label> */}

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
