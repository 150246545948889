import React from "react";
import { useParams, Link } from "react-router-dom";
import toast from "react-hot-toast";
// import axios from "../../config/apiClient";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm";
// import { useAuthState } from "../../context/auth";
import { acceptInvite } from "../../services/auth.service";
import CryptoJS from "crypto-js";

interface FPFormValues {
  password: string;
  confirm_password: string;
}

const ChangePassword: React.FC = () => {
  const [userName, setUserName] = React.useState<string | null>(null);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [isError, setError] = React.useState(false);

  // let location = useLocation();
  // console.log(useParams()["*"]);
  //TODO: Better method of fetching the cipherText
  let cipherText: any = useParams()["*"];

  React.useEffect(() => {
    //de-crypt email address
    console.log(cipherText);
    try {
      if (typeof process.env.REACT_APP_MAIL_ENCRYPTION_KEY === "string") {
        let _ciphertext = CryptoJS.AES.decrypt(
          cipherText,
          process.env.REACT_APP_MAIL_ENCRYPTION_KEY
        ).toString(CryptoJS.enc.Utf8);
        console.log(process.env.REACT_APP_MAIL_ENCRYPTION_KEY, _ciphertext);
        if (_ciphertext.length > 0) {
          setUserName(_ciphertext);
        } else {
          setError(true);
        }
      } else {
        console.log("invalid secret key");
        setError(true);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    }
    // var _ciphertext = CryptoJS.AES.decrypt(path, 'secret-key-123');
    // console.log(_ciphertext.toString(CryptoJS.enc.Utf8));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePassword = async (data: FPFormValues) => {
    const { password } = data;
    console.log(password, userName);
    try {
      console.log("request password reset", data);
      acceptInvite({ password, user_name: userName }).then((res: any) => {
        console.log(res);
        setFormSuccess(true);
        // navigate("/dashboard");
      });
    } catch (error) {
      toast.error("Invalid user credentials");
    }
  };
  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-xl">
      <div className="w-full mx-auto my-12">
        <h1 className="text-3xl text-center py-4 font-bold">
          CARILEC Automated Utility Benchmarking Platform
        </h1>
        <h3 className="text-xl text-center py-4 font-bold">Accept Invite</h3>
        <div>
          {formSuccess ? (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline ml-2">
                Please go ahead and{" "}
                <Link to="/login" className="underline semi-bold text-blue-500">
                  login
                </Link>{" "}
                with the username "{userName}" and your chosen password
              </span>
            </div>
          ) : (
            <>
              {isError ? (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                  role="alert"
                >
                  <strong className="font-bold">An error occurred</strong>
                  <span className="block">
                    Something went wrong or the login name is invalid.
                  </span>
                </div>
              ) : (
                <ChangePasswordForm onSubmit={handleChangePassword} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
