import axios from "axios"
import jwt_decode from "jwt-decode"

const LocalStorageService = (function () {
    var _service
    function _getService() {
        if (!_service) {
            _service = this
            return _service
        }
        return _service
    }
    function _setToken(tokenObj) {
        localStorage.setItem("access_token", tokenObj.accessToken)
        localStorage.setItem("refresh_token", tokenObj.refreshToken)
    }
    function _getAccessToken() {
        return localStorage.getItem("access_token")
    }
    // function _setRefreshToken(tokenObj) {
    //     return localStorage.setItem("refresh_token", tokenObj.refreshToken)
    // }
    function _getRefreshToken() {
        return localStorage.getItem("refresh_token")
    }
    function _clearToken() {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
    }
    async function _refreshAccessToken() {
        const refreshToken = _getRefreshToken()
        const payload = jwt_decode(refreshToken)
        const expDate = new Date(payload.exp * 1000)
        const now = new Date()

        if (expDate.getTime() > now.getTime()) {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_API_SERVER + "auth/refreshtoken",
                    {
                        refresh_token: refreshToken,
                    }
                )

                _setToken({
                    accessToken: response.data.accessToken,
                    refreshToken,
                })

                return true
            } catch (error) {
                return false
            }
        } else {
            return false
        }
    }
    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        // setRefreshToken: _setRefreshToken,
        clearToken: _clearToken,
        refreshAccessToken: _refreshAccessToken,
    }
})()
export default LocalStorageService
