// import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { ConfirmationDialogProvider } from 'context/dialog';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 60 * 1000,
      cacheTime: 2 * (60 * 1000), // 2 mins
      // retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ConfirmationDialogProvider>
        <AuthProvider>
          <App />
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            toastOptions={{
              // success: {
              //   style: {
              //     background: 'green',
              //   },
              // },
              error: {
                style: {
                  color: 'white',
                  background: 'red',
                },
              },
            }}
          />
        </AuthProvider>
      </ConfirmationDialogProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
