import React from 'react';
// import faker from "@faker-js/faker";
// import Table from "../../components/Table";
import Loading from '../../components/Loading';
import ErrorPage from '../../components/ErrorPage';
// import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from 'react-query';
import UtilityTechnicalDataForm from '../../components/forms/UtilityTechnicalDataForm';
import VoltageCategoryData from 'pages/management/VoltageCategoryData';
// import RestorationPeriod from "pages/management/RestorationPeriod";
// import UsageCategory from "pages/management/UsageCategoryData";
import { findUtilities } from 'services/utilities.service';
import {
  addUtilityTechnicalData,
  // getUtilityCustomerData,
  findUtilityTechnicalData,
  updateUtilityTechnicalData,
} from 'services/utilitytechnicaldata.service';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import { HiArrowLeft } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import TechnologyData from './TechnologyData';
import DerSupportData from './DerSupportData';
import FuelData from './FuelData';
import PageTitle from 'containers/PageTitle';

const UtilityCustomerData = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const navigate = useNavigate();

  let { dataId } = useParams();

  const minYear = 1980;
  const maxYear = new Date().getFullYear() - 1;

  const fillRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );

  const yearFilters = fillRange(maxYear, minYear, -1);

  const queryClient = useQueryClient();

  const {
    // isLoading,
    // error,
    data: utilities,
  } = useQuery('utilities', () => findUtilities().then(res => res.data));

  const {
    isLoading,
    error,
    data: utilityTechnicalData,
  } = useQuery(
    ['utilitytechnicaldata', dataId],
    () => findUtilityTechnicalData(dataId).then(res => res.data),
    {
      enabled: !!dataId,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 0,
    },
  );

  // console.log(utilityTechnicalData);
  // const {
  //   isLoading,
  //   error,
  //   data: utilityCustomerData,
  // } = useQuery("utilitycustomerdata", () =>
  //   getUtilityCustomerData().then((res) => res.data)
  // );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  // const handleViewRecord = (data: any) => {
  //   console.log(data);
  //   navigate(`/dashboard/kpi/customers/${data.utility_cust_data_id}`);
  // };

  const handleAddRecord = (data: any) => {
    addUtilityTechnicalData(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record created');
        queryClient.invalidateQueries('utilitytechnicaldata');
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateUtilityTechnicalData({
      ...data,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record updated');
        queryClient.invalidateQueries('utilitytechnicaldata');
        handleToggleModal();
      }
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="flex h-full">
      <div className="flex-1 bg-grey-50">
        <PageTitle
          goBack={true}
          title="Utility Technical Data"
          subTitle={utilityTechnicalData?.utility_name}
          // onClick={toggleModal}
        />
        {utilityTechnicalData && (
          <div className="grid grid-cols-2 gap-4 divide-x bg-white border-b border-gray-200">
            <div className="p-3">
              <span className="block text-sm font-medium text-gray-400">
                Utility
              </span>
              {utilityTechnicalData?.utility_name}
            </div>
            <div className="p-3">
              <span className="block text-sm font-medium text-gray-400">
                Year
              </span>
              {utilityTechnicalData?.data_year}
            </div>
          </div>
        )}
        <Tab.Group>
          <Tab.List className="bg-white border-b border-gray-300">
            <Tab
              className={({ selected }) =>
                selected
                  ? 'px-4 py-2 bg-blue-500 text-white'
                  : 'px-4 py-2 bg-white text-black'
              }
            >
              General
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? 'px-4 py-2 bg-blue-500 text-white'
                  : 'px-4 py-2 bg-white text-black'
              }
            >
              Voltage Categories
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? 'px-4 py-2 bg-blue-500 text-white'
                  : 'px-4 py-2 bg-white text-black'
              }
            >
              Technology
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? 'px-4 py-2 bg-blue-500 text-white'
                  : 'px-4 py-2 bg-white text-black'
              }
            >
              DER Support Mechanism
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? 'px-4 py-2 bg-blue-500 text-white'
                  : 'px-4 py-2 bg-white text-black'
              }
            >
              Fuel
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="p-3">
              <UtilityTechnicalDataForm
                utilities={utilities}
                initialValues={utilityTechnicalData}
                onEdit={handleEditRecord}
                onAdd={handleAddRecord}
              />
            </Tab.Panel>
            <Tab.Panel>
              <VoltageCategoryData utility={utilityTechnicalData} />
            </Tab.Panel>
            <Tab.Panel>
              <TechnologyData utility={utilityTechnicalData} />
            </Tab.Panel>
            <Tab.Panel>
              <DerSupportData utility={utilityTechnicalData} />
            </Tab.Panel>
            <Tab.Panel>
              <FuelData utility={utilityTechnicalData} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default UtilityCustomerData;
