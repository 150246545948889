import {
  HiOutlineDownload,
  HiPlus,
  HiArrowLeft,
  HiOutlineMail,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const PageTitle = (props: any) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="flex items-center p-3 justify-between bg-white border-b border-t border-gray-200">
        <h3 className="text-gray-900 text-xl font-semibold flex gap-3 items-center">
          {props.goBack && (
            <button
              className="flex items-center text-sm gap-4 text-gray-400 p-2 hover:bg-gray-200 rounded"
              onClick={handleGoBack}
            >
              <HiArrowLeft className="w-4 h-4 " />
              Back
            </button>
          )}
          {props?.title}
          {/* {props?.subTitle && (
            <span className="flex items-center">
              <HiChevronRight className="w-6 h-6 text-gray-400" />
              {props.subTitle}
            </span>
          )} */}
        </h3>
        <div className="flex items-center gap-4">
          {props.onPrint && (
            <button
              className="flex items-center px-4 py-1 bg-blue-600 hover:bg-blue-800 text-white rounded"
              onClick={props.onPrint}
            >
              <HiOutlineDownload className="w-4 h-4 mr-3" />
              Download
            </button>
          )}
          {props.onSendEmail && (
            <button
              className="flex items-center px-4 py-1 bg-blue-600 hover:bg-blue-800 text-white rounded"
              onClick={props.onSendEmail}
            >
              <HiOutlineMail className="w-4 h-4 mr-3" />
              Email
            </button>
          )}
          {props.onClick && (
            <button
              className="flex items-center px-4 py-1 bg-blue-600 hover:bg-blue-800 text-white rounded"
              onClick={props.onClick}
            >
              Add
            </button>
          )}
        </div>
      </div>
      {/* {props?.subTitle && (
        <div className="flex items-center p-3 bg-white border-b border-gray-200">
          <p>{props.subTitle}</p>
        </div>
      )} */}
    </>
  );
};

export default PageTitle;
