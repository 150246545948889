import React from "react";
import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";

import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import ClimateDataForm from "../../components/forms/ClimateDataForm";
import { findUtilities } from "services/utilities.service";
import { findClimateScore } from "services/climatescore.service";
import {
  addClimateData,
  getClimateData,
  updateClimateData,
  removeClimateData,
} from "services/climateresiliencedata.service";
import toast from "react-hot-toast";
// import { Tab } from "@headlessui/react";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useConfirmationDialog from "hooks/useConfirm";
import PageTitle from "containers/PageTitle";

const UtilityCustomerData = (props: any) => {
  const { utility } = props;
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const { getConfirmation } = useConfirmationDialog();

  const navigate = useNavigate();
  const minYear = 1980;
  const maxYear = new Date().getFullYear() - 1;

  const fillRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const yearFilters = fillRange(maxYear, minYear, -1);

  const queryClient = useQueryClient();

  const {
    // isLoading,
    // error,
    data: utilities,
  } = useQuery("utilities", () => findUtilities().then((res) => res.data));

  const {
    // isLoading,
    // error,
    data: climateMechanisms,
  } = useQuery("climatescore", () =>
    findClimateScore().then((res) => res.data)
  );

  const {
    isLoading,
    error,
    data: climateData,
  } = useQuery("climatedata", () => getClimateData().then((res) => res.data));

  const columns = React.useMemo(
    () => [
      {
        Header: "Year",
        accessor: "data_year",
        width: 100,
        disableResizing: true,
      },
      {
        Header: "Utility",
        accessor: "utility_name",
        width: 200,
        disableResizing: true,
      },
      {
        Header: "Climate Resilience",
        accessor: "climate_change",
      },
      // {
      //   Header: "Climate Score",
      //   accessor: "climate_score",
      // },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions
              // onEdit={() => handleToggleModal(row?.original)}
              onDelete={() => handleRemove(row?.original)}
            />
          );
        },
      },
    ],
    []
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord({
        data_year: utility.data_year,
        utility_id: utility.utility_id,
      });
    } else setRecord(data);
    toggleModal();
  };

  // const handleViewRecord = (data: any) => {
  //   console.log(data);
  //   navigate(`/dashboard/kpi/customer/${data.clim_resil_id}`);
  // };

  const handleAddRecord = (data: any) => {
    addClimateData(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("climatedata");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateClimateData({ ...data }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("climatedata");
        handleToggleModal();
      }
    });
  };

  const handleRemove = async (data: any) => {
    const confirmed = await getConfirmation({
      title: "Are you sure?",
      message: "Removing this record cannot be reverted",
    });

    if (confirmed) {
      console.log("remove record");
      // deleteUserMutation.mutate(data);
      removeClimateData(data?.clim_resil_id).then((res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.data.message);
        } else {
          toast.success("Record removed");
          queryClient.invalidateQueries("climatedata");
          // handleToggleModal();
        }
      });
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="flex h-full">
      <div className="flex-1 bg-grey-50">
        <PageTitle
          title="Climate Resilience Data"
          onClick={() => handleToggleModal()}
        />
        {climateData && (
          <Table
            columns={columns}
            data={climateData.filter(
              (record: any) =>
                record.utility_id === utility.utility_id &&
                record.data_year === utility.data_year
            )}
          />
        )}
        {isModalOpen && (
          <Modal
            modalTitle={`${
              record?.clim_resil_id ? "Update" : "Add"
            } Climate Resilience Data`}
            isOpen={isModalOpen}
            closeModal={() => handleToggleModal()}
          >
            <ClimateDataForm
              utilities={utilities}
              climatechangescores={climateMechanisms}
              initialValues={record}
              onEdit={handleEditRecord}
              onAdd={handleAddRecord}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default UtilityCustomerData;
