import axios from "../config/apiClient";

export const findCountries = async () => {
  const response = await axios.get("/countries");

  return response;
};

export const addCountry = async (data: any) => {
  const response = await axios.post("/countries", data);

  return response;
};

export const updateCountry = async (data: any) => {
  const response = await axios.put("/countries", data);

  return response;
};

export const removeCountry = async () => {
  const response = await axios.delete("/countries");

  return response;
};
