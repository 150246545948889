import React from 'react';
import {
	HiOutlineDesktopComputer,
	HiOutlineInformationCircle,
	HiOutlineCurrencyDollar,
	HiOutlineThumbUp,
	HiOutlineLightBulb,
	HiOutlineLightningBolt,
	HiOutlineSwitchVertical,
	HiOutlineSparkles,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Reports = () => {
	const iconStyle = 'w-16 h-16 text-white';
	const panels = [
		{
			title: 'Major Company KPIs',
			url: 'kpi/major',
			icon: <HiOutlineDesktopComputer className={iconStyle} />,
			bgcolor: 'rgba(246,137,34,0.4)',
		},
		{
			title: 'Customer Service Rates KPIs',
			url: 'kpi/customerservice',
			icon: <HiOutlineThumbUp className={iconStyle} />,
			bgcolor: 'rgba(39,74,164,0.4)',
		},
		{
			title: 'Financial KPIs',
			url: 'kpi/financial',
			icon: <HiOutlineCurrencyDollar className={iconStyle} />,
			bgcolor: 'rgba(246,137,34,0.6)',
		},
		{
			title: 'Generation KPIs',
			url: 'kpi/generation',
			icon: <HiOutlineLightBulb className={iconStyle} />,
			bgcolor: 'rgba(39,74,164,0.6)',
		},
		{
			title: 'IPP KPIs',
			url: 'kpi/ipp',
			icon: <HiOutlineLightningBolt className={iconStyle} />,
			bgcolor: 'rgba(246,208,29,0.4)',
		},
		{
			title: 'T&D KPIs',
			url: 'kpi/td',
			icon: <HiOutlineSwitchVertical className={iconStyle} />,
			bgcolor: 'rgba(39,74,164,0.8)',
		},
		{
			title: 'Commercialization KPIs',
			url: 'kpi/commercialization',
			icon: <HiOutlineSparkles className={iconStyle} />,
			bgcolor: 'rgba(246,208,29,0.8)',
		},
		{
			title: 'Sustainability & Resilience KPIs',
			url: 'kpi/sustainability',
			icon: <HiOutlineInformationCircle className={iconStyle} />,
			bgcolor: 'rgba(246,137,34,0.8)',
		},
	];

	return (
		<>
			<div className="w-full p-10 grid grid-cols-2 md:grid-cols-4 gap-4">
				{panels.map(
					(
						item: {
							title: string;
							url: string;
							icon: React.ReactElement;
							bgcolor: string;
						},
						index: number,
					) => (
						<div
							key={item.title}
							style={{
								backgroundColor: item.bgcolor,
							}}
							className="h-56 bg-white shadow rounded-lg p-4"
						>
							<Link to={`/dashboard/${item.url}`} className="h-10">
								<div className="h-full flex flex-col text-center">
									<div className="flex-1 grid place-items-center">
										{item.icon}
									</div>

									<p>{item.title}</p>
								</div>
							</Link>
						</div>
					),
				)}
			</div>{' '}
		</>
	);
};

export default Reports;
