import axios from "../config/apiClient";

const api_route = "/fueldata";

export const getFuelData = async () => {
  const response = await axios.get(api_route);

  return response;
};

// export const findFuelData = async (data_id: any) => {
//   const response = await axios.get(`${api_route}/${data_id}`);

//   return response;
// };

export const addFuelData = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateFuelData = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeFuelData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res;
  });
};
