// import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphanumericRegExp } from "../../_utils";
import { ErrorMessage } from "@hookform/error-message";

type LoginFormValues = {
  email: string;
  password: string;
};

interface FormProps {
  onSubmit: (data: LoginFormValues) => void;
}

const LoginForm = (props: FormProps) => {
  const schema = yup
    .object({
      email: yup
        .string()
        .email("must be a valid email")
        // .min(6, "Minimum of 6 characters")
        // .matches(
        //   alphanumericRegExp,
        //   "User name must not contain any spaces or special characters (e.g. user.name or username)"
        // )
        .required("Field is required"),
      password: yup
        .string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          `Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character`
        )
        .min(8, "Minimum of 8 characters")
        .max(15, "Minimum of 15 characters")
        .required("Field is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isValid },
    reset,
  } = useForm<LoginFormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues: { username: "shannonc", password: "shannon" },
  });

  const onHandleSubmit = (data: LoginFormValues) => {
    // console.log(data, errors, Object.values(errors))
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      setTimeout(() => {
        props.onSubmit(data);
        //Clear the form fields using react-hook-form
        reset();
        // props.toggleModal()
      }, 500);
    }
  };
  console.log(isSubmitting, isSubmitted);
  return (
    <div className="w-2/3 p-4 mx-auto relative">
      <form className="space-y-5" onSubmit={handleSubmit(onHandleSubmit)}>
        <label className="form-label">
          Username / Email
          <input
            type="text"
            {...register("email")}
            className="w-full p-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:bg-white mt-2"
            placeholder="Enter your user name/email"
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }: any) => (
              <p className="block text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="form-label">
          Password
          <input
            type="password"
            {...register("password")}
            className="w-full p-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:bg-white mt-2"
            placeholder="Enter your password"
            autoComplete="phantom-password"
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }: any) => (
              <div className="block text-xs m-2 text-red-500">
                <p>This password must</p>
                <ol className="ml-4 list-disc">
                  <li>between 8 and 15 characters</li>
                  <li>contain at least one uppercase letter</li>
                  <li>contain at least one lowercase letter</li>
                  <li>contain at least one number</li>
                  <li>contain at least one special character</li>
                </ol>
              </div>
            )}
          />
        </label>
        <div className="mt-6 mb-5">
          <button
            type="submit"
            data-testid="login-button"
            disabled={!isValid || isSubmitting}
            className="mt-5 tracking-wide font-semibold bg-blue-500 text-white w-full py-4 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
          >
            {isSubmitted && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Login
          </button>
        </div>
      </form>
      <div>
        <a
          href="/forgotpassword"
          className="text-xs font-display text-indigo-600 hover:text-indigo-800 cursor-pointer"
        >
          Forgot Password?
        </a>
      </div>
    </div>
  );
};

export default LoginForm;
