import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { fillRange } from "../../utils";

type Inputs = {
  utility_id: string;
  data_year: string;
  clim_change_id: string;
  climate_score: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  climatechangescores?: any;
  onEdit: any;
  onAdd: any;
};

export default function ClimateDataForm(props: IFormProps) {
  // console.log(props);

  const { utilities, climatechangescores } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required("Field is required"),
    clim_change_id: yup.string().required("Field is required"),
    data_year: yup.string().required("Field is required"),
    climate_score: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      // .max(12)
      .required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    // watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues?.clim_resil_id) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-4">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("utility_id")}
            placeholder="Select a Utility"
            disabled
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("data_year")}
            placeholder="Select a Year"
            disabled
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-1 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Climate Disaster & Resilience Performance Measures
          </span>
          <select
            // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("clim_change_id")}
            placeholder="Select each Climate Disaster & Resilience Performance Measures your utility implemented during this year”"
            onChange={(evt: any) => {
              const { value } = evt.target;
              // console.log(value);
              if (value) {
                const score = climatechangescores.find(
                  (score: any) => score.clim_change_id === value
                );
                // console.log(score);
                setValue("climate_score", score?.climate_change_score);
              }
            }}
          >
            <option value="">
              Select each Climate Disaster & Resilience Performance Measures
              your utility implements
            </option>
            {climatechangescores?.map((option: any) => (
              <option key={option.clim_change_id} value={option.clim_change_id}>
                {option.climate_change}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="clim_change_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          {/* <span className="text-sm font-medium text-gray-400">
            Climate Score
          </span> */}
          <input
            readOnly
            {...register("climate_score")}
            type="hidden"
            placeholder="Enter amount (number)"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="climate_score"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
