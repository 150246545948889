import { useRef, useState } from 'react';
import { Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
// import Block from "@/components/Block";
import { NavLink } from 'react-router-dom';

export default function ExampleMenuHasArrow(props: any) {
	const { icon, title, url, options, position } = props;
	// console.log(title);
	const [show, setShow] = useState(false);
	const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

	const open = () => {
		if (timer.current !== null) {
			clearTimeout(timer.current);
			timer.current = null;
		}
		setShow(true);
	};

	const close = () => {
		setShow(false);
	};

	const delayClose = () => {
		timer.current = setTimeout(() => {
			setShow(false);
		}, 100);
	};

	return (
		<>
			<Menu>
				<Float show={show} placement={position} offset={12} arrow>
					<Menu.Button
						className="w-full border-none flex justify-start items-center gap-4 p-2 hover:bg-indigo-100 text-gray-600 rounded-md"
						onMouseEnter={open}
						onMouseLeave={delayClose}
					>
						{icon}
						{title}
					</Menu.Button>
					{options ? (
						<Menu.Items
							className="w-56 bg-white border border-gray-300 rounded-md shadow-lg focus:outline-none"
							onMouseEnter={open}
							onMouseLeave={delayClose}
						>
							<Float.Arrow className="absolute bg-white w-5 h-5 rotate-45 border border-gray-300" />
							<div className="relative bg-white rounded-md overflow-hidden">
								{url === 'help'
									? options?.map((option: any) => (
											<Menu.Item key={option.title}>
												{({ active }) => (
													<a
														key={option.title}
														target="_blank"
														rel="noreferrer"
														className={`block w-full px-4 py-1.5 text-left  ${
															active ? 'bg-blue-600 text-white' : ''
														}`}
														download
														// className="block px-4 py-2 text-sm capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-indigo-300 text-white"
														href={`/assets/files/${option.url}`}
													>
														<li className="">{option.title}</li>
													</a>
												)}
											</Menu.Item>
									  ))
									: options?.map((option: any) => (
											<Menu.Item key={option.title}>
												{({ active }) => (
													<NavLink
														to={`/dashboard/${url}/${option.url}`}
														className={`block w-full px-4 py-1.5 text-left  ${
															active ? 'bg-blue-600 text-white' : ''
														}`}
													>
														{option.title}
													</NavLink>
												)}
											</Menu.Item>
									  ))}
							</div>
						</Menu.Items>
					) : (
						<></>
					)}
				</Float>
			</Menu>
		</>
	);
}
