import axios from "../config/apiClient";

const api_route = "/voltagecategorydata";

export const getVoltageCategoryData = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addVoltageCategoryData = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateVoltageCategoryData = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeVoltageCategoryData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res;
  });
};
