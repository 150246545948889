import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import * as sessionsApi from "../services/sessions.service";
import LocalStorageService from "../services/localstorageservice";
import * as usersApi from "../services/users.service";
// import jwt_decode from "jwt-decode";

// interface any:any;

interface AuthContextType {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  currentUser?: any;
  loading: boolean;
  error?: any;
  login: (data: any) => Promise<{}>;
  signUp: (email: string, name: string, password: string) => void;
  logout: () => any; //TODO: Resolve type-checking
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const localStorageDomain =
  process.env.REACT_APP_LOCALSTORAGE || "carilec_session";

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const storedUser = window.localStorage.getItem(localStorageDomain);
  // console.log(storedUser);
  const [currentUser, setCurrentUser] = useState(
    storedUser !== "undefined" && typeof storedUser === "string"
      ? JSON.parse(storedUser)
      : null
  );
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  // const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(
    undefined
  );
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  // const navigate = useNavigate();
  const location = useLocation();

  // Reset the error state if we change page
  useEffect(() => {
    if (error) setError(undefined);
    //eslint-disable-next-line
  }, [location.pathname]);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  // useEffect(() => {
  //   usersApi
  //     .getCurrentUser()
  //     .then((user) => setCurrentUser(user))
  //     .catch((_error) => {})
  //     .finally(() => setLoadingInitial(false));
  // }, []);

  useEffect(() => {
    console.log("updating current user", currentUser);
    window.localStorage.setItem(
      localStorageDomain,
      JSON.stringify(currentUser)
    );
    if (currentUser) {
      LocalStorageService.setToken(currentUser);
      setAuthenticated(true);
      // handleAuth();
    } else {
      LocalStorageService.clearToken();
      setAuthenticated(false);
    }
    // setAuthenticated(currentUser ? true : false);
  }, [currentUser]);

  // const _clearSessionData = () => {
  //   LocalStorageService.clearToken();
  //   setCurrentUser(null);
  // };

  // const isAuthenticated = async () => {
  //   // const currentUser = JSON.parse(
  //   //   window.localStorage.getItem('wm_storedUser')
  //   // );
  //   if (!currentUser) return false;

  //   // Decode the access token so we can check it's payload. This will allow
  //   // the app to determine if to recognize the user as currently authenticated.
  //   let authenticated = true;
  //   try {
  //     const accessToken = LocalStorageService.getAccessToken();

  //     if (!accessToken) return false;
  //     let decoded: any = jwt_decode(accessToken);

  //     if (!decoded.id || decoded.id !== currentUser.id) {
  //       _clearSessionData();
  //       authenticated = false;
  //     } else {
  //       let expDate = new Date(decoded.exp * 1000);
  //       const now = new Date();

  //       if (expDate.getTime() <= now.getTime()) {
  //         // Access token has expired. Check if the refresh token is still valid.
  //         const refreshToken = LocalStorageService.getRefreshToken();
  //         if (!refreshToken) return false;
  //         decoded = jwt_decode(refreshToken);

  //         if (!decoded.id || decoded.id !== currentUser.id) {
  //           _clearSessionData();
  //           authenticated = false;
  //         } else {
  //           expDate = new Date(decoded.exp * 1000);
  //           if (expDate.getTime() <= now.getTime()) {
  //             // Refresh token has also expired.
  //             _clearSessionData();
  //             authenticated = false;
  //           } else {
  //             // Refresh token is still valid so silently refresh the user's access token.
  //             const refreshed = await LocalStorageService.refreshAccessToken();
  //             if (!refreshed) {
  //               _clearSessionData();
  //               authenticated = false;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     _clearSessionData();
  //     authenticated = false;
  //   }

  //   return authenticated;
  // };

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  function login(data: any) {
    const { email, password } = data;
    console.log(data);
    setLoading(true);

    return sessionsApi
      .login({ email, password })
      .then((response) => {
        console.log(response);
        if (response.error) {
          return { error: true };
          // throw "An error occurred";
        } else {
          const { user }: any = response;
          setCurrentUser(user);
          // navigate("/dashboard");
          return user;
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  function signUp(email: string, name: string, password: string) {
    setLoading(true);

    usersApi
      .signUp({ email, user_name: name, user_password: password })
      .then((user) => {
        setCurrentUser(user);
        // navigate("/");
        return user;
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout() {
    return sessionsApi.logout().then(() => setCurrentUser(undefined));
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      authenticated,
      currentUser,
      loading,
      error,
      login,
      signUp,
      logout,
    }),
    //eslint-disable-next-line
    [currentUser, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
