import React from "react";
// import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import axios from "../../config/apiClient";
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";
// import { useAuthState } from "../../context/auth";
import { forgotPassword } from "../../services/auth.service";

interface FPFormValues {
  username: string;
}

const LoginPage: React.FC = () => {
  // const navigate = useNavigate();

  // const { authenticated } = useAuthState();

  // React.useEffect(() => {
  //   if (authenticated) {
  //     navigate("/dashboard");
  //   }
  //   //eslint-disable-next-line
  // }, [authenticated]);

  const handleForgotPassword = async (data: FPFormValues) => {
    try {
      console.log("request password reset", data);
      forgotPassword(data).then((res: any) => {
        console.log(res);
        // navigate("/dashboard");
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error("An error occurred : " + res.message);
        }
      });
    } catch (error) {
      toast.error("Invalid user credentials");
    }
  };
  return (
    <div className="max-w-xl  mt-36 p-6 bg-white shadow-xl border border-gray-100">
      <div className="w-full mx-auto my-12">
        <h1 className="text-3xl text-center py-4 font-bold">
          CARILEC Automated Utility Benchmarking Platform
          {/* {process.env.REACT_APP_API_SERVER} */}
        </h1>
        <h3 className="text-xl text-center py-4 font-bold">Forgot Password</h3>
        {/* <h1 className="text-lg font-bold">Login</h1> */}
        <ForgotPasswordForm onSubmit={handleForgotPassword} />
      </div>
    </div>
  );
};

export default LoginPage;
