import axios from "../config/apiClient";

export const findTechnologies = async () => {
  const response = await axios.get("/technologies");

  return response;
};

export const addTechnology = async (data: any) => {
  const response = await axios.post("/technologies", data);

  return response;
};

export const updateTechnology = async (data: any) => {
  const response = await axios.put("/technologies", data);

  return response;
};

export const removeTechnology = async () => {
  const response = await axios.delete("/technologies");

  return response;
};
