import axios from "../config/apiClient";

export const findDERSupport = async () => {
  const response = await axios.get("/dersupport");

  return response;
};

export const addDERSupport = async (data: any) => {
  const response = await axios.post("/dersupport", data);

  return response;
};

export const updateDERSupport = async (data: any) => {
  const response = await axios.put("/dersupport", data);

  return response;
};

export const removeDERSupport = async () => {
  const response = await axios.delete("/dersupport");

  return response;
};
