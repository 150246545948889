import React, { useState, useEffect } from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { regex } from "_utils";
const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minimum of 3 characters")
    .required("Field is required"),
  // user_name: yup
  //   .string()
  //   .min(3, "Minimum of 3 characters")
  //   .required("Username is required"),
  email: yup
    .string()
    .email("Example John@email.com")
    .required("Field is required"),
  phone: yup
    .string()
    .matches(regex.phoneNumber.validation, regex.phoneNumber.message)
    .required("Field is required"),
  country_id: yup.string().required("Field is required"),
  passw_expiry: yup.date().nullable().default(new Date()),
});

type Inputs = {
  user_name: string;
  name: string;
  email: string;
  phone: string;
  country_id: number;
};

type IFormProps = {
  initialValues?: any;
  countries: any;
  onSubmit: any;
};

const ProfileForm = (props: IFormProps) => {
  const countries = props.countries;

  //   const [formSuccess, setFormSuccess] = useState(false);
  //   const [countryOptions, setCountryOptions] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data, errors, Object.values(errors));
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      props.onSubmit(data);
      //   setFormSuccess(!formSuccess);
      //Clear the form fields using react-hook-form
      // reset()
      // props.toggleModal()
    }
  };
  return (
    <div className="p-4 flex flex-col items-center md:mt-0 relative z-1">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div id="print-area">
          <label className="form-label">
            Name
            <input
              {...register("name")}
              placeholder="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label>

          {/* <label className="form-label">
            User Name
            <input
              {...register("user_name")}
              placeholder="user name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <ErrorMessage
              errors={errors}
              name="user_name"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label> */}

          <label className="form-label">
            Email
            <input
              {...register("email")}
              placeholder="email address"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label>

          <label className="form-label">
            Telephone
            <input
              {...register("phone")}
              placeholder="telephone"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label>

          <label className="text-gray-400 font-semibold text-sm">
            Choose Country
            <select
              className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
              {...register("country_id")}
              placeholder="Select a Country"
            >
              <option value="">--Select One--</option>
              {countries
                ?.sort((a: any, b: any) =>
                  a.country_name.toLowerCase() > b.country_name.toLowerCase()
                    ? 1
                    : b.country_name.toLowerCase() >
                      a.country_name.toLowerCase()
                    ? -1
                    : 0
                )
                .map((option: any) => (
                  <option key={option.country_name} value={option.country_id}>
                    {option.country_name}
                  </option>
                ))}
            </select>
            <ErrorMessage
              errors={errors}
              name="country_id"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label>
          {/* <label htmlFor="passwordExpiryDate" className="form-label">
            Password Expiration Date
            <Controller
              control={control}
              name="passw_expiry"
              render={({ onChange, value }) => (
                <Flatpickr
                  className="form-control"
                  placeholder="Password Expiration Date"
                  onChange={(date) => onChange(date)}
                  value={value}
                  data-testid="passwordExpirationDateId"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="passw_expiry"
              render={({ message }) => (
                <p className="text-xs text-red-500">{message}</p>
              )}
            />
          </label> */}
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="appearance-none tracking-wide font-semibold px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline block w-full"
          >
            Update User
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
