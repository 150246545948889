import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { fillRange } from '../../utils';
import { regex } from '_utils';
import useAuth from 'hooks/useAuth';
// import { NumericFormat } from 'react-number-format';

type Inputs = {
  utility_id: string;
  data_year: number;
  annual_utility_costs: number;
  before_tax_net_income: string;
  operational_revenue: string;
  energy_sold: string;
  earnings: string;
  non_current_assets_value: string;
  shareholder_equity: string;
  totaldebt: string;
  capital_cost: string;
  o_m_cost: string;
  fuel_cost: string;
  overhead_cost: string;
  generation_cost: string;
  opex_cost: string;
  t_d_cost: string;
  depreciation: number;
  total_generation_o_m_cost: string;
  total_generation_operation_cost: string;
  total_generation_maintain_cost: number;
  t_d_o_m_cost: string;
  t_d_capital_cost: string;
  commercialization_costs: string;
  bad_debt: string;
  cost_of_own_re_generation: string;
  pcent_of_asset_cover: number;
  disaster_fund: boolean;
  esco_models: boolean;
  integrated_utility_service: boolean;
  grant_loan_prog_ee_in_residential: boolean;
  grant_loan_prog_ee_in_commercial: boolean;
  grant_loan_prog_ee_in_industry: boolean;
  grant_loan_prog_ee_in_tourism: boolean;
  other_ee_measure: string;
  other_ee: boolean;
  finance_comment: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  onEdit: any;
  onAdd: any;
};

export default function UtilityFinancialDataForm(props: IFormProps) {
  // console.log(props);
  let { currentUser } = useAuth();
  const { utilities } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required('Field is required'),
    data_year: yup
      .number()
      .typeError('Must be a number')
      .required('Field is required'),
    annual_utility_costs: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    before_tax_net_income: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    operational_revenue: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    energy_sold: yup
      .number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, '')),
      )
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    earnings: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    non_current_assets_value: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    shareholder_equity: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    totaldebt: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    capital_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    o_m_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    fuel_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    overhead_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    generation_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    opex_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    t_d_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    depreciation: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    total_generation_o_m_cost: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    total_generation_operation_cost: yup
      // .string()
      // .matches(regex.integer.validation, regex.integer.message)
      // .transform((_, val) => (val === Number(val) ? val : null))
      .number()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Must be a number')
      .nullable(true),
    total_generation_maintain_cost: yup
      .number()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Must be a number')
      .nullable(true),
    t_d_o_m_cost: yup
      .number()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Must be a number')
      .nullable(true),
    t_d_capital_cost: yup
      .number()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Must be a number')
      .nullable(true),
    commercialization_costs: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      ),
    bad_debt: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    cost_of_own_re_generation: yup
      .number()
      .typeError('Must be a number')
      .test(
        'len',
        'Must be 10 digits or less',
        (val: any) => val.toString().length < 11,
      )
      .required('Field is required'),
    pcent_of_asset_cover: yup
      .number()
      .typeError('Must be a number')
      .max(999, 'Must be less than 999')
      .required('Field is required'),
    disaster_fund: yup.bool().oneOf([true, false], 'Must be true or false'),
    esco_models: yup.bool().oneOf([true, false], 'Must be true or false'),
    integrated_utility_service: yup
      .bool()
      .oneOf([true, false], 'Must be true or false'),
    grant_loan_prog_ee_in_residential: yup
      .bool()
      .oneOf([true, false], 'Must be true or false'),
    grant_loan_prog_ee_in_commercial: yup
      .bool()
      .oneOf([true, false], 'Must be true or false'),
    grant_loan_prog_ee_in_industry: yup
      .bool()
      .oneOf([true, false], 'Must be true or false'),
    grant_loan_prog_ee_in_tourism: yup
      .bool()
      .oneOf([true, false], 'Must be true or false'),
    other_ee_measure: yup
      .string()
      .max(40, 'Cannot be longer than 40 characters'),
    other_ee: yup.bool().oneOf([true, false], 'Must be true or false'),
    finance_comment: yup
      .string()
      .max(400, 'Cannot be longer than 400 characters'),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    console.log(props.initialValues);
    if (props.initialValues) {
      reset(props.initialValues);
    } else {
      console.log(currentUser);
      if (currentUser.user_role.toLowerCase().includes('utility')) {
        reset({ utility_id: currentUser.utility_id });
      }
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    // console.log(errors, data);
    if (Object.values(errors).length < 1) {
      console.log('no errors', data);
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-1">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic "
            {...register('utility_id')}
            placeholder="Select a Utility"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0,
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic "
            {...register('data_year')}
            placeholder="Select a Year"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-4 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Annual Utility Costs
          </span>
          <input
            {...register('annual_utility_costs')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="annual_utility_costs"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Before Tax Net Income
          </span>
          <input
            {...register('before_tax_net_income')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="before_tax_net_income"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Operational Revenue
          </span>
          <input
            {...register('operational_revenue')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="operational_revenue"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Energy Sold (GWh)
          </span>
          <Controller
            name="energy_sold"
            control={control}
            render={({ field: { ...rest } }) => (
              <input type="number" placeholder="Enter amount (GWh)" {...rest} />
            )}
          />

          <ErrorMessage
            errors={errors}
            name="energy_sold"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">Earnings</span>
          <input
            {...register('earnings')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="earnings"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Non Current Assets
          </span>
          <input
            {...register('non_current_assets_value')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="non_current_assets_value"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Shareholder Equity
          </span>
          <input
            {...register('shareholder_equity')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="shareholder_equity"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">Total Debt</span>
          <input
            {...register('totaldebt')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="totaldebt"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Overhead Cost
          </span>
          <input
            {...register('overhead_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="overhead_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">O&M Cost</span>
          <input
            {...register('o_m_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="o_m_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Variable Cost
          </span>
          <input
            {...register('fuel_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="fuel_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Capital Cost
          </span>
          <input
            {...register('capital_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="capital_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generation Cost
          </span>
          <input
            {...register('generation_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="generation_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">OPEX Cost</span>
          <input
            {...register('opex_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="opex_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">T&D Cost</span>
          <input
            {...register('t_d_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="t_d_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Depreciation
          </span>
          <input
            {...register('depreciation')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="depreciation"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generation O&M Cost
          </span>
          <input
            {...register('total_generation_o_m_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="total_generation_o_m_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generation Operation Cost
          </span>
          <input
            {...register('total_generation_operation_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="total_generation_operation_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generation Maintain Cost
          </span>
          <input
            {...register('total_generation_maintain_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="total_generation_maintain_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            T&D O&M Cost
          </span>
          <input
            {...register('t_d_o_m_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="t_d_o_m_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            T&D Capital Cost
          </span>
          <input
            {...register('t_d_capital_cost')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="t_d_capital_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Commercialization Cost
          </span>
          <input
            {...register('commercialization_costs')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="commercialization_costs"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">Bad Debt</span>
          <input
            {...register('bad_debt')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="bad_debt"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Cost of Own RE Generation
          </span>
          <input
            {...register('cost_of_own_re_generation')}
            placeholder="Enter amount (US $)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="cost_of_own_re_generation"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            % Asset Cover
          </span>
          <input
            {...register('pcent_of_asset_cover')}
            placeholder="Enter amount (%)"
            className="bg-gray-50 border border-gray-300 placeholder:text-xs placeholder:text-slate-400 placeholder:italic text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name="pcent_of_asset_cover"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>
      <div className="border-b border-indigo-200 w-full">
        <h2 className="text-indigo-400 text-sm">
          Please tick each facility below if it is present in your
          utility/jurisdiction
        </h2>
      </div>
      <div className="grid grid-cols-4 gap-2">
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Disaster Fund
          </span>
          <input
            {...register('disaster_fund')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'disaster_fund'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            ESCO Models
          </span>
          <input
            {...register('esco_models')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'esco_models'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Integrated Utility Service
          </span>
          <input
            {...register('integrated_utility_service')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'integrated_utility_service'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="text-sm font-medium text-gray-400">
            Grant/Loan Program for EE in Residential
          </span>
          <input
            {...register('grant_loan_prog_ee_in_residential')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'grant_loan_prog_ee_in_residential'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="text-sm font-medium text-gray-400">
            Grant/Loan Program for EE in Commercial
          </span>
          <input
            {...register('grant_loan_prog_ee_in_commercial')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'grant_loan_prog_ee_in_commercial'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Grant/Loan Program for EE in Industry
          </span>
          <input
            {...register('grant_loan_prog_ee_in_industry')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'grant_loan_prog_ee_in_industry'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Grant/Loan Program for EE in Tourism
          </span>
          <input
            {...register('grant_loan_prog_ee_in_tourism')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'grant_loan_prog_ee_in_tourism'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="inline-flex items-center">
          <span className="flex-1 text-sm font-medium text-gray-400">
            Other EE
          </span>
          <input
            {...register('other_ee')}
            type="checkbox"
            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{' '}
          <ErrorMessage
            errors={errors}
            name={'other_ee'}
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>
      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Other EE Measure
        </span>
        <input
          {...register('other_ee_measure')}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{' '}
        <ErrorMessage
          errors={errors}
          name="other_ee_measure"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Comments</span>
        <textarea
          {...register('finance_comment')}
          className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name={'finance_comment'}
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
