import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import ClimateScoreForm from "../../components/forms/ClimateScoreForm";
import {
  findClimateScore,
  addClimateScore,
  updateClimateScore,
} from "services/climatescore.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const ClimateScore = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: climateMechanisms,
  } = useQuery("climatescore", () =>
    findClimateScore().then((res) => res.data)
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addClimateScore(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("climatescore");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateClimateScore({
      ...data,
      clim_change_id: record?.clim_change_id,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("climatescore");
        handleToggleModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Climate Resilience",
        accessor: "climate_change",
      },
      {
        Header: "Score",
        accessor: "climate_change_score",
        width: 100,
        disableResizing: true,
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(utilities);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Climate Resilience" onClick={toggleModal} />
      <Table columns={columns} data={climateMechanisms} />
      {isModalOpen && (
        <Modal
          modalTitle={`${record ? "Update" : "Add"} a Climate Resilience`}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <ClimateScoreForm
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default ClimateScore;
