import axios from "../config/apiClient";

export async function login(params: any): Promise<any> {
  const { email, password } = params;
  // const response: any = await axios.post("/auth/signin", {
  //   user_name,
  //   password,
  // });
  // console.log(response);
  // if (response.error) {
  //   return response;
  // } else {
  //   return response.data;
  // }

  return await axios
    .post("/auth/login", {
      email,
      password,
    })
    .then((response) => {
      // if (response.data.accessToken) {
      //   localStorage.setItem("user", JSON.stringify(response.data));
      // }
      // console.log(response);
      // setCurrentUser(response.data.user);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
}

export async function logout() {
  // const response = await axios.delete("/auth/signout");
  const response = { data: { data: { message: "logout successful" } } };
  return response.data.data;
}
