import axios from "../config/apiClient";

const api_route = "/customercategorydata";

export const getCustomerCategoryData = async () => {
  const response = await axios.get(api_route);

  return response;
};

export const addCustomerCategoryData = async (data: any) => {
  const response = await axios.post(api_route, data);

  return response;
};

export const updateCustomerCategoryData = async (data: any) => {
  const response = await axios.put(api_route, data);

  return response;
};

export const removeCustomerCategoryData = async (id: string) => {
  return await axios.delete(`${api_route}/${id}`).then((res) => {
    return res.data;
  });
};
