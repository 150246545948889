// import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

type Inputs = {
  usagecategory: string;
  description: string;
  kwh: string;
  cust_category_id: number;
};

type IFormProps = {
  initialValues?: any;
  customercategories?: any;
  onEdit: any;
  onAdd: any;
};

export default function KPIUtilityGeneralForm(props: IFormProps) {
  console.log(props);

  const { customercategories } = props;

  const schema = yup.object().shape({
    usagecategory: yup.string().required("Field is required"),
    kwh: yup.string().required("Field is required"),
    description: yup.string().required("Field is required"),
    cust_category_id: yup.string().required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };
  // console.log(errors);
  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Category</span>
        <input
          {...register("usagecategory")}
          placeholder="usage category"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="usagecategory"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">kWh</span>
        <input
          {...register("kwh")}
          placeholder="KiloWatt Hours"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="kwh"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Description</span>
        <textarea
          {...register("description")}
          placeholder="description"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>{" "}
        <ErrorMessage
          errors={errors}
          name="description"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Customer Category
        </span>
        <select
          {...register("cust_category_id")}
          placeholder="Type value here"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">--Select One--</option>
          {customercategories?.map((value: any) => (
            <option key={value.cust_category_id} value={value.cust_category_id}>
              {value.cust_category}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="cust_category_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
