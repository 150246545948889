import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";

type FormValues = {
 password: string;
 confirm_password: string;
};

interface FormProps {
 onSubmit: (data: FormValues) => void;
}
const ChangePasswordForm = (props: FormProps) => {
 // const [formSuccess, setFormSuccess] = useState(false)
 const [submitting, setSubmitting] = useState(false);

 const schema = yup.object().shape({
  password: yup
   .string()
   .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    `Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character`
   )
   .min(8, "Minimum of 8 characters")
   .max(15, "Maximum of 15 characters")
   .required("Field is required"),
  confirm_password: yup
   .string()
   .oneOf([yup.ref("password"), null], "Passwords must match"),
 });
 const {
  register,
  handleSubmit,
  formState: { errors, isValid },
  reset,
 } = useForm<FormValues>({
  mode: "onChange",
  resolver: yupResolver(schema),
 });

 const onSubmit = (data: FormValues) => {
  console.log(data, errors, Object.values(errors));
  if (Object.values(errors).length < 1) {
   console.log("no errors");
   // return;
   setSubmitting(true);
   props.onSubmit(data);
   // setFormSuccess(!formSuccess)
   //Clear the form fields using react-hook-form
   reset();
   setSubmitting(false);
   // props.toggleModal()
  }
 };
 return (
  <div className="bg-white rounded-lg p-4 flex flex-col md:ml-auto w-full md:mt-0 relative z-10">
   <form onSubmit={handleSubmit(onSubmit)}>
    <label className="form-label">
     New Password
     <input
      type="password"
      {...register("password")}
      className="w-full p-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:bg-white mt-2"
      placeholder="Enter your new password"
     />
     <ErrorMessage
      errors={errors}
      name="password"
      render={({ message }: any) => (
       <p className="block text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="form-label">
     Confirm Password
     <input
      type="password"
      {...register("confirm_password")}
      className="w-full p-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:bg-white mt-2"
      placeholder="Confirm your new password"
     />
     <ErrorMessage
      errors={errors}
      name="confirm_password"
      render={({ message }: any) => (
       <p className="block text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <div className="mt-6">
     <button
      type="submit"
      className="appearance-none px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline block w-full"
      disabled={!isValid || submitting}
     >
      Save Password
     </button>
    </div>
   </form>
  </div>
 );
};

export default ChangePasswordForm;
