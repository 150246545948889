import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { fillRange } from "../../utils";
import { regex } from "_utils";

type Inputs = {
  utility_id: string;
  data_year: string;
  fuel_id: string;
  fuel_cost: string;
  fuel_used: string;
  generation_cost: string;
  energy_generated: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  fuels?: any;
  onEdit: any;
  onAdd: any;
};

export default function FuelDataForm(props: IFormProps) {
  // console.log(props);

  const { utilities, fuels } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required("Field is required"),
    data_year: yup.string().required("Field is required"),
    fuel_id: yup.string().required("Field is required"),
    energy_generated: yup
      .string()
      .matches(regex.numeric101.validation, regex.numeric101.message)
      .required("Field is required"),
    generation_cost: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Field is required"),
    fuel_used: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Field is required"),
    fuel_cost: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    // watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues?.fuel_data_id) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-2">
        <label className="text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("utility_id")}
            placeholder="Select a Utility"
            disabled
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("data_year")}
            placeholder="Select a Year"
            disabled
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>
      <label className="block">
        <span className="text-sm font-medium text-gray-400">Fuel</span>
        <select
          // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register("fuel_id")}
          placeholder="Select a Fuel"
        >
          <option value="">--Select One--</option>
          {fuels?.map((option: any) => (
            <option key={option.fuel_id} value={option.fuel_id}>
              {option.fuel_name}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="fuel_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>
      <div className="grid grid-cols-2 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Energy Generated
          </span>
          <input
            {...register("energy_generated")}
            type="number"
            step="any"
            placeholder="Enter amount (GWh)"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="energy_generated"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            Generation Cost
          </span>
          <input
            {...register("generation_cost")}
            type="number"
            placeholder="Enter amount (US $)"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="generation_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">Fuel Used</span>
          <input
            {...register("fuel_used")}
            type="number"
            placeholder="Enter amount (US Gallon)"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="fuel_used"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">Fuel Cost</span>
          <input
            {...register("fuel_cost")}
            type="number"
            placeholder="Enter amount (US $)"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="fuel_cost"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
