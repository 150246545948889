import React from 'react';
// import faker from "@faker-js/faker";
import Table from '../../components/Table';
import TableActions from 'components/TableActions';
import Modal from '../../components/Modal';
import { useQuery, useQueries, useQueryClient } from 'react-query';
import BenchmarkForm from '../../components/forms/BenchmarkForm';
import {
  findBenchmarks,
  addBenchmark,
  updateBenchmark,
  removeBenchmark,
} from 'services/benchmarks.service';
import { findUtilities } from 'services/utilities.service';
import { findKPIs } from 'services/kpi.service';
import { getUtilityGeneralData } from 'services/utilitygeneraldata.service';
import { getFinancialData } from 'services/utilityfinancialdata.service';
import { getUtilityTechnicalData } from 'services/utilitytechnicaldata.service';
import { getTechnologyData } from 'services/technologydata.service';
import { getRestorationPeriod } from 'services/restorationperiod.service';
import { getUtilityCustomerData } from 'services/utilitycustomerdata.service';
import { findTechnologies } from 'services/technologies.service';
import { findUsageCategories } from 'services/usagecategories.service';

import { findVoltageCategories } from 'services/voltagecategories.service';
import { getUsageCategoryData } from 'services/usagecategorydata.service';
import { getVoltageCategoryData } from 'services/voltagecategorydata.service';

import { findCustomerCategories } from 'services/customercategories.service';
import { getCustomerCategoryData } from 'services/customercategorydata.service';

import { getFuelData } from 'services/fueldata.service';
import { findFuels } from 'services/fuels.service';
import { findDERSupport } from 'services/dersupport.service';
import { getDerSupportData } from 'services/dersupportdata.service';
import { getClimateData } from 'services/climateresiliencedata.service';
import { findRoles } from 'services/roles.service';
import { uploadFile } from 'services/file.server';
import useAuth from 'hooks/useAuth';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import PageTitle from 'containers/PageTitle';
import BarChart from 'components/charts/BarChart';
import { HiOutlineDownload } from 'react-icons/hi';
import useConfirmationDialog from 'hooks/useConfirm';
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS } from 'chart.js';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import {
  calculateAvgEnergyCost,
  calculateEnergySystemLosses,
  calculateLabourProductivity,
  calculateOperationalProfitMargin,
  calculateServiceCoverage,
  calculateSAIDI,
  calculateSAIFI,
  calculateEnergyByRES,
  calculateCO2EquivEmissions,
  calculateInjuryRate,
  calculateMonthlyCustomer,
  calculateEBITDA,
  calculateReturnOnAssets,
  calculateReturnOnEquity,
  calculateDebtLevel,
  calculateOperatingRatio,
  calculateSystemLoadFactor,
  calculateReserveMargin,
  calculateSystemEquiv,
  calculateGenerationEfficiency,
  calculateCapacityChangePES,
  calculateEnergyGenerationPES,
  // calculateFuelCost,
  // calculateEfficiencyFuelUnits,
  calculateGenerationOM,
  calculateLubeOilConsumption,
  calculateAverageGenerationCost,
  calculateThermalGenerationCost,
  calculateGenerationProductivity,
  calculateFossilFuelByIPPs,
  calculateRenewableEnergyByIPPs,
  calculateTDCost,
  calculateTDProductivity,
  calculateGridLossPerVolt,
  calculateCAIDI,
  calculateNonTechnicalLosses,
  calculateNetworkDisaggregation,
  calculateNumberCustomerComplaints,
  calculateSmartMeterCustomers,
  calculateCommercializationCost,
  calculateBadDebt,
  calculateCommercialProductivity,
  calculateCostByType,
  calculateCostByBusinessArea,
  calculateShareOfUndergroundLine,
  calculateInstalledCapacity,
  calculateGenerationChangePES,
  calculateSharePerCustomerGroup,
  calculateFuelCost,
  calculateEfficiencyFuelUnits,
  calculateCapThresholdDER,
  calculateSupportMechanismDER,
  calculateEEDSMProgram,
  calculateAvailabilityOfFunds,
  calculateGridModernization,
  calculateGender,
  calculateAvgRECost,
  calculateSolarCapacityFactor,
  calculateWindCapacityFactor,
  calculateDERCapacity,
  calculateEnvironmentalPerformance,
  calculateClimateResilience,
  calculateOSHImplementation,
  calculateDisaggregatedTariffs,
} from '_utils/calculations';
// import Loading from "components/Loading";
import FileUploadForm from 'components/forms/FileUploadForm';
import { formatReportTitle } from '_utils';

const ManageBenchmarks = () => {
  const [isProcessing, setProcessing] = React.useState(false);
  const [isFileModalOpen, toggleFileModal] = React.useState(false);
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  let [duplicateRecord, setDuplicateRecord] = React.useState<any>(null);
  let { currentUser } = useAuth();
  const [graphData, setGraphData] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [tableRowData, setTableRowData] = React.useState<any>([]);
  const [usageCategoryTableData, setUsageCategoryTableData] =
    React.useState<any>([]);
  const [tableColumns, setTableColumns] = React.useState<any>(null);
  const [reportTitles, setReportTitles] = React.useState<any>(null);

  const [selectedUtility, setSelectedUtility] = React.useState<any>(null);
  const [selectedStartYear, setSelectedStartYear] = React.useState<any>(null);

  const [iframeSrc, setIframeSrc] = React.useState<any>(null);

  const { getConfirmation } = useConfirmationDialog();
  let navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    isLoading: loadingBenchmarks,
    error,
    data: benchmarks,
  } = useQuery('benchmarks', () => findBenchmarks().then(res => res.data));

  const { data: userroles } = useQuery('userroles', () =>
    findRoles().then(res => res.data),
  );

  const {
    isLoading: loadingKPIs,
    error: errorKPIs,
    data: kpis,
  } = useQuery('kpilist', () =>
    findKPIs().then(res => {
      return res.data;
    }),
  );

  const {
    isLoading,
    error: voltageCategoryDataError,
    data: voltageCategoryData,
  } = useQuery('voltagecategorydata', () =>
    getVoltageCategoryData().then(res => res.data),
  );

  const { data: fuelData } = useQuery('fueldata', () =>
    getFuelData().then(res => res.data),
  );
  const { data: derSupport } = useQuery('dersupport', () =>
    findDERSupport().then(res => res.data),
  );
  const { data: derSupportData } = useQuery('dersupportdata', () =>
    getDerSupportData().then(res => res.data),
  );

  const { data: climateResilienceData } = useQuery(
    'climateresiliencedata',
    () => getClimateData().then(res => res.data),
  );

  const { data: fuels } = useQuery(
    'fuels',
    () =>
      findFuels().then(res => {
        const record = res.data;
        // console.log(record, fuelData);
        record.map((rec: any) => {
          const fuel = fuelData?.filter(
            (fuel: any) => fuel.fuel_id === rec.fuel_id,
          );
          // console.log(fuel);
          fuel.map((f: any) => {
            rec.utility_id = f?.utility_id;
            rec.data_year = f?.data_year;
            rec.utility_code = f?.utility_code;
            rec.fuel_cost = f?.fuel_cost;
            rec.energy_generated = f?.energy_generated;
          });

          return rec;
        });
        // console.log(record);
        // const utilityTechInfo = technologyData.find(
        //   (techData: any) => techData.utility_id === record.utility_id
        // );
        // console.log(utilityTechInfo);
        return record;
      }),
    { enabled: !!fuelData },
  );

  const { isLoading: loadingUsageCategories, data: usageCategories } = useQuery(
    'usagecategories',
    () => findUsageCategories().then(res => res.data),
  );

  const { isLoading: loadingVoltageCategories, data: voltageCategories } =
    useQuery('voltagecategories', () =>
      findVoltageCategories().then(res => res.data),
    );

  const { isLoading: loadingUsageCategoryData, data: usageCategoryData } =
    useQuery(
      'usagecategorydata',
      () => getUsageCategoryData().then(res => res.data),
      {
        enabled: !!usageCategories,
      },
    );

  const { data: customerCategories } = useQuery('customercategories', () =>
    findCustomerCategories().then(res => res.data),
  );

  const {
    // isLoading,
    // error,
    data: customerCategoryData,
  } = useQuery(
    'customercategorydata',
    () => getCustomerCategoryData().then(res => res.data),
    {
      enabled: !!customerCategories,
    },
  );

  const { isLoading: loadingTechnologies, data: technologies } = useQuery(
    'technologies',
    () => findTechnologies().then(res => res.data),
  );

  const { isLoading: loadingTechnologyData, data: technologyData } = useQuery(
    'technologydata',
    () => getTechnologyData().then(res => res.data),
  );

  const { isLoading: loadingUtilityFinancialData, data: utilityFinancialData } =
    useQuery('utilityfinancialdata', () =>
      getFinancialData().then(res => res.data),
    );

  const { isLoading: loadingUtilityTechnicalData, data: utilityTechnicalData } =
    useQuery('utilitytechnicaldata', () =>
      getUtilityTechnicalData().then(res => res.data),
    );
  const { isLoading: loadingUtilities, data: utilities } = useQuery(
    'utilities',
    () => findUtilities().then(res => res.data),
  );

  const {
    isLoading: loadingRestorationPeriodData,
    data: restorationPeriodData,
  } = useQuery('restorationperiod', () =>
    getRestorationPeriod().then(res => res.data),
  );

  const { isLoading: loadingUtilityCustomerData, data: utilityCustomerData } =
    useQuery('utilitycustomerdata', () =>
      getUtilityCustomerData().then(res => res.data),
    );

  const { isLoading: loadingUtilityGeneralData, data: utilityGeneralData } =
    useQuery('utilitygeneraldata', () =>
      getUtilityGeneralData().then(res => res.data),
    );

  const getPermission = (reportTitle: any, role_id: any) => {
    // const kpis = res.data;
    //Find the current KPI
    // const kpi = kpis?.find((kpi: any) =>
    //   kpi.kpi.trim().toLowerCase() ===
    //   reportTitle.includes("monthly-customer-bills")
    //     ? reportTitle
    //         ?.split("_")
    //         .shift()
    //         .split("-")
    //         .join(" ")
    //         .trim()
    //         .toLowerCase()
    //     : reportTitle?.split("-").join(" ")
    // );

    const isGroupedReport =
      reportTitle.includes('monthly-customer-bills') ||
      reportTitle.includes('disaggregated-tariffs');

    const kpi = kpis?.find((kpi: any) =>
      isGroupedReport
        ? kpi.kpi.trim().toLowerCase() ===
          reportTitle
            ?.split('_')
            .shift()
            .split('-')
            .join(' ')
            .trim()
            .toLowerCase()
        : kpi.kpi.trim().toLowerCase() ===
          reportTitle?.split('-').join(' ').trim().toLowerCase(),
    );
    // console.log(kpi);
    console.log(kpi, reportTitle, currentUser);
    //Find the assigned permission for the specified role
    const userPermission = kpi?.kpipermissions?.find(
      (permission: any) => permission.user_role_id === role_id,
    )?.kpi_status;
    console.log(reportTitle, role_id, userPermission);
    return userPermission;
  };

  React.useEffect(() => {
    // queryClient.invalidateQueries("kpilist");
    console.log(kpis);
    const items = kpis
      ?.filter(
        (item: any) =>
          item.kpi_category.toLowerCase().includes('major company kpis') ||
          item.kpi_category.toLowerCase().includes('customer service') ||
          item.kpi_category.toLowerCase().includes('financial') ||
          item.kpi_category.toLowerCase().includes('generation') ||
          item.kpi_category.toLowerCase().includes('ipp') ||
          item.kpi_category.toLowerCase().includes('t&d') ||
          item.kpi_category.toLowerCase().includes('commercialization') ||
          item.kpi_category.toLowerCase().includes('sustainability'),
      )
      .map((item: any) => {
        const formattedTitle = item.kpi
          .trim()
          .toLowerCase()
          .split(' ')
          .join('-');

        return {
          id: item.kpi_id,
          title: formattedTitle,
          label: formatReportTitle(item),
          // label: `${item.description?.substring(0, 6).trim()}-${item.kpi}-${
          //   item.kpi_units
          // }`,
          type: [
            'disaggregated-tariffs',
            'installed-capacity',
            'energy-generation-per-pes',
            'capacity-change-per-pes',
            'generation-change-per-pes',
            'cost-by-type',
            'cost-by-business-area',
            'fuel-cost',
            'fuel-efficiency-own-units',
            'thermal-generation-cost',
            'grid-loss-per-volt-level',
            'share-of-underground-line',
            'share-per-customer-group',
            'cap-threshold-for-der',
            'support-mechanism-for-der',
            'ee-and-dsm-programs',
            'availability-of-funds',
            'grid-modernization',
            // "osh-implementation",
            'gender',
          ].includes(formattedTitle)
            ? 'table'
            : 'graph',
        };
      });
    // console.log(items);

    const tempTitles = items?.map((kpi: any) => {
      if (kpi.title === 'monthly-customer-bills') {
        return usageCategories?.map((category: any) => {
          return {
            title: `${kpi.title}_${category.usage_category_id}_${category.kwh}`,
            type: kpi.type,
            // label: kpi.label,
            label: kpi.label + ' (' + category.usagecategory + ')',
          };
        });
      } else if (kpi.title === 'disaggregated-tariffs') {
        return usageCategories?.map((category: any) => {
          return {
            title: `${kpi.title}_${category.usage_category_id}_${category.kwh}`,
            type: kpi.type,
            label: kpi.label + ' (' + category.usagecategory + ')',
            // label: kpi.label + " (" + category.usagecategory + ")",
          };
        });
      } else return { title: kpi.title, type: kpi.type, label: kpi.label };
    });
    console.log(items, tempTitles?.flat(2));
    setReportTitles(tempTitles?.flat(2));
  }, [usageCategories, kpis]);

  React.useEffect(() => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 70,
        // disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 70,
        // disableResizing: true,
      },
    ];

    technologies?.map((tech: any) => {
      // console.log(tech);
      headerColumns.push({
        Header: tech?.technology,
        accessor: tech?.technology.split(' ').join('_').toLowerCase(),
        width: 50,
        minWidth: 50,
        maxWidth: 70,
        // disableResizing: true,
      });
    });
    // headerColumns.push()
    // console.log(technologies);

    setTableColumns(headerColumns);
  }, [technologies]);

  const getTableData = (voltagecategories: any, voltageCategoryData: any) => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const utilityVoltageData = voltageCategoryData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      utilityVoltageData?.map((data: any) => {
        const category = voltagecategories?.find(
          (category: any) => category.voltage_cat_id === data.voltage_cat_id,
        );

        // const utilityTechnical = utilityTechnicalData?.find(
        //   (data: any) => data.utility_id === utility.utility_id
        // );
        // console.log(
        //   utilityTechnical,
        //   utility.code,
        //   utilityTechnical?.own_thermal_energy_generated
        // );
        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.data_year,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility_code &&
            singleRecord.data_year === data.data_year,
        );

        const tableRow = {
          voltage_category: category?.voltage_category,
          percentage_underground: data?.percentage_underground,
          entry_energy: data?.entry_energy,
          delivered_energy: data?.delivered_energy,
        };
        //If exists
        if (existingRecord > -1) {
          if (category) {
            tableRows[existingRecord]?.fields.push(tableRow);
          }
        } else {
          if (category) {
            tableEntry.fields.push(tableRow);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record

        // tableData.push(tableEntry);
      });
    });
    return tableRows;
    // setTableRowData(tableRows);
  };

  const vcTableColumns = React.useMemo(
    () => getTableData(voltageCategories, voltageCategoryData),
    [utilities, voltageCategories, voltageCategoryData],
  );
  console.log(vcTableColumns);

  const customerTableData = React.useMemo(() => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const customerData = customerCategoryData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      customerData?.map((data: any) => {
        // console.log(data);
        const customercategory = customerCategories?.find(
          (category: any) =>
            category.cust_category_id === data.cust_category_id,
        );

        const utilityData = utilityCustomerData?.find(
          (record: any) =>
            record.utility_id === data.utility_id &&
            record.data_year === data.datayear,
        );
        // console.log(utilityData);
        // console.log(customercategory);
        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.datayear,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility.code &&
            singleRecord.data_year === data.datayear,
        );

        const tableRow = {
          cust_category_id: customercategory?.cust_category_id,
          nbr_customer_meters: Number(utilityData?.number_of_customer_meters),
          customers: data.customers,
          // fuel_cost: data.fuel_cost,
          // fuel_used: data.fuel_used,
          // energy_generated: data.energy_generated,
          // generation_cost: data.generation_cost,
        };
        //If exists
        if (existingRecord > -1) {
          if (customercategory) {
            tableRows[existingRecord]?.fields.push(tableRow);
          }
        } else {
          if (customercategory) {
            tableEntry.fields.push(tableRow);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record

        // tableData.push(tableEntry);
      });
    });
    return tableRows;
  }, [customerCategories, customerCategoryData]);
  console.log(customerTableData);

  const fuelTableData = React.useMemo(() => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const utilityFuelData = fuelData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      utilityFuelData?.map((data: any) => {
        const fuel = fuels?.find((fuel: any) => fuel.fuel_id === data.fuel_id);

        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.data_year,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility_code &&
            singleRecord.data_year === data.data_year,
        );

        const tableRow = {
          fuel_name: fuel?.fuel_name,
          fuel_cost: data.fuel_cost,
          fuel_used: data.fuel_used,
          energy_generated: data.energy_generated,
          generation_cost: data.generation_cost,
        };
        //If exists
        if (existingRecord > -1) {
          if (fuel) {
            tableRows[existingRecord]?.fields.push(tableRow);
          }
        } else {
          if (fuel) {
            tableEntry.fields.push(tableRow);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record

        // tableData.push(tableEntry);
      });
    });
    return tableRows;
  }, [utilities, fuels, fuelData]);
  console.log(fuelTableData);

  React.useEffect(() => {
    const tableRows: any = [];
    utilities?.forEach((utility: any) => {
      const utilityTechData = technologyData?.filter(
        (data: any) => data.utility_id === utility.utility_id,
      );
      // console.log(utilityFuelData?.length);
      utilityTechData?.map((data: any) => {
        const tech = technologies?.find(
          (tech: any) => tech.tech_id === data.tech_id,
        );

        const utilityTechnical = utilityTechnicalData?.find(
          (technicalData: any) =>
            technicalData.utility_id === utility.utility_id &&
            technicalData.data_year === data.data_year,
        );

        const tableEntry: any = {
          utility_id: utility.utility_id,
          utility_code: utility.code,
          data_year: data.data_year,
        };

        tableEntry.fields = [];
        //Check if utility code is already in tableData
        const existingRecord = tableRows?.findIndex(
          (singleRecord: any) =>
            singleRecord.utility_code === data.utility_code &&
            singleRecord.data_year === data.data_year,
        );

        const tableRecord = {
          technology: tech?.technology,
          net_energy_input: utilityTechnical?.net_energy_input,
          generated_energy: data.generated_energy,
          installed_capacity: data.installed_capacity,
        };
        //If exists
        if (existingRecord > -1) {
          if (tech) {
            tableRows[existingRecord]?.fields.push(tableRecord);
          }
        } else {
          if (tech) {
            tableEntry.fields.push(tableRecord);
          }
          tableRows.push(tableEntry);
        }
        //If not exists then add new record
        // console.log(tableRows);
        // tableData.push(tableEntry);
      });
    });
    setTableRowData(tableRows);
  }, [utilities, technologies, technologyData, utilityTechnicalData]);

  React.useEffect(() => {
    const tableRows: any = [];
    usageCategoryData?.forEach((data: any) => {
      // console.log(data);

      const usageCategory = usageCategories?.find(
        (item: any) =>
          item.usage_category_id === '6212575a-0137-4bcc-8fd6-aef3a6aaca26',
      );
      let charge =
        Number(usageCategory?.kwh) *
          (Number(data.base_rate) +
            Number(data.fuel_surcharge) +
            Number(data.vat) +
            Number(data.other_levies_taxes) +
            Number(data.other_variable_charges)) +
        Number(data.fixed_charges);

      let unitCharge = charge / Number(usageCategory?.kwh);
      tableRows.push({
        utility_code: data.utility_code,
        utility_id: data.utility_id,
        data_year: data.data_year,
        base_rate: Number(data.base_rate).toFixed(2),
        other_levies_taxes: Number(data.other_levies_taxes).toFixed(2),
        fuel_surcharge: Number(data.fuel_surcharge).toFixed(2),
        vat: Number(data.vat).toFixed(2),
        other_variable_charges: Number(data.other_variable_charges).toFixed(2),
        fixed_charges: Number(data.fixed_charges).toFixed(2),
        charge: Number(charge).toFixed(2),
        unitCharge: Number(unitCharge).toFixed(2),
      });
    });
    console.log(tableRows);
    setUsageCategoryTableData(tableRows);
  }, [usageCategories, usageCategoryData, selectedStartYear]);

  const generateReportName = (
    utility_code: string,
    year: number,
    role_id: string,
  ) => {
    const selectedRole = userroles
      ?.find((role: any) => role.user_role_id === role_id)
      .role.toLowerCase();

    let roleName = 'carilec';
    if (selectedRole.includes('management')) {
      roleName = 'util_man';
    } else if (selectedRole.includes('department')) {
      roleName = 'util_dept';
    } else if (selectedRole.includes('premium')) {
      roleName = 'premium';
    } else if (selectedRole.includes('external')) {
      roleName = 'external';
    } else {
      roleName = 'carilec';
    }
    const reportName = `${utility_code?.toUpperCase()}_${year}_${roleName.toUpperCase()}_report.pdf`;
    return reportName;
  };

  const addFooters = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();

    const pageHeight = doc.internal.pageSize.height;

    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        'Source: CARILEC Automated Utility Benchmarking Platform (CAUBP)',
        doc.internal.pageSize.width / 2,
        pageHeight - 10,
        {
          align: 'center',
        },
      );
      // doc.text(
      //   "Page " + String(i) + " of " + String(pageCount),
      //   doc.internal.pageSize.width / 2,
      //   pageHeight - 10,
      //   {
      //     align: "center",
      //   }
      // );
    }
  };

  const columnsCostByType = React.useMemo(
    () => [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
      // { Header: "Capital Cost", accessor: "capital_cost" },
      // { Header: "Annual Cost", accessor: "total_cost" },
      {
        Header: '% Capital',
        accessor: 'percent_capital',
      },
      {
        Header: '% O&M',
        accessor: 'percent_om',
      },
      {
        Header: '% Variable',
        accessor: 'percent_fuel',
      },
      {
        Header: '% Overhead',
        accessor: 'percent_overhead',
      },
    ], //eslint-disable-next-line
    [],
  );

  const columnsCostByBusinessArea = React.useMemo(
    () => [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
      // { Header: "Capital Cost", accessor: "capital_cost" },
      // { Header: "Annual Cost", accessor: "total_cost" },
      {
        Header: '% Generation',
        accessor: 'percent_generation',
      },
      {
        Header: '% T&D',
        accessor: 'percent_td',
      },
      {
        Header: '% Commercialization',
        accessor: 'percent_commercialization',
      },
    ], //eslint-disable-next-line
    [],
  );

  const tariffTableColumns = [
    {
      Header: 'Utility',
      accessor: 'utility_code',
      width: 100,
      disableResizing: true,
    },
    // {
    //   Header: "Year",
    //   accessor: "data_year",
    //   width: 100,
    //   disableResizing: true,
    // },

    {
      Header: 'Base Rate',
      accessor: 'base_rate',
    },
    {
      Header: 'Other Levies&Taxes',
      accessor: 'other_levies_taxes',
    },
    {
      Header: 'Fuel Surcharge',
      accessor: 'fuel_surcharge',
    },
    {
      Header: 'VAT',
      accessor: 'vat',
    },
    {
      Header: 'Variable Charges',
      accessor: 'other_variable_charges',
    },
    {
      Header: 'Fixed Charges',
      accessor: 'fixed_charges',
    },
    {
      Header: 'Monthly Bill',
      accessor: 'charge',
    },
    {
      Header: 'Average Unit Cost',
      accessor: 'unitCharge',
    },
  ];

  const createFile = (
    utilityId: any,
    utilityCode: any,
    reportYear: any,
    user_role_id: string,
    tableData: any,
    graphData: any,
  ) => {
    var canvas = document.getElementById('myChart') as HTMLCanvasElement | null;
    var ctx = canvas?.getContext('2d') as CanvasRenderingContext2D | null;

    const reportName = generateReportName(
      utilityCode,
      parseInt(reportYear),
      user_role_id,
    );

    var chartImage = null;
    var pageCnt = 0;
    // const pdf = new jsPDF("p", "px", "a4");
    // const pdf = new jsPDF('l', 'px', 'a6', true);
    const pdf = new jsPDF('l', 'px', [600, 300], true);
    // const pdf = new jsPDF({
    //   orientation: 'landscape',
    //   unit: 'px',
    //   format: [600, 300],
    // });
    const pdfWidth = pdf.internal.pageSize.width;
    const pdfHeight = pdf.internal.pageSize.height;

    graphData
      .sort((a: any, b: any) => {
        const partsA = a.title.substring(0, 6).split('.');
        const partsB = b.title.substring(0, 6).split('.');

        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          const numA = parseInt(partsA[i]) || 0;
          const numB = parseInt(partsB[i]) || 0;

          if (numA !== numB) {
            return numA - numB;
          }
        }

        // If the numbers are equal up to this point, compare the remaining characters
        if (a.title.substring(0, 6) < b.title.substring(0, 6)) return -1;
        if (a.title.substring(0, 6) > b.title.substring(0, 6)) return 1;
        return 0;
      })
      .map((data: any) => {
        console.log(data.title.substring(0, 6));
        if (ctx != null) {
          var myChart = new ChartJS(ctx, {
            type: 'bar',
            data: {
              labels: data.labels,
              datasets: data.datasets,
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              // devicePixelRatio: 1.5,
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      size: 16,
                    },
                  },
                },
                y: {
                  ticks: {
                    font: {
                      size: 16,
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  position: 'top' as const,
                  display: false,
                },
              },
            },
          });

          // Get the chart's base64 image string
          chartImage = myChart.toBase64Image();

          if (chartImage?.length > 0) {
            // const pdf = new jsPDF("l", "in", [612, 792]);

            // for (let i = 0; i < reportTitles.length; i++) {
            if (pageCnt > 0) {
              pdf.addPage();
            }

            pdf.text(data.title, pdfWidth / 2, 20, { align: 'center' });

            //addImage(imageData, format, x, y, width, height, alias, compression, rotation)
            pdf.addImage(
              chartImage,
              'PNG', //format
              50, //x
              50, //y
              500, //width
              200, //height
              // 0.5,
              // 0.25,
              // pdfWidth - 1.8,
              // pdfHeight - 0.5,
            );
            // pdf.addImage(octocatPNG, "PNG", 15, 40, 180, 300);
            pdf.setPage(pageCnt);
            // }
          }
          myChart.destroy();
          pageCnt++;
        }
      });

    pdf.addPage();

    pageCnt = 0;

    tableData
      .sort((a: any, b: any) =>
        a.label.substring(2, 6).trim().split('.').join('').localeCompare(
          b.label.substring(2, 6).trim().split('.').join(''),
          undefined,
          // {
          //   numeric: true,
          // }
        ),
      )
      // .map((tableData: any) => {
      //   console.log(tableData.label);
      // });
      .map((tableData: any) => {
        console.log(
          tableData.label.substring(2, 6),
          tableData.label,
          tableData.title,
        );
        if (pageCnt > 0) {
          pdf.addPage();
        }
        pdf.text(tableData.label, pdfWidth / 2, 20, { align: 'center' });
        autoTable(pdf, {
          styles: { halign: 'center' },
          head: [tableData.columns.map((column: any) => column.Header)],
          body: tableData.data.map((data: any) => {
            const tableColumns: any = [data.utility_code];
            tableData.columns
              .filter((column: any) => column.accessor !== 'utility_code')
              // .map((column: any) => column.Header)
              .map((column: any) => {
                let columnString = '';

                //For each data field
                //if accessor equals key, add value to columnString
                Object.keys(data).map((key: any) => {
                  if (key === column.accessor) {
                    columnString = data[key];
                  }
                });

                tableColumns.push(columnString);
              });
            console.log('table columns', tableColumns);
            return tableColumns;
          }),
        });

        pdf.setPage(pageCnt);
        pageCnt++;
      });

    addFooters(pdf);
    // const utility = utilities?.find(
    //   (utility: any) => utility.utility_id === selectedUtility
    // );
    // pdf.save(
    //   `${utilityCode}-${reportYear}-report-${format(
    //     new Date(),
    //     "yyyyMMddHHmm"
    //   )}.pdf`
    // );
    pdf.save(reportName);
    // setIframeSrc(pdf.output('bloburi'));
    // setTimeout(() => {
    //   myChart.destroy();
    // }, 3000);
  };

  const columnsShareUnderground = (voltagecategories: any) => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];
    if (voltagecategories?.length > 0) {
      voltagecategories?.map((tech: any) => {
        // console.log(tech);
        headerColumns.push({
          Header: tech?.voltage_category,
          accessor: tech?.voltage_category
            .trim()
            .split(' ')
            .join('_')
            .toLowerCase(),
          // width: 100,
          // disableResizing: true,
        });
      });
      // headerColumns.push()
      console.log(voltagecategories);
      return headerColumns;
    }
  };

  console.log(columnsShareUnderground(voltageCategories));

  const columnsCommercialization = (customercategories: any) => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];
    if (customercategories?.length > 0) {
      customercategories?.map((data: any) => {
        // console.log(tech);
        headerColumns.push({
          Header: data.cust_category,
          accessor: data.cust_category_id,
          // width: 100,
          // disableResizing: true,
        });
      });
      // headerColumns.push()
      console.log(customercategories);
      return headerColumns;
    }
  };

  const columnsGeneration = (fuels: any) => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];
    if (fuels?.length > 0) {
      fuels?.map((data: any) => {
        // console.log(tech);
        headerColumns.push({
          Header: data.fuel_name,
          accessor: data.fuel_name.split(' ').join('_').toLowerCase(),
          // width: 100,
          // disableResizing: true,
        });
      });
      // headerColumns.push()
      // console.log(customercategories);
      return headerColumns;
    }
  };

  const columnsDER = (derSupport: any) => {
    let headerColumns: any = [
      {
        Header: 'Utility',
        accessor: 'utility_code',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
    ];
    if (derSupport?.length > 0) {
      derSupport?.map((data: any) => {
        // console.log(tech);
        headerColumns.push({
          Header: data?.support,
          accessor: data?.support.split(' ').join('_').toLowerCase(),
          // accessor: data?.der_supp_id,
          // width: 100,
          // disableResizing: true,
        });
      });
      // headerColumns.push()
      // console.log(customercategories);
      return headerColumns;
    }
  };

  const processReportData = async (data: any) => {
    console.log(data);
    // return;
    const { selectedUtilities, year_start } = data;
    setSelectedUtility(selectedUtilities);
    setSelectedStartYear(year_start);
    // const utility = utilities?.find(
    //   (utility: any) => utility.utility_id === selectedUtility
    // );
    // console.log(utilities, utility?.code);
    // return;

    // return items;

    // let calcResults: any = [];
    // const usageCategory = usageCategories?.find(
    //   (item: any) =>
    //     item.usage_category_id === "6212575a-0137-4bcc-8fd6-aef3a6aaca26"
    // );

    // setGraphData([]);
    // setTableData([]);

    const graphData: any = [];
    const tableData: any = [];

    console.log('# reports', reportTitles?.length);
    for (var i = 0; i <= reportTitles?.length - 1; i++) {
      let results: any = null;
      const selectedReport = reportTitles[i].title;
      const reportLabel = reportTitles[i].label;
      // console.log(reportTitles[i]);
      console.log(selectedReport, reportLabel, year_start);

      const findTitle = (title: any) => {
        if (selectedReport.includes(title)) {
          return selectedReport;
        }
      };

      const selectedRole = userroles
        ?.find((role: any) => role.user_role_id === data.user_role_id)
        .role.toLowerCase();

      const isMultipleUtilitiesSelected = selectedUtilities?.length > 1;
      const isAllUtilitiesSelected =
        selectedUtilities?.length === utilities?.length;

      //the utilities to display in the report
      const utilityOptions = utilities
        .filter((utility: any) =>
          isMultipleUtilitiesSelected && !isAllUtilitiesSelected
            ? selectedUtilities
                .map((id: any) => id.utility_id)
                .includes(utility.utility_id)
            : true,
        )
        .map((utility: any) => ({
          label: utility.code,
          value: utility.utility_id,
        }));

      //utility Ids to use for reference in calculations
      const utilityIds =
        isMultipleUtilitiesSelected && !isAllUtilitiesSelected
          ? [selectedUtilities[0].utility_id]
          : utilities
              .filter((utility: any) =>
                selectedUtilities
                  .map((id: any) => id.utility_id)
                  .includes(utility.utility_id),
              )
              .map((utility: any) => utility.utility_id);

      if (reportTitles[i].type === 'graph') {
        //If the report is a graph, calculate the data  and set the graph data
        switch (selectedReport) {
          case 'service-coverage':
            results = calculateServiceCoverage(
              year_start,
              year_start,
              utilityOptions, //all utilities
              utilityGeneralData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds, //selected utilities
              utilities,
            );
            break;
          case 'average-energy-cost':
            results = calculateAvgEnergyCost(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'energy-system-losses': //report120
            results = calculateEnergySystemLosses(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'operational-profit-margin': //report115
            results = calculateOperationalProfitMargin(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'labour-productivity': //report125
            results = calculateLabourProductivity(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              utilityGeneralData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'saidi': //report130
            results = calculateSAIDI(
              year_start,
              year_start,
              utilityOptions,
              restorationPeriodData,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'saifi': //report135
            results = calculateSAIFI(
              year_start,
              year_start,
              utilityOptions,
              restorationPeriodData,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'energy-delivered-by-res': //report140
            results = calculateEnergyByRES(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'co2-equivalent-emissions': //report140
            results = calculateCO2EquivEmissions(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              utilityTechnicalData,
              utilityFinancialData,
              technologies,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'injury-severity-rate': //report140
            results = calculateInjuryRate(
              year_start,
              year_start,
              utilityOptions,
              utilityGeneralData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;

          case findTitle('monthly-customer-bills'):
            const titleSegments = selectedReport.split('_');
            const usage_category_id = titleSegments[titleSegments.length - 2];
            const usage_category_kwh = titleSegments[titleSegments.length - 1];
            console.log(usage_category_id, usage_category_kwh);

            results = calculateMonthlyCustomer(
              year_start,
              year_start,
              utilityOptions,
              usage_category_id,
              usage_category_kwh, //"500", //usage category kwh
              usageCategoryData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;

          case 'ebitda-margin': //
            results = calculateEBITDA(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'return-on-assets': //
            results = calculateReturnOnAssets(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'return-on-equity': //
            results = calculateReturnOnEquity(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'debt-level':
            results = calculateDebtLevel(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'operating-ratio':
            results = calculateOperatingRatio(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'system-load-factor': //
            results = calculateSystemLoadFactor(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'generation-reserve-margin':
            results = calculateReserveMargin(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'system-equiv-availability':
            results = calculateSystemEquiv(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'plant-energy-consumption':
            results = calculateGenerationEfficiency(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'generation-o&m-cost':
            results = calculateGenerationOM(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'lube-oil-consumption':
            results = calculateLubeOilConsumption(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'average-generation-cost':
            results = calculateAverageGenerationCost(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;

          case 'generation-productivity':
            results = calculateGenerationProductivity(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilityGeneralData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'fossil-energy-from-ipps': //
            results = calculateFossilFuelByIPPs(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'renewable-energy-by-ipps': //
            results = calculateRenewableEnergyByIPPs(
              data.year_start,
              data.year_end,
              utilityOptions,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 't&d-cost': //
            results = calculateTDCost(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 't&d-productivity': //
            results = calculateTDProductivity(
              year_start,
              year_start,
              utilityOptions,
              utilityGeneralData,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;

          case 'caidi':
            results = calculateCAIDI(
              year_start,
              year_start,
              utilityOptions,
              restorationPeriodData,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'technical-losses':
            results = calculateNonTechnicalLosses(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'network-disaggregation': //report620
            results = calculateNetworkDisaggregation(
              year_start,
              year_start,
              utilityOptions,
              utilityCustomerData,
              utilityTechnicalData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'number-of-complaints': //
            results = calculateNumberCustomerComplaints(
              year_start,
              year_start,
              utilityOptions,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'smart-meter-customers': //
            results = calculateSmartMeterCustomers(
              year_start,
              year_start,
              utilityOptions,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'commercialization-cost': //
            results = calculateCommercializationCost(
              year_start,
              year_start,
              utilityOptions,
              utilityCustomerData,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'bad-debt':
            results = calculateBadDebt(
              year_start,
              year_start,
              utilityOptions,
              utilityFinancialData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'commercial-productivity':
            results = calculateCommercialProductivity(
              year_start,
              year_start,
              utilityOptions,
              utilityGeneralData,
              utilityCustomerData,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
              utilities,
            );
            break;
          case 'average-re-cost-per-mwh': //
            results = calculateAvgRECost(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilityFinancialData,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'capacity-factor-solar-pv': //
            results = calculateSolarCapacityFactor(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              technologies,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'capacity-factor-wind': //
            results = calculateWindCapacityFactor(
              year_start,
              year_start,
              utilityOptions,
              technologyData,
              technologies,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'installed-der-capacity': //
            results = calculateDERCapacity(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'environmental-performance': //
            results = calculateEnvironmentalPerformance(
              year_start,
              year_start,
              utilityOptions,
              utilityTechnicalData,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'assess-climate-resilience': //
            results = calculateClimateResilience(
              year_start,
              year_start,
              utilityOptions,
              climateResilienceData,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
          case 'osh-implementation': //
            results = calculateOSHImplementation(
              year_start,
              year_start,
              utilityOptions,
              utilityGeneralData,
              utilities,
              getPermission(selectedReport, data.user_role_id),
              utilityIds,
            );
            break;
        }

        if (results) {
          // calcResults.push(results);
          console.log(selectedUtilities, year_start);
          console.log('graph', selectedReport, results);
          graphData.push({
            title: reportLabel,
            labels: results?.labels,
            //   labels: graphicalData.map((data) => data.year),
            datasets: [
              {
                label: selectedReport,
                data: results?.results,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          });

          setGraphData(graphData);
        }
      } else {
        // 	//If the report is a table, calculate the data and set the table data
        // 	console.log(utility_id, year_start);
        // 	console.log('table', selectedReport, reportLabel, tableRowData);

        switch (selectedReport) {
          case 'cost-by-type':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsCostByType,
              data: calculateCostByType(
                utilityFinancialData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'cost-by-business-area':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsCostByBusinessArea,
              data: calculateCostByBusinessArea(
                utilityFinancialData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case findTitle('disaggregated-tariffs'):
            const titleSegments = selectedReport.split('_');
            const usage_category_id = titleSegments[titleSegments.length - 2];
            const usage_category_kwh = titleSegments[titleSegments.length - 1];
            console.log(usage_category_id, usage_category_kwh);

            const tariffColumns = [
              {
                Header: 'Utility',
                accessor: 'utility_code',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Year',
                accessor: 'data_year',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Base Rate',
                accessor: 'base_rate',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Other Levies&Taxes',
                accessor: 'other_levies_taxes',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Fuel Surcharge',
                accessor: 'fuel_surcharge',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'VAT',
                accessor: 'vat',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Variable Charges',
                accessor: 'other_variable_charges',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Fixed Charges',
                accessor: 'fixed_charges',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Monthly Bill',
                accessor: 'charge',
                width: 100,
                // disableResizing: true,
              },
              {
                Header: 'Average Unit Cost',
                accessor: 'unitCharge',
                width: 100,
                // disableResizing: true,
              },
            ];

            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: tariffColumns,
              data: calculateDisaggregatedTariffs(
                usageCategoryData,
                utilityOptions,
                usage_category_id,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                usageCategories,
                utilityIds,
              ),
            });
            break;
          case 'energy-generation-per-pes':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: tableColumns,
              data: calculateEnergyGenerationPES(
                tableRowData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'generation-change-per-pes':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: tableColumns,
              data: calculateGenerationChangePES(
                tableRowData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'capacity-change-per-pes':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: tableColumns,
              data: calculateCapacityChangePES(
                tableRowData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'installed-capacity':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: tableColumns,
              data: calculateInstalledCapacity(
                tableRowData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'share-of-underground-line':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsShareUnderground(voltageCategories),
              data: calculateShareOfUndergroundLine(
                vcTableColumns,
                utilityOptions,
                data.year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'grid-loss-per-volt-level':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsShareUnderground(voltageCategories),
              data: calculateGridLossPerVolt(
                vcTableColumns,
                utilityOptions,
                data.year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'share-per-customer-group':
            console.log(customerTableData);
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsCommercialization(customerCategories),
              data: calculateSharePerCustomerGroup(
                customerTableData,
                utilityOptions,
                data.year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'fuel-cost':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsGeneration(fuels),
              data: calculateFuelCost(
                fuelTableData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'fuel-efficiency-own-units':
            // console.log(fuelTableData);
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsGeneration(fuels),
              data: calculateEfficiencyFuelUnits(
                fuelTableData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'thermal-generation-cost':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsGeneration(fuels),
              data: calculateThermalGenerationCost(
                fuelTableData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'cap-threshold-for-der':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: derColumns1,
              data: calculateCapThresholdDER(
                utilityTechnicalData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'support-mechanism-for-der':
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: columnsDER(derSupport),
              data: calculateSupportMechanismDER(
                derSupportData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'ee-and-dsm-programs': //
            let headerColumns1: any = [
              {
                Header: 'Utility',
                accessor: 'utility_code',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Year',
                accessor: 'data_year',
                width: 100,
                disableResizing: true,
              },
            ];

            [
              { title: 'Esco Models', key: 'esco_models' },
              {
                title: 'EE Grant/Loan Residential',
                key: 'grant_loan_prog_ee_in_residential',
              },
              {
                title: 'EE Grant/Loan Commercial',
                key: 'grant_loan_prog_ee_in_commercial',
              },
              {
                title: 'EE Grant/Loan Industry',
                key: 'grant_loan_prog_ee_in_industry',
              },
              {
                title: 'EE Grant/Loan Tourism',
                key: 'grant_loan_prog_ee_in_tourism',
              },
              { title: 'Other EE', key: 'other_ee' },
              { title: 'Time-in-use Tariffs', key: 'time_in_use_tariffs' },
              {
                title: 'Microgrids',
                key: 'microgrids_with_flexible_connection',
              },
              {
                title: 'Load provision programme',
                key: 'incentivization_via_storage_capacities',
              },
              { title: 'Other DMS', key: 'other_dms' },
            ].map((item: any) => {
              // console.log(tech);
              headerColumns1.push({
                Header: item.title,
                accessor: item.key,
                // width: 100,
                // disableResizing: true,
              });
            });
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: headerColumns1,
              data: calculateEEDSMProgram(
                utilityFinancialData,
                utilityTechnicalData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'availability-of-funds': //
            let headerColumns2: any = [
              {
                Header: 'Utility',
                accessor: 'utility_code',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Year',
                accessor: 'data_year',
                width: 100,
                disableResizing: true,
              },
            ];

            [
              { title: '% Asset Cover', key: 'pcent_of_asset_cover' },
              {
                title: 'Disaster Fund',
                key: 'disaster_fund',
              },
            ].map((item: any) => {
              // console.log(tech);
              headerColumns2.push({
                Header: item.title,
                accessor: item.key,
                // width: 100,
                // disableResizing: true,
              });
            });
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: headerColumns2,
              data: calculateAvailabilityOfFunds(
                utilityFinancialData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'grid-modernization': //
            let headerColumns3: any = [
              {
                Header: 'Utility',
                accessor: 'utility_code',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Year',
                accessor: 'data_year',
                width: 100,
                disableResizing: true,
              },
            ];

            [
              { title: 'Gen Forecast', key: 'existing_gen_forecast_system' },
              {
                title: 'Power Ctrl',
                key: 'auto_pwr_ctrl_netwk_monitoring',
              },
              {
                title: 'Dyn Tariff',
                key: 'dynamic_electric_cust_tariffs',
              },
              {
                title: 'Demand Response',
                key: 'dispatchable_demand_response',
              },
              {
                title: '# Microgrids',
                key: 'nbr_of_grid_connected_microgrids',
              },
              {
                title: 'Battery Storage',
                key: 'grid_connected_battery_storage',
              },
            ].map((item: any) => {
              // console.log(tech);
              headerColumns3.push({
                Header: item.title,
                accessor: item.key,
                // width: 100,
                // disableResizing: true,
              });
            });
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: headerColumns3,
              data: calculateGridModernization(
                utilityTechnicalData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          case 'gender': //
            let headerColumns4: any = [
              {
                Header: 'Utility',
                accessor: 'utility_code',
                width: 100,
                disableResizing: true,
              },
              {
                Header: 'Year',
                accessor: 'data_year',
                width: 100,
                disableResizing: true,
              },
            ];

            [
              { title: '% Males', key: 'percent_male_staff' },
              { title: '% Females', key: 'percent_female_staff' },
              { title: '% Tech Males', key: 'percent_technical_male_staff' },
              {
                title: '% Tech Females',
                key: 'percent_technical_female_staff',
              },
              { title: '% Senior Males', key: 'percent_male_senior_staff' },
              { title: '% Senior Females', key: 'percent_senior_female_staff' },
            ].map((item: any) => {
              // console.log(tech);
              headerColumns4.push({
                Header: item.title,
                accessor: item.key,
                // width: 100,
                // disableResizing: true,
              });
            });
            tableData.push({
              title: selectedReport,
              label: reportLabel,
              columns: headerColumns4,
              data: calculateGender(
                utilityGeneralData,
                utilityOptions,
                year_start,
                getPermission(selectedReport, data.user_role_id),
                utilityIds,
              ),
            });
            break;
          // 		default:
          // 			tableData.push({
          // 				title: selectedReport,
          // 				label: reportLabel,
          // 				columns: tableColumns,
          // 				data: tableRowData,
          // 			});
        }
        // 	console.log(tableData);
        setTableData(tableData);
      }
    }

    return { tableData, graphData };
  };

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    console.log('regular modal', data);
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleFileModal = (data?: any) => {
    console.log('file modal', data);
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleFileModal(true);
  };

  const handleAddRecord = (data: any) => {
    const {
      datayear,
      selectedUtilities,
      // bm_reportname,
      user_role_id,
      bmr_comments,
    } = data;
    // console.log(selectedUtilities);
    // return;
    const data_utility =
      selectedUtilities.length > 1 ? null : selectedUtilities[0].utility_id;
    const data_utility_code =
      selectedUtilities.length === utilities.length
        ? 'all'
        : selectedUtilities.length > 1
        ? 'multi'
        : selectedUtilities[0].code;
    addBenchmark({
      datayear: parseInt(datayear),
      utility_id: data_utility,
      bm_reportname: generateReportName(
        data_utility_code,
        parseInt(datayear),
        user_role_id,
      ),
      user_role_id,
      bmr_comments,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        console.log(res.data.message);
        // console.log(res.data.message.toLowerCase().includes("duplicate"));
        //If duplicate error then display option to redownload report data
        if (res.data.message.toLowerCase().includes(`duplicate`)) {
          // setDuplicateModalOpen(true);
          // console.log(datayear, utility_id, utility_code);
          handleToggleModal();
          setDuplicateRecord({
            datayear,
            selectedUtilities,
            utility_code: null,
            user_role_id,
          });
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.success('Record created');
        queryClient.invalidateQueries('benchmarks');
        // console.log(datayear, utility_id, utility_code);
        handleDownloadFile({
          datayear,
          selectedUtilities,
          utility_code: null,
          user_role_id,
        });

        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateBenchmark({ ...data, id: record?.country_id }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record updated');
        queryClient.invalidateQueries('benchmarks');
        handleToggleModal();
      }
    });
  };

  const handleRemoveRecord = async (data: any) => {
    const confirmed = await getConfirmation({
      title: 'Are you sure?',
      message: 'Removing this record is an action that cannot be reverted',
    });

    if (confirmed) {
      console.log('remove record');
      // deleteUserMutation.mutate(data);
      removeBenchmark(data.bm_report_id).then((res: any) => {
        console.log(res);
        if (res.error) {
          toast.error(res.data.message);
        } else {
          toast.success('Record removed');
          queryClient.invalidateQueries('benchmarks');
          // handleToggleModal();
        }
      });
    }
  };

  const handleDuplicateRecord = (data: any) => {
    const { datayear, utility_id } = data;
    // handleDownloadFile({ datayear, utility_id });
  };

  const handleUploadFile = (data: any) => {
    //TODO: Upload file to S3
    console.log(data.file_attachment);
    const fData = new FormData();
    fData.append('file_attachment', data.file_attachment[0]);
    uploadFile(fData, data.id).then((results: any) => {
      console.log(results);
      if (results.status === 200) {
        toast.success('Successfully uploaded file');
      } else {
        toast.error(results.data.message);
      }
      toggleFileModal(!isFileModalOpen);
    });
  };

  const handleDownloadFile = (data: any) => {
    const { datayear, selectedUtilities, utility_code, user_role_id } = data;
    console.log(datayear, selectedUtilities, user_role_id);
    const utility_identifier =
      selectedUtilities.length === utilities.length
        ? 'all'
        : selectedUtilities.length > 1
        ? 'multi'
        : selectedUtilities[0].code;
    console.log('download file params', datayear, utility_identifier);
    // const utility = utilities?.find(
    //   (utility: any) => utility.utility_id === utility_id
    // );
    // console.log(utility.code);
    // console.log(reportTitles);
    // return;
    //Display loading modal
    setProcessing(true);
    setTimeout(() => {
      console.log(reportTitles);
      //Process data for generating reports
      processReportData({
        year_start: datayear,
        selectedUtilities,
        user_role_id,
      }).then((results: any) => {
        if (!results) return;

        const { graphData, tableData } = results;
        // console.log(graphData, tableData);
        // console.log(graphData.length);
        if (graphData?.length > 0) {
          //Generate PDF file
          createFile(
            utility_identifier,
            utility_identifier,
            datayear,
            user_role_id,
            tableData,
            graphData,
          );
          setProcessing(false);
        } else {
          alert('There was no data to generate report');
        }
        // setProcessing(false);
      });
    }, 1000); //wait for the data to be processed before generating reports again))
    setDuplicateRecord(null);
  };

  let derColumns1: any = [
    {
      Header: 'Utility',
      accessor: 'utility_code',
      width: 100,
      disableResizing: true,
    },
    {
      Header: 'Year',
      accessor: 'data_year',
      width: 100,
      disableResizing: true,
    },
    {
      Header: 'Residential Band',
      accessor: 'capacity_threshold_for_residential_re',
    },
    {
      Header: 'Commercial/Industrial Band',
      accessor: 'capacity_threshold_for_c_i_re',
    },
  ];

  const columns = [
    {
      Header: 'Year',
      accessor: 'datayear',
      width: 70,
      disableResizing: true,
    },
    {
      Header: 'Utility',
      accessor: 'utility_code',
      width: 70,
      disableResizing: true,
      Cell: ({ cell: { value } }: any) => {
        // console.log(value);
        return value || 'All';
      },
    },
    {
      Header: 'Filename',
      accessor: 'bm_reportname',
    },
    // {
    //   Header: "Status",
    //   accessor: "bmr_status",
    //   width: 70,
    //   disableResizing: true,
    // },
    {
      Header: 'Role',
      accessor: 'user_role',
      // width: 100,
      // disableResizing: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      width: 150,
      disableResizing: true,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ cell: { row } }: any) => {
        return (
          <TableActions
            onEdit={() => handleToggleModal(row?.original)}
            onUpload={() => handleFileModal(row?.original)}
            onDelete={() => handleRemoveRecord(row?.original)}
            // onDownload={() => {
            //   const { datayear, utility_id, utility_code } = row.original;
            //   // processReportData({ year_start: datayear, utility_id });
            //   handleDownloadFile({ datayear, utility_id, utility_code });
            // }}
          />
        );
      },
    },
  ];
  console.log(benchmarks);

  if (loadingBenchmarks) return <div>Loading benchmarks...</div>;
  if (loadingKPIs && reportTitles === null)
    return <div>Loading list of kpis reports...</div>;
  if (
    loadingRestorationPeriodData ||
    loadingTechnologies ||
    loadingTechnologyData ||
    loadingUsageCategories ||
    loadingUsageCategoryData ||
    loadingUtilityCustomerData ||
    loadingUtilityGeneralData ||
    loadingUtilityTechnicalData ||
    loadingUtilityFinancialData
  )
    return <div>Loading kpi report data...</div>;

  if (error || errorKPIs) return <div>An error occurred...</div>;

  console.log(utilities);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Benchmarks" onClick={toggleModal} />
      {/* <div>Reports: {reportTitles?.length}</div> */}
      <Table columns={columns} data={benchmarks} />
      <iframe src={iframeSrc} title="report" height="600" width="100%"></iframe>
      {/* Placeholder canvas used for generating kpi reports */}
      <div style={{ position: 'relative', height: '50vh', width: '70vw' }}>
        <canvas id="myChart"></canvas>
      </div>

      <div id="print-area"></div>

      {/* {graphData.map((chart: any) => (
        <BarChart data={chart} />
      ))} */}

      {/* {tableData.map((table: any) => (
				<Table columns={table.headers} data={table.data} />
			))} */}

      {isModalOpen && utilities.length > 0 && (
        <Modal
          modalTitle={`${record ? 'Update' : 'Add'} Benchmark`}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <BenchmarkForm
            initialValues={record}
            utilities={utilities?.sort((a: any, b: any) =>
              a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                ? 1
                : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                ? -1
                : 0,
            )}
            roles={userroles}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}

      <Modal
        modalTitle={`Processing Report`}
        isOpen={isProcessing}
        closeModal={() => setProcessing(false)}
        small={true}
      >
        <div className="flex items-center">
          <span className="text-3xl mr-4">Loading</span>

          <svg
            className="animate-spin h-5 w-5 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </Modal>

      <Modal
        modalTitle={`Upload File`}
        isOpen={isFileModalOpen}
        closeModal={() => toggleFileModal(false)}
        small={true}
      >
        <FileUploadForm initialValues={record} onAdd={handleUploadFile} />
      </Modal>

      {duplicateRecord && (
        <Modal
          modalTitle={'Notice'}
          isOpen={!!duplicateRecord}
          closeModal={() => setDuplicateRecord(null)}
        >
          <div className="grid place-items-center text-center">
            <h2 className="text-xl">
              The Benchmark report for{' '}
              <span> {duplicateRecord.utility_code}</span> for the year{' '}
              <span>{duplicateRecord.datayear}</span> already exists
            </h2>
            <p className="text-gray-500">
              Would you like to re-generate the Benchmark report?
            </p>
            <button
              className="flex items-center gap-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white my-3 rounded-md"
              onClick={() => handleDownloadFile({ ...duplicateRecord })}
            >
              <HiOutlineDownload className="w-6 h-6 " /> Re-generate
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default ManageBenchmarks;
