import React from "react";
import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Loading from "../../components/Loading";
import ErrorPage from "../../components/ErrorPage";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import DataRegistryForm from "../../components/forms/DataRegistryForm";
import { findUtilities } from "services/utilities.service";
import { findUsers } from "services/users.service";
import { format, isValid } from "date-fns";
import {
  addDataRegistry,
  getDataRegistry,
  updateDataRegistry,
} from "services/dataregister.service";
import toast from "react-hot-toast";
import PageTitle from "containers/PageTitle";
import useAuth from "hooks/useAuth";

const formatDate = (value: any) => {
  if (!value) return;
  return isValid(new Date(value)) && format(new Date(value), "PP");
};

const DataRegistry = () => {
  let { currentUser } = useAuth();
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const [filter, setFilter] = React.useState("");

  const minYear = 1980;
  const maxYear = new Date().getFullYear() - 1;

  const fillRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const yearFilters = fillRange(maxYear, minYear, -1);

  const queryClient = useQueryClient();

  const {
    // isLoading,
    // error,
    data: utilities,
  } = useQuery(["utilities", currentUser.user_role], () =>
    findUtilities().then((res) => {
      const records = res.data;
      if (currentUser.user_role.toLowerCase() === "carilec key user") {
        return records;
      } else {
        return records.filter(
          (record: any) => record.utility_id === currentUser.utility_id
        );
      }
    })
  );

  const { data: users } = useQuery("users", () =>
    findUsers().then((res) => res.data)
  );

  const { isLoading, error, data } = useQuery("dataregistry", () =>
    getDataRegistry().then((res) => res.data)
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Year",
        accessor: "bmdatayear",
        width: 100,
        disableResizing: true,
      },
      {
        Header: "Utility",
        accessor: "utility_name",
      },
      {
        Header: "General Date",
        accessor: "general_load_date",
        Cell: ({ cell: { value } }: any) => {
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        Header: "Customer Date",
        accessor: "customer_load_date",
        Cell: ({ cell: { value } }: any) => {
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        Header: "Financial Date",
        accessor: "financial_load_date",
        Cell: ({ cell: { value } }: any) => {
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        Header: "Technical Date",
        accessor: "technical_load_date",
        Cell: ({ cell: { value } }: any) => {
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        Header: "Extra Date",
        accessor: "extra_load_date",
        Cell: ({ cell: { value } }: any) => {
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    []
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addDataRegistry(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record created");
        queryClient.invalidateQueries("dataregistry");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    const {
      id,
      financial_user_name,
      general_user_name,
      customer_user_name,
      technical_user_name,
      user_data_register_extra_load_byTouser,
      ...rest
    } = data;
    updateDataRegistry({
      ...rest,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated");
        queryClient.invalidateQueries("dataregistry");
        handleToggleModal();
      }
    });
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="flex h-full">
      <div className="flex-1 bg-grey-50">
        <PageTitle
          title="Data Registry"
          // onSendEmail={() => console.log("send email")}
          onClick={toggleModal}
        />
        {currentUser.user_role?.toLowerCase() === "carilec key user" && (
          <div className="flex space-x-6 p-3">
            <label className="text-gray-400 font-semibold text-sm">
              Choose Utility
              <select
                className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
                defaultValue=""
                // placeholder="Select a Utility"
                onChange={(evt: any) => setFilter(evt.target.value)}
              >
                <option value="">--Select One--</option>
                {utilities
                  ?.sort((a: any, b: any) =>
                    a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                      ? 1
                      : b.utility_name.toLowerCase() >
                        a.utility_name.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((option: any) => (
                    <option key={option.utility_id} value={option.utility_id}>
                      {option.utility_name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
        )}

        {!isLoading && data && (
          <Table
            columns={columns}
            data={
              currentUser.user_role?.toLowerCase() !== "carilec key user"
                ? data.filter(
                    (utility: any) =>
                      utility.utility_id === currentUser.utility_id
                  )
                : filter?.length > 0
                ? data.filter((utility: any) => utility.utility_id === filter)
                : data
            }
          />
        )}
        {isModalOpen && (
          <Modal
            modalTitle={`${record ? "Update" : "Add"} Data Registry`}
            isOpen={isModalOpen}
            closeModal={() => handleToggleModal()}
          >
            <DataRegistryForm
              users={users}
              utilities={utilities}
              initialValues={record}
              onEdit={handleEditRecord}
              onAdd={handleAddRecord}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default DataRegistry;
