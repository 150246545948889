import React from 'react';
// import Icon from "components/Icon";
import {
	HiOutlineDownload,
	HiOutlinePencil,
	HiOutlineTrash,
	HiOutlineUpload,
} from 'react-icons/hi';
import { FiMoreHorizontal } from 'react-icons/fi';

const TableActions = ({
	onEdit,
	onDelete,
	onUpload,
	onDownload,
	onView,
	linkExtras,
}: any) => {
	return (
		<div className="flex justify-center space-x-1">
			{onEdit && (
				<button
					className="text-indigo-500 border-none rounded-md p-1 hover:bg-indigo-100"
					onClick={onEdit}
					data-testid="actions-edit"
				>
					<HiOutlinePencil className="w-6 h-6 text-blue-500 " />
					{/* <span className="hidden">Edit</span> */}
				</button>
			)}
			{onUpload && (
				<button
					className="text-blue-500 border-none rounded-md p-1 hover:bg-blue-100 "
					onClick={onUpload}
					data-testid="actions-upload"
				>
					<HiOutlineUpload className="w-6 h-6 text-blue-500 " />
					{/* <span className="hidden">Delete</span> */}
				</button>
			)}
			{onDownload && (
				<button
					className="text-blue-500 border-none  rounded-md p-1 hover:bg-blue-100 "
					onClick={onDownload}
					data-testid="actions-download"
				>
					<HiOutlineDownload className="w-6 h-6 text-blue-500 " />
					{/* <span className="hidden">Delete</span> */}
				</button>
			)}
			{onView && (
				<button
					className="bg-white border-none  text-indigo-400 hover:bg-indigo-100 rounded-md p-1"
					onClick={onView}
					data-testid="actions-view-more"
					{...linkExtras}
				>
					<FiMoreHorizontal className="w-6 h-6 text-blue-500 " />
				</button>
			)}
			{onDelete && (
				<button
					className="text-red-500 border-none  rounded-md p-1 hover:bg-red-100 "
					onClick={onDelete}
					data-testid="actions-delete"
				>
					<HiOutlineTrash className="w-6 h-6 text-red-500 " />
					{/* <span className="hidden">Delete</span> */}
				</button>
			)}
		</div>
	);
};

export default TableActions;
