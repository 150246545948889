import axios from "../config/apiClient";

export const findClimateScore = async () => {
  const response = await axios.get("/climatescore");

  return response;
};

export const addClimateScore = async (data: any) => {
  const response = await axios.post("/climatescore", data);

  return response;
};

export const updateClimateScore = async (data: any) => {
  const response = await axios.put("/climatescore", data);

  return response;
};

export const removeClimateScore = async () => {
  const response = await axios.delete("/climatescore");

  return response;
};
