import axios from "../config/apiClient";

export const getDataRegistry = async () => {
  const response = await axios.get("/dataregistry");

  return response;
};

export const addDataRegistry = async (data: any) => {
  const response = await axios.post("/dataregistry", data);

  return response;
};

export const updateDataRegistry = async (data: any) => {
  const response = await axios.put("/dataregistry", data);

  return response;
};

export const removeDataRegistry = async () => {
  const response = await axios.delete("/dataregistry");

  return response;
};
