import axios from "../config/apiClient";

export const getUtilityCustomerData = async () => {
  const response = await axios.get("/utilitycustomerdata");

  return response;
};

export const findUtilityCustomerData = async (data_id: any) => {
  const response = await axios.get(`/utilitycustomerdata/${data_id}`);

  return response;
};

export const addUtilityCustomerData = async (data: any) => {
  const response = await axios.post("/utilitycustomerdata", data);

  return response;
};

export const updateUtilityCustomerData = async (data: any) => {
  const response = await axios.put("/utilitycustomerdata", data);

  return response;
};

export const removeUtilityCustomerData = async () => {
  const response = await axios.delete("/utilitycustomerdata");

  return response;
};
