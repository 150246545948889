import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { fillRange } from "../../utils";

type Inputs = {
  utility_id: string;
  datayear: string;
  cust_category_id: string;
  customers: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  customercategories?: any;
  onEdit: any;
  onAdd: any;
};

export default function CustomerCategoryDataForm(props: IFormProps) {
  // console.log(props);

  const { utilities, customercategories } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required("Field is required"),
    datayear: yup.string().required("Field is required"),
    cust_category_id: yup.string().required("Field is required"),
    customers: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      // .max(12)
      .required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(errors, data);
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues?.cust_categ_dataid) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-2 gap-2">
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("utility_id")}
            placeholder="Select a Utility"
            disabled
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("datayear")}
            placeholder="Select a Year"
            disabled
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="datayear"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">
          Customer Category
        </span>
        <select
          // className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          {...register("cust_category_id")}
          placeholder="Select a Customer Category"
        >
          <option value="">--Select One--</option>
          {customercategories?.map((option: any) => (
            <option
              key={option.cust_category_id}
              value={option.cust_category_id}
            >
              {option.cust_category}
            </option>
          ))}
        </select>
        <ErrorMessage
          errors={errors}
          name="cust_category_id"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Customers</span>
        <input
          type="number"
          {...register("customers")}
          placeholder="Enter number here"
          // placeholder="12345"
          // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />{" "}
        <ErrorMessage
          errors={errors}
          name="customers"
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
