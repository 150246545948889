import { Routes, Route, Link, Navigate } from "react-router-dom";
import PageWrapper from "./containers/PageWrapper";
import AuthWrapper from "./containers/AuthWrapper";

import {
  LoginPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  AcceptInvite as AcceptInvitePage,
  RegisterPage,
  Dashboard,
  // Reports,
  Profile,
  Users,
  UserRoles,
  Countries,
  Utilities,
  Technologies,
  Fuels,
  CustomerCategories,
  UsageCategories,
  VoltageCategories,
  // RestorationCategories,
  KPIs,
  KPICategories,
  KPIPermissions,
  DERMechanisms,
  ClimateMechanisms,
  DataRegistry,
  UtilityCustomerData,
  UtilityFinancialData,
  UtilityCustomerDetails,
  UtilityGeneralData,
  UtilityGeneralDetails,
  UtilityExtraData,
  UtilityTechnicalData,
  UtilityTechnicalDetails,
} from "./pages";
// import DashboardDetails from "./pages/DashboardDetails";
import PrivateRoute from "./guards/PrivateRoute";

import Benchmarks from "pages/annualreports/Benchmarks";
// import BenchmarkReports from "pages/annualreports/BenchmarkReports";
import AccessBenchmarks from "pages/annualreports/AccessingReports";
import RawData from "pages/annualreports/RawData";

import MajorCompanyKPIs from "pages/reports/majorkpis";
import CustomerServiceKPIs from "pages/reports/customerservicekpis";
import FinancialKPIs from "pages/reports/financialkpis";
import GenerationKPIs from "pages/reports/generationkpis";
import IPPKPIs from "pages/reports/ippkpis";
import TDKPIs from "pages/reports/tdkpis";
import CommercializationKPIs from "pages/reports/commercializationkpis";
import SustainabilityKPIs from "pages/reports/sustainabilitykpis";

const BlankPage = () => (
  <div className="p-3">This page is in-progress. Coming soon....</div>
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="changepassword/*" element={<ChangePasswordPage />} />
        <Route path="acceptinvite/*" element={<AcceptInvitePage />} />
      </Route>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <PageWrapper />
          </PrivateRoute>
        }
      >
        {/* Add default route */}
        <Route path="profile" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        {/* <Route path="details" element={<DashboardDetails />} /> */}
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        {/* <Route path="reports/report105" element={<Report105 />} />
        <Route path="reports/report110" element={<Report110 />} />
        <Route path="reports/report115" element={<Report115 />} />
        <Route path="reports/report120" element={<Report120 />} />
        <Route path="reports/report125" element={<Report125 />} />
        <Route path="reports/report130" element={<Report130 />} />
        <Route path="reports/report135" element={<Report135 />} />
        <Route path="reports/report140" element={<Report140 />} />
        <Route path="reports/report145" element={<Report145 />} />
        <Route path="reports/report150" element={<Report150 />} />
        <Route path="reports/report155" element={<Report155 />} />
        <Route path="reports/report160" element={<Report160 />} />
        <Route path="reports/report165" element={<Report165 />} />
        <Route path="reports/report170" element={<Report170 />} /> */}
        <Route path="kpi/financial/:title" element={<FinancialKPIs />} />
        <Route path="kpi/generation/:title" element={<GenerationKPIs />} />
        <Route path="kpi/ipp/:title" element={<IPPKPIs />} />
        <Route
          path="kpi/commercialization/:title"
          element={<CommercializationKPIs />}
        />
        <Route path="kpi/td/:title" element={<TDKPIs />} />
        <Route
          path="kpi/sustainability/:title"
          element={<SustainabilityKPIs />}
        />
        <Route path="kpi/major/:title" element={<MajorCompanyKPIs />} />
        <Route
          path="kpi/customerservice/:title"
          element={<CustomerServiceKPIs />}
        />
        <Route
          path="kpi/major/"
          element={
            <Navigate to="/dashboard/kpi/major/service-coverage" replace />
          }
        />
        <Route
          path="kpi/customerservice/"
          element={
            <Navigate
              to="/dashboard/kpi/customerservice/monthly-customer-bills"
              replace
            />
          }
        />
        <Route
          path="kpi/financial/"
          element={
            <Navigate to="/dashboard/kpi/financial/ebitda-margin" replace />
          }
        />
        <Route
          path="kpi/generation/"
          element={
            <Navigate
              to="/dashboard/kpi/generation/system-load-factor"
              replace
            />
          }
        />{" "}
        <Route
          path="kpi/ipp/"
          element={
            <Navigate to="/dashboard/kpi/ipp/fossil-energy-from-ipps" replace />
          }
        />
        <Route
          path="kpi/td/"
          element={<Navigate to="/dashboard/kpi/td/t&d-cost" replace />}
        />
        <Route
          path="kpi/commercialization/"
          element={
            <Navigate
              to="/dashboard/kpi/commercialization/number-of-complaints"
              replace
            />
          }
        />
        <Route
          path="kpi/sustainability/"
          element={
            <Navigate
              to="/dashboard/kpi/sustainability/average-re-cost-per-mwh"
              replace
            />
          }
        />
        {/* <Route path="kpi/customerservice" element={<Report />} /> */}
        {/* <Route path="kpis/financial" element={<BlankPage />} />
        <Route path="kpis/generation" element={<BlankPage />} />
        <Route path="kpis/ipp" element={<BlankPage />} />
        <Route path="kpis/transmissiondistribution" element={<BlankPage />} />
        <Route path="kpis/commercialization" element={<BlankPage />} />
        <Route path="kpis/sustainabilityresilience" element={<BlankPage />} /> */}
        {/* <Route path=":id" element={<DashboardDetails />} /> */}
        <Route path="admin/users" element={<Users />} />
        <Route path="admin/roles" element={<UserRoles />} />
        <Route path="configuration/countries" element={<Countries />} />
        <Route path="configuration/utilities" element={<Utilities />} />
        <Route path="configuration/technologies" element={<Technologies />} />
        <Route path="configuration/fuels" element={<Fuels />} />
        <Route
          path="configuration/customers"
          element={<CustomerCategories />}
        />
        <Route path="configuration/usage" element={<UsageCategories />} />
        <Route path="configuration/voltages" element={<VoltageCategories />} />
        {/* <Route
          path="configuration/restoration"
          element={<RestorationCategories />}
        /> */}
        <Route path="configuration/kpis" element={<KPIs />} />
        <Route path="configuration/kpicategories" element={<KPICategories />} />
        <Route
          path="configuration/kpipermissions"
          element={<KPIPermissions />}
        />
        <Route path="configuration/dersupport" element={<DERMechanisms />} />
        <Route path="configuration/climate" element={<ClimateMechanisms />} />
        {/* <Route path="kpi" element={<UtilityCustomerData />} /> */}
        <Route path="mgmt/registry" element={<DataRegistry />} />
        <Route path="mgmt/customer" element={<UtilityCustomerData />} />
        <Route
          path="mgmt/customer/:dataId"
          element={<UtilityCustomerDetails />}
        />
        <Route path="mgmt/financial" element={<UtilityFinancialData />} />
        <Route path="mgmt/general" element={<UtilityGeneralData />} />
        <Route
          path="mgmt/general/:dataId"
          element={<UtilityGeneralDetails />}
        />
        <Route path="mgmt/technical" element={<UtilityTechnicalData />} />
        <Route
          path="mgmt/technical/:dataId"
          element={<UtilityTechnicalDetails />}
        />
        <Route path="mgmt/extra" element={<UtilityExtraData />} />
        {/* <Route path="reports" element={<Reports />} /> */}
        <Route path="annualreports/benchmarks" element={<Benchmarks />} />
        {/* <Route path="annualreports/runreports" element={<BenchmarkReports />} /> */}
        {/* <Route path="annualreports/manage" element={<ManageBenchmarks />} /> */}
        <Route path="annualreports/accessing" element={<AccessBenchmarks />} />
        <Route path="annualreports/raw" element={<RawData />} />
        {/* <Route path="reports/:id" element={<Reports />} /> */}
        <Route path="*" element={<BlankPage />} />
      </Route>
      <Route
        path="/inactivity"
        element={
          <div className="p-3 min-h-screen h-full grid place-items-center">
            <div className="text-center">
              <p className="my-6">You have been logged out</p>
              <Link
                to="/login"
                className="px-4 py-2 bg-blue-200 hover:bg-blue-400 text-gray-600 hover:text-blue-800 rounded-lg my-3"
              >
                Return to Login
              </Link>
            </div>
          </div>
        }
      />
      <Route
        path="*"
        element={
          <div className="p-3 min-h-screen h-full grid place-items-center">
            Page Not Found
          </div>
        }
      />
    </Routes>
  );
}

export default App;
