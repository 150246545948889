import axios from "../config/apiClient";

export const acceptInvite = async ({ user_name, password }: any) => {
  const response = await axios.post<any>("/auth/acceptinvite", {
    user_name,
    user_password: password,
  });
  return response.data;
};

export const changePassword = async ({ user_name, password }: any) => {
  const response = await axios.post<any>("/auth/changepassword", {
    user_name,
    password,
  });
  return response.data;
};

export const forgotPassword = async ({ username }: any) => {
  const response = await axios.post<any>("/auth/forgotpassword", {
    user_name: username,
  });
  return response.data;
};
