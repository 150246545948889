import React from 'react';
import faker from '@faker-js/faker';
import Table from '../../components/Table';
import TableActions from 'components/TableActions';

import Loading from '../../components/Loading';
import ErrorPage from '../../components/ErrorPage';
import Modal from '../../components/Modal';
import { useQuery, useQueryClient } from 'react-query';
import UtilityFinancialDataForm from '../../components/forms/UtilityFinancialDataForm';
import { findUtilities } from 'services/utilities.service';
import {
  addFinancialData,
  getFinancialData,
  updateFinancialData,
} from 'services/utilityfinancialdata.service';
import { findUsers } from 'services/users.service';
import { sendMessage } from 'services/messages.service';
import toast from 'react-hot-toast';
import PageTitle from 'containers/PageTitle';
import useAuth from 'hooks/useAuth';
import useConfirmationDialog from 'hooks/useConfirm';
import EmailForm from 'components/forms/EmailForm';

const UtilityCustomerData = () => {
  let { currentUser } = useAuth();
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [showEmailModal, toggleEmailModal] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  const [filter, setFilter] = React.useState('');
  const { getConfirmation } = useConfirmationDialog();
  // const minYear = 1980;
  // const maxYear = new Date().getFullYear() - 1;

  // const fillRange = (start: number, stop: number, step: number) =>
  //   Array.from(
  //     { length: (stop - start) / step + 1 },
  //     (_, i) => start + i * step
  //   );

  // const yearFilters = fillRange(maxYear, minYear, -1);

  const queryClient = useQueryClient();

  const {
    // isLoading,
    // error,
    data: utilities,
  } = useQuery(['utilities', currentUser.user_role], () =>
    findUtilities().then(res => {
      const records = res.data;
      if (currentUser.user_role.toLowerCase() === 'carilec key user') {
        return records;
      } else {
        return records.filter(
          (record: any) => record.utility_id === currentUser.utility_id,
        );
      }
    }),
  );
  const {
    // isLoading,
    error: errorLoadingManagers,
    data: managers,
  } = useQuery('managers', () =>
    findUsers().then(res =>
      res.data.filter(
        (user: any) =>
          user.utility_id === currentUser.utility_id &&
          user.user_role.toLowerCase().includes('manage'),
      ),
    ),
  );
  const {
    isLoading,
    error,
    data: utilityFinancialData,
  } = useQuery(
    'utilityfinancialdata',
    () => getFinancialData().then(res => res.data),
    { refetchOnMount: true, refetchOnWindowFocus: true, staleTime: 0 },
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Year',
        accessor: 'data_year',
        width: 100,
        disableResizing: true,
      },
      {
        Header: 'Utility',
        accessor: 'utility_name',
      },
      {
        Header: 'Annual Utility Costs',
        accessor: 'annual_utility_costs',
      },
      {
        Header: 'Before Tax Net Income',
        accessor: 'before_tax_net_income',
      },
      {
        Header: 'Operational Revenue',
        accessor: 'operational_revenue',
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    [],
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addFinancialData(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record created');
        queryClient.invalidateQueries('utilityfinancialdata');
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    updateFinancialData({
      ...data,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Record updated');
        queryClient.invalidateQueries('utilityfinancialdata');
        handleToggleModal();
      }
    });
  };

  const handleSendNotification = (data: any) => {
    sendMessage({
      ...data,
      recipient_emails: managers.map((manager: any) => manager.email),
      category: 'customer',
      sender_id: currentUser?.user_id,
    }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success('Email notification sent');
        toggleEmailModal(!showEmailModal);
      }
    });
  };

  if (isLoading) return <Loading />;

  if (error || errorLoadingManagers) return <ErrorPage />;

  return (
    <div className="flex h-full">
      <div className="flex-1 bg-grey-50">
        <PageTitle
          title="Utlity Financial Data"
          onSendEmail={() => {
            if (!currentUser?.utility_id) {
              getConfirmation({
                title: 'Cannot send notification',
                message:
                  'Sorry but sending notifications is not allowed for your account because you are not assigned to a utilty. Please contact your system administrator.',
              }).then((res: any) => {
                console.log('dismiss modal');
              });
            } else toggleEmailModal(!showEmailModal);
          }}
          onClick={toggleModal}
        />
        {currentUser.user_role?.toLowerCase() === 'carilec key user' && (
          <div className="flex space-x-6 p-3">
            <label className="text-gray-400 font-semibold text-sm">
              Choose Utility
              <select
                className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
                defaultValue=""
                // placeholder="Select a Utility"
                onChange={(evt: any) => setFilter(evt.target.value)}
              >
                <option value="">--Select One--</option>
                {utilities
                  ?.sort((a: any, b: any) =>
                    a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                      ? 1
                      : b.utility_name.toLowerCase() >
                        a.utility_name.toLowerCase()
                      ? -1
                      : 0,
                  )
                  .map((option: any) => (
                    <option key={option.utility_id} value={option.utility_id}>
                      {option.utility_name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
        )}

        {utilityFinancialData && (
          <Table
            columns={columns}
            data={
              currentUser.user_role?.toLowerCase() !== 'carilec key user'
                ? utilityFinancialData.filter(
                    (utility: any) =>
                      utility.utility_id === currentUser.utility_id,
                  )
                : filter?.length > 0
                ? utilityFinancialData.filter(
                    (utility: any) => utility.utility_id === filter,
                  )
                : utilityFinancialData
            }
          />
        )}
        {isModalOpen && (
          <Modal
            modalTitle={`${record ? 'Update' : 'Add'} Utility Financial Data`}
            isOpen={isModalOpen}
            closeModal={() => handleToggleModal()}
          >
            <UtilityFinancialDataForm
              utilities={utilities}
              initialValues={record}
              onEdit={handleEditRecord}
              onAdd={handleAddRecord}
            />
          </Modal>
        )}
        {showEmailModal && (
          <Modal
            modalTitle={`Send Notification`}
            isOpen={showEmailModal}
            closeModal={() => toggleEmailModal(!showEmailModal)}
          >
            <EmailForm
              onSend={handleSendNotification}
              emails={managers.map((manager: any) => manager.email)}
              categoryName="financial"
              onClose={() => toggleEmailModal(!showEmailModal)}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default UtilityCustomerData;
