import React from 'react';

import {
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useTable,
  useFilters,
  useSortBy,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    // return Array.from(options.values());
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export default function Table({ columns, data }: any) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20, pageIndex: 0 },
      filterTypes,
    },
    useFilters, // useFilters!
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    usePagination,
  );
  // Render the UI for your table
  return (
    <div className="p-4">
      {pageOptions?.length > 1 && (
        <div className="flex items-center gap-10 justify-center mb-2">
          {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <HiChevronDoubleLeft className="w-6 h-6 text-gray-400 border border-gray-100 rounded shadow" />
        </button>{" "} */}

          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex items-center gap-4 text-gray-400 p-2 hover:bg-gray-200 rounded"
          >
            <HiArrowLeft className="w-4 h-4 " />
            Prev
          </button>

          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex items-center gap-4 text-gray-400 p-2 hover:bg-gray-200 rounded"
          >
            Next <HiArrowRight className="w-4 h-4 " />
          </button>

          {/* <button
          onClick={() => gotoPage(pageCount! - 1)}
          disabled={!canNextPage}
        >
          <HiChevronDoubleRight className="w-6 h-6 text-gray-400 border border-gray-100 rounded shadow" />
        </button> */}
        </div>
      )}
      <table {...getTableProps()} data-testid="data-table" className="w-full">
        <thead className="bg-blue-600 text-white">
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="divide-x divide-gray-100"
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="p-2 text-xs text-left uppercase break-words w-auto"
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.length < 1 ? (
            <tr className="grid place-items-center h-72 text-slate-400 italic bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              Sorry but there is currently no available data for this table
            </tr>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white border border-gray-200 divide-x divide-gray-200"
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-2 text-sm bg-white font-medium text-gray-900 text-left break-all"
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
