const fillRange = (start: number, stop: number, step: number) =>
 Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const avgReducer = (acc: any, value: any, index: any, array: any) => {
 const parsedValue = isFinite(value) ? Number(value) : 0;
 var calculatedValue = Number(acc) + parsedValue;
 // console.log(acc, value);
 if (index === array.length - 1) {
  // console.log(
  //  array.filter((value: number) => Number(value) > 0).length,
  //  array.length
  // );
  const validResults = array.filter(
   (value: number) => Number(value) > 0
  ).length;
  console.log(array, calculatedValue, array.length);
  return Number(calculatedValue) / Number(validResults);
 }

 return calculatedValue;
};

export { fillRange, avgReducer };
