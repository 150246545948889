import { Link, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const Navbar = () => {
	let { currentUser, logout } = useAuth();
	const navigate = useNavigate();

	const handleSignOut = () => {
		// logoutUser();
		console.log('logout user');
		logout().then(navigate('/login'));
	};
	return (
		<nav className="bg-white border-b border-gray-200 z-10 w-full">
			<div className="px-3 py-3 lg:px-5 lg:pl-3">
				<div className="flex items-center justify-between">
					<div className="flex items-center justify-start gap-4">
						<a
							href="https://www.carilec.org"
							target="_blank"
							className="text-xl font-bold flex items-center lg:ml-2.5"
							rel="noreferrer"
						>
							<img
								alt="caubt_logo"
								src={`/assets/carilec_logo.png`}
								width="150"
							/>
						</a>
					</div>{' '}
					<div className="text-blue-500 text-2xl font-semibold truncate text-center">
						CARILEC Automated Utility Benchmarking Platform (CAUBP)
					</div>
					<div className="flex items-center">
						<ul className="flex items-center justify-center">
							<li className="mr-3">
								<div className="dropdown inline-block relative">
									<button className="text-gray-600 px-4 border-none rounded inline-flex items-center rounded-lg hover:bg-gray-200">
										<div className="flex items-center h-10">
											{/* <Icon type="profile" /> */}
											<div className="ml-2 text-right text-sm font-medium">
												<p className="block">{currentUser.user_name}</p>
												<p className="block text-xs">{currentUser.user_role}</p>
											</div>
										</div>

										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
										</svg>
									</button>
									<div className="dropdown-menu hidden absolute right-0 shadow-lg z-50">
										<ul className="list-none w-full mt-2 origin-top-right rounded-md md:w-48 bg-white rounded-md shadow text-gray-700">
											<Link
												className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-500 hover:text-white dark:hover:text-white"
												to="/dashboard/profile"
											>
												<li className="">Profile</li>
											</Link>

											<li
												className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-500 hover:text-white dark:hover:text-white"
												onClick={() => {
													console.log('sign out user');
													handleSignOut();
												}}
											>
												Sign Out
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
