import React from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const RawDataForm = ({ utilityOptions, onSubmit }: any) => {
 const minYear = 1980;
 const maxYear = new Date().getFullYear() - 1;

 const fillRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

 const yearFilters = fillRange(maxYear, minYear, -1);

 const dataTypeList = [
  "General",
  "Technical",
  "Financial",
  "Customer",
  "Extra",
 ];

 const schema = yup
  .object({
   data_year: yup.string().required("Field is required"),
   data_type: yup.string().required("Field is required"),
   utility_id: yup.string().required("Field is required"),
  })
  .required();
 const {
  register,
  handleSubmit,
  setValue,
  getValues,
  watch,
  // reset,
  formState: { errors },
 } = useForm<any>({
  mode: "onChange",
  resolver: yupResolver(schema),
 });

 return (
  <form
   onSubmit={handleSubmit(onSubmit)}
   className="flex items-center gap-4 mb-6 p-3 bg-white border border-gray-200"
  >
   <label className="flex-1 text-gray-400 text-sm">
    Choose Year
    <select
     className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
     {...register("data_year")}
     placeholder="Select a Year"
    >
     <option value="">--Select Year--</option>
     {yearFilters.map((option: number) => (
      <option key={option} value={option}>
       {option}
      </option>
     ))}
    </select>
    <ErrorMessage
     errors={errors}
     name="data_year"
     render={({ message }: any) => (
      <p className="block text-xs text-red-500">{message}</p>
     )}
    />
   </label>
   <label className="flex-1 text-gray-400 text-sm">
    Choose Type
    <select
     className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
     {...register("data_type")}
     placeholder="Select a Data Type"
    >
     <option value="">--Select Type--</option>
     {dataTypeList.sort().map((option: string) => (
      <option key={option} value={option.toLowerCase()}>
       {option}
      </option>
     ))}
    </select>
    <ErrorMessage
     errors={errors}
     name="data_type"
     render={({ message }: any) => (
      <p className="block text-xs text-red-500">{message}</p>
     )}
    />
   </label>
   <label className="flex-1 text-gray-400 text-sm">
    Choose Utility
    <select
     className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
     {...register("utility_id")}
     placeholder="Select a Utility"
     onChange={(evt: any) => {
      const { value } = evt.target;
      if (value === "all") {
       const year_start = getValues("year_start");
       setValue("year_end", year_start, {
        shouldValidate: true,
        shouldDirty: true,
       });
      }
     }}
    >
     <option value="">--Select One--</option>
     {utilityOptions?.length > 1 ? (
      <option value="all">All</option>
     ) : (
      watch("data_type") === "extra" && <option value="all">All</option>
     )}
     {utilityOptions
      ?.sort((a: any, b: any) =>
       a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
        ? 1
        : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
        ? -1
        : 0
      )
      ?.map((option: any) => (
       <option key={option.utility_id} value={option.utility_id}>
        {option.utility_name}
       </option>
      ))}
    </select>
    <ErrorMessage
     errors={errors}
     name="utility_id"
     render={({ message }: any) => (
      <p className="block text-xs text-red-500">{message}</p>
     )}
    />
   </label>

   <div>
    <button
     type="submit"
     className="px-4 py-2 leading-6 text-base rounded-md border border-transparent text-white focus:outline-none bg-blue-600 text-white hover:bg-blue-800 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-flex items-center justify-center items-center font-medium focus:outline-none"
    >
     Run
    </button>
   </div>
  </form>
 );
};

export default RawDataForm;
