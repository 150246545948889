import React from "react";
// import faker from "@faker-js/faker";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import UtilityForm from "../../components/forms/UtilityForm";
import { findCountries } from "services/countries.service";

import {
  findUtilities,
  addUtility,
  updateUtility,
} from "services/utilities.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const Utilities = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);

  const queryClient = useQueryClient();

  const { data: countries } = useQuery("countries", () =>
    findCountries().then((res) => res.data)
  );
  const {
    isLoading,
    error,
    data: utilities,
  } = useQuery("utilities", () => findUtilities().then((res) => res.data));

  // if (isLoading) return <div>Loading</div>;

  // if (error) return <div>An error occurred...</div>;

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handleAddRecord = (data: any) => {
    addUtility(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated successfully");
        queryClient.invalidateQueries("utilities");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    const { country_name, ...formData } = data;
    updateUtility(formData).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record updated successfully");
        queryClient.invalidateQueries("utilities");
        handleToggleModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Utility",
        accessor: "utility_name",
      },
      {
        Header: "Address",
        accessor: "address_1",
      },
      {
        Header: "Contact Person",
        accessor: "contact",
      },
      {
        Header: "Country",
        accessor: "country_name",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <TableActions onEdit={() => handleToggleModal(row?.original)} />
          );
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="Utilities" onClick={toggleModal} />
      <Table columns={columns} data={utilities} />
      {isModalOpen && (
        <Modal
          modalTitle={record ? "Edit Utility" : "Add Utility"}
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <UtilityForm
            countries={countries}
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}
    </div>
  );
};
export default Utilities;
