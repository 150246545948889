import axios from "../config/apiClient";

const api_route = "/kpipermissions";

export const findKPIs = async () => {
  const response = await axios.get("/kpis");
  return response;
};

export const addKPI = async (data: any) => {
  const response = await axios.post("/kpis", data);
  return response;
};

export const updateKPI = async (data: any) => {
  const response = await axios.put("/kpis", data);
  return response;
};

export const removeKPI = async () => {
  const response = await axios.delete("/kpis");
  return response;
};

export const findKPICategories = async () => {
  const response = await axios.get("/kpis/category");
  return response;
};

export const addKPICategories = async (data: any) => {
  const response = await axios.post("/kpis/category", data);
  return response;
};

export const updateKPICategories = async (data: any) => {
  const response = await axios.put("/kpis/category", data);
  return response;
};

export const findKPIPermissions = async () => {
  const response = await axios.get(api_route);
  return response;
};

export const addKPIPermission = async (data: any) => {
  const response = await axios.post(api_route, data);
  return response;
};

export const updateKPIPermission = async (data: any) => {
  const response = await axios.put("kpis/permissions", data);
  return response;
};

export const removeKPIPermission = async () => {
  const response = await axios.delete(api_route);
  return response;
};
