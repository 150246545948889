import axios, {
  AxiosError,
  //   AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import LocalStorageService from '../services/localstorageservice';
const localStorageDomain = process.env.REACT_APP_LOCALSTORAGE || 'cp_session';
const axiosApiInstance = axios.create();
axiosApiInstance.defaults.baseURL = process.env.REACT_APP_API_SERVER;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);
  const token = LocalStorageService.getAccessToken();
  // console.log("token", token);
  if (token) {
    config.headers['x-access-token'] = token;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = async (error: any): Promise<any> => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  // const originalRequest = error.config;
  // console.log(originalRequest);

  if (error.response?.status === 401) {
    // history.push(urls.authentication);
    window.localStorage.removeItem(localStorageDomain);
    window.location.href = '/inactivity';
    // console.log("incorrect authentication header");
  } else if (error.response?.status === 404) {
    // history.push(urls.authentication);
    // window.localStorage.removeItem(localStorageDomain);
    // window.location.href = "/inactivity";
    console.log('cannot request api');
  } else if (error.response?.status === 500) {
    console.log('an error occurred', error.response);
    // return Promise.resolve(error);
    return {
      error: true,
      data: {
        error: true,
        message: error.response.data.message || 'Unknown error',
      },
    };
  }

  // if (error?.response?.status === 401 && !originalRequest._retry) {
  //   console.log('unauthorized or token expired', error.response);
  //   originalRequest._retry = true;
  //   window.localStorage.removeItem('wm_storedUser');

  //   const refreshToken = LocalStorageService.getRefreshToken();
  //   return axiosApiInstance
  //     .post(process.env.REACT_APP_API_SERVER + 'auth/refreshtoken', {
  //       refresh_token: refreshToken,
  //     })
  //     .then((res) => {
  //       console.log('refresh token response', res.data, res.status);
  //       if (res.status === 201) {
  //         LocalStorageService.setToken({
  //           accessToken: res.data.accessToken,
  //           refreshToken: refreshToken,
  //         });
  //         return axiosApiInstance(originalRequest);
  //       } else {
  //         console.log('failed to refresh token', res.data, res.status);
  //         LocalStorageService.clearToken();
  //         // window.location = '/login';
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('An error occurred refreshing token', err);
  //     });
  // }
  return Promise.reject(error);
};

axiosApiInstance.interceptors.request.use(onRequest, onRequestError);
axiosApiInstance.interceptors.response.use(onResponse, onResponseError);

export default axiosApiInstance;
