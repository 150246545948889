import React from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

import { fillRange } from "../../utils";
import useAuth from "hooks/useAuth";

type Inputs = {
 utility_id: string;
 bmdatayear: number;
 general_load_date: string;
 customer_load_date: string;
 financial_load_date: string;
 technical_load_date: string;
 extra_load_date: string;
 general_load_by: string | null;
 customer_load_by: string | null;
 financial_load_by: string | null;
 technical_load_by: string | null;
 extra_load_by: string | null;
 approval_comments: string;
};

type IFormProps = {
 initialValues: any;
 users: any;
 utilities?: any;
 onEdit: any;
 onAdd: any;
};

export default function DataRegistryForm(props: IFormProps) {
 const [isEditing, setIsEditing] = React.useState(false);
 // console.log(props);
 const { currentUser } = useAuth();

 const { utilities, users } = props;

 const getUserName = (id: string | null) => {
  console.log(id);
  if (!id) {
   return <br />;
  }
  const user = users?.find((user: any) => user.user_id === id);
  // console.log(user);
  return user?.user_name;
 };
 // const yearFilters: any[] = props.initialValues[1];
 const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

 const schema = yup.object().shape({
  utility_id: yup.string().required("Field is required"),
  bmdatayear: yup.number().required("Field is required"),
  general_load_date: yup
   .date()
   .nullable()
   .transform((curr, orig) => (orig === "" ? null : curr)),
  customer_load_date: yup
   .date()
   .nullable()
   .transform((curr, orig) => (orig === "" ? null : curr)),
  financial_load_date: yup
   .date()
   .nullable()
   .transform((curr, orig) => (orig === "" ? null : curr)),
  technical_load_date: yup
   .date()
   .nullable()
   .transform((curr, orig) => (orig === "" ? null : curr)),
  extra_load_date: yup
   .date()
   .nullable()
   .transform((curr, orig) => (orig === "" ? null : curr)),
 });

 const {
  register,
  handleSubmit,
  control,
  reset,
  resetField,
  getValues,
  setValue,
  // watch,
  formState: { errors },
 } = useForm<Inputs>({
  mode: "onChange",
  resolver: yupResolver(schema),
  // defaultValues: props.initialValues,
 });

 React.useEffect(() => {
  if (props.initialValues) {
   setIsEditing(true);
   reset(props.initialValues);
  } else {
   setIsEditing(false);
   if (currentUser.user_role.toLowerCase().includes("utility")) {
    reset({ utility_id: currentUser.utility_id });
   }
  }
  // console.log(props.initialValues);
 }, [props.initialValues]);

 const onSubmit: SubmitHandler<Inputs> = (data) => {
  console.log(errors, data);
  if (Object.values(errors).length < 1) {
   console.log("no errors");
   // return;
   if (props.initialValues) {
    props.onEdit(data);
   } else {
    props.onAdd(data);
   }
  }
 };

 // const clearFields = (dateField: string, userField: string) => {
 //   setValue(dateField, "");
 //   setValue(userField, null);
 // };

 // console.log(getValues(), errors);

 return (
  /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
  <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
   <div className="grid grid-cols-2 gap-2">
    <label className="flex-1 text-gray-400 font-semibold text-sm">
     Choose Utility
     <select
      className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
      {...register("utility_id")}
      placeholder="Select a Utility"
      disabled={isEditing}
     >
      <option value="">--Select One--</option>
      {utilities
       ?.sort((a: any, b: any) =>
        a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
         ? 1
         : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
         ? -1
         : 0
       )
       .map((option: any) => (
        <option key={option.utility_id} value={option.utility_id}>
         {option.utility_name}
        </option>
       ))}
     </select>
     <ErrorMessage
      errors={errors}
      name="utility_id"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="flex-1 text-gray-400 font-semibold text-sm">
     Choose Year
     <select
      className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
      {...register("bmdatayear")}
      placeholder="Select a Year"
      disabled={isEditing}
     >
      <option value="">--Select One--</option>
      {yearFilters.map((option: number) => (
       <option key={option} value={option}>
        {option}
       </option>
      ))}
     </select>
     <ErrorMessage
      errors={errors}
      name="bmdatayear"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
   </div>

   <div className="grid grid-cols-3 gap-2">
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Utility General Data</span>
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Approval Date</span>
     <Controller
      control={control}
      name="general_load_date"
      render={({
       field: { onChange, onBlur, value, name, ref },
       fieldState: { invalid, isTouched, isDirty, error },
       formState,
      }) => (
       <div className="flex items-center border border-gray-300 focus:ring-1 ring-indigo-600 rounded-lg shadow-sm pr-3">
        <Flatpickr
         className="border-none placeholder:italic placeholder:text-slate-400 text-gray-600"
         options={{
          static: true,
         }}
         placeholder="General Load Date"
         onChange={(date) => {
          onChange(date);
          console.log(date);
          // if (date?.length > 0) {
          setValue("general_load_by", currentUser.id, {
           shouldDirty: true,
           shouldValidate: true,
          });
          // } else {
          //   setValue("general_load_by", "");
          // }
         }}
         value={value}
        />{" "}
        {getValues("general_load_date") && (
         <button
          type="button"
          onClick={() => {
           setValue("general_load_date", "", {
            shouldDirty: false,
           });
           setValue("general_load_by", "", {
            shouldDirty: true,
            shouldValidate: true,
           });
          }}
         >
          Clear
         </button>
        )}
       </div>
      )}
     />
     <ErrorMessage
      errors={errors}
      name="general_load_date"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400">
     <span className="font-semibold text-sm">Approved by</span>
     <div className="text-gray-600 px-2 py-2 rounded-md text-md border border-gray-300 bg-gray-200">
      {getUserName(getValues("general_load_by"))}
     </div>
    </label>

    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Utility Customer Data</span>
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Approval Date</span>
     <Controller
      control={control}
      name="customer_load_date"
      render={({
       field: { onChange, onBlur, value, name, ref },
       fieldState: { invalid, isTouched, isDirty, error },
       formState,
      }) => (
       <div className="flex items-center border border-gray-300 focus:ring-1 ring-indigo-600 rounded-lg shadow-sm pr-3">
        <Flatpickr
         className="border-none placeholder:italic placeholder:text-slate-400 text-gray-600"
         options={{
          static: true,
         }}
         placeholder="Customer Load Date"
         onChange={(date) => {
          onChange(date);
          // console.log(currentUser);
          setValue("customer_load_by", currentUser.id, {
           shouldDirty: true,
           shouldValidate: true,
          });
         }}
         value={value}
         // data-testid="passwordExpirationDateId"
        />{" "}
        {getValues("customer_load_date") && (
         <button
          type="button"
          onClick={() => {
           setValue("customer_load_date", "", {
            shouldDirty: false,
           });
           setValue("customer_load_by", "", {
            shouldDirty: true,
            shouldValidate: true,
           });
          }}
         >
          Clear
         </button>
        )}
       </div>
      )}
     />
     <ErrorMessage
      errors={errors}
      name="customer_load_date"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400">
     <span className="font-semibold text-sm">Approved by</span>
     <div className="text-gray-600 px-2 py-2 rounded-md text-md border border-gray-300 bg-gray-200">
      {getUserName(getValues("customer_load_by"))}
     </div>
    </label>

    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Utility Financial Data</span>
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Approval Date</span>
     <Controller
      control={control}
      name="financial_load_date"
      render={({
       field: { onChange, onBlur, value, name, ref },
       fieldState: { invalid, isTouched, isDirty, error },
       formState,
      }) => (
       <div className="flex items-center border border-gray-300 focus:ring-1 ring-indigo-600 rounded-lg shadow-sm pr-3">
        <Flatpickr
         className="border-none placeholder:italic placeholder:text-slate-400 text-gray-600"
         options={{
          static: true,
         }}
         placeholder="Financial Load Date"
         onChange={(date) => {
          onChange(date);
          // console.log(currentUser);
          setValue("financial_load_by", currentUser.id, {
           shouldDirty: true,
           shouldValidate: true,
          });
         }}
         value={value}
         // data-testid="passwordExpirationDateId"
        />
        {getValues("financial_load_date") && (
         <button
          type="button"
          onClick={() => {
           setValue("financial_load_date", "", {
            shouldDirty: false,
           });
           setValue("financial_load_by", "", {
            shouldDirty: true,
            shouldValidate: true,
           });
          }}
         >
          Clear
         </button>
        )}
       </div>
      )}
     />
     <ErrorMessage
      errors={errors}
      name="financial_load_date"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400">
     <span className="font-semibold text-sm">Approved by</span>
     <div className="text-gray-600 px-2 py-2 rounded-md text-md border border-gray-300 bg-gray-200">
      {getUserName(getValues("financial_load_by"))}
     </div>
    </label>

    <label className="text-gray-400 font-semibold text-sm placeholder:italic placeholder:text-slate-400 ">
     <span className="block">Utility Technical Data</span>
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Approval Date</span>
     <Controller
      control={control}
      name="technical_load_date"
      render={({
       field: { onChange, onBlur, value, name, ref },
       fieldState: { invalid, isTouched, isDirty, error },
       formState,
      }) => (
       <div className="flex items-center border border-gray-300 focus:ring-1 ring-indigo-600 rounded-lg shadow-sm pr-3">
        <Flatpickr
         className="border-none placeholder:italic placeholder:text-slate-400 text-gray-600"
         options={{
          static: true,
         }}
         placeholder="Technical Load Date"
         onChange={(date) => {
          onChange(date);
          // console.log(currentUser);
          setValue("technical_load_by", currentUser.id, {
           shouldDirty: true,
           shouldValidate: true,
          });
         }}
         value={value}
         // data-testid="passwordExpirationDateId"
        />{" "}
        {getValues("technical_load_date") && (
         <button
          type="button"
          onClick={() => {
           setValue("technical_load_date", "", {
            shouldDirty: false,
           });
           setValue("technical_load_by", "", {
            shouldDirty: true,
            shouldValidate: true,
           });
          }}
         >
          Clear
         </button>
        )}
       </div>
      )}
     />
     <ErrorMessage
      errors={errors}
      name="technical_load_date"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400">
     <span className="font-semibold text-sm">Approved by</span>
     <div className="text-gray-600 px-2 py-2 rounded-md text-md border border-gray-300 bg-gray-200">
      {getUserName(getValues("technical_load_by"))}
     </div>
    </label>

    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Utility OSH Data</span>
    </label>
    <label className="text-gray-400 font-semibold text-sm">
     <span className="block">Approval Date</span>
     <Controller
      control={control}
      name="extra_load_date"
      render={({
       field: { onChange, onBlur, value, name, ref },
       fieldState: { invalid, isTouched, isDirty, error },
       formState,
      }) => (
       <div className="flex items-center border border-gray-300 focus:ring-1 ring-indigo-600 rounded-lg shadow-sm pr-3">
        <Flatpickr
         className="border-none placeholder:italic placeholder:text-slate-400 text-gray-600"
         options={{
          static: true,
         }}
         placeholder="Extra Load Date"
         onChange={(date) => {
          onChange(date);
          // console.log(currentUser);
          setValue("extra_load_by", currentUser.id, {
           shouldDirty: true,
           shouldValidate: true,
          });
         }}
         value={value}
         // data-testid="passwordExpirationDateId"
        />
        {getValues("extra_load_date") && (
         <button
          type="button"
          onClick={() => {
           setValue("extra_load_date", "", {
            shouldDirty: false,
           });
           setValue("extra_load_by", null, {
            shouldDirty: true,
            shouldValidate: true,
           });
          }}
         >
          Clear
         </button>
        )}
       </div>
      )}
     />
     <ErrorMessage
      errors={errors}
      name="extra_load_date"
      render={({ message }) => (
       <p className="text-xs text-red-500">{message}</p>
      )}
     />
    </label>
    <label className="text-gray-400 text-sm">
     <span className="font-semibold text-sm">Approved by</span>
     <div className="text-gray-600 px-2 py-2 rounded-md text-md border border-gray-300 bg-gray-200">
      {getUserName(getValues("extra_load_by"))}
     </div>
    </label>
   </div>

   <label className="block">
    <span className="text-sm font-medium text-gray-400">Approval Comment</span>
    <textarea
     {...register("approval_comments")}
     className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
     placeholder="Enter text here"
    ></textarea>
    <ErrorMessage
     errors={errors}
     name={"approval_comments"}
     render={({ message }) => <p className="text-xs text-red-500">{message}</p>}
    />
   </label>

   <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
    Save
   </button>
  </form>
 );
}
