import axios from "../config/apiClient";

export const findUtilities = async () => {
  const response = await axios.get("/utilities");

  return response;
};

export const addUtility = async (data: any) => {
  const response = await axios.post("/utilities", data);

  return response;
};

export const updateUtility = async (data: any) => {
  const response = await axios.put("/utilities", data);

  return response;
};

export const removeUtility = async () => {
  const response = await axios.delete("/utilities");

  return response;
};
