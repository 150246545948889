import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { regex } from "_utils";

type Inputs = {
 // recipient_emails: any;
 msg_text: string;
};

type IFormProps = {
 onSend: any;
 onClose: any;
 emails: any;
 categoryName: string;
};

export default function EmailForm(props: IFormProps) {
 // console.log(props);
 // const countries = props.countries;
 // const utilities = props.utilities;
 // const roles = props.userroles;
 // const utilities: any[] = props.initialValues[0];
 // const yearFilters: any[] = props.initialValues[1];

 const schema = yup.object().shape({
  // user_name: yup.string().required(),
  // user_name: yup.string().required("Field is required"),
  // email: yup.string().email().required("Field is required"),
  // phone: yup
  //   .string()
  //   .matches(regex.phoneNumber.validation, regex.phoneNumber.message)
  //   .required("Field is required"),
  // country_id: yup.string().required("Field is required"),
  // // utility_id: yup.string().required(),
  msg_text: yup.string().required("Field is required"),
 });

 const {
  register,
  handleSubmit,
  reset,
  formState: { errors },
 } = useForm<Inputs>({
  mode: "onChange",
  resolver: yupResolver(schema),
  // defaultValues: props.initialValues,
  defaultValues: {
   msg_text: `Utility ${props.categoryName} data has been updated`,
  },
 });

 // React.useEffect(() => {
 //   reset(props.initialValues);
 // }, [props.initialValues]);

 const onSubmit: SubmitHandler<Inputs> = (data) => {
  if (Object.values(errors).length < 1) {
   console.log("no errors");

   const formData = { ...data };

   props.onSend(formData);
   // setFormSuccess(!formSuccess)
   //Clear the form fields using react-hook-form
   // props.toggleModal()
  }
 };

 //   console.log(watch("example")); // watch input value by passing the name of it

 return (
  /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
  <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
   <label className="block">
    <span className="text-sm font-medium text-gray-400">Recipients</span>
    {props.emails.map((email: any, index: any) => {
     return (
      <p key={index} className="text-sm font-medium text-gray-600">
       {email}
      </p>
     );
    })}
   </label>
   <label className="block">
    <span className="text-sm font-medium text-gray-400">
     Notification message
    </span>
    <textarea
     {...register("msg_text")}
     placeholder="Message text goes here"
     rows={3}
     // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    ></textarea>
    <ErrorMessage
     errors={errors}
     name="msg_text"
     render={({ message }) => <p className="text-xs text-red-500">{message}</p>}
    />
   </label>
   <div className="flex justify-between items-center">
    <button
     type="button"
     onClick={props.onClose}
     className="px-4 py-2 hover:bg-red-600 hover:text-white text-red-600 rounded"
    >
     Cancel
    </button>
    <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
     Send
    </button>
   </div>
  </form>
 );
}
