import {
  Navigate,
  // Routes,
  // Route,
  // Outlet,
  RouteProps,
  // useLocation,
} from "react-router-dom";
import useAuth from "../hooks/useAuth";
// import { useEffect, useState } from 'react';
import Loading from "../components/Loading";

interface IProps extends RouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<IProps> = ({ children }) => {
  let { currentUser, loading } = useAuth();

  // console.log(useAuth());

  if (loading) return <Loading />;

  return currentUser ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
