import { fillRange, avgReducer } from 'utils';

const sortGraphData = (results: any, userPermission: any, userUtility: any) => {
  let sortedResults = handleGraphValues(results, userPermission, userUtility);
  let graphData = sortedResults.map((data: any) => data.calc_result);

  let labels = handleGraphLabels(sortedResults, userPermission, userUtility);

  return { graphData, labels };
};

const handleGraphValues = (
  results: any,
  userPermission: any,
  userUtility: any,
) => {
  const graphData = results;
  // console.log('sort graph data for user permission: ', userPermission);
  if (userPermission === 'ANON' || userPermission === 'UTIL') {
    return graphData
      .filter((result: any) => !isNaN(result.calc_result))
      .sort((a: any, b: any) => b.calc_result - a.calc_result);
  } else {
    return graphData;
  }
};

const handleGraphLabels = (
  results: any,
  userPermission: any,
  userUtility: any,
) => {
  // console.log(
  // 	'sort graph labels for user permission: ',
  // 	userPermission,
  // 	results,
  // 	userUtility,
  // );
  let labels = results.map((data: any) => {
    if (userPermission === 'UTIL') {
      if (
        userUtility.some((utility_id: any) => utility_id === data.utility_id)
      ) {
        return data.label;
      } else {
        return '';
      }
    } else if (userPermission === 'ANON') {
      return '';
    } else {
      return data.label;
    }
  });

  return labels;
};

export const calculateCapacityChangePES = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data.data_year, data.utility_id);

      const prevYear = tableData.find(
        (record: any) =>
          record.utility_id === data.utility_id &&
          record.data_year === Number(data.data_year) - 1,
      );
      console.log(tableData, prevYear);
      // TODO: Group results by utility code??
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && data.utility_id === userUtility
            ? data.utility_code
            : '',
        // utility_code: data.utility_code,
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const prevRecord = prevYear?.fields?.find(
          (prevField: any) => prevField.technology === element.technology,
        );
        const calc_result =
          (Number(element.installed_capacity) /
            Number(prevRecord?.installed_capacity) -
            1) *
          100;

        tableColumns[element?.technology?.split(' ').join('_').toLowerCase()] =
          isNaN(calc_result)
            ? ''
            : calc_result?.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              });
      });

      return tableColumns;
    });
};

export const calculateGenerationChangePES = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return (
    tableData
      .filter(
        (data: any) =>
          selectedUtility
            .map((id: any) => id.value)
            .includes(data.utility_id) &&
          data.data_year === Number(selectedStartYear),
      )
      // .sort(() => Math.random() - 0.5)
      .sort(() =>
        userPermission === 'UTIL' || userPermission === 'ANON'
          ? Math.random() - 0.5
          : 0,
      )
      .map((data: any) => {
        console.log(data.data_year, data.utility_id);

        const prevYear = tableData.find(
          (record: any) =>
            record.utility_id === data.utility_id &&
            record.data_year === Number(data.data_year) - 1,
        );
        console.log(tableData, prevYear);
        // TODO: Group results by utility code??
        const tableColumns: any = {
          utility_code:
            userPermission === 'FULL'
              ? data.utility_code
              : userPermission === 'UTIL' && data.utility_id === selectedUtility
              ? data.utility_code
              : '',
          data_year: data.data_year,
        };

        data.fields.forEach((element: any) => {
          const prevRecord = prevYear?.fields?.find(
            (prevField: any) => prevField.technology === element.technology,
          );
          const calc_result =
            (Number(element.generated_energy) /
              Number(prevRecord?.generated_energy) -
              1) *
            100;

          tableColumns[
            element?.technology?.split(' ').join('_').toLowerCase()
          ] = isNaN(calc_result)
            ? ''
            : calc_result?.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              });
        });

        return tableColumns;
      })
  );
};

export const calculateCostByType = (
  utilityData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return utilityData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      // console.log(data);

      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };
      return {
        ...tableColumns,
        capital_cost: data.capital_cost,
        total_cost: data.annual_utility_costs,
        percent_capital: (
          (Number(data.capital_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
        percent_om: (
          (Number(data.o_m_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
        percent_fuel: (
          (Number(data.fuel_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
        percent_overhead: (
          (Number(data.overhead_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
      };
    });
};

export const calculateServiceCoverage = (
  year_start: string,
  year_end: string,
  utility_id: any,
  utilityData: any,
  userPermission: any,
  userUtility: any,
  utilities: any,
) => {
  console.log('service coverage calc', utility_id[0].value);

  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const selectedUtility = utilityData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (selectedUtility) {
          calc_result =
            (Number(selectedUtility.population_with_service) /
              Number(selectedUtility.total_population)) *
            100;

          console.log(utilityData, calc_result);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }

    return { labels, results: graphData };
  } else {
    //Service Coverage - select utility
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const selectedDataRange = utilityData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    console.log(selectedDataRange);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const utility = selectedDataRange.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var service_coverage = 0;
      if (utility) {
        service_coverage =
          (Number(utility.population_with_service) /
            Number(utility.total_population)) *
          100;
      }
      console.log(service_coverage);
      //   console.log({ year: utility.data_year, service_coverage });
      results.push(service_coverage);
      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateFossilFuelByIPPs = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            (Number(technical.purchased_fossil_energy_from_ipps) * 100) /
            Number(technical.net_energy_input);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredFinancial = financialData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      // const financial = filteredFinancial.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var purchased_fossil_energy = 0;
      if (technical) {
        purchased_fossil_energy =
          (Number(technical.purchased_fossil_energy_from_ipps) * 100) /
          Number(technical.net_energy_input);
      }
      console.log('purchased_fossil_energy', purchased_fossil_energy);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(purchased_fossil_energy);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateEnvironmentalPerformance = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  utilities: any,
  userPermission: string,
  userUtility: string[],
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    const numUtilities = utilities.length;
    const technical = technicalData
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .filter((technical: any) => technical.data_year === Number(year_start));
    // console.log(utility_id, year_start, technical, technicalData);
    var calc_result = 0;
    if (technical) {
      console.log(technical, technical.length);
      var spills = technical.filter(
        (data: any) => data.spill_management_program === true,
      );
      var pcent_spills = spills ? Number(spills?.length) / numUtilities : 0;

      var waste = technical.filter(
        (data: any) => data.waste_management_program === true,
      );
      var pcent_waste = waste ? Number(waste?.length) / numUtilities : 0;

      var recycling = technical.filter(
        (data: any) => data.recycling_program === true,
      );
      var pcent_recycling = recycling
        ? Number(recycling?.length) / numUtilities
        : 0;

      var certified = technical.filter(
        (data: any) => data.certified_environ_mgmt === true,
      );
      var pcent_certified = certified
        ? Number(certified?.length) / numUtilities
        : 0;

      results.push({
        label: 'Spill Management',
        calc_result: pcent_spills,
      });

      results.push({
        label: 'Waste Management',
        calc_result: pcent_waste,
      });

      results.push({
        label: 'Recycling',
        calc_result: pcent_recycling,
      });

      results.push({
        label: 'Certified',
        calc_result: pcent_certified,
      });
    }
    // utilities.map((utility: any) => {

    // });
    // graphData = results.map((data: any) => data.calc_result * 100);
    results = results.map((data: any) => ({
      ...data,
      calc_result: data.calc_result * 100,
    }));
    let { graphData } = sortGraphData(results, userPermission, userUtility);
    let labels = results.map((data: any) => data.label);
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);
    const chartLabels = [
      'Spill management',
      'Waste management',
      'Recycling Program',
      'Certified Program',
    ];
    // const numUtilities = Number(utilities?.length);
    const numUtilities = 1;

    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      // var calcResults: any = [];
      // console.log(technical, technical.length, numUtilities);
      if (technical) {
        // var numSpills = Number(technical.length);
        var spills = technical.filter(
          (data: any) => data.spill_management_program === true,
        );
        var pcent_spills = spills ? Number(spills?.length) / numUtilities : 0;

        var waste = technical.filter(
          (data: any) => data.waste_management_program === true,
        );
        var pcent_waste = waste ? Number(waste?.length) / numUtilities : 0;

        var recycling = technical.filter(
          (data: any) => data.recycling_program === true,
        );
        var pcent_recycling = recycling
          ? Number(recycling?.length) / numUtilities
          : 0;

        var certified = technical.filter(
          (data: any) => data.certified_environ_mgmt === true,
        );
        var pcent_certified = certified
          ? Number(certified?.length) / numUtilities
          : 0;

        results.push(pcent_spills * 100);
        results.push(pcent_waste * 100);
        results.push(pcent_recycling * 100);
        results.push(pcent_certified * 100);
      }
      // console.log("purchased_fossil_energy", purchased_fossil_energy);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      // results.push({ ...calcResults });

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: chartLabels, results };
  }
};

export const calculateOSHImplementation = (
  year_start: string,
  year_end: string,
  utility_id: any,
  generalData: any,
  utilities: any,
  userPermission: string,
  userUtility: string[],
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    const numUtilities = utilities.length;
    const general = generalData
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .filter((data: any) => data.data_year === Number(year_start));
    // console.log(utility_id, year_start, technical, technicalData);
    var calc_result = 0;
    if (general) {
      console.log(general, general.length);
      var hs_audits = general.filter(
        (data: any) => data.health_and_safety_audits === true,
      );
      var pcent_hs_audits = hs_audits
        ? Number(hs_audits?.length) / numUtilities
        : 0;
      var hs_manual = general.filter(
        (data: any) => data.health_and_safety_manual === true,
      );
      var pcent_hs_manual = hs_manual
        ? Number(hs_manual?.length) / numUtilities
        : 0;

      var hs_committee = general.filter(
        (data: any) => data.health_and_safety_committee === true,
      );
      var pcent_hs_committee = hs_committee
        ? Number(hs_committee?.length) / numUtilities
        : 0;

      var rs_meetings = general.filter(
        (data: any) => data.regular_safety_meetings === true,
      );
      var pcent_rs_meetings = rs_meetings
        ? Number(rs_meetings?.length) / numUtilities
        : 0;
      var rs_tailboards = general.filter(
        (data: any) => data.regular_tailboards_meetings === true,
      );
      var pcent_rs_tailboards = rs_tailboards
        ? Number(rs_tailboards?.length) / numUtilities
        : 0;
      var continued_work = general.filter(
        (data: any) => data.continued_work_inspections === true,
      );
      var pcent_continued_work = continued_work
        ? Number(continued_work?.length) / numUtilities
        : 0;
      var reg_facility = general.filter(
        (data: any) => data.regular_facility_inspections === true,
      );
      var pcent_reg_facility = reg_facility
        ? Number(reg_facility?.length) / numUtilities
        : 0;
      var reg_worksite = general.filter(
        (data: any) => data.regular_worksite_inspections === true,
      );
      var pcent_reg_worksite = reg_worksite
        ? Number(reg_worksite?.length) / numUtilities
        : 0;

      const chartLabels = [
        { label: 'H & S Audits', key: pcent_hs_audits * 100 },
        { label: 'H & S Manual', key: pcent_hs_manual * 100 },
        { label: 'H & S Committee', key: pcent_hs_committee * 100 },
        { label: 'Reg Safety Meetings', key: pcent_rs_meetings * 100 },
        { label: 'Reg Tailboards Meetings', key: pcent_rs_tailboards * 100 },
        {
          label: 'Continued Work Observations',
          key: pcent_continued_work * 100,
        },
        { label: 'Reg Facility Inspections', key: pcent_reg_facility * 100 },
        { label: 'Reg Worksite Inspections', key: pcent_reg_worksite * 100 },
      ];
      console.log(chartLabels);
      chartLabels.map((label: any) => {
        results.push({ label: label.label, calc_result: label.key });
      });
    }
    let { graphData } = sortGraphData(results, userPermission, userUtility);
    let labels = results.map((data: any) => data.label);
    // Add average data and label
    graphData.push(graphData.reduce(avgReducer, 0));
    labels.push('Avg');
    console.log(labels, 'graph data', graphData);
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);
    const chartLabels = [
      'H & S Audits',
      'H & S Manual',
      'H & S Committee',
      'Reg Safety Meetings',
      'Reg Tailboards Meetings',
      'Continued Work Observations',
      'Reg Facility Inspections',
      'Reg Worksite Inspections',
    ];
    // const numUtilities = Number(utilities?.length);
    const numUtilities = 1;

    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const general = filteredGeneral.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      // var calcResults: any = [];
      // console.log(technical, technical.length, numUtilities);
      if (general) {
        // var numSpills = Number(technical.length);
        var hs_audits = general.filter(
          (data: any) => data.health_and_safety_audits === true,
        );
        var pcent_hs_audits = hs_audits
          ? Number(hs_audits?.length) / numUtilities
          : 0;
        var hs_manual = general.filter(
          (data: any) => data.health_and_safety_manual === true,
        );
        var pcent_hs_manual = hs_manual
          ? Number(hs_manual?.length) / numUtilities
          : 0;

        var hs_committee = general.filter(
          (data: any) => data.health_and_safety_committee === true,
        );
        var pcent_hs_committee = hs_committee
          ? Number(hs_committee?.length) / numUtilities
          : 0;

        var rs_meetings = general.filter(
          (data: any) => data.regular_safety_meetings === true,
        );
        var pcent_rs_meetings = rs_meetings
          ? Number(rs_meetings?.length) / numUtilities
          : 0;
        var rs_tailboards = general.filter(
          (data: any) => data.regular_tailboards_meetings === true,
        );
        var pcent_rs_tailboards = rs_tailboards
          ? Number(rs_tailboards?.length) / numUtilities
          : 0;
        var continued_work = general.filter(
          (data: any) => data.continued_work_inspections === true,
        );
        var pcent_continued_work = continued_work
          ? Number(continued_work?.length) / numUtilities
          : 0;
        var reg_facility = general.filter(
          (data: any) => data.regular_facility_inspections === true,
        );
        var pcent_reg_facility = reg_facility
          ? Number(reg_facility?.length) / numUtilities
          : 0;
        var reg_worksite = general.filter(
          (data: any) => data.regular_worksite_inspections === true,
        );
        var pcent_reg_worksite = reg_worksite
          ? Number(reg_worksite?.length) / numUtilities
          : 0;

        results.push(pcent_hs_audits * 100);
        results.push(pcent_hs_manual * 100);
        results.push(pcent_hs_committee * 100);
        results.push(pcent_rs_meetings * 100);
        results.push(pcent_rs_tailboards * 100);
        results.push(pcent_continued_work * 100);
        results.push(pcent_reg_facility * 100);
        results.push(pcent_reg_worksite * 100);
      }
    });

    return { labels: chartLabels, results };
  }
};

export const calculateRenewableEnergyByIPPs = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            (Number(technical.re_delivered_by_ipps) * 100) /
            Number(technical.net_energy_input);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredFinancial = financialData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      // const financial = filteredFinancial.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var purchased_fossil_energy = 0;
      if (technical) {
        purchased_fossil_energy =
          (Number(technical.re_delivered_by_ipps) * 100) /
          Number(technical.net_energy_input);
      }
      console.log('purchased_fossil_energy', purchased_fossil_energy);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(purchased_fossil_energy);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateTDCost = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  customerData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        var calc_result = 0;
        if (financial) {
          calc_result =
            Number(financial.t_d_cost) / Number(financial.energy_sold) / 1000;

          // console.log(utilityData, calc_result);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var td_cost = 0;
      if (financial && customer) {
        td_cost =
          Number(financial.t_d_cost) / Number(financial.energy_sold) / 1000;
      }
      console.log('td_cost', td_cost);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(td_cost);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateTDProductivity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  generalData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const general = generalData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (general && financial) {
          calc_result =
            (Number(general.fte_td_staff) / Number(financial.energy_sold)) * 10;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const general = filteredGeneral.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var td_prod = 0;
      if (general && financial) {
        td_prod =
          (Number(general.fte_td_staff) / Number(financial.energy_sold)) * 10;
      }
      console.log('td_prod', td_prod);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(td_prod);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateFuelCost = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  console.log(selectedUtility, selectedStartYear, userPermission, userUtility);
  console.log(userPermission, userUtility);
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data);
      const tableColumns: any = {
        // utility_code: data.utility_code,
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result =
          Number(element.fuel_cost) / Number(element.energy_generated) / 1000;

        tableColumns[element.fuel_name.split(' ').join('_').toLowerCase()] =
          calc_result.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          });
      });

      return tableColumns;
    });
};

export const calculateEfficiencyFuelUnits = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data);
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result =
          (Number(element.energy_generated) * 1000000) /
          Number(element.fuel_used);

        tableColumns[element.fuel_name.split(' ').join('_').toLowerCase()] =
          calc_result.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          });
      });

      return tableColumns;
    });
};

export const calculateThermalGenerationCost = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data);
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result =
          Number(element.generation_cost) /
          Number(element.energy_generated) /
          1000;

        tableColumns[element.fuel_name.split(' ').join('_').toLowerCase()] =
          calc_result.toFixed(2);
      });

      return tableColumns;
    });
};

export const calculateCapThresholdDER = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data);
      const {
        capacity_threshold_for_residential_re,
        capacity_threshold_for_c_i_re,
      } = data;
      // TODO: Group results by utility code??
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        capacity_threshold_for_residential_re,
        capacity_threshold_for_c_i_re,
      };

      return tableColumns;
    });
};

export const calculateSupportMechanismDER = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      const { support_title } = data;
      const supportKey = support_title.split(' ').join('_').toLowerCase();
      console.log(supportKey);
      // TODO: Group results by utility code??
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        [supportKey]: 'Y',
      };

      return tableColumns;
    });
};

const mergeArraysById = (a1: any, a2: any) =>
  a1.map((data: any) => ({
    ...a2.find((item: any) => item.utility_id === data.utility_id && item),
    ...data,
  }));

export const calculateEEDSMProgram = (
  financialData: any,
  technicalData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  const filteredFinancial = financialData.filter(
    (data: any) => data.data_year === Number(selectedStartYear),
  );
  const filteredTechnical = technicalData.filter(
    (data: any) => data.data_year === Number(selectedStartYear),
  );

  // console.log(filteredFinancial, filteredTechnical);

  // console.log(mergeArraysById(filteredFinancial, filteredTechnical));
  const mergedData = mergeArraysById(filteredFinancial, filteredTechnical);
  return mergedData
    .filter((utility: any) =>
      selectedUtility.map((id: any) => id.value).includes(utility.utility_id),
    )
    .map((data: any) => {
      let tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        // esco_models: "Y",
      };

      [
        'esco_models',
        'grant_loan_prog_ee_in_residential',
        'grant_loan_prog_ee_in_commercial',
        'grant_loan_prog_ee_in_industry',
        'grant_loan_prog_ee_in_tourism',
        'other_ee',
        'time_in_use_tariffs',
        'microgrids_with_flexible_connection',
        'incentivization_via_storage_capacities',
        'other_dms',
      ].map((item: any) => {
        // console.log(item, data[item]);
        tableColumns[item] = data[item] ? 'Y' : 'N';
      });

      return tableColumns;
    });
};

// export const calculateEEDSMProgram = (
// 	tableData: any,
// 	technicalData: any,
// 	selectedUtility: any,
// 	selectedStartYear: any,
// 	userPermission: any,
// 	userUtility: any,
// ) => {
// 	return tableData
// 		.filter((data: any) => {
// 			// Filter by selected utility and start year
// 			if (selectedUtility.toLowerCase() === 'all')
// 				return data.data_year === Number(selectedStartYear);
// 			else
// 				return (
// 					data.utility_id === selectedUtility &&
// 					data.data_year === Number(selectedStartYear)
// 				);
// 		})
// 		.sort(() =>
// 			userPermission === 'UTIL' || userPermission === 'ANON'
// 				? Math.random() - 0.5
// 				: 0,
// 		)
// 		.map((data: any) => {
// 			let tableColumns: any = {
// 				utility_code:
// 					userPermission === 'FULL'
// 						? data.utility_code
// 						: userPermission === 'UTIL' && data.utility_id === userUtility
// 						? data.utility_code
// 						: '',
// 				data_year: data.data_year,
// 				// esco_models: "Y",
// 			};

// 			[
// 				'esco_models',
// 				'grant_loan_prog_ee_in_residential',
// 				'grant_loan_prog_ee_in_commercial',
// 				'grant_loan_prog_ee_in_industry',
// 				'grant_loan_prog_ee_in_tourism',
// 				'other_ee',
// 			].map((item: any) => {
// 				// console.log(item, data[item]);
// 				tableColumns[item] = data[item] ? 'Y' : 'N';
// 			});

// 			return tableColumns;
// 		});
// };

export const calculateAvailabilityOfFunds = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      let tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        // esco_models: "Y",
      };

      ['pcent_of_asset_cover', 'disaster_fund'].map((item: any) => {
        // console.log(item, data[item]);
        tableColumns[item] =
          item === 'pcent_of_asset_cover' ? data[item] : data[item] ? 'Y' : 'N';
      });

      return tableColumns;
    });
};

export const calculateGridModernization = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      let tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        // esco_models: "Y",
      };

      [
        'existing_gen_forecast_system',
        'auto_pwr_ctrl_netwk_monitoring',
        'dynamic_electric_cust_tariffs',
        'dispatchable_demand_response',
        'nbr_of_grid_connected_microgrids',
        'grid_connected_battery_storage',
      ].map((item: any) => {
        // console.log(item, data[item]);
        tableColumns[item] = data[item]
          ? typeof data[item] === 'boolean'
            ? 'Y'
            : data[item]
          : 'N';
      });

      return tableColumns;
    });
};

export const calculateGender = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      let tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
        // esco_models: "Y",
      };

      [
        'percent_male_staff',
        'percent_female_staff',
        'percent_technical_male_staff',
        'percent_technical_female_staff',
        'percent_male_senior_staff',
        'percent_senior_female_staff',
      ].map((item: any) => {
        // console.log(item, data[item]);
        tableColumns[item] = data[item] + '%';
      });

      return tableColumns;
    });
};

export const calculateSharePerCustomerGroup = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      // console.log(data);
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result =
          (Number(element.customers) / Number(element.num_customers)) * 100;
        // console.log(element);
        tableColumns[element.cust_category_id] = calc_result.toLocaleString(
          'en-US',
          {
            maximumFractionDigits: 2,
          },
        );
      });

      return tableColumns;
    });
};

export const calculateGridLossPerVolt = (
  tableData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return tableData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .map((data: any) => {
      console.log(data);
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result =
          ((Number(element.entry_energy) - Number(element.delivered_energy)) /
            Number(element.entry_energy)) *
          100;

        tableColumns[
          element.voltage_category?.split(' ').join('_').toLowerCase()
        ] = calc_result?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        });
      });

      return tableColumns;
    });
};

export const calculateNonTechnicalLosses = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        var calc_result = 0;
        if (technical && financial) {
          calc_result =
            ((Number(technical.net_energy_generated) +
              Number(technical.energy_purchased) -
              Number(technical.energy_losses) -
              Number(financial.energy_sold)) /
              (Number(technical.net_energy_generated) +
                Number(technical.energy_purchased) -
                Number(technical.energy_losses))) *
            100;

          // console.log(utilityData, calc_result);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      console.log(technical);
      var net_loss = 0;
      if (technical) {
        net_loss =
          ((Number(technical.net_energy_generated) +
            Number(technical.energy_purchased) -
            Number(technical.energy_losses) -
            Number(financial.energy_sold)) /
            (Number(technical.net_energy_generated) +
              Number(technical.energy_purchased) -
              Number(technical.energy_losses))) *
          100;
      }
      console.log('net_loss', net_loss);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(net_loss);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

// export const calculateLengthTDNetwork = (
// 	year_start: string,
// 	year_end: string,
// 	utility_id: string,
// 	technicalData: any,
// 	userPermission: string,
// 	userUtility: string[],
// ) => {
// 	console.log(utility_id, '*******************************');
// 	if (utility_id === 'all') {
// 		//Service Coverage - select all
// 		var results: any = [];
// 		technicalData
// 			.filter((technical: any) => technical.datayear === Number(year_start))
// 			.map((technical: any) => {
// 				// const general = generalData.find(
// 				//   (utility: any) => utility.data_year === Number(year_start)
// 				// );
// 				var net_loss = 0;
// 				if (technical) {
// 					net_loss =
// 						((technical.net_energy_generated +
// 							technical.energy_purchased -
// 							technical.energy_losses -
// 							technical.energy_sold) /
// 							(technical.net_energy_generated +
// 								technical.energy_purchased -
// 								technical.energy_losses)) *
// 						100;
// 				}
// 				//   console.log({ year: utility.data_year, service_coverage });
// 				results.push({
// 					utility_id: technical.utility_id,
// 					label: technical.utility_code,
// 					year: technical.datayear,
// 					net_loss,
// 					color: 'rgba(255, 99, 132, 0.5)',
// 					// color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
// 					//   Math.floor(Math.random() * 2)
// 					// ],
// 				});
// 			});
// 		// console.log(graphicalData);
// 		// console.log(selectedUtilities);
// 		let graphData = results.map((data: any) => data.net_loss);
// 		// console.log(graphData);
// 		graphData.push(graphData.reduce(avgReducer, 0));
// 		// let labels = graphicalData.map((data: any) => data.label);
// 		let labels = results.map((data: any) => {
// 			//filter based on kpi permissions and utility id
// 			if (userPermission !== 'FULL') {
// 				if (utility_id === userUtility) {
// 					return data.label;
// 				} else {
// 					return '';
// 				}
// 			} else {
// 				return data.label;
// 			}
// 		});
// 		labels.push('Avg');
// 		console.log('graph data', graphData);
// 		// let labels: any = [];
// 		// let graphData: any = [];
// 		return { labels, results: graphData };
// 	} else {
// 		const yearRange = fillRange(Number(year_start), Number(year_end), +1);

// 		//   console.log(yearRange);
// 		//Retreive info from utility_general_data - find records matching utility and years
// 		// const filteredGeneral = generalData.filter(
// 		//   (utility: any) =>
// 		//     utility.data_year >= Number(year_start) &&
// 		//     utility.data_year <= Number(year_end)
// 		// );
// 		const filteredTechnical = technicalData.filter(
// 			(utility: any) =>
// 				utility.datayear >= Number(year_start) &&
// 				utility.datayear <= Number(year_end),
// 		);
// 		//   console.log(selectedUtility);

// 		//Calculate service coverage = population with service/total population

// 		var results: any = [];

// 		yearRange.map((singleYear: any) => {
// 			const technical = filteredTechnical.find(
// 				(utility: any) =>
// 					utility.datayear === singleYear && utility.utility_id === utility_id,
// 			);

// 			// const financial = filteredFinancial.find(
// 			//   (utility: any) =>
// 			//     utility.data_year === singleYear && utility.utility_id === utility_id
// 			// );

// 			const energy_gen = technical.net_energy_generated;

// 			var net_loss = 0;
// 			if (technical) {
// 				net_loss =
// 					((technical.net_energy_generated +
// 						technical.energy_purchased -
// 						technical.energy_losses -
// 						technical.energy_sold) /
// 						(technical.net_energy_generated +
// 							technical.energy_purchased -
// 							technical.energy_losses)) *
// 					100;
// 			}
// 			console.log('net_loss', net_loss);
// 			//   console.log({ year: utility.data_year, service_coverage });

// 			// results.push({
// 			//   year: singleYear,
// 			//   average_energy: averageEnergyCost,
// 			// });
// 			results.push(net_loss);

// 			// results.push({ year: singleYear, service_coverage });
// 		});

// 		return { labels: yearRange, results };
// 	}
// };

export const calculateNetworkDisaggregation = (
  year_start: string,
  year_end: string,
  utility_id: any,
  customerData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, 'disaggregation*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );

        const customer = customerData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;

        // console.log("calculate saidi");
        if (technical && customer) {
          // console.log(restorationPeriod, customer);
          //where restoration period is greater than 5
          calc_result =
            (Number(technical.nbr_of_grids_in_areas_served) /
              Number(customer.no_of_customers_served)) *
            1000000;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (technical: any) =>
          technical.data_year === singleYear &&
          technical.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (customer: any) =>
          customer.data_year === singleYear &&
          customer.utility_id === utility_id[0].value,
      );
      console.log(utility_id, technical, customer);
      // const energy_gen = technical.net_energy_generated;

      var network_disaggregation = 0;
      if (technical && customer) {
        console.log(
          Number(technical.nbr_of_grids_in_areas_served),
          Number(customer.no_of_customers_served),
        );
        network_disaggregation =
          (Number(technical.nbr_of_grids_in_areas_served) /
            Number(customer.no_of_customers_served)) *
          1000000;
      }
      console.log('network_disaggregation ', network_disaggregation);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(network_disaggregation);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateNumberCustomerComplaints = (
  year_start: string,
  year_end: string,
  utility_id: any,
  customerData: any,
  // technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, 'complaints*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const customer = customerData.find(
          (customer: any) =>
            customer.data_year === Number(year_start) &&
            customer.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (customer) {
          calc_result =
            (customer.no_of_complaints / customer.no_of_customers_served) *
            1000;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    // const filteredTechnical = technicalData.filter(
    //   (utility: any) =>
    //     utility.datayear >= Number(year_start) &&
    //     utility.datayear <= Number(year_end)
    // );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      // const technical = filteredTechnical.find(
      //   (utility: any) =>
      //     utility.datayear === singleYear && utility.utility_id === utility_id
      // );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;

      var number_complaints = 0;
      if (customer) {
        console.log(customer);
        number_complaints =
          (customer.no_of_complaints / customer.no_of_customers_served) * 1000;
      }
      console.log('number_complaints', number_complaints);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(number_complaints);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateSmartMeterCustomers = (
  year_start: string,
  year_end: string,
  utility_id: any,
  customerData: any,
  // technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const customer = customerData.find(
          (customer: any) =>
            customer.data_year === Number(year_start) &&
            customer.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (customer) {
          calc_result =
            (customer.no_of_smart_meter_customers /
              customer.no_of_customers_served) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    // const filteredTechnical = technicalData.filter(
    //   (utility: any) =>
    //     utility.datayear >= Number(year_start) &&
    //     utility.datayear <= Number(year_end)
    // );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      // const technical = filteredTechnical.find(
      //   (utility: any) =>
      //     utility.datayear === singleYear && utility.utility_id === utility_id
      // );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;

      var number_smartmeter_customers = 0;
      if (customer) {
        console.log(customer);
        number_smartmeter_customers =
          (customer.no_of_smart_meter_customers /
            customer.no_of_customers_served) *
          100;
      }
      console.log('number_smartmeter_customers', number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(number_smartmeter_customers);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateInstalledCapacity = (
  utilityData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return (
    utilityData
      // .filter((data: any) => {
      // 	// console.log(data, selectedUtility);
      // 	if (selectedUtility.toLowerCase() === 'all')
      // 		return data.data_year === Number(selectedStartYear);
      // 	else
      // 		return (
      // 			data.utility_id === selectedUtility &&
      // 			data.data_year === Number(selectedStartYear)
      // 		);
      // })
      .filter(
        (data: any) =>
          selectedUtility
            .map((id: any) => id.value)
            .includes(data.utility_id) &&
          data.data_year === Number(selectedStartYear),
      )
      .sort(() =>
        userPermission === 'UTIL' || userPermission === 'ANON'
          ? Math.random() - 0.5
          : 0,
      )
      .map((data: any) => {
        console.log(data);
        // TODO: Group results by utility code??
        const tableColumns: any = {
          utility_code:
            userPermission === 'FULL'
              ? data.utility_code
              : userPermission === 'UTIL' &&
                userUtility.includes(data.utility_id)
              ? data.utility_code
              : '',
          data_year: data.data_year,
        };

        data.fields.forEach((element: any) => {
          const calc_result = element.installed_capacity;

          tableColumns[
            element?.technology?.split(' ').join('_').toLowerCase()
          ] = calc_result?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          });
        });

        return tableColumns;
      })
  );
};

export const calculateEnergyGenerationPES = (
  utilityData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return utilityData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      console.log(data);
      // TODO: Group results by utility code??
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        console.log(
          data.utility_code,
          element.generated_energy,
          element.net_energy_input,
        );
        const calc_result =
          (Number(element.generated_energy) /
            Number(element.net_energy_input)) *
          100;

        tableColumns[element.technology?.split(' ').join('_').toLowerCase()] =
          calc_result.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          });
      });

      return tableColumns;
    });
};

export const calculateDERCapacity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  utilities: any,
  userPermission: any,
  userUtility: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        console.log(technical);
        var calc_result = 0;
        if (technical) {
          calc_result = technical.installed_capacity_for_small_scale_re;

          // console.log(technicalData, calc_results);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;

      var calc_results = 0;
      if (technical) {
        calc_results = technical.installed_capacity_for_small_scale_re;
      }
      results.push(calc_results);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateAvgRECost = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  financialData: any,
  utilities: any,
  userPermission: any,
  userUtility: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );

        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (technical && financial) {
          calc_result =
            Number(financial.cost_of_own_re_generation) /
            Number(technical.energy_from_own_re_sources) /
            1000;

          // console.log(technicalData, calc_results);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    //Service Coverage - select utility
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var calc_result = 0;
      if (technical && financial) {
        calc_result =
          Number(financial.cost_of_own_re_generation) /
          Number(technical.energy_from_own_re_sources) /
          1000;
      }

      //   console.log({ year: utility.data_year, service_coverage });
      results.push(calc_result);
      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateWindCapacityFactor = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  financialData: any,
  utilities: any,
  userPermission: any,
  userUtility: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const techData = technologyData.find(
          (data: any) =>
            data.data_year === Number(year_start) &&
            data.utility_id === utility.utility_id &&
            data.wind === true,
        );

        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (techData) {
          calc_result =
            100 *
            ((Number(techData.generated_energy) * 1000) /
              Number(techData.installed_capacity) /
              8760);

          console.log(techData, calc_result);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    //Service Coverage - select utility
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    const filteredTechData = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const techData = filteredTechData.find(
        (data: any) =>
          data.data_year === singleYear &&
          data.utility_id === utility_id[0].value &&
          data.wind === true,
      );

      console.log(filteredTechData, techData);
      var calc_result = 0;
      if (techData) {
        calc_result =
          100 *
          ((Number(techData.generated_energy) * 1000) /
            Number(techData.installed_capacity) /
            8760);
      }

      //   console.log({ year: utility.data_year, service_coverage });
      results.push(calc_result);
      // results.push({ year: singleYear, service_coverage });
    });
    return { labels: yearRange, results };
  }
};

export const calculateSolarCapacityFactor = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technologies: any,
  utilities: any,
  userPermission: any,
  userUtility: any,
) => {
  if (utility_id?.length) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const techData = technologyData.find(
          (data: any) =>
            data.data_year === Number(year_start) &&
            data.utility_id === utility.utility_id &&
            data.pv === true,
        );

        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (techData) {
          calc_result =
            100 *
            ((Number(techData.generated_energy) * 1000) /
              Number(techData.installed_capacity) /
              8760);

          // console.log(techData, calc_results);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    //Service Coverage - select utility
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechData = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const techData = filteredTechData.find(
        (data: any) =>
          data.data_year === singleYear &&
          data.utility_id === utility_id[0].value &&
          data.pv === true,
      );

      console.log(filteredTechData, techData);
      var calc_result = 0;
      if (techData) {
        calc_result =
          100 *
          ((Number(techData.generated_energy) * 1000) /
            Number(techData.installed_capacity) /
            8760);
      }

      //   console.log({ year: utility.data_year, service_coverage });
      results.push(calc_result);
      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

// export const calculateCapThreshold = (
//   year_start: string,
//   year_end: string,
//   utility_id: string,
//   technicalData: any,
//   financialData: any,
//   utilities: any,
//   userPermission: any,
//   userUtility: any
// ) => {
//   if (utility_id === "all") {
//     //TODO: Loop through all utilities FIRST
//     var results: any = [];
//     // var graphData: any = [];
//     console.log(utilities);
//     utilities.map((utility: any) => {
//       const technical = technicalData.find(
//         (technical: any) =>
//           technical.data_year === Number(year_start) &&
//           technical.utility_id === utility.utility_id
//       );

//       const financial = financialData.find(
//         (financial: any) =>
//           financial.data_year === Number(year_start) &&
//           financial.utility_id === utility.utility_id
//       );
//       // console.log(utility.code, financial, technical);

//       var calc_result = 0;
//       if (technical && financial) {
//         calc_result =
//           Number(financial.cost_of_own_re_generation) /
//           Number(technical.energy_from_own_re_sources) /
//           1000;

//         // console.log(technicalData, calc_results);
//         //   console.log({ year: utility.data_year, service_coverage });
//         results.push({
//           utility_id: utility.utility_id,
//           label: utility.code,
//           year: year_start,
//           calc_result,
//           color: "rgba(255, 99, 132, 0.5)",
//           // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
//           //   Math.floor(Math.random() * 2)
//           // ],
//         });
//       } else {
//         results.push({
//           utility_id: utility.utility_id,
//           label: utility.code,
//           year: year_start,
//           calc_result: 0,
//           color: "rgba(255, 99, 132, 0.5)",
//           // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
//           //   Math.floor(Math.random() * 2)
//           // ],
//         });
//       }
//     });

//     // console.log(graphicalData);
//     // console.log(selectedUtilities);
//     let { graphData, labels } = sortGraphData(
//       results,
//       userPermission,
//       userUtility
//     );
//     // Add average data and label
//     graphData.push(graphData.reduce(avgReducer, 0));
//     labels.push("Avg");
//     console.log(labels, "graph data", graphData);
//     return { labels, results: graphData };
//   } else {
//     //Service Coverage - select utility
//     const yearRange = fillRange(Number(year_start), Number(year_end), +1);

//     //   console.log(yearRange);
//     //Retreive info from utility_general_data - find records matching utility and years
//     const filteredFinancial = financialData.filter(
//       (utility: any) =>
//         utility.data_year >= Number(year_start) &&
//         utility.data_year <= Number(year_end)
//     );
//     const filteredTechnical = technicalData.filter(
//       (utility: any) =>
//         utility.data_year >= Number(year_start) &&
//         utility.data_year <= Number(year_end)
//     );

//     //Calculate service coverage = population with service/total population

//     var results: any = [];

//     yearRange.map((singleYear: any) => {
//       const technical = filteredTechnical.find(
//         (utility: any) =>
//           utility.data_year === singleYear && utility.utility_id === utility_id
//       );

//       const financial = filteredFinancial.find(
//         (utility: any) =>
//           utility.data_year === singleYear && utility.utility_id === utility_id
//       );
//       var calc_result = 0;
//       if (technical && financial) {
//         calc_result =
//           Number(financial.cost_of_own_re_generation) /
//           Number(technical.energy_from_own_re_sources) /
//           1000;
//       }

//       //   console.log({ year: utility.data_year, service_coverage });
//       results.push(calc_result);
//       // results.push({ year: singleYear, service_coverage });
//     });

//     return { labels: yearRange, results };
//   }
// };

// export const calculateMWGenerationByRE = (
// 	year_start: string,
// 	year_end: string,
// 	utility_id: string,
// 	financialData: any,
// 	technicalData: any,
// 	userPermission: string,
// 	userUtility: string[],
// ) => {
// 	console.log(utility_id, '*******************************');
// 	if (utility_id === 'all') {
// 		//Service Coverage - select all
// 		var results: any = [];
// 		technicalData
// 			.filter((technical: any) => technical.datayear === Number(year_start))
// 			.map((technical: any) => {
// 				// const customer = customerData.find(
// 				//   (utility: any) => utility.data_year === Number(year_start)
// 				// );
// 				var calc_result = 0;
// 				// if (technical) {
// 				//   number_smartmeter_customers =
// 				//     customer.no_of_smart_meter_customers /
// 				//     (customer.no_of_customers_served * 1000);
// 				// }
// 				// console.log("network_disaggregation ", network_disaggregation);
// 				//   console.log({ year: utility.data_year, service_coverage });
// 				results.push({
// 					utility_id: technical.utility_id,
// 					label: technical.utility_code,
// 					year: technical.data_year,
// 					calc_result,
// 					color: 'rgba(255, 99, 132, 0.5)',
// 					// color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
// 					//   Math.floor(Math.random() * 2)
// 					// ],
// 				});
// 			});
// 		// console.log(graphicalData);
// 		// console.log(selectedUtilities);
// 		let graphData = handleGraphValues(results, userPermission, userUtility);
// 		// console.log(graphData);
// 		graphData.push(graphData.reduce(avgReducer, 0));
// 		// let labels = graphicalData.map((data: any) => data.label);
// 		let labels = results.map((data: any) => {
// 			//filter based on kpi permissions and utility id
// 			if (userPermission !== 'FULL') {
// 				if (utility_id === userUtility) {
// 					return data.label;
// 				} else {
// 					return '';
// 				}
// 			} else {
// 				return data.label;
// 			}
// 		});
// 		labels.push('Avg');
// 		console.log('graph data', graphData);
// 		// let labels: any = [];
// 		// let graphData: any = [];
// 		return { labels, results: graphData };
// 	} else {
// 		const yearRange = fillRange(Number(year_start), Number(year_end), +1);

// 		//   console.log(yearRange);
// 		//Retreive info from utility_general_data - find records matching utility and years
// 		const filteredCustomer = technicalData.filter(
// 			(utility: any) =>
// 				utility.data_year >= Number(year_start) &&
// 				utility.data_year <= Number(year_end),
// 		);
// 		// const filteredTechnical = technicalData.filter(
// 		//   (utility: any) =>
// 		//     utility.datayear >= Number(year_start) &&
// 		//     utility.datayear <= Number(year_end)
// 		// );
// 		//   console.log(selectedUtility);

// 		//Calculate service coverage = population with service/total population

// 		var results: any = [];

// 		yearRange.map((singleYear: any) => {
// 			// const technical = filteredTechnical.find(
// 			//   (utility: any) =>
// 			//     utility.datayear === singleYear && utility.utility_id === utility_id
// 			// );

// 			const customer = filteredCustomer.find(
// 				(utility: any) =>
// 					utility.data_year === singleYear && utility.utility_id === utility_id,
// 			);

// 			// const energy_gen = technical.net_energy_generated;

// 			var number_smartmeter_customers = 0;
// 			if (customer) {
// 				console.log(customer);
// 				number_smartmeter_customers =
// 					customer.no_of_complaints / (customer.no_of_customers_served * 1);
// 			}
// 			console.log('number_smartmeter_customers', number_smartmeter_customers);
// 			//   console.log({ year: utility.data_year, service_coverage });

// 			// results.push({
// 			//   year: singleYear,
// 			//   average_energy: averageEnergyCost,
// 			// });
// 			results.push(number_smartmeter_customers);

// 			// results.push({ year: singleYear, service_coverage });
// 		});

// 		return { labels: yearRange, results };
// 	}
// };

export const calculateCommercializationCost = (
  year_start: string,
  year_end: string,
  utility_id: any,
  customerData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const customer = customerData.find(
          (customer: any) =>
            customer.data_year === Number(year_start) &&
            customer.utility_id === utility.utility_id,
        );

        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (customer && financial) {
          calc_result =
            Number(financial.commercialization_costs) /
            Number(customer.no_of_customers_served);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var commercialization_cost = 0;
      if (customer && financial) {
        commercialization_cost =
          Number(financial.commercialization_costs) /
          Number(customer.no_of_customers_served);
      }
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(commercialization_cost);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateEBITDA = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.earnings) /
              Number(financial.operational_revenue)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      var ebitda = 0;
      if (financial) {
        ebitda =
          (Number(financial.earnings) / Number(financial.operational_revenue)) *
          100;
      }
      console.log(ebitda);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(ebitda);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateReturnOnAssets = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        //  console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.before_tax_net_income) /
              Number(financial.non_current_assets_value)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var return_on_assets = 0;
      if (financial) {
        return_on_assets =
          (Number(financial.before_tax_net_income) /
            Number(financial.non_current_assets_value)) *
          100;
      }
      console.log(return_on_assets);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(return_on_assets);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateReturnOnEquity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        //  console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.before_tax_net_income) /
              Number(financial.shareholder_equity)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var return_on_equity = 0;
      if (financial) {
        return_on_equity =
          (Number(financial.before_tax_net_income) /
            Number(financial.shareholder_equity)) *
          100;
      }
      console.log(return_on_equity);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(return_on_equity);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateShareOfUndergroundLine = (
  utilityData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return utilityData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .map((data: any) => {
      console.log(data);
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };

      data.fields.forEach((element: any) => {
        const calc_result = element.percentage_underground;

        tableColumns[
          element.voltage_category.split(' ').join('_').toLowerCase()
        ] = calc_result?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        });
      });

      return tableColumns;
    });
};

export const calculateCostByBusinessArea = (
  utilityData: any,
  selectedUtility: any,
  selectedStartYear: any,
  userPermission: any,
  userUtility: any,
) => {
  return utilityData
    .filter(
      (data: any) =>
        selectedUtility.map((id: any) => id.value).includes(data.utility_id) &&
        data.data_year === Number(selectedStartYear),
    )
    .sort(() =>
      userPermission === 'UTIL' || userPermission === 'ANON'
        ? Math.random() - 0.5
        : 0,
    )
    .map((data: any) => {
      const tableColumns: any = {
        utility_code:
          userPermission === 'FULL'
            ? data.utility_code
            : userPermission === 'UTIL' && userUtility.includes(data.utility_id)
            ? data.utility_code
            : '',
        data_year: data.data_year,
      };
      return {
        ...tableColumns,
        // capital_cost: data.capital_cost,
        // total_cost: data.annual_utility_costs,
        percent_generation: (
          (Number(data.generation_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
        percent_td: (
          (Number(data.t_d_cost) / Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
        percent_commercialization: (
          (Number(data.commercialization_costs) /
            Number(data.annual_utility_costs)) *
          100
        ).toFixed(2),
      };
    });
};

export const calculateOperatingRatio = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        //  console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            ((Number(financial.opex_cost) + Number(financial.depreciation)) /
              Number(financial.operational_revenue)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (financial) {
        calc_result =
          ((Number(financial.opex_cost) + Number(financial.depreciation)) /
            Number(financial.operational_revenue)) *
          100;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateSystemLoadFactor = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            (Number(technical.net_energy_input) * 1000 * 100) /
            (Number(technical.annual_system_peak_load) * 8760);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical) {
        calc_result =
          (Number(technical.net_energy_input) * 1000 * 100) /
          (Number(technical.annual_system_peak_load) * 8760);
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateReserveMargin = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            ((Number(technical.system_installed_capacity) -
              Number(technical.annual_system_peak_load)) /
              Number(technical.annual_system_peak_load)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechnology = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technology = filteredTechnology.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical) {
        calc_result =
          ((Number(technical.system_installed_capacity) -
            Number(technical.annual_system_peak_load)) /
            Number(technical.annual_system_peak_load)) *
          100;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateSystemEquiv = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const technology = technologyData.find(
          (technology: any) =>
            technology.data_year >= Number(year_start) &&
            technology.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical && technology) {
          calc_result =
            (Number(technical.available_energy_from_installs) /
              Number(technical.system_installed_capacity) /
              8760) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechnology = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technology = filteredTechnology.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // TODO: Sum installed capacity for utility and year?
      var calc_result = 0;
      if (technical && technology) {
        calc_result =
          (Number(technical.available_energy_from_installs) /
            Number(technical.system_installed_capacity) /
            8760) *
          100;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateGenerationEfficiency = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        // const restorationPeriod = restorationData.filter(
        //   (financial: any) =>
        //     financial.data_year === Number(year_start) &&
        //     financial.utility_id === utility.utility_id
        // );

        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(
        //   utility.code,
        //   technical?.own_thermal_energy_generated,
        //   technical?.energy_from_own_re_sources,
        //   technical?.net_energy_generated
        // );

        var calc_result = 0;
        if (technical) {
          const total_energy_generated =
            Number(technical.own_thermal_energy_generated) +
            Number(technical.energy_from_own_re_sources);
          calc_result =
            ((total_energy_generated - Number(technical.net_energy_generated)) /
              total_energy_generated) *
            100;
          console.log(total_energy_generated, calc_result);
          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredTechnology = technologyData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const technology = filteredTechnology.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical) {
        const total_energy_generated =
          Number(technical.own_thermal_energy_generated) +
          Number(technical.energy_from_own_re_sources);
        calc_result =
          ((total_energy_generated - Number(technical.net_energy_generated)) /
            total_energy_generated) *
          100;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateGenerationOM = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(
        //   utility.code,
        //   technical?.own_thermal_energy_generated,
        //   technical?.energy_from_own_re_sources,
        //   technical?.net_energy_generated
        // );

        var calc_result = 0;
        if (technical && financial) {
          const total_energy_generated =
            Number(technical.own_thermal_energy_generated) +
            Number(technical.energy_from_own_re_sources);
          calc_result =
            Number(financial.total_generation_o_m_cost) /
            Number(total_energy_generated) /
            1000;
          console.log(total_energy_generated, calc_result);
          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical && financial) {
        const total_energy_generated =
          Number(technical.own_thermal_energy_generated) +
          Number(technical.energy_from_own_re_sources);
        calc_result =
          Number(financial.total_generation_o_m_cost) /
          Number(total_energy_generated) /
          1000;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateLubeOilConsumption = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        // const technology = technologyData.find(
        //   (technology: any) =>
        //     technology.data_year >= Number(year_start) &&
        //     technology.utility_id === utility.utility_id
        // );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            Number(technical.total_lube_oil_consumed) /
            Number(technical.own_thermal_energy_generated);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical) {
        calc_result =
          Number(technical.total_lube_oil_consumed) /
          Number(technical.own_thermal_energy_generated);
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateAverageGenerationCost = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );

        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical && financial) {
          const total_energy_generated =
            Number(technical.own_thermal_energy_generated) +
            Number(technical.energy_from_own_re_sources) +
            Number(technical.energy_purchased);
          calc_result =
            Number(financial.generation_cost) /
            Number(total_energy_generated) /
            1000;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical && financial) {
        const total_energy_generated =
          Number(technical.own_thermal_energy_generated) +
          Number(technical.energy_from_own_re_sources) +
          Number(technical.energy_purchased);
        calc_result =
          Number(financial.generation_cost) /
          Number(total_energy_generated) /
          1000;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

// export const calculateThermalGenerationCost = (
//   year_start: string,
//   year_end: string,
//   utility_id: string,
//   technicalData: any,
//   userPermission: string,
//   userUtility: string[],
//   utilities: any
// ) => {
//   console.log(utility_id, "*******************************");
//   if (utility_id === "all") {
//     //TODO: Loop through all utilities FIRST
//     var results: any = [];
//     // var graphData: any = [];
//     // console.log(utilities);
//     utilities.map((utility: any) => {
//       const technical = technicalData.find(
//         (financial: any) =>
//           financial.data_year === Number(year_start) &&
//           financial.utility_id === utility.utility_id
//       );

//       // const technology = technologyData.find(
//       //   (technology: any) =>
//       //     technology.data_year >= Number(year_start) &&
//       //     technology.utility_id === utility.utility_id
//       // );
//       // console.log(utility_id, year_start, technical, technicalData);
//       var calc_result = 0;
//       if (technical) {
//         calc_result =
//           (Number(technical.net_energy_input) * 1000 * 100) /
//           (Number(technical.annual_system_peak_load) * 8760);

//         // console.log(financial, technicalData, energySystemLosses);
//         //   console.log({ year: utility.data_year, service_coverage });
//         results.push({
//           utility_id: utility.utility_id,
//           label: utility.code,
//           year: year_start,
//           calc_result,
//           color: "rgba(255, 99, 132, 0.5)",
//           // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
//           //   Math.floor(Math.random() * 2)
//           // ],
//         });
//       } else {
//         results.push({
//           utility_id: utility.utility_id,
//           label: utility.code,
//           year: year_start,
//           calc_result: 0,
//           color: "rgba(255, 99, 132, 0.5)",
//           // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
//           //   Math.floor(Math.random() * 2)
//           // ],
//         });
//       }
//     });
//     let { graphData, labels } = sortGraphData(
//       results,
//       userPermission,
//       userUtility
//     );
//     // Add average data and label
//     graphData.push(graphData.reduce(avgReducer, 0));
//     labels.push("Avg");
//     console.log(labels, "graph data", graphData);
//     return { labels, results: graphData };
//   } else {
//     const yearRange = fillRange(Number(year_start), Number(year_end), +1);

//     //   console.log(yearRange);
//     //Retreive info from utility_general_data - find records matching utility and years
//     // const filteredCustomer = customerData.filter(
//     //   (utility: any) =>
//     //     utility.data_year >= Number(year_start) &&
//     //     utility.data_year <= Number(year_end)
//     // );
//     const filteredTechnical = technicalData.filter(
//       (utility: any) =>
//         utility.data_year >= Number(year_start) &&
//         utility.data_year <= Number(year_end)
//     );
//     //   console.log(selectedUtility);
//     // console.log(filteredCustomer, filteredFinancial);
//     //Calculate service coverage = population with service/total population

//     var results: any = [];

//     yearRange.map((singleYear: any) => {
//       const technical = filteredTechnical.find(
//         (utility: any) =>
//           utility.data_year === singleYear && utility.utility_id === utility_id
//       );

//       // const customer = filteredCustomer.find(
//       //   (utility: any) =>
//       //     utility.data_year === singleYear && utility.utility_id === utility_id
//       // );

//       // const energy_gen = technical.net_energy_generated;
//       // console.log(financial, customer);
//       var calc_result = 0;
//       if (technical) {
//         calc_result =
//           (Number(technical.net_energy_input) * 1000 * 100) /
//           (Number(technical.annual_system_peak_load) * 8760);
//       }
//       console.log(calc_result);
//       // console.log("number_smartmeter_customers", number_smartmeter_customers);
//       //   console.log({ year: utility.data_year, service_coverage });

//       // results.push({
//       //   year: singleYear,
//       //   average_energy: averageEnergyCost,
//       // });
//       results.push(calc_result);

//       // results.push({ year: singleYear, service_coverage });
//     });

//     return { labels: yearRange, results };
//   }
// };

export const calculateGenerationProductivity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technicalData: any,
  generalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const general = generalData.find(
          (general: any) =>
            general.data_year >= Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical && general) {
          const total_energy_generated =
            Number(technical.own_thermal_energy_generated) +
            Number(technical.energy_from_own_re_sources);
          calc_result =
            (Number(general.fte_generation_staff) /
              Number(total_energy_generated)) *
            10;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const general = filteredGeneral.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (technical && general) {
        const total_energy_generated =
          Number(technical.own_thermal_energy_generated) +
          Number(technical.energy_from_own_re_sources);
        calc_result =
          (Number(general.fte_generation_staff) /
            Number(total_energy_generated)) *
          10;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateDebtLevel = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        //  console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.totaldebt) /
              Number(financial.non_current_assets_value)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var calc_result = 0;
      if (financial) {
        calc_result =
          (Number(financial.totaldebt) /
            Number(financial.non_current_assets_value)) *
          100;
      }
      console.log(calc_result);
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(calc_result);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateBadDebt = (
  year_start: string,
  year_end: string,
  utility_id: any,
  // customerData: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        // const customer = customerData.find(
        //   (customer: any) =>
        //     customer.data_year === Number(year_start) &&
        //     customer.utility_id === utility.utility_id
        // );

        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.bad_debt) /
              Number(financial.operational_revenue)) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    // const filteredCustomer = customerData.filter(
    //   (utility: any) =>
    //     utility.data_year >= Number(year_start) &&
    //     utility.data_year <= Number(year_end)
    // );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const customer = filteredCustomer.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      var bad_debt = 0;
      if (financial) {
        bad_debt =
          (Number(financial.bad_debt) / Number(financial.operational_revenue)) *
          100;
      }
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(bad_debt);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateCommercialProductivity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  generalData: any,
  customerData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const customer = customerData.find(
          (customer: any) =>
            customer.data_year === Number(year_start) &&
            customer.utility_id === utility.utility_id,
        );

        const general = generalData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (general && customer) {
          calc_result =
            (Number(general.fte_commercial_staff) /
              Number(customer.no_of_customers_served)) *
            1000;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);
    // console.log(filteredCustomer, filteredFinancial);
    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const general = filteredGeneral.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const energy_gen = technical.net_energy_generated;
      // console.log(financial, customer);
      let productivity = 0;
      if (customer && general) {
        productivity =
          (Number(general.fte_commercial_staff) /
            Number(customer.no_of_customers_served)) *
          1000;
      }
      // console.log("number_smartmeter_customers", number_smartmeter_customers);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(productivity);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateCAIDI = (
  year_start: string,
  year_end: string,
  utility_id: any,
  restorationData: any,
  customerData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const restorationPeriod = restorationData.filter(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const customer = customerData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var SAIDI = 0;
        var SAIFI = 0;
        var calc_result = 0;
        // console.log("calculate saidi");
        if (restorationPeriod && customer) {
          console.log(restorationPeriod, customer);
          //where restoration period is greater than 5
          // const sumProduct = restorationPeriod
          //  .filter((value: any) => value.restoration_period > 5)
          //  .map((value: any) => value.restoration_period * value.customers_restored)
          //  .reduce((prev: any, curr: any) => prev + curr, 0);
          // console.log(sumProduct);
          SAIDI =
            restorationPeriod[0]?.restoration_period /
            60 /
            Number(customer.no_of_customers_served);

          // const sumRestored = restorationPeriod
          //  .filter((value: any) => value.restoration_period > 5)
          //  .map((value: any) => value.customers_restored)
          //  .reduce((prev: any, curr: any) => prev + curr, 0);
          // console.log(sumProduct);
          SAIFI =
            restorationPeriod[0]?.customers_restored /
            Number(customer.no_of_customers_served);

          calc_result = SAIDI / SAIFI;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredRestoration = restorationData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const restorationPeriod = filteredRestoration.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var SAIDI = 0;
      var SAIFI = 0;
      var CAIDI = 0;
      if (restorationPeriod && customer) {
        console.log(restorationPeriod, customer);
        //where restoration period is greater than 5
        // const sumProduct = restorationPeriod
        //  .filter((value: any) => value.restoration_period > 5)
        //  .map((value: any) => value.restoration_period * value.customers_restored)
        //  .reduce((prev: any, curr: any) => prev + curr, 0);
        // console.log(sumProduct);
        SAIDI =
          restorationPeriod[0]?.restoration_period /
          60 /
          Number(customer.no_of_customers_served);

        // const sumRestored = restorationPeriod
        //  .filter((value: any) => value.restoration_period > 5)
        //  .map((value: any) => value.customers_restored)
        //  .reduce((prev: any, curr: any) => prev + curr, 0);
        // console.log(sumProduct);
        SAIFI =
          restorationPeriod[0]?.customers_restored /
          Number(customer.no_of_customers_served);

        CAIDI = SAIDI / SAIFI;
        console.log(SAIDI, SAIFI, CAIDI);
      }
      results.push(CAIDI);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateAvgEnergyCost = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  console.log(utility_id, '*******************************');
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (financial && technical) {
          calc_result =
            Number(financial.annual_utility_costs) /
            (Number(technical.gross_energy_input) * 1000);

          console.log(financial, technicalData, calc_result);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    // console.log(graphicalData);
    // console.log(selectedUtilities);

    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }

    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var averageEnergyCost = 0;
      if (financial && technical) {
        averageEnergyCost =
          Number(financial.annual_utility_costs) /
          (Number(technical.gross_energy_input) * 1000);
      }
      console.log('average energy cost', averageEnergyCost);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(averageEnergyCost);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateEnergySystemLosses = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const technical = technicalData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (financial && technical) {
          calc_result =
            ((Number(technical.net_energy_input) -
              Number(financial.energy_sold)) *
              100) /
            Number(technical.net_energy_input);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    // graphData = results.map((data: any) => data.energySystemLosses);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var energySystemLosses = 0;
      if (financial && technical) {
        energySystemLosses =
          ((Number(technical.net_energy_input) -
            Number(financial.energy_sold)) *
            100) /
          Number(technical.net_energy_input);

        console.log(
          technical.net_energy_input,
          financial.energy_sold,
          energySystemLosses,
        );
      }
      // console.log("average energy cost", averageEnergyCost);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(energySystemLosses);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateOperationalProfitMargin = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // // var graphData: any = [];
    console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (technical: any) =>
            technical.data_year === Number(year_start) &&
            technical.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (financial) {
          calc_result =
            (Number(financial.before_tax_net_income) /
              Number(financial.operational_revenue)) *
            100;

          //  console.log(utilityData, service_coverage);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });

    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }

    // console.log(labels, "graph data", graphData);
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   const filteredTechnical = technicalData.filter(
    //     (utility: any) =>
    //       utility.data_year >= Number(year_start) &&
    //       utility.data_year <= Number(year_end)
    //   );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const technical = filteredTechnical.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );
      var operationalProfitMargin = 0;
      if (financial) {
        operationalProfitMargin =
          (Number(financial.before_tax_net_income) /
            Number(financial.operational_revenue)) *
          100;
      }
      // console.log("average energy cost", averageEnergyCost);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(operationalProfitMargin);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateLabourProductivity = (
  year_start: string,
  year_end: string,
  utility_id: any,
  financialData: any,
  generalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const general = generalData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (financial && general) {
          calc_result =
            Number(general.fte_staff) / (Number(financial.energy_sold) / 10);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const general = filteredGeneral.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var operationalProfitMargin = 0;
      var labourProductivity = 0;
      if (general && financial) {
        // console.log(
        //   Number(general.fte_staff) / (Number(financial.energy_sold) / 10)
        // );
        labourProductivity =
          Number(general.fte_staff) / (Number(financial.energy_sold) / 10);
      }
      // console.log("average energy cost", averageEnergyCost);
      //   console.log({ year: utility.data_year, service_coverage });

      // results.push({
      //   year: singleYear,
      //   average_energy: averageEnergyCost,
      // });
      results.push(labourProductivity);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateClimateResilience = (
  year_start: string,
  year_end: string,
  utility_id: any,
  climateData: any,
  utilities: any,
  userPermission: string,
  userUtility: string[],
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        // const restorationPeriod = restorationData.filter(
        //   (financial: any) =>
        //     financial.data_year === Number(year_start) &&
        //     financial.utility_id === utility.utility_id
        // );
        const climateresildata = climateData.filter(
          (data: any) =>
            data.data_year === Number(year_start) &&
            data.utility_id === utility.utility_id,
        );
        var calc_result = 0;
        // console.log("calculate saidi");
        if (climateresildata) {
          // console.log(restorationPeriod, customer);
          console.log(climateresildata);
          //where restoration period is greater than 5
          const sumClimateScore = climateresildata
            .map((value: any) => value.climate_score)
            .reduce((prev: any, curr: any) => prev + curr, 0);
          // console.log(sumProduct);
          calc_result = sumClimateScore;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    const filteredClimateResil = climateData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const climateresildata = filteredClimateResil.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var calcResults = 0;
      // console.log("calculate saidi");
      if (climateresildata) {
        // console.log(restorationPeriod, customer);
        console.log(climateresildata);
        //where restoration period is greater than 5
        const sumClimateScore = climateresildata
          .map((value: any) => value.climate_score)
          .reduce((prev: any, curr: any) => prev + curr, 0);
        // console.log(sumProduct);
        calcResults = sumClimateScore;
        // SAIDI =
        //   (Number(restorationPeriod.restoration_period) *
        //     Number(restorationPeriod.customers_restored)) /
        //   Number(customer.no_of_customers_served);
      }
      results.push(calcResults);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateSAIDI = (
  year_start: string,
  year_end: string,
  utility_id: any,
  restorationData: any,
  customerData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const restorationPeriod = restorationData.filter(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const customer = customerData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        // console.log("calculate saidi");
        if (restorationPeriod && customer) {
          console.log(restorationPeriod, customer);
          //where restoration period is greater than 5
          // const sumProduct = restorationPeriod
          //  .filter((value: any) => value.restoration_period > 5)
          //  .map((value: any) => value.restoration_period * value.customers_restored)
          //  .reduce((prev: any, curr: any) => prev + curr, 0);
          // console.log(sumProduct);
          calc_result =
            restorationPeriod[0]?.restoration_period /
            60 /
            Number(customer.no_of_customers_served);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredRestoration = restorationData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const restorationPeriod = filteredRestoration.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var SAIDI = 0;
      // console.log("calculate saidi");
      if (restorationPeriod && customer) {
        // console.log(
        //  restorationPeriod.restoration_period,
        //  customer.no_of_customers_served
        // );
        //where restoration period is greater than 5
        // const sumProduct = restorationPeriod
        //  .filter((value: any) => value.restoration_period > 5)
        //  .map((value: any) => value.restoration_period * value.customers_restored)
        //  .reduce((prev: any, curr: any) => prev + curr, 0);
        // console.log(sumProduct);
        SAIDI =
          restorationPeriod[0]?.restoration_period /
          60 /
          Number(customer.no_of_customers_served);
        // SAIDI =
        //   (Number(restorationPeriod.restoration_period) *
        //     Number(restorationPeriod.customers_restored)) /
        //   Number(customer.no_of_customers_served);
      }
      console.log('saidi', SAIDI);
      results.push(SAIDI);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateSAIFI = (
  year_start: string,
  year_end: string,
  utility_id: any,
  restorationData: any,
  customerData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const restorationPeriod = restorationData.filter(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );

        const customer = customerData.find(
          (general: any) =>
            general.data_year === Number(year_start) &&
            general.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial, technical);

        var calc_result = 0;
        if (restorationPeriod && customer) {
          console.log(restorationPeriod);
          // const sumRestored = restorationPeriod
          //  .filter((value: any) => value.restoration_period > 5)
          //  .map((value: any) => value.customers_restored)
          //  .reduce((prev: any, curr: any) => prev + curr, 0);
          // // console.log(sumProduct);
          // calc_result = sumRestored / Number(customer.no_of_customers_served);
          calc_result =
            restorationPeriod[0]?.customers_restored /
            Number(customer.no_of_customers_served);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredRestoration = restorationData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredCustomer = customerData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const restorationPeriod = filteredRestoration.filter(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const customer = filteredCustomer.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var SAIFI = 0;
      if (restorationPeriod && customer) {
        console.log(restorationPeriod, customer);
        // const sumRestored = restorationPeriod
        //  .filter((value: any) => value.restoration_period > 5)
        //  .map((value: any) => value.customers_restored)
        //  .reduce((prev: any, curr: any) => prev + curr, 0);
        // console.log(sumProduct);
        SAIFI =
          restorationPeriod[0]?.customers_restored /
          Number(customer.no_of_customers_served);
      }
      results.push(SAIFI);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateEnergyByRES = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technicalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const technical = technicalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        console.log(utility_id, year_start, technical, technicalData);
        var calc_result = 0;
        if (technical) {
          calc_result =
            (Number(technical.total_re_delivered) /
              (Number(technical.energy_purchased) +
                Number(technical.own_thermal_energy_generated) +
                Number(technical.energy_from_own_re_sources))) *
            100;

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechnology = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technology = filteredTechnology.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );
      var re_energy_delivered = 0;
      if (technology && technical) {
        re_energy_delivered =
          (Number(technical.total_re_delivered) /
            (Number(technical.energy_purchased) +
              Number(technical.own_thermal_energy_generated) +
              Number(technical.energy_from_own_re_sources))) *
          100;
      }
      results.push(re_energy_delivered);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateCO2EquivEmissions = (
  year_start: string,
  year_end: string,
  utility_id: any,
  technologyData: any,
  technicalData: any,
  financialData: any,
  technologies: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const unionTechnologyData = technologyData
          .filter(
            (techData: any) =>
              techData.data_year === Number(year_start) &&
              techData.utility_id === utility.utility_id,
          )
          .map((techData: any) => {
            const technology = technologies.find(
              (tech: any) => tech?.tech_id === techData?.tech_id,
            );
            return {
              ...techData,
              emissions_factor: technology?.emissions_factor,
            };
          });

        const financial = financialData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial.energy_sold, unionTechnologyData);

        var calc_result = 0;
        if (unionTechnologyData && financial) {
          console.log(utility.code, financial.energy_sold);

          console.log(unionTechnologyData);

          const sumCO2 = unionTechnologyData
            .map(
              (value: any) => value.generated_energy * value.emissions_factor,
            )
            .reduce((prev: any, curr: any) => prev + curr, 0);
          // console.log(utility.utility_id, sumCO2);
          calc_result = sumCO2 / Number(financial.energy_sold);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredTechnology = technologyData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    const filteredFinancial = financialData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    const filteredTechnical = technicalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );
    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const technologyData = filteredTechnology
        .filter(
          (utility: any) =>
            utility.data_year === singleYear &&
            utility.utility_id === utility_id[0].value,
        )
        .map((techData: any) => {
          const technology = technologies.find(
            (tech: any) => tech?.tech_id === techData?.tech_id,
          );
          return {
            ...techData,
            emissions_factor: technology?.emissions_factor,
          };
        });

      const financial = filteredFinancial.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      const technical = filteredTechnical.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      var co2EquivalentEmissions = 0;
      if (technologyData && financial) {
        const sumCO2 = technologyData
          .map((value: any) => value.generated_energy * value.emissions_factor)
          .reduce((prev: any, curr: any) => prev + curr, 0);
        console.log(sumCO2);
        co2EquivalentEmissions = sumCO2 / Number(financial.energy_sold);
      }

      results.push(co2EquivalentEmissions);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateInjuryRate = (
  year_start: string,
  year_end: string,
  utility_id: any,
  generalData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        const general = generalData.find(
          (financial: any) =>
            financial.data_year === Number(year_start) &&
            financial.utility_id === utility.utility_id,
        );
        // console.log(utility.code, financial.energy_sold, unionTechnologyData);

        var calc_result = 0;
        if (general) {
          calc_result =
            Number(general.lost_employee_working_hours) /
            (Number(general.total_employee_working_hours) / 200000);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    //   console.log(yearRange);
    //Retreive info from utility_general_data - find records matching utility and years
    const filteredGeneral = generalData.filter(
      (utility: any) =>
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    //   console.log(selectedUtility);

    //Calculate service coverage = population with service/total population

    var results: any = [];

    yearRange.map((singleYear: any) => {
      const general = filteredGeneral.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // console.log(technologyData, financial, technical, technology);
      var injurySeverityRate = 0;
      if (general) {
        injurySeverityRate =
          Number(general.lost_employee_working_hours) /
          (Number(general.total_employee_working_hours) / 200000);
      }

      results.push(injurySeverityRate);

      // results.push({ year: singleYear, service_coverage });
    });

    return { labels: yearRange, results };
  }
};

export const calculateMonthlyCustomer = (
  year_start: string,
  year_end: string,
  utility_id: any,
  usage_category_id: string,
  usage_category_kwh: string,
  usageCategoryData: any,
  userPermission: string,
  userUtility: string[],
  utilities: any,
) => {
  if (utility_id?.length > 1) {
    //TODO: Loop through all utilities FIRST
    var results: any = [];
    // var graphData: any = [];
    // console.log(utilities);
    utilities
      .filter((utility: any) =>
        utility_id.map((id: any) => id.value).includes(utility.utility_id),
      )
      .map((utility: any) => {
        // console.log(utility);
        // const technical = technicalData.find(
        //   (financial: any) =>
        //     financial.data_year === Number(year_start) &&
        //     financial.utility_id === utility.utility_id
        // );
        // console.log(utility_id, year_start, technical, technicalData);

        const usage_cat = usageCategoryData.find(
          (record: any) =>
            record.utility_id === utility.utility_id &&
            record.usage_category_id === usage_category_id &&
            record.data_year === Number(year_start),
        );
        console.log(utility.code, utility.utility_id, usage_cat);
        // var re_energy_delivered = 0;
        let calc_result = 0;
        if (usage_cat) {
          calc_result =
            Number(usage_category_kwh) *
              (Number(usage_cat.base_rate) +
                Number(usage_cat.fuel_surcharge) +
                Number(usage_cat.vat) +
                Number(usage_cat.other_levies_taxes) +
                Number(usage_cat.other_variable_charges)) +
            Number(usage_cat.fixed_charges);

          // console.log(financial, technicalData, energySystemLosses);
          //   console.log({ year: utility.data_year, service_coverage });
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        } else {
          results.push({
            utility_id: utility.utility_id,
            label: utility.code,
            year: year_start,
            calc_result: 0,
            color: 'rgba(255, 99, 132, 0.5)',
            // color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
            //   Math.floor(Math.random() * 2)
            // ],
          });
        }
      });
    // graphData = results.map((data: any) => data.calculatedRate);
    let { graphData, labels } = sortGraphData(
      results,
      userPermission,
      userUtility,
    );
    if (utility_id.length === utilities.length) {
      // Add average data and label
      graphData.push(graphData.reduce(avgReducer, 0));
      labels.push('Avg');
      console.log(labels, 'graph data', graphData);
    }
    return { labels, results: graphData };
  } else {
    const results: any = [];
    const yearRange = fillRange(Number(year_start), Number(year_end), +1);

    const filteredData = usageCategoryData.filter(
      (utility: any) =>
        utility.utility_id === utility_id[0].value &&
        utility.usage_category_id === usage_category_id &&
        utility.data_year >= Number(year_start) &&
        utility.data_year <= Number(year_end),
    );

    yearRange.map((singleYear: any) => {
      // const technologyData = filteredTechnology.find(
      //   (utility: any) =>
      //     utility.data_year === singleYear && utility.utility_id === utility_id
      // );

      const usage_cat = filteredData.find(
        (utility: any) =>
          utility.data_year === singleYear &&
          utility.utility_id === utility_id[0].value,
      );

      // const usage_category_id = "04bfaf9d-fe92-40e3-b560-c4854e591e5b";

      // console.log(filteredData);
      let calculatedRate = 0;
      if (usage_cat) {
        // console.log(
        //   Number(usage_category_kwh),
        //   (Number(usage_cat.base_rate),
        //   Number(usage_cat.fuel_surcharge),
        //   Number(usage_cat.vat),
        //   Number(usage_cat.other_levies_taxes),
        //   Number(usage_cat.other_variable_charges)),
        //   Number(usage_cat.fixed_charges)
        // );
        calculatedRate =
          Number(usage_category_kwh) *
            (Number(usage_cat.base_rate) +
              Number(usage_cat.fuel_surcharge) +
              Number(usage_cat.vat) +
              Number(usage_cat.other_levies_taxes) +
              Number(usage_cat.other_variable_charges)) +
          Number(usage_cat.fixed_charges);
      }

      results.push(calculatedRate);
    });

    return { labels: yearRange, results };
  }

  // console.log(results);
};

export const calculateDisaggregatedTariffs = (
  tableData: any,
  selectedUtility: any,
  selectedCategory: any,
  selectedStartYear: any,
  userPermission: any,
  usageCategories: any,
  userUtility: any,
) => {
  return (
    tableData
      // .filter((data: any) => {
      // 	console.log(data, selectedUtility, selectedCategory, usageCategories);
      // 	if (selectedUtility.toLowerCase() === 'all')
      // 		return (
      // 			data.data_year === Number(selectedStartYear) &&
      // 			data.usage_category_id === selectedCategory
      // 		);
      // 	else
      // 		return (
      // 			data.utility_id === selectedUtility &&
      // 			data.usage_category_id === selectedCategory &&
      // 			data.data_year === Number(selectedStartYear)
      // 		);
      // })
      .filter(
        (data: any) =>
          selectedUtility
            .map((id: any) => id.value)
            .includes(data.utility_id) &&
          data.data_year === Number(selectedStartYear) &&
          data.usage_category_id === selectedCategory,
      )
      .sort(() =>
        userPermission === 'UTIL' || userPermission === 'ANON'
          ? Math.random() - 0.5
          : 0,
      )
      .map((data: any) => {
        console.log(data);

        const tableColumns: any = {
          utility_code:
            userPermission === 'FULL'
              ? data.utility_code
              : userPermission === 'UTIL' &&
                userUtility.includes(data.utility_id)
              ? data.utility_code
              : '',
          data_year: data.data_year,
        };

        const usageCategory = usageCategories.find(
          (item: any) => item.usage_category_id === selectedCategory,
        );
        let charge =
          Number(usageCategory.kwh) *
            (Number(data.base_rate) +
              Number(data.fuel_surcharge) +
              Number(data.vat) +
              Number(data.other_levies_taxes) +
              Number(data.other_variable_charges)) +
          Number(data.fixed_charges);

        let unitCharge = charge / Number(usageCategory.kwh);
        return {
          ...tableColumns,
          base_rate: Number(data.base_rate).toFixed(2),
          other_levies_taxes: Number(data.other_levies_taxes).toFixed(2),
          fuel_surcharge: Number(data.fuel_surcharge).toFixed(2),
          vat: Number(data.vat).toFixed(2),
          other_variable_charges: Number(data.other_variable_charges).toFixed(
            2,
          ),
          fixed_charges: Number(data.fixed_charges).toFixed(2),
          charge: Number(charge).toFixed(2),
          unitCharge: Number(unitCharge).toFixed(2),
        };
      })
  );
};

// export const calculateTariffs = (
// 	year_start: string,
// 	year_end: string,
// 	utility_id: string,
// 	usage_category_id: string,
// 	usage_category_kwh: string,
// 	usageCategoryData: any,
// 	userPermission: string,
// 	userUtility: string[],
// ) => {
// 	if (utility_id === 'all') {
// 		//Service Coverage - select all
// 		console.log('select-all-tariffs');
// 		var results: any = [];
// 		usageCategoryData
// 			.filter((utility: any) => utility.data_year === Number(year_start))
// 			.map((utility: any) => {
// 				console.log(utility);
// 				const calculatedRate =
// 					Number(utility.base_rate) +
// 					Number(utility.fuel_surcharge) +
// 					Number(utility.vat) +
// 					Number(utility.other_levies_taxes) +
// 					Number(utility.other_variable_charges) +
// 					Number(utility.fixed_charges) / Number(usage_category_kwh);
// 				//   console.log({ year: utility.data_year, service_coverage });
// 				console.log(calculatedRate);
// 				results.push({
// 					utility_id: utility.utility_id,
// 					label: utility.utility_code,
// 					year: utility.data_year,
// 					calculatedRate,
// 					color: 'rgba(255, 99, 132, 0.5)',
// 					// color: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)"][
// 					//   Math.floor(Math.random() * 2)
// 					// ],
// 				});
// 			});
// 		// console.log(graphicalData);
// 		// console.log(selectedUtilities);
// 		let graphData = results.map((data: any) => data.calculatedRate);

// 		// console.log(graphData);
// 		graphData.push(graphData.reduce(avgReducer, 0));
// 		// let labels = graphicalData.map((data: any) => data.label);
// 		let labels = results.map((data: any) => {
// 			//filter based on kpi permissions and utility id
// 			if (userPermission !== 'FULL') {
// 				if (utility_id === userUtility) {
// 					return data.label;
// 				} else {
// 					return '';
// 				}
// 			} else {
// 				return data.label;
// 			}
// 		});
// 		labels.push('Avg');
// 		return { labels, results: graphData };
// 	} else {
// 		const results: any = [];

// 		const yearRange = fillRange(Number(year_start), Number(year_end), +1);

// 		const filteredData = usageCategoryData.filter(
// 			(utility: any) =>
// 				utility.utility_id === utility_id &&
// 				utility.usage_category_id === usage_category_id &&
// 				utility.data_year === Number(year_start),
// 		);

// 		// let charge =
// 		//   Number(usage_category_kwh) *
// 		//     (Number(filteredData[0].base_rate) +
// 		//       Number(filteredData[0].fuel_surcharge) +
// 		//       Number(filteredData[0].vat) +
// 		//       Number(filteredData[0].other_levies_taxes) +
// 		//       Number(filteredData[0].other_variable_charges)) +
// 		//   Number(filteredData[0].fixed_charges);

// 		// let unitCharge = charge / Number(usage_category_kwh);

// 		// results.push(calculatedRate);
// 		// const results = { ...filteredData[0], charge, unitCharge };

// 		filteredData.map((data: any) => {
// 			// const technologyData = filteredTechnology.find(
// 			//   (utility: any) =>
// 			//     utility.data_year === singleYear && utility.utility_id === utility_id
// 			// );

// 			// const usage_cat = filteredData.find(
// 			//   (utility: any) =>
// 			//     utility.data_year === singleYear && utility.utility_id === utility_id
// 			// );

// 			// const usage_category_id = "04bfaf9d-fe92-40e3-b560-c4854e591e5b";

// 			// console.log(filteredData);
// 			let charge =
// 				Number(usage_category_kwh) *
// 					(Number(data.base_rate) +
// 						Number(data.fuel_surcharge) +
// 						Number(data.vat) +
// 						Number(data.other_levies_taxes) +
// 						Number(data.other_variable_charges)) +
// 				Number(data.fixed_charges);

// 			let unitCharge = charge / Number(usage_category_kwh);

// 			results.push({ ...data, charge, unitCharge });
// 		});

// 		return { labels: yearRange, results };
// 	}
// };
