import React from "react";
import Table from "../../components/Table";
import TableActions from "components/TableActions";
import Modal from "../../components/Modal";
import { useQuery, useQueryClient } from "react-query";
import KPIForm from "../../components/forms/KPIForm";
import KPIPermissionForm from "../../components/forms/KPIPermissionForm";

import {
  findKPIs,
  findKPICategories,
  addKPI,
  updateKPI,
  // findKPIPermissions,
  // addKPIPermission,
  updateKPIPermission,
} from "services/kpi.service";
import { findRoles } from "services/roles.service";
import PageTitle from "containers/PageTitle";
import toast from "react-hot-toast";

const Technologies = () => {
  let [isModalOpen, setIsOpen] = React.useState(false);
  let [record, setRecord] = React.useState<any>(null);
  let [isPermissionsModalOpen, togglePermissionsModal] = React.useState(false);

  // let [permissionsRecord, setPermissionsRecord] = React.useState<any>(null);

  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: kpis,
  } = useQuery("kpis", () => findKPIs().then((res) => res.data));

  const { data: userroles } = useQuery("userroles", () =>
    findRoles().then((res) => res.data)
  );
  // console.log(kpis);
  // const {
  //   // isLoading,
  //   // error,
  //   data: kpipermissions,
  // } = useQuery(
  //   "kpipermissions",
  //   () => findKPIPermissions().then((res) => res.data),
  //   { enabled: false }
  // );

  const { data: kpicategories } = useQuery("kpicategories", () =>
    findKPICategories().then((res) => res.data)
  );

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  const handleToggleModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    toggleModal();
  };

  const handlePermissionsModal = (data?: any) => {
    if (!data) {
      setRecord(null);
    } else setRecord(data);
    togglePermissionsModal(!isPermissionsModalOpen);
  };

  const handleAddRecord = (data: any) => {
    console.log(data);
    addKPI(data).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record added");
        queryClient.invalidateQueries("kpis");
        handleToggleModal();
      }
    });
  };
  const handleEditRecord = (data: any) => {
    console.log(data, record?.kpi_id);
    const { kpi_category, ...formData } = data;
    updateKPI({ ...formData, kpi_id: record?.kpi_id }).then((res: any) => {
      console.log(res);
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Record added");
        queryClient.invalidateQueries("kpis");
        handleToggleModal();
      }
    });
  };

  const handleAddPermissions = (data: any) => {
    console.log(data);
    const { kpi_category, ...formData } = data;
    // addKPI(formData).then((res) => {
    //   console.log(res);
    //   queryClient.invalidateQueries("kpis");
    //   handlePermissionsModal();
    // });
  };
  const handleEditPermissions = (data: any) => {
    const { kpi_category, ...formData } = data;
    console.log(formData);
    updateKPIPermission(formData).then((res: any) => {
      if (res.error) {
        toast.error(res.data.message);
      } else {
        toast.success("Permissions updated");
        queryClient.invalidateQueries("kpis");
        handlePermissionsModal();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "KPI",
        accessor: "kpi",
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }: any) => (
          <span>{value.substring(0, 50)}...</span>
        ),
      },
      {
        Header: "Category",
        accessor: "kpi_category",
        // Cell: ({ cell: { value } }: any) => (
        //   <span>{value.substring(0, 50)}...</span>
        // ),
      },
      // {
      //   Header: "Roles",
      //   accessor: "kpipermissions",
      //   Cell: ({ cell: { value } }: any) => (
      //     <div className="flex">
      //       {value.map((item: any) => (
      //         <span className="text-xs bg-gray-300 rounded-lg px-2 py-1 mx-1">
      //           {item.role_title}
      //         </span>
      //       ))}
      //     </div>
      //   ),
      // },
      {
        Header: "Formula",
        accessor: "kpi_formula",
        Cell: ({ cell: { value } }: any) => (
          <span>{value.substring(0, 50)}...</span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
        disableResizing: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { row } }: any) => {
          return (
            <div className="flex gap-4">
              <TableActions
                onEdit={() => handleToggleModal(row?.original)}
                onView={() => handlePermissionsModal(row?.original)}
              />
              {/* <button
                type="button"
                onClick={() => handlePermissionsModal(row?.original)}
                className="px-4 py-2 leading-6 text-base rounded-md border border-transparent text-white focus:outline-none bg-blue-500 text-blue-100 hover:text-white focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-flex items-center justify-center items-center font-medium focus:outline-none"
              >
                Modify Permissions
              </button> */}
            </div>
          );
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  if (isLoading) return <div>Loading</div>;

  if (error) return <div>An error occurred...</div>;

  // console.log(technologies);

  return (
    <div className="h-full bg-grey-100">
      <PageTitle title="KPIs with Permissions" onClick={toggleModal} />
      <Table columns={columns} data={kpis} />
      {isModalOpen && (
        <Modal
          modalTitle="Manage KPIs"
          isOpen={isModalOpen}
          closeModal={() => handleToggleModal()}
        >
          <KPIForm
            categories={kpicategories}
            initialValues={record}
            onEdit={handleEditRecord}
            onAdd={handleAddRecord}
          />
        </Modal>
      )}

      {isPermissionsModalOpen && (
        <Modal
          modalTitle="Manage KPI Permissions"
          isOpen={isPermissionsModalOpen}
          closeModal={() => handlePermissionsModal()}
        >
          <KPIPermissionForm
            userroles={userroles}
            // kpi_id={record.id}
            initialValues={record}
            onEdit={handleEditPermissions}
            onAdd={handleAddPermissions}
            toggleModal={() => handlePermissionsModal()}
          />
        </Modal>
      )}
    </div>
  );
};
export default Technologies;
