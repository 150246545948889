// import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
// import { regex } from "_utils";
import { fillRange } from "../../utils";

type Inputs = {
  file_attachment: string;
};

type IFormProps = {
  initialValues?: any;
  onAdd: any;
};

export default function FileUploadForm(props: IFormProps) {
  console.log(props);
  // const { utilities } = props;
  // const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);
  const schema = yup.object().shape({
    file_attachment: yup
      .mixed()
      .test("file", "You need to provide a file", (value) => {
        // return Array.isArray(value) ? value?.length > 0 : true
        return value?.length > 0;
      })
      .test(
        "fileName",
        "The filename is incorrect. See helper text above",
        (value) => {
          // console.log(value, value[0].size, value[0].type)
          console.log("filename", value[0].name);
          return value[0].name === props.initialValues.bm_reportname;
        }
      )
      .test("fileSize", "The file is over 5MB", (value) => {
        // console.log(value, value[0].size, value[0].type)
        return Array.isArray(value) ? value[0]?.size <= 5000000 : true;
      })
      // .test("type", "The file must be PDF", (value) => {
      //     console.log(value)
      //     const file_ext =
      //         value[0]?.name?.substring(
      //             value[0]?.name?.lastIndexOf(".") + 1,
      //             value[0]?.name?.length
      //         ) || value[0]?.name
      //     // console.log(file_ext, file_ext === "pdf")
      //     return Array.isArray(value) ? file_ext?.includes("pdf") : true
      // })
      .test("type", "The file is not a valid PDF", (value) => {
        return Array.isArray(value)
          ? ["application/pdf"].includes(value[0].type)
          : true;
      })
      .required(),
  });
  // const utilities: any[] = props.initialValues[0];
  // const yearFilters: any[] = props.initialValues[1];
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors", data);

      // return;

      props.onAdd({ ...data, id: props.initialValues.bm_report_id });

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <label className="form-label">
        Select File{" "}
        <p className="text-sm text-gray-500">
          The filename must match `{props.initialValues.bm_reportname}`
        </p>
        <input
          type="file"
          accept="application/pdf"
          {...register("file_attachment")}
          className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
          // placeholder="File Attachment"
        />
        <ErrorMessage
          errors={errors}
          name="file_attachment"
          render={({ message }) => (
            <p className="text-sm text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
