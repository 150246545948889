import { Navigate, RouteProps, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";

interface IProps extends RouteProps {}

const AuthWrapper: React.FC<IProps> = () => {
  let { currentUser, loading } = useAuth();
  // Grab the pathname for the current path
  //   const routeName = location.pathname.split("/").pop();
  // console.log(routeName);

  if (loading) return <Loading />;

  return currentUser ? (
    <Navigate to="/dashboard" />
  ) : (
    <div
      className="w-full h-screen grid place-items-center bg-cover bg-no-repeat"
      style={{ backgroundImage: `url("/assets/CAUBPBG-01.jpg")` }}
    >
      <Outlet />
      <p className="text-center text-lg text-gray-600 capitalize">
        The Caribbean's Leading Energy Hub
        <br />
        Serving you since 1989!
      </p>
    </div>
  );
};

export default AuthWrapper;
