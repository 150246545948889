import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { fillRange } from "../../utils";
import { regex } from "_utils";
import useAuth from "hooks/useAuth";

type Inputs = {
  utility_id: string;
  data_year: string;
  no_of_customers_served: string;
  no_of_complaints: string;
  no_of_smart_meter_customers: string;
  number_of_customer_meters: string;
  customer_comment: string;
};

type IFormProps = {
  initialValues: any;
  utilities?: any;
  onEdit: any;
  onAdd: any;
};

export default function UtilityCustomerDataForm(props: IFormProps) {
  console.log(props);
  let { currentUser } = useAuth();
  const { utilities } = props;
  // const yearFilters: any[] = props.initialValues[1];
  const yearFilters = fillRange(new Date().getFullYear() - 1, 1980, -1);

  const schema = yup.object().shape({
    utility_id: yup.string().required("Field is required"),
    data_year: yup.string().required("Field is required"),
    no_of_customers_served: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .max(12, "Cannot be more than 12 digits")
      .required("Field is required"),
    no_of_complaints: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .max(6, "Cannot be more than 6 digits")
      .required("Field is required"),
    no_of_smart_meter_customers: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .max(6, "Cannot be more than 6 digits")
      .required("Field is required"),
    number_of_customer_meters: yup
      .string()
      .matches(regex.integer.validation, regex.integer.message)
      .max(12, "Cannot be more than 12 digits")
      .required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props.initialValues,
  });

  React.useEffect(() => {
    console.log(props.initialValues);
    if (props.initialValues) {
      reset(props.initialValues);
    } else {
      console.log(currentUser);
      if (currentUser.user_role.toLowerCase().includes("utility")) {
        reset({ utility_id: currentUser.utility_id });
      }
    }
  }, [props.initialValues]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (Object.values(errors).length < 1) {
      console.log("no errors");
      // return;
      if (props.initialValues) {
        props.onEdit(data);
      } else {
        props.onAdd(data);
      }

      // setFormSuccess(!formSuccess)
      //Clear the form fields using react-hook-form
      // props.toggleModal()
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="flex space-x-4">
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Utility
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("utility_id")}
            placeholder="Select a Utility"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {utilities
              ?.sort((a: any, b: any) =>
                a.utility_name.toLowerCase() > b.utility_name.toLowerCase()
                  ? 1
                  : b.utility_name.toLowerCase() > a.utility_name.toLowerCase()
                  ? -1
                  : 0
              )
              .map((option: any) => (
                <option key={option.utility_id} value={option.utility_id}>
                  {option.utility_name}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="utility_id"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="flex-1 text-gray-400 font-semibold text-sm">
          Choose Year
          <select
            className="w-full text-gray-600 bg-white rounded-lg p-2 border border-gray-300"
            {...register("data_year")}
            placeholder="Select a Year"
            disabled={props.initialValues ? true : false}
          >
            <option value="">--Select One--</option>
            {yearFilters.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="data_year"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Customers Served
          </span>
          <input
            type="number"
            {...register("no_of_customers_served")}
            placeholder="Type number"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="no_of_customers_served"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>

        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Complaints
          </span>
          <input
            type="number"
            {...register("no_of_complaints")}
            placeholder="Type number"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="no_of_complaints"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Smart Meter Customers
          </span>
          <input
            type="number"
            {...register("no_of_smart_meter_customers")}
            placeholder="Type number"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="no_of_smart_meter_customers"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
        <label className="block">
          <span className="text-sm font-medium text-gray-400">
            # Customer Meters
          </span>
          <input
            type="number"
            {...register("number_of_customer_meters")}
            placeholder="Type number"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />{" "}
          <ErrorMessage
            errors={errors}
            name="number_of_customer_meters"
            render={({ message }) => (
              <p className="text-xs text-red-500">{message}</p>
            )}
          />
        </label>
      </div>

      <label className="block">
        <span className="text-sm font-medium text-gray-400">Comments</span>
        <textarea
          {...register("customer_comment")}
          placeholder="Customer comments"
          // className="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        ></textarea>
        <ErrorMessage
          errors={errors}
          name={"customer_comment"}
          render={({ message }) => (
            <p className="text-xs text-red-500">{message}</p>
          )}
        />
      </label>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Save
      </button>
    </form>
  );
}
